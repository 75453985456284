import { EmbeddedScene, EmbeddedSceneState, SceneFlexLayout, SceneRouteMatch } from '@grafana/scenes';

import { EnvironmentFilterScene } from 'components/EnvironmentFilter/EnvironmentFilterScene';
import { FilterByScene } from 'components/FilterBy/FilterByScene';
import { PanelType } from 'constants/panels';
import { getServiceOverviewPanels } from 'panels/getServiceOverviewPanels';
import { getMetadataService } from 'services/MetadataService';
import { onRefreshMetadataBehavior } from 'utils/title';

import { ServiceEmbeddedScene } from '../service/components/ServiceEmbeddedScene';

export function makeMaxPanelScene(
  job: string,
  operation: string | undefined,
  panelType: PanelType
): (routeMatch: SceneRouteMatch<any>) => EmbeddedScene {
  const body = () => {
    const panel = getServiceOverviewPanels(job, operation)[panelType];

    return new SceneFlexLayout({
      direction: 'column',
      ySizing: 'fill',
      children: [
        new SceneFlexLayout({
          direction: 'row',
          wrap: 'wrap',
          children: [new EnvironmentFilterScene(), new FilterByScene()],
        }),

        panel(),
      ],
    });
  };

  return () =>
    new ServiceEmbeddedScene(
      {
        body: getMetadataService().isServiceStored(job) ? body() : new SceneFlexLayout({ children: [] }),

        $behaviors: [
          onRefreshMetadataBehavior<EmbeddedSceneState>(() => ({
            body: body(),
          })),
        ],
      },
      true
    ) as unknown as EmbeddedScene;
}
