import React from 'react';

import { SceneAppPage, SceneAppPageLike, SceneRouteMatch } from '@grafana/scenes';

import { PRESERVE_URL_KEYS, ROUTES } from 'constants/routing';
import { TrackedSceneAppPage } from 'faro/TrackedSceneAppPage';
import { makeMaxPanelScene } from 'modules/maxPanel/makeMaxPanelScene';
import { makeCommonControls } from 'utils/controls';
import { isPanelType, panelTypeToTitle } from 'utils/maxPanel';
import { makeTimeRange } from 'utils/timeRange';
import { makeVariables } from 'utils/variables';

import { MaxPanelTitle } from './maxPanelTitle';

export function makeOperationMaxPanelPage(
  job: string,
  encodedJob: string,
  operation?: string,
  encodedOperation?: string
): (routeMatch: SceneRouteMatch<any>, parent: SceneAppPageLike) => SceneAppPage {
  return (routeMatch, parent) => {
    const {
      params: { panel },
    } = routeMatch;
    const title = isPanelType(panel) ? panelTypeToTitle[panel] : '';

    return new TrackedSceneAppPage({
      title,
      renderTitle: () => (
        <MaxPanelTitle
          title={title}
          job={job}
          encodedJob={encodedJob}
          operation={operation}
          encodedOperation={encodedOperation}
        />
      ),
      viewName: 'maxOperationPanel',
      url: ROUTES.maxOperationPanel(encodedJob, encodedOperation, panel),
      preserveUrlKeys: PRESERVE_URL_KEYS,

      $variables: makeVariables({
        usePrometheus: true,
        useTempo: true,
        useEnvironmentFilter: true,
        job,
        operation,
        useGroupByFilter: true,
        useFilterBy: true,
      }),

      $timeRange: makeTimeRange(),

      controls: makeCommonControls(true),

      getParentPage: () => parent,
      getScene: makeMaxPanelScene(job, operation, panel),
    });
  };
}
