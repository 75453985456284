export function makeFeO11yLinks(
  clientInfo: Array<Record<string, string>>
): Record<string, Array<{ label: string; link?: string }>> {
  const items = clientInfo.map((labels) => {
    const { gf_feo11y_app_id, gf_feo11y_app_name } = labels;

    return {
      label: gf_feo11y_app_name,
      ...(gf_feo11y_app_id ? { link: `/a/grafana-kowalski-app/apps/${gf_feo11y_app_id}` } : {}),
    };
  });

  // Since we're only surfacing these links, let's not show the category, since it's internal anyways
  return items.length > 0 ? { '': items } : {};
}
