import { css } from '@emotion/css';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocalStorage, useSessionStorage } from 'react-use';

import { dateTime, GrafanaTheme2, LoadingState } from '@grafana/data';
import { SceneComponentProps, SceneObjectBase, SceneObjectState, SceneQueryRunner } from '@grafana/scenes';
import { Button, Checkbox, Field, LinkButton, Modal, Text, useStyles2 } from '@grafana/ui';

import { USAGE_DS } from 'constants/datasources';
import { USER } from 'constants/user';
import { getHostInfoService } from 'services/HostInfoService';
import { getInstanceService, initializeInstanceService } from 'services/InstanceService';
import { getPluginConfigService } from 'services/PluginConfigService';
import { isContractedOrg } from 'utils/org';
import { makeTimeRange } from 'utils/timeRange';
import { trackDefaultHostInfoModalState, trackHostInfoModalClosed } from 'utils/tracking';

interface HostModalState {
  dismissedAt?: string;
}

const STORAGE_KEY = 'app-observability-host-info-modal';

export interface HostModalSceneProps extends SceneObjectState {
  loading?: boolean;
  hasHostData?: boolean;
}

export class HostModalScene extends SceneObjectBase<HostModalSceneProps> {
  static Component = HostModal;

  constructor() {
    super({ loading: true, hasHostData: true });

    const sceneQueryRunner = new SceneQueryRunner({
      $timeRange: makeTimeRange(),
      datasource: USAGE_DS,
      queries: [],
    });

    this.addActivationHandler(() => {
      sceneQueryRunner.subscribeToState((newState) => {
        if (newState.data?.state === LoadingState.Done) {
          if (newState.data.series.length === 0) {
            this.setState({ hasHostData: false });
            return;
          }

          const field = newState.data.series.at(0)?.fields.find((field) => field.name === 'Value');
          if (!field) {
            this.setState({ hasHostData: false });
            return;
          }

          this.setState({ hasHostData: field.values.length > 0 && field.values.some((value) => value > 0) });
        }
      });

      this.subscribeToState((newState, oldState) => {
        if (newState.hasHostData !== oldState.hasHostData) {
          getHostInfoService().hasHostData = !!newState.hasHostData;
        }
      });

      async function fetchInfo() {
        const instanceService = getInstanceService() ?? (await initializeInstanceService());
        const instance = instanceService.getInstance();
        if (!instance) {
          return;
        }

        sceneQueryRunner.setState({
          queries: [
            {
              refId: 'hostInfo',
              expr: `(grafanacloud_instance_active_traces_host_info_series{org_id="${instance.orgId}"} * on (id) group_left(name, org_name) topk(1, grafanacloud_instance_info{type="prometheus", org_id="${instance.orgId}", name="${instance.hmInstancePromName}"}) by (id, name)) or vector(0)`,
              instant: true,
            },
          ],
        });

        sceneQueryRunner.runQueries();
      }

      fetchInfo();
    });
  }
}

export function HostModal({ model }: SceneComponentProps<HostModalScene>) {
  const { hasHostData } = model.useState();
  const [hostModalState, setHostModalState] = useLocalStorage<HostModalState>(STORAGE_KEY);
  const [savedForCurrentSession, setSavedForCurrentSession] = useSessionStorage<boolean>(STORAGE_KEY, false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const shouldShow = useMemo(() => {
    const isContracted = isContractedOrg();

    if (getPluginConfigService().getPluginConfig().disableHostInfoAlert || !USER.ROLE_FLAG.ADMIN || isContracted) {
      return false;
    }

    const shouldShowBasedOnDismissState =
      hostModalState?.dismissedAt !== undefined
        ? !dateTime().isSame(dateTime(hostModalState.dismissedAt), 'day')
        : true;

    return !hasHostData && shouldShowBasedOnDismissState && !savedForCurrentSession;
  }, [hostModalState, savedForCurrentSession, hasHostData]);

  useEffect(() => {
    setIsOpen(shouldShow);
  }, [shouldShow]);

  const styles = useStyles2(getStyles);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const hostInfoService = getHostInfoService();
    if (hostInfoService.hasSubmittedDefaultValue) {
      return;
    }

    trackDefaultHostInfoModalState(hasHostData!, savedForCurrentSession, isOpen);
    hostInfoService.hasSubmittedDefaultValue = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = (button: 'docs' | 'dismiss') => {
    setIsOpen(false);

    // Always override session storage value
    setHostModalState({
      ...(isChecked ? { dismissedAt: dateTime().toISOString() } : {}),
    });

    // Save current dismiss state to session storage
    // Only when user does not set the checkbox
    if (!isChecked) {
      setSavedForCurrentSession(true);
    }

    trackHostInfoModalClosed(isChecked, button);
  };

  return (
    <Modal
      data-testid="host-info-modal"
      title="Update required"
      isOpen={isOpen}
      onDismiss={() => {
        onClose('dismiss');
      }}
    >
      <Text element="p" color="secondary">
        From August 1, 2024, some changes to the way you send data are required in order to continue using Application
        Observability. Check the documentation to learn more.
      </Text>

      <br />

      <div
        onClick={() => {
          setIsChecked(!isChecked);
        }}
        className={styles.checkboxContainer}
      >
        <Field horizontal className={styles.field}>
          <Checkbox value={isChecked} onClick={(evt) => evt.stopPropagation()} data-cy="host-info-save-for-the-day" />
        </Field>
        <Text color="secondary">Don&apos;t show this message again for today</Text>
      </div>

      <Modal.ButtonRow>
        <Button
          data-cy="host-info-modal-cancel"
          variant="secondary"
          onClick={() => {
            onClose('dismiss');
          }}
        >
          Dismiss
        </Button>

        <LinkButton
          icon="external-link-alt"
          role="link"
          href="https://grafana.com/docs/grafana-cloud/monitor-applications/application-observability/setup/pricing"
          target="_blank"
          onClick={() => {
            onClose('docs');
          }}
        >
          Open documentation
        </LinkButton>
      </Modal.ButtonRow>
    </Modal>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    checkboxContainer: css`
      cursor: pointer;
      display: flex;
      gap: ${theme.spacing(1.5)};
    `,
    field: css`
      margin-bottom: 0;
    `,
  };
}
