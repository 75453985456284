import { ConstantVariable, VariableValue } from '@grafana/scenes';

import { escapeBackslash, escapeDoubleQuotes } from 'utils/format';

export class EscapedConstantVariable extends ConstantVariable {
  override getValue(): VariableValue {
    const value = (Array.isArray(this.state.value) ? this.state.value : [this.state.value]) as string[];

    // We need to escape backslash first, then the double quotes
    return value.map((v) => escapeDoubleQuotes(escapeBackslash(v)));
  }
}
