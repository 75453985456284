import { FilterPolicy } from 'types/overrides';

export const OVERRIDES_PROCESSORS = ['span-metrics', 'service-graphs'];
export const OVERRIDES_SPAN_METRICS_DIMENSIONS = [
  'cloud.availability_zone',
  'cloud.region',
  'deployment.environment',
  'k8s.cluster.name',
  'k8s.namespace.name',
  'service.namespace',
  'service.version',
];
export const OVERRIDES_SERVICE_GRAPHS_DIMENSIONS = [
  'cloud.availability_zone',
  'cloud.region',
  'deployment.environment',
  'k8s.cluster.name',
  'k8s.namespace.name',
  'service.namespace',
  'service.version',
];
export const OVERRIDES_SERVICE_GRAPHS_PEER_ATTRIBUTES = [
  'peer.service',
  'db.name',
  'db.system',
  'messaging.system',
  'db.url',
  'net.peer.name',
];
export const OVERRIDES_HISTOGRAM_BUCKETS = [
  0, 0.005, 0.01, 0.025, 0.05, 0.075, 0.1, 0.25, 0.5, 0.75, 1, 2.5, 5, 7.5, 10,
];

export const TARGET_INFO_EXCLUDED_DIMENSIONS = [
  'k8s.pod.start_time',
  'os.description',
  'os.type',
  'process.command_args',
  'process.executable.path',
  'process.pid',
  'process.runtime.description',
  'process.runtime.name',
  'process.runtime.version',
];

export const COLLECTION_INTERVAL = '60s';

export const OVERRIDES_SPANMETRICS_FILTER_POLICIES: (withClient?: boolean) => FilterPolicy[] = (withClient = false) => {
  return [
    {
      include: {
        match_type: 'regex',
        attributes: [
          {
            key: 'kind',
            value: withClient ? 'SPAN_KIND_(SERVER|CONSUMER|CLIENT|PRODUCER)' : 'SPAN_KIND_(SERVER|CONSUMER)',
          },
        ],
      },
    },
  ];
};
