import { EmbeddedScene, SceneFlexLayout, SceneRouteMatch } from '@grafana/scenes';

import { makeTimeRange } from 'utils/timeRange';

import { ServiceEmbeddedScene } from './ServiceEmbeddedScene';

export function makeServiceMetadataLoaderScene(
  job: string,
  operation?: string
): (routeMatch?: SceneRouteMatch<any>) => EmbeddedScene {
  return () => {
    return new ServiceEmbeddedScene({
      $timeRange: makeTimeRange(),

      body: new SceneFlexLayout({
        direction: 'column',
        children: [],
      }),
    }) as unknown as EmbeddedScene;
  };
}
