import { DataTransformerID, FieldConfigProperty, FieldMatcherID } from '@grafana/data';
import { locationService } from '@grafana/runtime';
import { SceneDataTransformer } from '@grafana/scenes';
import { FieldColorModeId } from '@grafana/schema';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { PromSceneQueryRunner } from 'components/PromSceneQueryRunner';
import { MIN_INTERVAL, PROMETHEUS_DS } from 'constants/datasources';
import { DEFAULT_SPARKLINE_CELL_OPTIONS, PanelType } from 'constants/panels';
import { ROUTES } from 'constants/routing';
import { MaximizePanelButton } from 'panels/maximizePanelButton';
import { makeRedQueriesInstrumented } from 'queries/makeRedQueriesInstrumented';
import { getMetadataService } from 'services/MetadataService';
import { getRenderService } from 'services/RenderService';
import { DURATION_COLOR, ERROR_COLOR, RATE_COLOR } from 'utils/colors';
import { isMaxPanelRoute } from 'utils/maxPanel';
import { encodeParameter } from 'utils/routing';

export function makeServiceOverviewOperationsPanel(job: string, operation?: string): ExplorablePanel {
  const { pathname } = locationService.getLocation();
  const isRunningAsExtension = getRenderService().isRunningAsExtension();
  const { rate, errors, p95 } = makeRedQueriesInstrumented({
    groupBy: 'span_name',
    withJob: true,
    serviceType: getMetadataService().getIsClientOnly(job) ? 'client' : undefined,
  });

  return new ExplorablePanel({
    title: 'Operations',

    trackingSection: 'service',
    trackingPage: 'overview',
    trackingPanel: 'operations',

    pluginId: 'table',

    headerActions: [new MaximizePanelButton(PanelType.OPERATIONS, job, operation)],

    fieldConfig: {
      defaults: {
        links: [
          {
            title: '',
            url: `${ROUTES.operation(
              encodeParameter(job),
              '${__data.fields.span_name:path}'
            )}?\${__url_time_range}&\${__all_variables}`,
            ...(isRunningAsExtension ? { targetBlank: true } : {}),
          },
        ],
      },
      overrides: [
        {
          matcher: {
            id: FieldMatcherID.byName,
            options: 'Rate',
          },
          properties: [
            {
              id: 'custom.cellOptions',
              value: DEFAULT_SPARKLINE_CELL_OPTIONS,
            },
            {
              id: FieldConfigProperty.Unit,
              value: 'reqps',
            },
            {
              id: FieldConfigProperty.Decimals,
              value: 2,
            },
            {
              id: FieldConfigProperty.Color,
              value: {
                mode: FieldColorModeId.Fixed,
                fixedColor: RATE_COLOR,
              },
            },
          ],
        },
        {
          matcher: {
            id: FieldMatcherID.byName,
            options: 'Errors',
          },
          properties: [
            {
              id: 'custom.cellOptions',
              value: DEFAULT_SPARKLINE_CELL_OPTIONS,
            },
            {
              id: FieldConfigProperty.Unit,
              value: 'percentunit',
            },
            {
              id: FieldConfigProperty.Min,
              value: 0,
            },
            {
              id: FieldConfigProperty.NoValue,
              value: '0%',
            },
            {
              id: FieldConfigProperty.Color,
              value: {
                mode: FieldColorModeId.Fixed,
                fixedColor: ERROR_COLOR,
              },
            },
          ],
        },
        {
          matcher: {
            id: FieldMatcherID.byName,
            options: 'Duration, p95',
          },
          properties: [
            {
              id: 'custom.cellOptions',
              value: DEFAULT_SPARKLINE_CELL_OPTIONS,
            },
            {
              id: FieldConfigProperty.Unit,
              value: 's',
            },
            {
              id: FieldConfigProperty.Decimals,
              value: 2,
            },
            {
              id: FieldConfigProperty.Color,
              value: {
                mode: FieldColorModeId.Fixed,
                fixedColor: DURATION_COLOR,
              },
            },
          ],
        },
        {
          matcher: {
            id: FieldMatcherID.byName,
            options: 'Operation',
          },
          properties: [
            {
              id: 'custom.filterable',
              value: true,
            },
          ],
        },
      ],
    },

    options: {
      footer: {
        enablePagination: true,
      },
      cellHeight: isMaxPanelRoute(pathname) ? 'lg' : 'sm',
    },

    $data: new SceneDataTransformer({
      transformations: [
        {
          id: DataTransformerID.timeSeriesTable,
          options: {},
        },
        {
          id: DataTransformerID.joinByField,
          options: {
            byField: 'span_name',
            mode: 'outer',
          },
        },
        {
          id: DataTransformerID.organize,
          options: {
            excludeByName: {
              Time: true,
              'Time 1': true,
              'Time 2': true,
              'Time 3': true,
            },
            indexByName: {},
            renameByName: {
              'Trend #serviceOverviewOperationsRate': 'Rate',
              'Trend #serviceOverviewOperationsErrors': 'Errors',
              'Trend #serviceOverviewOperationsDuration': 'Duration, p95',
              span_name: 'Operation',
            },
          },
        },
      ],

      $data: new PromSceneQueryRunner({
        datasource: PROMETHEUS_DS,
        maxDataPoints: 100,
        minInterval: MIN_INTERVAL,
        queries: [
          {
            expr: p95.query,
            refId: 'serviceOverviewOperationsDuration',
            timeRangeCompare: false,
          },
          {
            expr: errors.query,
            refId: 'serviceOverviewOperationsErrors',
            timeRangeCompare: false,
          },
          {
            expr: rate.query,
            refId: 'serviceOverviewOperationsRate',
            timeRangeCompare: false,
          },
        ],
      }),
    }),
  });
}
