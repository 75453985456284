import { PRESERVE_URL_KEYS, ROUTE_DEFINITIONS, ROUTES } from 'constants/routing';
import { TrackedSceneAppPage } from 'faro/TrackedSceneAppPage';

import { makeServiceJvmScene } from './makeServiceJvmScene';

export function makeServiceJvmPage(job: string, encodedJob: string): TrackedSceneAppPage {
  return new TrackedSceneAppPage({
    viewName: 'service-jvm',
    title: 'JVM',
    routePath: ROUTE_DEFINITIONS.java,
    url: ROUTES.java(encodedJob),
    preserveUrlKeys: PRESERVE_URL_KEYS,
    getScene: makeServiceJvmScene(job),
  });
}
