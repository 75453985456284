import { behaviors, EmbeddedScene, SceneFlexItem, SceneFlexLayout, SceneRouteMatch } from '@grafana/scenes';
import { DashboardCursorSync } from '@grafana/schema';

import { EnvironmentFilterScene } from 'components/EnvironmentFilter/EnvironmentFilterScene';
import { FilterByScene } from 'components/FilterBy/FilterByScene';
import { GroupByScene } from 'components/GroupBy/GroupByScene';
import { MIN_PANEL_HEIGHT, MIN_TABLE_PANEL_HEIGHT } from 'constants/styles';
import { ServiceEmbeddedScene } from 'modules/service/components/ServiceEmbeddedScene';
import { getServiceOverviewPanels } from 'panels/getServiceOverviewPanels';

export function makeOperationOverviewScene(
  job: string,
  operation: string
): (routeMatch: SceneRouteMatch<any>) => EmbeddedScene {
  return () => {
    const panels = getServiceOverviewPanels(job, operation);

    return new ServiceEmbeddedScene({
      $behaviors: [new behaviors.CursorSync({ key: 'sync1', sync: DashboardCursorSync.Tooltip })],

      body: new SceneFlexLayout({
        direction: 'column',
        ySizing: 'content',
        children: [
          new SceneFlexLayout({
            ySizing: 'content',
            direction: 'row',
            wrap: 'wrap',
            children: [new GroupByScene(), new EnvironmentFilterScene(), new FilterByScene()],
          }),

          new SceneFlexLayout({
            direction: 'row',
            minHeight: MIN_PANEL_HEIGHT,
            children: [
              new SceneFlexItem({ body: panels.duration() }),
              new SceneFlexItem({ body: panels.errors() }),
              new SceneFlexItem({ body: panels.rate() }),
            ],
          }),

          new SceneFlexLayout({
            direction: 'row',
            minHeight: MIN_TABLE_PANEL_HEIGHT,
            children: [new SceneFlexItem({ body: panels.durationDistribution() })],
          }),
        ],
      }),
    }) as unknown as EmbeddedScene;
  };
}
