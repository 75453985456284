import { EmbeddedScene, SceneControlsSpacer, SceneFlexLayout, SceneObjectRef, SceneRouteMatch } from '@grafana/scenes';

import { EnvironmentFilterScene } from 'components/EnvironmentFilter/EnvironmentFilterScene';
import { ServiceEmbeddedScene } from 'modules/service/components/ServiceEmbeddedScene';
import { makeVariables } from 'utils/variables';

import { LiveTailControl } from './components/LiveTailControl';
import { LogModeSwitch } from './components/LogModeSwitch';
import { RunQueryControl } from './components/RunQueryControl';
import { ServiceLogsScene } from './components/ServiceLogsScene';

export function makeServiceLogsScene(
  job: string,
  encodedJob: string
): (routeMatch: SceneRouteMatch<any>) => EmbeddedScene {
  return () => {
    const logsScene = new ServiceLogsScene({ job, encodedJob });
    const logsSceneRef = new SceneObjectRef(logsScene);

    return new ServiceEmbeddedScene({
      $variables: makeVariables({ useLoki: true }),
      controls: [
        new SceneControlsSpacer(),
        new RunQueryControl({ logsSceneRef }),
        new LiveTailControl({ logsSceneRef }),
      ],

      body: new SceneFlexLayout({
        direction: 'column',
        children: [
          new SceneFlexLayout({
            direction: 'row',
            children: [new EnvironmentFilterScene(), new SceneControlsSpacer(), new LogModeSwitch({ logsSceneRef })],
          }),
          logsScene,
        ],
      }),
    }) as unknown as EmbeddedScene;
  };
}
