import { css, cx } from '@emotion/css';
import React from 'react';
import { Link } from 'react-router-dom';

import { GrafanaTheme2 } from '@grafana/data';
import { SceneComponentProps, sceneGraph } from '@grafana/scenes';
import { LoadingPlaceholder, MultiSelect, Text, Tooltip, useStyles2 } from '@grafana/ui';

import { EnvironmentValueVariable } from 'components/EnvironmentValueVariable';
import { FilterLabel } from 'components/FilterLabel';
import { ROUTES } from 'constants/routing';
import { getDefaultEnvironmentValueService } from 'services/DefaultEnvironmentValueService';
import { normalizeLabel } from 'utils/format';

export function EnvironmentFilter({ model }: SceneComponentProps<EnvironmentValueVariable>) {
  const styles = useStyles2(getStyles);

  const { loading } = model.useState();

  if (loading) {
    return <LoadingPlaceholder className={styles.placeholder} text="Loading attribute values" />;
  }

  const environmentAttribute = normalizeLabel(sceneGraph.interpolate(model, '${environmentAttribute}'));

  const values = model.state.value as string[];
  const hasAllValue = model.hasAllValue();
  const options = model.getOptionsForSelect();
  const disabled = options.length <= (model.includeNoneOption ? 2 : 1);
  const defaultEnvironmentValueService = getDefaultEnvironmentValueService();
  const defaultIsAutomatic = defaultEnvironmentValueService.defaultIsAutomatic;
  const isManuallyChanged = defaultEnvironmentValueService.isManuallyChanged;

  const select = (
    <MultiSelect
      className={cx({ [styles.hideClearValue]: hasAllValue, [styles.disabled]: disabled })}
      id="environmentFilter"
      placeholder="Select attribute filter"
      width="auto"
      options={options}
      disabled={disabled}
      closeMenuOnSelect={false}
      tabSelectsValue={false}
      value={values}
      formatOptionLabel={({ label, value }) => {
        if (!defaultIsAutomatic || isManuallyChanged || values.length > 1) {
          return label;
        }

        const isValueSelected = values.find((currentValue) => value === currentValue);

        if (!isValueSelected) {
          return label;
        }

        const isDefaultValue = defaultEnvironmentValueService.findDefaultValue(values) === value;

        if (!isDefaultValue) {
          return label;
        }

        return `${label} (auto-selected)`;
      }}
      onChange={(values) => {
        model.changeValueTo(
          values.map(({ value }) => value!),
          values.map(({ label }) => label!),
          true
        );
      }}
    />
  );

  return (
    <div className={styles.container}>
      <Tooltip content={environmentAttribute || 'Environment Variable'}>
        <FilterLabel dataCy="environmentFilterLabel">Environment</FilterLabel>
      </Tooltip>

      {environmentAttribute ? (
        disabled ? (
          <Tooltip content={<Text>No available values found for {environmentAttribute}</Text>}>
            <div>{select}</div>
          </Tooltip>
        ) : (
          <>{select}</>
        )
      ) : (
        <Tooltip
          content={
            <>
              No environment attribute selected, you can configure this in the semantics tab at the{' '}
              <Link to={ROUTES.settingsConfiguration()}>Configuration page</Link>
            </>
          }
          interactive
        >
          <div>{select}</div>
        </Tooltip>
      )}
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    hideClearValue: css`
      label: hideClearValue;

      & button::before,
      & button[aria-label='Remove'] {
        display: none;
      }

      & [class*='grafana-select-multi-value-container'] {
        background: ${theme.components.input.background};
      }
    `,
    disabled: css`
      & [class*='grafana-select-multi-value-container'] {
        background: ${theme.colors.background.secondary};
      }

      &:hover {
        border-color: ${theme.colors.border.weak};
      }
    `,
    placeholder: css`
      margin-bottom: 0;
    `,
    container: css`
      display: flex;
      align-items: center;
      flex-shrink: 0;
    `,
  };
}
