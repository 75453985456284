import { css } from '@emotion/css';
import React, { ReactNode } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Text, useStyles2 } from '@grafana/ui';

import stepOne from 'img/icons/step-one.svg';
import stepTwo from 'img/icons/step-two.svg';

export interface GetStartedStepProps {
  no: number;
  title: string;
  description: string;
  button: ReactNode;
}

const stepImages = [stepOne, stepTwo];

export const GetStartedStep = ({ no, title, description, button }: GetStartedStepProps) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.container}>
      <div className={styles.no}>
        <img src={stepImages[no - 1]} height={32} alt={`Step ${no}`} />
      </div>

      <div className={styles.textContent}>
        <Text variant="h4" textAlignment="start">
          {title}
        </Text>
        <Text color="secondary" textAlignment="start">
          {description}
        </Text>
      </div>

      {button}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: ${theme.spacing(2.5)};
    max-width: 360px;

    background-color: ${theme.colors.background.secondary};
    padding: ${theme.spacing(4)};
    border-radius: ${theme.shape.radius.default};
  `,

  no: css`
    font-size: 38px;
    font-weight: 900;
    line-height: 1;
  `,

  textContent: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1.5)};
  `,
});
