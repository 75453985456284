import { Unsubscribable } from 'rxjs';

import { rangeUtil } from '@grafana/data';
import { sceneGraph, SceneQueryRunner, SceneStateChangedHandler, SceneTimeRangeLike } from '@grafana/scenes';

import { PROMETHEUS_DS_TYPE } from 'constants/variables';
import { getDataSourceService } from 'services/DataSourceService';
import { DataQueryExtended } from 'types/queries';

export type PromSceneQueryRunnerState = ConstructorParameters<typeof SceneQueryRunner>[0] & {
  queriesWithThresholds?: DataQueryExtended[];
};

/**
 * In order for our queries to behave the same way as they do in explore, we
 * need to attach the `interval` and `intervalMs` attributes to the each query
 * itself, it's not enough to add it to the request (which is the way that
 * scenes works).
 * This is only necessary for Prometheus queries, since it's an implementation
 * detail of how the Prometheus datasource works.
 */
export class PromSceneQueryRunner extends SceneQueryRunner {
  // bunch of silly overrides to extend state type when SceneQueryRunner is not generic
  constructor(state: PromSceneQueryRunnerState) {
    super({ ...state });
  }

  get state(): PromSceneQueryRunnerState {
    return super.state as PromSceneQueryRunnerState;
  }

  setState(update: Partial<PromSceneQueryRunnerState>): void {
    return super.setState(update);
  }

  subscribeToState(handler: SceneStateChangedHandler<PromSceneQueryRunnerState>): Unsubscribable {
    return super.subscribeToState(handler);
  }

  override runQueries(): void {
    const timeRange = sceneGraph.getTimeRange(this);

    this.prepareQueries(timeRange);

    super.runQueries();
  }

  private prepareQueries(timeRange: SceneTimeRangeLike) {
    const { minInterval, queries } = this.state;
    const ds = getDataSourceService().getSelectedDataSource(PROMETHEUS_DS_TYPE);

    const lowerIntervalLimit = minInterval ? minInterval : (ds?.jsonData as any)?.minInterval;
    const norm = rangeUtil.calculateInterval(timeRange.state.value, this._getMaxDataPoints(), lowerIntervalLimit);

    this.setState({
      queries: queries.map((query) => ({
        ...query,
        interval: query.interval ?? norm.interval,
        intervalMs: query.intervalMs ?? norm.intervalMs,
      })),
    });
  }

  private _getMaxDataPoints() {
    if (this.state.maxDataPoints) {
      return this.state.maxDataPoints;
    }

    // @ts-expect-error: accessing private variable
    return this.state.maxDataPointsFromWidth ? (this._containerWidth ?? 500) : 500;
  }
}
