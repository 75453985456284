import { AGGREGATED_LABEL_VALUE } from 'constants/query';
import { DEFAULT_ENVIRONMENT_ATTRIBUTE } from 'constants/semantics';
import { PROMETHEUS_DS_TYPE } from 'constants/variables';
import { deleteRulerRulesGroup, fetchRulerRulesGroup, setRulerRuleGroup } from 'modules/alerting/api/ruler';
import { PostableRuleDTO, PostableRulerRuleGroupDTO } from 'modules/alerting/types/alerting-dto';
import { getEnvironmentAttribute } from 'utils/environmentFilter';
import { dotToDash } from 'utils/format';
import { log, logError } from 'utils/logging';
import { getMetricName } from 'utils/semantics';

import { getDataSourceService } from './DataSourceService';
import { getPluginConfigService } from './PluginConfigService';

type AGGREGATION = 'average' | 'p95' | 'p99';

const APP_O11Y_NAMESPACE = 'app-o11y';

// adding a small offset to account for metrics aggregation delay
const AM_DELAY_OFFSET = '1m';

const SUBQUERY_RESOLUTION = '1m';

const OFFSET_DAY = '23h30m';
const OFFSET_WEEK = '167h30m';
const OFFSET_DEV = '1m';

const RATE_INTERVAL = '5m';

function getGroups(environmentAttribute: string = DEFAULT_ENVIRONMENT_ATTRIBUTE): PostableRulerRuleGroupDTO[] {
  const environmentAttributeLabel = dotToDash(environmentAttribute);
  const { baselineDev, baselineRulePrefix, baselineAggregateAllEnvs, baselineDurationAggregation } =
    getPluginConfigService().getPluginConfig();

  const PREFIX = baselineRulePrefix ?? 'appo11y';

  const DURATION_AGGREGATION = (baselineDurationAggregation ?? 'p95') as 'all' | AGGREGATION;

  const COUNT_METRIC = getMetricName('spanMetricsCount');
  const SUM_METRIC = getMetricName('spanMetricsSum');
  const BUCKET_METRIC = getMetricName('spanMetricsBucket');

  const GROUPS = baselineService.getGroupNames();

  // aggregated queries for rate, duration and errros

  function baseRate(sumByLabels: string[]): string {
    return `
      sum by(${sumByLabels.join(
        ', '
      )}) (rate(${COUNT_METRIC}{span_kind=~"SPAN_KIND_SERVER|SPAN_KIND_CONSUMER"}[${RATE_INTERVAL}] offset ${AM_DELAY_OFFSET}))
    `;
  }

  function baseDurationHistogram(pval: '0.95' | '0.99', sumByLabels: string[]): string {
    return `histogram_quantile(${pval}, sum by (${sumByLabels.join(
      ', '
    )}, le) (rate(${BUCKET_METRIC}{span_kind=~"SPAN_KIND_SERVER|SPAN_KIND_CONSUMER"} [${RATE_INTERVAL}] offset ${AM_DELAY_OFFSET})))`;
  }

  function baseDurationAverage(sumByLabels: string[]): string {
    return `sum by (${sumByLabels.join(
      ', '
    )}) (rate(${SUM_METRIC}{span_kind=~"SPAN_KIND_SERVER|SPAN_KIND_CONSUMER"} [${RATE_INTERVAL}] offset ${AM_DELAY_OFFSET}))
    / 
    (sum by (${sumByLabels.join(
      ', '
    )}) (rate(${COUNT_METRIC}{span_kind=~"SPAN_KIND_SERVER|SPAN_KIND_CONSUMER"} [${RATE_INTERVAL}] offset ${AM_DELAY_OFFSET})))`;
  }

  function baseErrorRatio(sumByLabels: string[]): string {
    return `(
      sum(rate(${COUNT_METRIC}{span_kind=~"SPAN_KIND_SERVER|SPAN_KIND_CONSUMER", status_code="STATUS_CODE_ERROR"} [${RATE_INTERVAL}] offset ${AM_DELAY_OFFSET})) by (${sumByLabels.join(
        ', '
      )})
      OR 
      sum(rate(${COUNT_METRIC}{span_kind=~"SPAN_KIND_SERVER|SPAN_KIND_CONSUMER"} [${RATE_INTERVAL}] offset ${AM_DELAY_OFFSET})) by (${sumByLabels.join(
        ', '
      )}) * 0
    ) 
    / sum(rate(${COUNT_METRIC}{span_kind=~"SPAN_KIND_SERVER|SPAN_KIND_CONSUMER"} [${RATE_INTERVAL}] offset ${AM_DELAY_OFFSET})) by (${sumByLabels.join(
      ', '
    )})`;
  }

  // create recording rules for different levels of aggregation.
  function expandToAggregations(metricName: string, exprFactory: (sumByLabels: string[]) => string): PostableRuleDTO[] {
    const rules: PostableRuleDTO[] = [
      {
        record: metricName,
        expr: exprFactory(['job', 'span_name', environmentAttributeLabel]),
      },
      {
        record: metricName,
        expr: exprFactory(['job', environmentAttributeLabel]),
        labels: {
          span_name: AGGREGATED_LABEL_VALUE,
        },
      },
    ];

    if (baselineAggregateAllEnvs) {
      rules.push(
        {
          record: metricName,
          expr: exprFactory(['job']),
          labels: {
            span_name: AGGREGATED_LABEL_VALUE,
            [environmentAttributeLabel]: AGGREGATED_LABEL_VALUE,
          },
        },
        {
          record: metricName,
          expr: exprFactory(['job', 'span_name']),
          labels: {
            [environmentAttributeLabel]: AGGREGATED_LABEL_VALUE,
          },
        }
      );
    }

    return rules;
  }

  const durationAggregations =
    DURATION_AGGREGATION === 'all' ? (['p95', 'p99', 'average'] as AGGREGATION[]) : [DURATION_AGGREGATION];

  return [
    {
      name: GROUPS.CONSTANTS,
      rules: [
        {
          record: `${PREFIX}:request:rate:threshold_by_stddev`,
          expr: '2',
        },
        {
          record: `${PREFIX}:request:rate:threshold_margin`,
          expr: '0.5',
        },
        {
          record: `${PREFIX}:request:rate:sparse_threshold`,
          expr: String(5 / 60),
        },
        {
          record: `${PREFIX}:request:stddev:threshold_by_covar`,
          expr: '0.5',
        },
        {
          record: `${PREFIX}:duration:stddev:threshold_by_covar`,
          expr: '0.5',
        },
        {
          record: `${PREFIX}:duration:threshold_by_stddev`,
          expr: '2',
        },
        {
          record: `${PREFIX}:duration:threshold_margin`,
          expr: '0.5',
        },
        {
          record: `${PREFIX}:duration:threshold`,
          expr: '3',
        },
        {
          record: `${PREFIX}:error:ratio:threshold_by_stddev`,
          expr: '2',
        },
        {
          record: `${PREFIX}:error:ratio:threshold_margin`,
          expr: '0.2',
        },
      ],
    },
    {
      name: GROUPS.REQUEST,
      rules: [
        ...expandToAggregations(`${PREFIX}:request:rate5m`, baseRate),

        {
          record: `${PREFIX}:request:rate5m:select`,
          expr: `
          ${PREFIX}:request:rate5m > 0
          and
          (
            ${
              baselineDev
                ? `max_over_time(${PREFIX}:request:rate5m[1h] offset ${OFFSET_DEV}) > 0
            or`
                : ''
            }
            max_over_time(${PREFIX}:request:rate5m[1h] offset ${OFFSET_DAY}) > 0
            or
            max_over_time(${PREFIX}:request:rate5m[1h] offset ${OFFSET_WEEK}) > 0
          )
          unless
          avg_over_time(${PREFIX}:request:rate5m[1h]) < on() group_left ${PREFIX}:request:rate:sparse_threshold
          `,
        },
        {
          record: `${PREFIX}:request:rate5m:prediction_st`,
          expr: `avg_over_time(${PREFIX}:request:rate5m:select[1h])`,
        },
        {
          record: `${PREFIX}:request:rate5m:stddev_st`,
          expr: `avg_over_time(${PREFIX}:request:rate5m:stddev_st_1h[26h])`,
        },
        {
          record: `${PREFIX}:request:rate5m:anomaly_lower_threshold`,
          expr: `clamp_min(
                  min without(prediction_type) (
                    label_replace(
                      last_over_time(${PREFIX}:request:rate5m:prediction_st[2m]) - last_over_time(${PREFIX}:request:rate5m:stddev_st[2m]) * on() group_left ${PREFIX}:request:rate:threshold_by_stddev,
                      "prediction_type", "short_term", "", ""
                    )
                    or
                    label_replace(
                      last_over_time(${PREFIX}:request:rate5m:prediction_st[2m]) - last_over_time(${PREFIX}:request:rate5m:prediction_st[2m]) * on() group_left ${PREFIX}:request:rate:threshold_margin,
                      "prediction_type", "margin", "", ""
                    )
                    or
                    last_over_time(
                      (
                        min without(look_back) (
                          label_replace(
                            avg_over_time(${PREFIX}:request:rate5m:select[1h] offset ${OFFSET_WEEK})
                            -
                            stddev_over_time(${PREFIX}:request:rate5m:select[1h] offset ${OFFSET_WEEK})
                            * on() group_left ${PREFIX}:request:rate:threshold_by_stddev,
                            "look_back", "1w", "", ""
                          )
                          or
                          label_replace(
                            avg_over_time(${PREFIX}:request:rate5m:select[1h] offset ${OFFSET_DAY})
                            -
                            stddev_over_time(${PREFIX}:request:rate5m:select[1h] offset ${OFFSET_DAY})
                            * on() group_left ${PREFIX}:request:rate:threshold_by_stddev,
                            "look_back", "1d", "", ""
                          )
                        )
                      )
                    [10m:${SUBQUERY_RESOLUTION}])
                  ),
                  0
                )`,
        },
        {
          record: `${PREFIX}:request:rate5m:anomaly_upper_threshold`,
          expr: `max without(prediction_type) (
                  label_replace(
                    last_over_time(${PREFIX}:request:rate5m:prediction_st[2m]) + last_over_time(${PREFIX}:request:rate5m:stddev_st[2m]) * on() group_left ${PREFIX}:request:rate:threshold_by_stddev,
                    "prediction_type", "short_term", "", ""
                  )
                  or
                  label_replace(
                    last_over_time(${PREFIX}:request:rate5m:prediction_st[2m]) + last_over_time(${PREFIX}:request:rate5m:prediction_st[2m]) * on() group_left ${PREFIX}:request:rate:threshold_margin,
                    "prediction_type", "margin", "", ""
                  )
                  or
                  last_over_time(
                    (
                      max without(look_back) (
                        label_replace(
                          avg_over_time(${PREFIX}:request:rate5m:select[1h] offset ${OFFSET_WEEK})
                          +
                          stddev_over_time(${PREFIX}:request:rate5m:select[1h] offset ${OFFSET_WEEK})
                          * on() group_left ${PREFIX}:request:rate:threshold_by_stddev,
                          "look_back", "1w", "", ""
                        )
                        or
                        label_replace(
                          avg_over_time(${PREFIX}:request:rate5m:select[1h] offset ${OFFSET_DAY})
                          +
                          stddev_over_time(${PREFIX}:request:rate5m:select[1h] offset ${OFFSET_DAY})
                          * on() group_left ${PREFIX}:request:rate:threshold_by_stddev,
                          "look_back", "1d", "", ""
                        )
                      )
                    )
                  [10m:${SUBQUERY_RESOLUTION}])
                )`,
        },
      ],
    },
    // separate group as otherwise it breaches 20 rule per group limit.
    // hope that's ok :-)
    {
      name: GROUPS.DURATION_BASE,
      rules: [
        ...(['all', 'average'].includes(DURATION_AGGREGATION)
          ? expandToAggregations(`${PREFIX}:duration:average`, baseDurationAverage)
          : []),
        ...(['all', 'p95'].includes(DURATION_AGGREGATION)
          ? expandToAggregations(`${PREFIX}:duration:p95`, (sumByLabels) => baseDurationHistogram('0.95', sumByLabels))
          : []),
        ...(['all', 'p99'].includes(DURATION_AGGREGATION)
          ? expandToAggregations(`${PREFIX}:duration:p99`, (sumByLabels) => baseDurationHistogram('0.99', sumByLabels))
          : []),
      ],
    },
    {
      name: GROUPS.DURATION,
      rules: durationAggregations.flatMap((aggregation: AGGREGATION) => {
        return [
          {
            record: `${PREFIX}:duration:${aggregation}:prediction`,
            expr: `quantile_over_time(0.5, ${PREFIX}:duration:${aggregation}[1h]) unless (${PREFIX}:request:rate5m unless ${PREFIX}:request:rate5m:select)`,
          },
          {
            record: `${PREFIX}:duration:${aggregation}:stddev`,
            expr: `avg_over_time(${PREFIX}:duration:${aggregation}:stddev_1h[26h])`,
          },
          {
            record: `${PREFIX}:duration:${aggregation}:anomaly_lower_threshold`,
            expr: `
              clamp_min(
                min without(prediction_type)(
                  label_replace(
                    last_over_time(${PREFIX}:duration:${aggregation}:prediction[1m]) - last_over_time(${PREFIX}:duration:${aggregation}:stddev[1m]) * on() group_left ${PREFIX}:duration:threshold_by_stddev,
                    "prediction_type", "stddev", "", ""
                  )
                  or
                  label_replace(
                    last_over_time(${PREFIX}:duration:${aggregation}:prediction[1m]) - last_over_time(${PREFIX}:duration:${aggregation}:prediction[1m]) * on() group_left ${PREFIX}:duration:threshold_margin,
                    "prediction_type", "margin", "", ""
                  )
                ),
                0
              )
            `,
          },
          {
            record: `${PREFIX}:duration:${aggregation}:anomaly_upper_threshold`,
            expr: `
              max without(prediction_type)(
                label_replace(
                  last_over_time(${PREFIX}:duration:${aggregation}:prediction[1m]) + last_over_time(${PREFIX}:duration:${aggregation}:stddev[1m]) * on() group_left ${PREFIX}:duration:threshold_by_stddev,
                  "prediction_type", "stddev", "", ""
                )
                or
                label_replace(
                  last_over_time(${PREFIX}:duration:${aggregation}:prediction[1m]) + last_over_time(${PREFIX}:duration:${aggregation}:prediction[1m]) * on() group_left ${PREFIX}:duration:threshold_margin,
                  "prediction_type", "margin", "", ""
                )
              )`,
          },
        ];
      }),
    },
    {
      name: GROUPS.ERROR_RATIO,
      rules: [
        ...expandToAggregations(`${PREFIX}:error:ratio`, baseErrorRatio),

        {
          record: `${PREFIX}:error:ratio:prediction`,
          expr: `avg_over_time(${PREFIX}:error:ratio[1h])`,
        },
        {
          record: `${PREFIX}:error:ratio:stddev`,
          expr: `avg_over_time(${PREFIX}:error:ratio:stddev_1h[26h])`,
        },
        {
          record: `${PREFIX}:error:ratio:anomaly_upper_threshold`,
          expr: `
            clamp_max(
              max without(prediction_type) (
                label_replace(
                  last_over_time(${PREFIX}:error:ratio:prediction[1m]) + last_over_time(${PREFIX}:error:ratio:stddev[1m]) * on() group_left ${PREFIX}:error:ratio:threshold_by_stddev,
                  "prediction_type", "stddev", "", ""
                )
                or
                label_replace(
                  last_over_time(${PREFIX}:error:ratio:prediction[1m]) + last_over_time(${PREFIX}:error:ratio:prediction[1m]) * on() group_left ${PREFIX}:error:ratio:threshold_margin,
                  "prediction_type", "margin", "", ""
                )
              ),
              1
            )`,
        },
      ],
    },
    // special group with 5 min evaluation interval to contain rules whose output is used
    // in stdev calculation, which averages values over 26h period. Increasing eval period
    // reduces number of samples to process
    // context https://raintank-corp.slack.com/archives/C9KL8THCY/p1713867309877359
    {
      name: GROUPS.STDDEV_1h,
      interval: '5m',
      rules: [
        {
          record: `${PREFIX}:request:rate5m:stddev_st_1h`,
          expr: `stddev_over_time(${PREFIX}:request:rate5m:select[1h]) > ${PREFIX}:request:rate5m:prediction_st * on() group_left ${PREFIX}:request:stddev:threshold_by_covar`,
        },
        ...durationAggregations.flatMap((aggregation: AGGREGATION) => ({
          record: `${PREFIX}:duration:${aggregation}:stddev_1h`,
          expr: `
            stddev_over_time(${PREFIX}:duration:${aggregation}[1h]) unless (${PREFIX}:request:rate5m unless ${PREFIX}:request:rate5m:select)
            >
            avg_over_time(${PREFIX}:duration:${aggregation}[1h]) * on() group_left ${PREFIX}:duration:stddev:threshold_by_covar`,
        })),
        {
          record: `${PREFIX}:error:ratio:stddev_1h`,
          expr: `stddev_over_time(${PREFIX}:error:ratio[1h])`,
        },
      ],
    },
  ];
}

class BaselineService {
  async isBaselineActivated(datasourceUid: string): Promise<boolean> {
    try {
      const result = await fetchRulerRulesGroup(
        {
          dataSourceName: datasourceUid,
          apiVersion: 'config',
        },
        APP_O11Y_NAMESPACE,
        this.getGroupNames().REQUEST
      );
      return !!result;
    } catch (e) {
      if ((e as any).status === 404) {
        return false;
      }
      throw e;
    }
  }

  getGroups() {
    return getGroups(getEnvironmentAttribute());
  }

  async pushBaselineRules(datasourceUid: string): Promise<void> {
    try {
      log('activating baseline recording rules');
      await Promise.all(
        getGroups(getEnvironmentAttribute()).map((group) => {
          return setRulerRuleGroup(
            {
              dataSourceName: datasourceUid,
              apiVersion: 'config',
            },
            APP_O11Y_NAMESPACE,
            group
          );
        })
      );
      log('baseline rules successfully activated');
    } catch (e) {
      logError(e);
      log('error activated baseline rules, deactivating...');
      try {
        await this.deactivateBaseline(datasourceUid);
      } catch (e) {
        // ignore
      }
      throw e;
    }
  }

  async pushBaselineRulesIfActivated(): Promise<void> {
    const datasourceUid = getDataSourceService().getSelectedDataSourceUID(PROMETHEUS_DS_TYPE);
    if (datasourceUid) {
      if (await this.isBaselineActivated(datasourceUid)) {
        await this.pushBaselineRules(datasourceUid);
      }
    }
  }

  async deactivateBaseline(datasourceUid: string): Promise<void> {
    log('deactivating baseline rules');
    try {
      await Promise.all(
        Object.values(this.getGroupNames()).map((groupName) =>
          deleteRulerRulesGroup(
            {
              dataSourceName: datasourceUid,
              apiVersion: 'config',
            },
            APP_O11Y_NAMESPACE,
            groupName
          ).catch((e) => {
            if (e.status === 404) {
              return;
            }
            throw e;
          })
        )
      );
      log('baseline rules successfully deactivated');
    } catch (e) {
      logError(e);
      log('failed to deactive baseline rules');
      throw e;
    }
  }

  getRulePrefix(): string {
    return getPluginConfigService().getPluginConfig().baselineRulePrefix ?? 'appo11y';
  }

  getGroupNames() {
    const prefix = getPluginConfigService().getPluginConfig().baselineGroupPrefix ?? 'baseline';
    return {
      CONSTANTS: `${prefix}-constants`,
      REQUEST: `${prefix}-request-rate`,
      DURATION_BASE: `${prefix}-duration-base`,
      DURATION: `${prefix}-duration`,
      ERROR_RATIO: `${prefix}-error-ratio`,
      STDDEV_1h: `${prefix}-stddev-1h`,
    };
  }
}

export const baselineService = new BaselineService();
