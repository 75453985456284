import { getInstanceService } from 'services/InstanceService';
import { getOrgService } from 'services/OrgService';
import { CODE_CONTRACTED, TYPE_CONTRACTED } from 'types/instance';

// Check if is a contracted org with the values we get from the org endpoint
// If it's not available, default to checking the instance plan
// Which will not be as accurate, but will be more restrictive
export function isContractedOrg(): boolean {
  const instanceService = getInstanceService();
  if (!instanceService) {
    return false;
  }

  const org = getOrgService()?.getOrg();
  if (org !== undefined && org.contractType === CODE_CONTRACTED && org.contractTypeId === TYPE_CONTRACTED) {
    return true;
  }

  const instance = instanceService.getInstance();
  return instance !== undefined && instance.plan !== 'free' && instance.plan !== 'gcloud';
}
