import React, { useMemo } from 'react';

import {
  SceneComponentProps,
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { LinkButton } from '@grafana/ui';

import { ACCESS_CONTROL } from 'constants/accessControl';
import { TempoQuery } from 'types/queries';
import { trackPanelExplore } from 'utils/tracking';

export interface LogsTracesOpenInExploreButtonState {
  dataSourceName: string;
  leftQuery: TempoQuery | undefined;
  pageName: string;
  sectionName: string;

  rightQuery?: TempoQuery | undefined;
}

export type InternalLogsTracesOpenInExploreButtonState = SceneObjectState & LogsTracesOpenInExploreButtonState;

export class LogsTracesOpenInExploreButton extends SceneObjectBase<InternalLogsTracesOpenInExploreButtonState> {
  static Component = LogsTracesOpenInExploreButtonRenderer;

  constructor(state: LogsTracesOpenInExploreButtonState) {
    super(state);

    this._variableDependency = new VariableDependencyConfig(this, {
      variableNames: [state.dataSourceName],
      statePaths: ['dataSourceName', 'leftQuery', 'rightQuery'],
    });
  }
}

function LogsTracesOpenInExploreButtonRenderer({ model }: SceneComponentProps<LogsTracesOpenInExploreButton>) {
  const { dataSourceName, leftQuery, pageName, rightQuery, sectionName } = model.useState();

  const { from, to } = sceneGraph.getTimeRange(model).useState();

  const datasource = useMemo(() => sceneGraph.interpolate(model, `$\{${dataSourceName}}`), [dataSourceName, model]);

  const [left, right] = useMemo(
    () => [
      encodeURIComponent(
        JSON.stringify({
          datasource,
          queries: [leftQuery],
          range: {
            from,
            to,
          },
        })
      ),
      rightQuery
        ? encodeURIComponent(
            JSON.stringify({
              datasource,
              queries: [rightQuery],
              range: {
                from,
                to,
              },
            })
          )
        : '',
    ],
    [datasource, from, to, leftQuery, rightQuery]
  );

  if (!ACCESS_CONTROL.EXPLORE.VIEW) {
    return null;
  }

  return (
    <LinkButton
      data-cy="openInExploreButton"
      variant="secondary"
      href={`/explore?left=${left}${right ? '&right=' + right : ''}`}
      onClick={() => trackPanelExplore(sectionName, pageName, pageName)}
    >
      Open in Explore
    </LinkButton>
  );
}
