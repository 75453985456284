import { BusEventBase, BusEventWithPayload } from '@grafana/data';

export interface InitializationCompletePayload {
  redirect?: boolean; // redirect to services?
}

// will dismiss landing page and go to serviecs
export class InitializationCompleteBusEvent extends BusEventWithPayload<InitializationCompletePayload> {
  static type = 'app-o11y-overrides-initialization-complete';
}

// overrides initialized, landing page needs to be shown
export class ShowLandingPageBusEvent extends BusEventBase {
  static type = 'app-o11y-show-landing-page';
}
