import { METRIC_NAMES, MetricNamesDefinition } from 'constants/metricNames';
import { DEFAULT_TARGET_INFO_METRIC_NAME } from 'constants/semantics';
import { getPluginConfigService } from 'services/PluginConfigService';
import { MetricsMode } from 'types/settings';

export let targetInfoMetricName = DEFAULT_TARGET_INFO_METRIC_NAME;
export let isDefaultTargetInfoMetricName = targetInfoMetricName === DEFAULT_TARGET_INFO_METRIC_NAME;

export function setTargetInfoMetricName(name: string): void {
  targetInfoMetricName = name;
  isDefaultTargetInfoMetricName = targetInfoMetricName === DEFAULT_TARGET_INFO_METRIC_NAME;
}

export function getMetricName(metricName: keyof MetricNamesDefinition[MetricsMode]): string {
  const metricsMode = getPluginConfigService().getMetricsMode();

  return METRIC_NAMES[metricsMode][metricName];
}
