import { isArray, isEqual } from 'lodash';
import { map, Observable, take, tap } from 'rxjs';

import { VariableRefresh } from '@grafana/data';
import { locationService } from '@grafana/runtime';
import {
  QueryVariable,
  SceneVariableValueChangedEvent,
  ValidateAndUpdateResult,
  VariableValue,
  VariableValueOption,
} from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';

import { PROMETHEUS_DS } from 'constants/datasources';
import { AGGREGATED_LABEL_VALUE } from 'constants/query';
import { environmentVariableStorageKey } from 'constants/sessionStorage';
import {
  ALL_VARIABLE_REGEX,
  ALL_VARIABLE_TEXT,
  ALL_VARIABLE_VALUE,
  ENVIRONMENT_VALUE_NAME,
  NONE_VARIABLE_REGEX,
  NONE_VARIABLE_TEXT,
  NONE_VARIABLE_VALUE,
} from 'constants/variables';
import { getFaro } from 'faro/instance';
import { NewMetadataFetchedEvent } from 'modules/service/events';
import { getDefaultEnvironmentValueService } from 'services/DefaultEnvironmentValueService';
import { getEnvironmentAttribute, getEnvironmentValueQuery } from 'utils/environmentFilter';
import { trackEnvironmentAttribute } from 'utils/tracking';

import { EnvironmentFilter } from './EnvironmentFilter/EnvironmentFilter';

export interface EnvironmentValueVariableState {
  defaultEnvironmentValue: string;
  withJob: boolean;
  withOperation: boolean;
  isInstrumented?: boolean;
}

// @ts-expect-error: We're overriding a private method
export class EnvironmentValueVariable extends QueryVariable {
  static Component = EnvironmentFilter;

  defaultEnvironmentValue: string;
  includeNoneOption: boolean;

  private withJob: boolean;
  private withOperation: boolean;
  private isInstrumented: boolean;
  private defaultEnvironmentValueService = getDefaultEnvironmentValueService();

  constructor({ withJob, withOperation, defaultEnvironmentValue, isInstrumented }: EnvironmentValueVariableState) {
    const environmentAttribute = getEnvironmentAttribute();
    let text: VariableValue = [];
    let value: VariableValue = [];

    try {
      const savedFilters = sessionStorage.getItem(environmentVariableStorageKey);
      if (savedFilters) {
        const parsedFilters = JSON.parse(savedFilters);
        if (parsedFilters.text) {
          text = parsedFilters.text;
        }
        if (parsedFilters.value) {
          value = parsedFilters.value;
        }
      }
    } catch {
      sessionStorage.removeItem(environmentVariableStorageKey);
    }

    super({
      allValue: ALL_VARIABLE_REGEX,
      datasource: PROMETHEUS_DS,
      defaultToAll: true,
      description: 'The attribute that reflects the environment of the service',
      hide: VariableHide.hideVariable,
      includeAll: true,
      isMulti: true,
      label: environmentAttribute || 'Environment Variable',
      name: ENVIRONMENT_VALUE_NAME,
      query: environmentAttribute
        ? getEnvironmentValueQuery(environmentAttribute, withJob, withOperation, isInstrumented ?? true)
        : undefined,
      refresh: VariableRefresh.onTimeRangeChanged,
      text,
      value,
    });

    this.withJob = withJob;
    this.withOperation = withOperation;
    this.defaultEnvironmentValue = defaultEnvironmentValue;
    this.includeNoneOption = locationService.getLocation().pathname.endsWith('/traces') ? false : true;
    this.isInstrumented = isInstrumented ?? true;

    this.addActivationHandler(() => {
      this._subs.add(
        this.getRoot().subscribeToEvent(NewMetadataFetchedEvent, ({ payload: { isInstrumented } }) => {
          if (isInstrumented !== undefined && this.isInstrumented !== isInstrumented) {
            this.isInstrumented = isInstrumented;
            this.updateQuery(environmentAttribute, this.defaultEnvironmentValueService.defaultValue);
          }
        })
      );

      this._subs.add(
        this.subscribeToState((newState, oldState) => {
          if (!isEqual(newState, oldState)) {
            sessionStorage.setItem(
              environmentVariableStorageKey,
              JSON.stringify({
                text: this.state.text,
                value: this.state.value,
              })
            );
          }
        })
      );

      sessionStorage.setItem(
        environmentVariableStorageKey,
        JSON.stringify({
          text: this.state.text,
          value: this.state.value,
        })
      );
    });
  }

  override getValue(): VariableValue {
    const value = this.state.value as string[];

    if (value.includes(ALL_VARIABLE_VALUE) || value.length === 0) {
      return {
        formatter: (formatNameOrFn) => {
          if (formatNameOrFn === 'anomalyThreshold') {
            return AGGREGATED_LABEL_VALUE;
          }
          return ALL_VARIABLE_REGEX;
        },
      };
    }
    if (value.length === 1) {
      if (value[0] === NONE_VARIABLE_VALUE) {
        return NONE_VARIABLE_REGEX;
      }

      return value[0];
    }

    return value.map((value) => {
      if (value === NONE_VARIABLE_VALUE) {
        return NONE_VARIABLE_REGEX;
      }

      return value;
    });
  }

  override validateAndUpdate(): Observable<ValidateAndUpdateResult> {
    return this.getValueOptions({ searchFilter: this.state.query as string | undefined }).pipe(
      tap((options) => {
        try {
          trackEnvironmentAttribute(
            getEnvironmentAttribute(),
            options.map(({ value }) => String(value))
          );
        } catch (err) {
          getFaro()?.api.pushError(
            err instanceof Error ? err : new Error('Failed to parse environment attribute options')
          );
        }
      }),
      map((options) => {
        this.updateValueGivenNewOptions(options);

        return {};
      })
    );
  }

  private override updateValueGivenNewOptions(options: VariableValueOption[]): void {
    const { text, value } = this.state;

    const newState = {
      loading: false,
      options,
      value: value ? (Array.isArray(value) ? value : [value]) : [],
      text: text ? (Array.isArray(text) ? text : [text]) : [],
    };

    this.defaultEnvironmentValueService.isManuallyChanged = true;

    if (newState.value.length === 0) {
      this.defaultEnvironmentValueService.isManuallyChanged = false;

      const defaultValue =
        this.defaultEnvironmentValueService.findDefaultValue(options.map((option) => String(option.value))) ??
        ALL_VARIABLE_VALUE;

      newState.text = [defaultValue];
      newState.value = [defaultValue];
    }

    // if selected value is not in options, reset it
    if (newState.value.length > 0 && !options.find((option) => option.value === newState.value[0])) {
      newState.text = [ALL_VARIABLE_VALUE];
      newState.value = [ALL_VARIABLE_VALUE];
    }

    this.setState(newState);

    // Publish value changed event only if value changed
    if (!isEqual(newState.value, value) || !isEqual(newState.text, text) || this.hasAllValue()) {
      this.publishEvent(new SceneVariableValueChangedEvent(this), true);
    }
  }

  override getOptionsForSelect(): VariableValueOption[] {
    let options = this.state.options;

    if (this.state.includeAll) {
      options = [
        { label: ALL_VARIABLE_TEXT, value: ALL_VARIABLE_VALUE },
        ...(this.includeNoneOption ? [{ label: NONE_VARIABLE_TEXT, value: NONE_VARIABLE_VALUE }] : []),
        ...options,
      ];
    }

    if (!Array.isArray(this.state.value)) {
      const current = options.find((option) => option.value === this.state.value);

      if (!current) {
        options = [{ label: String(this.state.text), value: this.state.value }, ...options];
      }
    }

    return options;
  }

  override changeValueTo(value: VariableValue, text?: VariableValue | undefined, isManualChange?: boolean): void {
    if (isManualChange) {
      this.defaultEnvironmentValueService.isManuallyChanged = true;
    }

    super.changeValueTo(isArray(value) ? value : [value], text === undefined ? text : isArray(text) ? text : [text]);
  }

  updateQuery(environmentAttribute: string | undefined, defaultEnvironmentValue: string): void {
    this.defaultEnvironmentValue = defaultEnvironmentValue;

    this.setState({
      query: getEnvironmentValueQuery(environmentAttribute, this.withJob, this.withOperation, this.isInstrumented),
    });

    setTimeout(() => this.validateAndUpdate().pipe(take(1)).subscribe());
  }
}
