import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { SceneComponentProps } from '@grafana/scenes';
import { ButtonGroup, LoadingPlaceholder, MultiSelect, Select, Tooltip, useStyles2 } from '@grafana/ui';

import { FilterLabel } from 'components/FilterLabel';
import { GroupByVariable } from 'components/GroupByVariable';

export const GroupByComponent = ({ model }: SceneComponentProps<GroupByVariable>) => {
  const styles = useStyles2(getStyles);

  const { loading, value } = model.useState();

  if (loading) {
    return <LoadingPlaceholder className={styles.placeholder} text="Loading attribute values" />;
  }

  const options = model.getOptionsForSelect();
  const isEmpty = model.isEmpty();

  return (
    <ButtonGroup>
      <Tooltip content="Group by">
        <FilterLabel dataCy="groupByLabel">Group by</FilterLabel>
      </Tooltip>

      {isEmpty ? (
        <Select
          aria-label="Group by selector"
          placeholder="None"
          id="groupBySelectorSingle"
          options={options}
          value={value}
          onChange={(newValue) => {
            if (!newValue?.value) {
              return;
            }
            model.changeValueTo(newValue.value, newValue.label);
          }}
          closeMenuOnSelect={false}
          tabSelectsValue={false}
        />
      ) : (
        <MultiSelect
          aria-label="Group by selector"
          options={options}
          value={value as string[]}
          id="groupBySelectorMultiple"
          onChange={(values) => {
            model.changeValueTo(
              values.map(({ value }) => value!),
              values.map(({ label }) => label!)
            );
          }}
          closeMenuOnSelect={false}
          tabSelectsValue={false}
        />
      )}
    </ButtonGroup>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    placeholder: css`
      margin-bottom: 0;
    `,
  };
}
