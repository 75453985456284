import { PanelType } from 'constants/panels';
import { makeServiceOverviewDownstreamPanel } from 'modules/service/overview/panels/makeServiceOverviewDownstreamPanel';
import { makeServiceOverviewOperationsPanel } from 'modules/service/overview/panels/makeServiceOverviewOperationsPanel';
import { makeServiceOverviewUpstreamPanel } from 'modules/service/overview/panels/makeServiceOverviewUpstreamPanel';
import { getMetadataService } from 'services/MetadataService';
import { getPluginConfigService } from 'services/PluginConfigService';

import { makeDurationDistributionPanel } from './makeDurationDistributionPanel';
import { makeRedPanels } from './red/makeRedPanels';

export const getServiceOverviewPanels = (job: string, operation?: string) => {
  const metadataService = getMetadataService();
  const configService = getPluginConfigService();

  const isInstrumented = metadataService.getIsInstrumented(job);
  const serviceType = configService.getPluginConfig().isClientOnlyServicesEnabled
    ? metadataService.getIsClientOnly(job)
      ? 'client'
      : undefined
    : undefined;

  const [ratePanel, errorsPanel, durationPanel] = makeRedPanels(job, operation, !!isInstrumented, serviceType);

  return {
    [PanelType.ERRORS]: () => errorsPanel(),
    [PanelType.DURATION]: () => durationPanel(),
    [PanelType.RATE]: () => ratePanel(),
    [PanelType.DURATION_DISTRIBUTION]: () =>
      makeDurationDistributionPanel({ job, operation, isInstrumented: !!isInstrumented }),
    [PanelType.OPERATIONS]: () => makeServiceOverviewOperationsPanel(job, operation),
    [PanelType.DOWNSTREAM]: () => makeServiceOverviewDownstreamPanel(job, operation),
    [PanelType.UPSTREAM]: () => makeServiceOverviewUpstreamPanel(job, operation),
  };
};
