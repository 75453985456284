import { prefixRoute } from 'utils/routing';

import {
  ENVIRONMENT_VALUE_NAME,
  FILTER_BY_NAME,
  GROUP_BY_NAME,
  LOKI_DS_NAME,
  PROMETHEUS_DS_NAME,
  PYROSCOPE_DS_NAME,
  TEMPO_DS_NAME,
} from './variables';

export const PRESERVE_URL_KEYS = [
  'from',
  'to',
  `var-${PROMETHEUS_DS_NAME}`,
  `var-${LOKI_DS_NAME}`,
  `var-${TEMPO_DS_NAME}`,
  `var-${PYROSCOPE_DS_NAME}`,
  `var-${ENVIRONMENT_VALUE_NAME}`,
  `var-${GROUP_BY_NAME}`,
  `var-${FILTER_BY_NAME}`,
  'scenes-debugger',
];

export const ROUTES = {
  landing: () => prefixRoute('landing'),
  services: () => prefixRoute(`services`),
  servicesMap: () => prefixRoute(`services/map`),
  overview: (service: string) => prefixRoute(`services/service/${service}`),
  overviewWithTab: (service: string, tab?: string) => prefixRoute(`services/service/${service}/${tab}`),
  traces: (service: string) => prefixRoute(`services/service/${service}/traces`),
  logs: (service: string) => prefixRoute(`services/service/${service}/logs`),
  profiles: (service: string) => prefixRoute(`services/service/${service}/profiles`),
  serviceMap: (service: string) => prefixRoute(`services/service/${service}/service-map`),
  go: (service: string) => prefixRoute(`services/service/${service}/go`),
  java: (service: string) => prefixRoute(`services/service/${service}/java`),
  dotnet: (service: string) => prefixRoute(`services/service/${service}/dotnet`),
  operation: (service: string, operation: string) => prefixRoute(`services/service/${service}/operations/${operation}`),
  operationWithTab: (service: string, operation: string, tab: string) =>
    prefixRoute(`services/service/${service}/operations/${operation}/${tab}`),
  operationTraces: (service: string, operation: string) =>
    prefixRoute(`services/service/${service}/operations/${operation}/traces`),
  configuration: () => prefixRoute(`configuration`),
  dataSourceConfiguration: () => prefixRoute(`configuration/data-source`),
  settingsConfiguration: () => prefixRoute(`configuration/settings`),
  logsQueryConfiguration: () => prefixRoute(`configuration/logs`),
  systemConfiguration: () => prefixRoute(`configuration/system`),
  maxPanel: (service: string, panel: string) => prefixRoute(`services/service/${service}/panels/${panel}`),
  maxOperationPanel: (service: string, operation: string | undefined, panel: string) =>
    prefixRoute(`services/service/${service}/operations/${operation}/panels/${panel}`),
} as const;

export const ROUTE_DEFINITIONS: Record<keyof typeof ROUTES, string> = {
  landing: prefixRoute('landing'),
  services: prefixRoute('services'),
  servicesMap: prefixRoute('map'),
  overview: prefixRoute('services/service/:job'),
  overviewWithTab: prefixRoute('services/service/:job/:tab'),
  traces: prefixRoute('services/service/:job/traces'),
  logs: prefixRoute('services/service/:job/logs'),
  profiles: prefixRoute('services/service/:job/profiles'),
  serviceMap: prefixRoute('services/service/:job/service-map'),
  go: prefixRoute('services/service/:job/go'),
  java: prefixRoute('services/service/:job/java'),
  dotnet: prefixRoute('services/service/:job/dotnet'),
  operation: prefixRoute('services/service/:job/operations/:operation'),
  operationWithTab: prefixRoute('services/service/:job/operations/:operation/:operationTab'),
  operationTraces: prefixRoute('services/service/:job/operations/:operation/traces'),
  configuration: prefixRoute('configuration/:tab?'),
  dataSourceConfiguration: prefixRoute('configuration/data-source'),
  settingsConfiguration: prefixRoute('configuration/settings'),
  logsQueryConfiguration: prefixRoute('configuration/logs'),
  systemConfiguration: prefixRoute('configuration/system'),
  maxPanel: prefixRoute(`services/service/:job/panels/:panel`),
  maxOperationPanel: prefixRoute('services/service/:job/operations/:operation/panels/:panel'),
};
