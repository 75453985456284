import React from 'react';

import { SceneComponentProps, SceneObjectBase, SceneObjectRef, SceneObjectState } from '@grafana/scenes';
import { Button } from '@grafana/ui';

import { ServiceLogsScene } from './ServiceLogsScene';

export interface RunQueryControlState extends SceneObjectState {
  logsSceneRef: SceneObjectRef<ServiceLogsScene>;
}

export class RunQueryControl extends SceneObjectBase<RunQueryControlState> {
  static Component = RunQueryControlRenderer;
}

function RunQueryControlRenderer({ model }: SceneComponentProps<RunQueryControl>) {
  const logsScene = model.useState().logsSceneRef.resolve();
  const { searchEditor } = logsScene.useState();

  return (
    <Button
      variant="primary"
      onClick={() => {
        searchEditor?.propagateQuery();
      }}
      data-fs-element="App o11y - Run query"
    >
      Run query
    </Button>
  );
}
