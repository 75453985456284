import { CLOUD } from 'constants/cloud';
import { ENV } from 'constants/environment';
import { TECHNOLOGY } from 'constants/technology';
import { TRACKING_EVENTS } from 'constants/tracking';
import { getFaro } from 'faro/instance';
import { getPluginConfigService } from 'services/PluginConfigService';
import { DS_TYPE } from 'types/datasources';

import { isProvisionedDataSource } from './datasources';
import { getGroupByFilterByAttributes } from './groupByFilterBy';

export function trackEvent(eventName: TRACKING_EVENTS, attributes?: Record<string, any>) {
  const finalEventName = `appo11y_${eventName}`;
  const config = getPluginConfigService().getPluginConfig();

  const attrs = {
    ...attributes,
  };

  if (config.orgId) {
    attrs.org_id = config.orgId;
  }

  if (config.stackId) {
    attrs.stack_id = config.stackId;
  }

  if (!ENV.LOCAL) {
    (window as any).rudderanalytics?.track(finalEventName, attrs);
  } else {
    // eslint-disable-next-line no-console
    console.debug(`[TRACKING] ${finalEventName}`, attrs);
  }

  getFaro()?.api.pushEvent(finalEventName, recordValuesToString(attrs));
}

// Optin

export function trackOptinModalOpen(page: 'initialize' | 'config') {
  trackEvent(TRACKING_EVENTS.OPTIN_MODAL_OPEN, { page });
}

export function trackOptinAcknowledge(page: 'initialize' | 'config') {
  trackEvent(TRACKING_EVENTS.OPTIN_ACKNOWLEDGE, { page });
}

export function trackOptinActivate(page: 'initialize' | 'config') {
  trackEvent(TRACKING_EVENTS.OPTIN_ACTIVATE, { page });
}

export function trackOptinModalCancel(page: 'initialize' | 'config') {
  trackEvent(TRACKING_EVENTS.OPTIN_MODAL_CANCEL, { page });
}

// Optout

export function trackOptoutModalOpen() {
  trackEvent(TRACKING_EVENTS.OPTOUT_MODAL_OPEN);
}

export function trackOptoutAcknowledge() {
  trackEvent(TRACKING_EVENTS.OPTOUT_ACKNOWLEDGE);
}

export function trackOptoutDeactivate() {
  trackEvent(TRACKING_EVENTS.OPTOUT_DEACTIVATE);
}

export function trackOptoutModalCancel() {
  trackEvent(TRACKING_EVENTS.OPTOUT_MODAL_CANCEL);
}

// Datasource

export function trackDatasourceSelect(ds: string, dsType: DS_TYPE) {
  trackEvent(TRACKING_EVENTS.DATASOURCE_SELECT, { ds, ds_type: dsType });
}

export function trackDatasources() {
  const datasources = getPluginConfigService().getPluginConfig().defaultDataSources;
  if (!datasources) {
    return;
  }

  const entries = Object.entries(datasources);
  if (entries.length > 0 && entries.some(([type, uid]) => !isProvisionedDataSource(uid, type as DS_TYPE))) {
    trackEvent(TRACKING_EVENTS.DATASOURCES, datasources);
  }
}

// Time Window Comparison

export function trackTimeWindowComparisonEnable(period: string) {
  trackEvent(TRACKING_EVENTS.TIME_WINDOW_COMPARISON_ENABLE, { period });
}

export function trackTimeWindowComparisonChange(from: string, to: string) {
  trackEvent(TRACKING_EVENTS.TIME_WINDOW_COMPARISON_CHANGE, { from_period: from, to_period: to });
}

// Attributes and environment

export function trackAttributes() {
  const attributes = getGroupByFilterByAttributes({ withEnvironmentAttribute: true });
  trackEvent(TRACKING_EVENTS.FILTER_ATTRIBUTES, { attributes });
}

export function trackEnvironmentAttribute(attribute: string | undefined, values: string[] = []) {
  trackEvent(TRACKING_EVENTS.ENV_ATTRIBUTE, { attribute, values });
}

// Service Map

export function trackServiceMapLayout(map: 'service' | 'global', layout: 'grid' | 'graph') {
  trackEvent(TRACKING_EVENTS.SERVICE_MAP_LAYOUT, { map, layout });
}

export function trackServiceMapExplore(
  target: 'request_rate' | 'request_histogram' | 'failed_request_rate' | 'traces'
) {
  trackEvent(TRACKING_EVENTS.SERVICE_MAP_EXPLORE, { target });
}

export function trackServiceMapDetails() {
  trackEvent(TRACKING_EVENTS.SERVICE_MAP_DETAILS);
}

// Services

export function trackServicesList(
  count: number,
  technologyCount: Partial<Record<TECHNOLOGY, number>>,
  uninstrumentedCount: number,
  filteredServicesCount: number,
  namespacesCount: number,
  datasourceIsDefault: boolean
) {
  trackEvent(TRACKING_EVENTS.SERVICES_LIST, {
    services_count: count,
    services_filtered_count: filteredServicesCount,
    services_uninstrumented_count: uninstrumentedCount,
    namespaces_count: namespacesCount,
    datasourceIsDefault,
    ...Object.fromEntries(Object.entries(technologyCount).map(([key, value]) => [`services_${key}_count`, value])),
  });
}

export function trackServicesFilter(filterName: string, filterValue: string | boolean) {
  trackEvent(TRACKING_EVENTS.SERVICES_FILTER, {
    [filterName]: filterValue,
  });
}

export function trackServicesSearch(searchTerm: string) {
  trackEvent(TRACKING_EVENTS.SERVICES_SEARCH, {
    length: searchTerm,
  });
}

export function trackServicesDetails() {
  trackEvent(TRACKING_EVENTS.SERVICES_SERVICE_SELECT);
}

export function trackServicesQueryErrrors(errors: string[]) {
  trackEvent(TRACKING_EVENTS.SERVICES_ERRORS, { errors });
}

// Service

export function trackServiceDetail(
  operations: number | undefined,
  upstreams: number | undefined,
  downstreams: number | undefined,
  technology: TECHNOLOGY,
  cloud: CLOUD,
  hasK8s: boolean,
  namespaced: boolean,
  isDefaultPromDatasource: boolean,
  isDefaultTempoDatasource: boolean
) {
  trackEvent(TRACKING_EVENTS.SERVICE_DETAIL, {
    operation_count: operations,
    upstream_count: upstreams,
    downstream_count: downstreams,
    technology,
    cloud,
    k8s: hasK8s,
    namespaced,
    isDefaultPromDatasource,
    isDefaultTempoDatasource,
  });
}

export function trackServiceOperationSelect() {
  trackEvent(TRACKING_EVENTS.SERVICE_OPERATION_SELECT);
}

export function trackServiceUpstreamSelect() {
  trackEvent(TRACKING_EVENTS.SERVICE_UPSTREAM_SELECT);
}

export function trackServiceDownstreamSelect() {
  trackEvent(TRACKING_EVENTS.SERVICE_DOWNSTREAM_SELECT);
}

export function trackServiceRuntimeDetail(technology: TECHNOLOGY, hasData: boolean) {
  trackEvent(TRACKING_EVENTS.SERVICE_RUNTIME_DETAIL, { runtime: technology, has_data: hasData });
}

export function trackPanelExplore(section: string, page: string, panel: string) {
  trackEvent(TRACKING_EVENTS.PANEL_EXPLORE, { section, page, panel });
}

export function trackMaximizePanel(section: string, panel: string) {
  trackEvent(TRACKING_EVENTS.PANEL_MAXIMIZE, { section, panel });
}

export function trackDependenciesServiceMapClick() {
  trackEvent(TRACKING_EVENTS.DEPENDENCIES_SERVICE_MAP_CLICK);
}

function recordValuesToString(rec: Record<string, any>): Record<string, string> {
  return Object.fromEntries(Object.entries(rec).map(([key, value]) => [key, String(value)]));
}

export function trackBaselineModalOpen() {
  trackEvent(TRACKING_EVENTS.BASELINE_MODAL_OPEN);
}

export function trackBaselineAcknowledge() {
  trackEvent(TRACKING_EVENTS.BASELINE_ACKNOWLEDGE);
}

export function trackBaselineActivate() {
  trackEvent(TRACKING_EVENTS.BASELINE_ACTIVATE);
}

export function trackBaselineModalCancel() {
  trackEvent(TRACKING_EVENTS.BASELINE_MODAL_CANCEL);
}

export function trackBaselineDeactivate() {
  trackEvent(TRACKING_EVENTS.BASELINE_DEACTIVATE);
}

// Logs
export function trackLogModeSwitch(value: string) {
  trackEvent(TRACKING_EVENTS.LOG_MODE_SWITCH, { logQueryMode: value });
}

export function trackDefaultLogMode() {
  trackEvent(TRACKING_EVENTS.LOG_MODE_DEFAULT, {
    defaultLogQueryMode: getPluginConfigService().getPluginConfig().defaultLogQueryMode,
  });
}

// Host info and host usage
export function trackDefaultHostInfoModalState(hasHostData: boolean, savedForCurrentSession: boolean, isOpen: boolean) {
  trackEvent(TRACKING_EVENTS.HOST_INFO_MODAL_DEFAULT_STATE, { hasHostData, savedForCurrentSession, isOpen });
}

export function trackHostInfoModalClosed(withDontShowAgain: boolean, reason: string) {
  trackEvent(TRACKING_EVENTS.HOST_INFO_MODAL_CLOSED, { withDontShowAgain, reason });
}

export function trackHostUsageModalOpen() {
  trackEvent(TRACKING_EVENTS.HOST_USAGE_MODAL_OPENED);
}

export function trackHostUsage(usage: {
  billableHostHours: number;
  hostHours: number;
  instances: number;
  services: number;
}) {
  trackEvent(TRACKING_EVENTS.HOST_USAGE, usage);
}

export function trackHostInfoRequiredDocs() {
  trackEvent(TRACKING_EVENTS.HOST_INFO_REQUIRED_DOCS);
}
