import { css } from '@emotion/css';
import { intersection, isEqual, orderBy, remove, sortBy } from 'lodash';
import React from 'react';
import { Unsubscribable } from 'rxjs';

import { DataFrame, Field, GrafanaTheme2, Labels, LoadingState, MetricFindValue, PanelData } from '@grafana/data';
import {
  QueryVariable,
  SceneComponentProps,
  SceneDataProvider,
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  SceneObjectUrlSyncConfig,
  SceneObjectUrlValues,
  VariableDependencyConfig,
} from '@grafana/scenes';
import {
  Alert,
  Icon,
  Input,
  LoadingBar,
  LoadingPlaceholder,
  Pagination,
  RadioButtonGroup,
  Select,
  Text,
  useStyles2,
} from '@grafana/ui';

import { EnvironmentFilterScene } from 'components/EnvironmentFilter/EnvironmentFilterScene';
import { FilterByScene } from 'components/FilterBy/FilterByScene';
import { AttributeFilter, FilterByVariable } from 'components/FilterByVariable';
import { PromSceneQueryRunner } from 'components/PromSceneQueryRunner';
import { PROMETHEUS_DS } from 'constants/datasources';
import { EMPTY_FILTER_LABEL, SERVICES_FILTERS_KEYS } from 'constants/filterBy';
import {
  DEFAULT_PAGE_SIZE,
  FILTERS_SESSION_STORAGE,
  INPUT_DEBOUNCE_MS,
  PAGE_SIZE_OPTIONS,
  SELECTABLE_PAGE_SIZE_OPTIONS,
} from 'constants/misc';
import { BASELINE_CONSTANT_REF_ID } from 'constants/query';
import { isValidSpanKind } from 'constants/spanKind';
import { EMPTY_SPARKLINE_DATA_ITEM } from 'constants/sparkline';
import { TECHNOLOGY } from 'constants/technology';
import {
  ENVIRONMENT_ATTRIBUTE_NAME,
  ENVIRONMENT_VALUE_NAME,
  FILTER_BY_NAME,
  PROMETHEUS_DS_NAME,
} from 'constants/variables';
import { getFaro } from 'faro/instance';
import { getMetadataService, MetadataService } from 'services/MetadataService';
import { getPluginConfigService } from 'services/PluginConfigService';
import { DS_TYPE } from 'types/datasources';
import { Service, ServiceData, ServicesInventory } from 'types/services';
import { Sort } from 'types/sorting';
import { getSelectedDataSourceName, isProvisionedDataSource } from 'utils/datasources';
import { parseJob } from 'utils/services';
import { makeSparklineData } from 'utils/sparkline';
import { trackServicesFilter, trackServicesList, trackServicesQueryErrrors, trackServicesSearch } from 'utils/tracking';

import { ServicesTable } from './table/ServicesTable';
import { ServicesTablePlaceholder } from './table/ServicesTablePlaceholder';
import { EmptyState } from '../../../components/EmptyState';

const STATE_PRIORITIES = [
  undefined,
  LoadingState.NotStarted,
  LoadingState.Done,
  LoadingState.Streaming,
  LoadingState.Error,
  LoadingState.Loading,
];

export interface ServicesSceneState extends SceneObjectState {
  allServices: Service[];
  errors: string[];
  filteredServices: Service[];
  filterLabels: Record<string, string[]>;
  isDataLoading: boolean;
  isDatasourcesSelected: boolean;
  page: number;
  pages: number;
  pageSize: number;
  prometheusDataSourceName: string;
  services: Service[];
  instrumentedFilter?: 'instrumented' | 'uninstrumented' | 'all';
  servicesFilters: AttributeFilter[];
  sortFilter: Sort;
  servicesSearch: string;
  environmentFilter?: EnvironmentFilterScene;
  filterByScene?: FilterByScene;
  $data: SceneDataProvider;
  stackHasData?: boolean;
  redQueryRunner: PromSceneQueryRunner;
}

const initialState: Omit<
  ServicesSceneState,
  '$data' | 'redQueryRunner' | 'isDatasourcesSelected' | 'pageSize' | 'errors' | 'isDataLoading'
> = Object.freeze({
  allServices: [],
  filteredServices: [],
  filterLabels: {},
  page: 1,
  pages: 1,
  prometheusDataSourceName: PROMETHEUS_DS.uid,
  services: [],
  servicesFilters: [],
  servicesSearch: '',
  sortFilter: { id: 'serviceName', desc: false },
});

export class ServicesScene extends SceneObjectBase<ServicesSceneState> {
  static Component = ServicesSceneRenderer;

  protected _urlSync = new SceneObjectUrlSyncConfig(this, {
    keys: ['servicesSearch', 'instrumentedFilter', 'page', 'pageSize', 'sortFilterId', 'sortFilterDesc'],
  });

  protected _variableDependency = new VariableDependencyConfig(this, {
    variableNames: [ENVIRONMENT_ATTRIBUTE_NAME, ENVIRONMENT_VALUE_NAME, PROMETHEUS_DS_NAME, FILTER_BY_NAME],
    statePaths: ['prometheusDataSourceName'],
    onVariableUpdateCompleted: () => {
      this.setIsDatasourcesSelected();
    },
  });

  private metadataService: MetadataService;
  private searchTimeoutId?: NodeJS.Timeout;

  constructor(state: Pick<ServicesSceneState, '$data' | 'redQueryRunner'>) {
    let servicesSearch: string = '';
    let instrumentedFilter: 'instrumented' | 'uninstrumented' | 'all' = 'all';

    try {
      const saved = JSON.parse(sessionStorage.getItem(FILTERS_SESSION_STORAGE) || '{}');

      if (saved.servicesSearch) {
        servicesSearch = saved.servicesSearch;
      }
      if (saved.instrumentedFilter && ['all', 'instrumented', 'uninstrumented'].includes(saved.instrumentedFilter)) {
        instrumentedFilter = saved.instrumentedFilter;
      }
    } catch (err) {
      // If we fail to parse it, we continue with the default value
      const error = err instanceof Error ? err : new Error('Could not parse filters saved in session storage');
      getFaro()?.api.pushError(error);

      sessionStorage.removeItem(FILTERS_SESSION_STORAGE);
    }

    super({
      ...initialState,
      errors: [],
      stackHasData: getPluginConfigService().checkStackHasData(),
      isDataLoading: true,
      isDatasourcesSelected: false,
      pageSize: DEFAULT_PAGE_SIZE,
      prometheusDataSourceName: PROMETHEUS_DS.uid,
      servicesSearch,
      environmentFilter: new EnvironmentFilterScene(),
      filterByScene: new FilterByScene(),
      instrumentedFilter,
      $data: state.$data,
      redQueryRunner: state.redQueryRunner,
    });

    this.metadataService = getMetadataService();

    this.initActivationHandlers();
  }

  getUrlState() {
    const { servicesSearch, instrumentedFilter, page, pageSize, sortFilter } = this.state;

    return {
      servicesSearch: servicesSearch ? servicesSearch : undefined,
      instrumentedFilter: instrumentedFilter ? instrumentedFilter : undefined,
      page: page > 1 ? `${page}` : undefined,
      pageSize: pageSize !== DEFAULT_PAGE_SIZE ? `${pageSize}` : undefined,
      sortFilterId: sortFilter?.id ? sortFilter.id : undefined,
      sortFilterDesc: sortFilter?.desc ? 'true' : undefined,
    };
  }

  updateFromUrl(values: SceneObjectUrlValues) {
    const servicesSearch =
      typeof values.servicesSearch === 'string' ? values.servicesSearch : this.state.servicesSearch;

    const instrumentedFilter = (
      typeof values.instrumentedFilter === 'string' &&
      ['all', 'instrumented', 'uninstrumented'].includes(values.instrumentedFilter)
        ? values.instrumentedFilter
        : this.state.instrumentedFilter
    ) as ServicesSceneState['instrumentedFilter'];

    const sortFilterId = values.sortFilterId;
    const sortFilterDesc = values.sortFilterDesc;
    const sortFilter = {
      id: typeof sortFilterId === 'string' ? sortFilterId : initialState.sortFilter.id,
      desc: sortFilterDesc === 'true',
    };

    const filteredServices = this.filterServices(
      servicesSearch,
      instrumentedFilter,
      this.state.servicesFilters,
      sortFilter,
      this.state.allServices ?? []
    );

    const rawPageSize =
      typeof values.pageSize === 'string' ? Number(Number(values.pageSize).toFixed()) : DEFAULT_PAGE_SIZE;
    const pageSize = PAGE_SIZE_OPTIONS.includes(rawPageSize) ? rawPageSize : DEFAULT_PAGE_SIZE;

    const rawPage = typeof values.page === 'string' ? Number(Number(values.page).toFixed()) : 1;
    const numberOfPages = this.calculateNumberOfPages(pageSize, filteredServices);
    const page = !Number.isNaN(rawPage) && rawPage > 0 ? rawPage : 1;

    this.setState({
      filteredServices,
      page,
      pageSize,
      pages: numberOfPages,
      services: this.selectServicesForPage(page, this.state.pageSize, filteredServices),
      servicesSearch,
      instrumentedFilter,
      sortFilter,
    });
  }

  changePage(page: number) {
    this.setState({
      page,
      services: this.selectServicesForPage(page, this.state.pageSize, this.state.filteredServices),
    });
  }

  changeSearch(servicesSearch: string) {
    this.setState({
      servicesSearch,
    });

    clearTimeout(this.searchTimeoutId);
    this.searchTimeoutId = setTimeout(() => {
      this.performSearch();
    }, INPUT_DEBOUNCE_MS);
  }

  performSearch() {
    trackServicesSearch(this.state.servicesSearch);

    const filteredServices = this.filterServices(
      this.state.servicesSearch,
      this.state.instrumentedFilter,
      this.state.servicesFilters,
      this.state.sortFilter,
      this.state.allServices ?? []
    );

    this.setState({
      filteredServices,
      page: 1,
      pages: this.calculateNumberOfPages(this.state.pageSize, filteredServices),
      services: this.selectServicesForPage(1, this.state.pageSize, filteredServices),
    });
  }

  changeFilters(servicesFilters: AttributeFilter[]) {
    const filteredServices = this.filterServices(
      this.state.servicesSearch,
      this.state.instrumentedFilter,
      servicesFilters,
      this.state.sortFilter,
      this.state.allServices ?? []
    );

    this.setState({
      filteredServices,
      page: 1,
      pages: this.calculateNumberOfPages(this.state.pageSize, filteredServices),
      services: this.selectServicesForPage(1, this.state.pageSize, filteredServices),
      servicesFilters,
    });
  }

  changePageSize(pageSize: number) {
    this.setState({
      page: 1,
      pageSize,
      pages: this.calculateNumberOfPages(pageSize, this.state.filteredServices),
      services: this.selectServicesForPage(1, pageSize, this.state.filteredServices),
    });
  }

  addFilter(filterKey: string, filterValue: string) {
    const variable = this.getFilterByVariable();
    const hasFilter = variable.state.filters.find(({ key, value }) => key === filterKey && value.includes(filterValue));

    if (hasFilter) {
      return;
    }

    trackServicesFilter(filterKey, filterValue);

    variable.setState({
      filters: [
        ...variable.state.filters,
        {
          value: [filterValue],
          operator: '=',
          condition: '',
          key: filterKey,
        },
      ],
    });
  }

  changeInstrumentedFilter(value: ServicesSceneState['instrumentedFilter']) {
    const filteredServices = this.filterServices(
      this.state.servicesSearch,
      value,
      this.state.servicesFilters,
      this.state.sortFilter,
      this.state.allServices
    );

    this.setState({
      instrumentedFilter: value,
      services: this.selectServicesForPage(1, this.state.pageSize, filteredServices),
      filteredServices,
      page: 1,
      pages: this.calculateNumberOfPages(this.state.pageSize, filteredServices),
    });
  }

  changeSortFilter(id: string, desc: boolean): void {
    // Only reset pagination state if sorting has changed
    if (this.state.sortFilter?.id === id && this.state.sortFilter?.desc === desc) {
      return;
    }

    const sorted = this.sortServices(id, desc, this.state.filteredServices);

    this.setState({
      page: 1,
      services: this.selectServicesForPage(1, this.state.pageSize, sorted),
      filteredServices: sorted,
      sortFilter: { id, desc },
    });
  }

  private initActivationHandlers() {
    this.addActivationHandler(() => {
      this.setIsDatasourcesSelected();
      this.setState({ servicesFilters: this.getFilterByVariable().state.filters || [] });

      const unsubscribables: Unsubscribable[] = [
        this.initVariableListener(),
        this.initServicesListener(),
        this.subscribeToState(({ servicesSearch, instrumentedFilter }) => {
          sessionStorage.setItem(FILTERS_SESSION_STORAGE, JSON.stringify({ servicesSearch, instrumentedFilter }));
        }),
        (sceneGraph.lookupVariable(PROMETHEUS_DS_NAME, this) as QueryVariable).subscribeToState(
          ({ value: newValue }, { value: oldValue }) => {
            if (oldValue && oldValue !== newValue) {
              this.setState({
                ...initialState,
                isDataLoading: true,
              });
            }
          }
        ),
        this.getFilterByVariable().subscribeToState((newState, oldState) => {
          const hasServicesFiltersSelected = () =>
            intersection(
              newState.filters.map(({ key }) => key),
              SERVICES_FILTERS_KEYS
            ).length > 0;
          const hadServicesFiltersSelected = () =>
            intersection(
              oldState.filters.map(({ key }) => key),
              SERVICES_FILTERS_KEYS
            ).length > 0;

          if (
            !isEqual(newState.filters, oldState.filters) &&
            (newState.filters.length === 0 || hasServicesFiltersSelected() || hadServicesFiltersSelected())
          ) {
            this.changeFilters(newState.filters);
          }
        }),
        this.state.redQueryRunner.subscribeToState(({ data }, { data: oldData }) => {
          if (!isEqual(data, oldData)) {
            this.enrichREDMetrics(data);
          }
        }),
      ];

      this._subs.add(this.state.redQueryRunner.activate());

      return () => {
        clearTimeout(this.searchTimeoutId);
        unsubscribables.forEach((unsubscribable) => unsubscribable.unsubscribe());
      };
    });
  }

  private initVariableListener(): Unsubscribable {
    return sceneGraph.getVariables(this).subscribeToState(() => {
      this.setIsDatasourcesSelected();
    });
  }

  private initServicesListener(): Unsubscribable {
    return sceneGraph.getData(this).subscribeToState(({ data }) => {
      this.buildServices(data);
    });
  }

  private setIsDatasourcesSelected() {
    this.setState({
      isDatasourcesSelected: !!getSelectedDataSourceName(this, PROMETHEUS_DS_NAME),
    });
  }

  private sortServices(id: string, desc: boolean, services: Service[]): Service[] {
    const order = desc ? 'desc' : 'asc';

    switch (id) {
      case 'errors':
      case 'duration':
      case 'rate':
        return orderBy(
          services,
          [
            (service) => {
              const value = service.data[id].value;

              if (!value || isNaN(value)) {
                return -1;
              }

              return value;
            },
          ],
          order
        );

      case 'serviceName':
      case 'serviceNamespace':
        return orderBy(services, [(service) => service[id].toLowerCase()], order);

      default:
        return services;
    }
  }

  private selectServicesForPage(page: number, pageSize: number, services: Service[]): Service[] {
    return services.slice((page - 1) * pageSize, page * pageSize);
  }

  private calculateNumberOfPages(pageSize: number, services: Service[]): number {
    if (services.length === 0 || pageSize === 0) {
      return 1;
    }

    return Math.ceil(services.length / pageSize);
  }

  private filterServices(
    servicesSearch: string,
    isInstrumentedFilter: ServicesSceneState['instrumentedFilter'],
    servicesFilters: AttributeFilter[],
    sortFilter: Sort | undefined,
    services: Service[]
  ): Service[] {
    const parsedSearch = servicesSearch.toLowerCase();

    // Search
    let newServices = !servicesSearch
      ? services
      : services.filter((service) => service.serviceName.toLowerCase().includes(parsedSearch));

    // Instrumented
    newServices = newServices.filter((service) => {
      const isInstrumented = this.metadataService.getIsInstrumented(service.job);
      switch (isInstrumentedFilter) {
        case 'instrumented':
          return isInstrumented;
        case 'uninstrumented':
          return !isInstrumented;
        case 'all':
        default:
          return true;
      }
    });

    servicesFilters.forEach((filter) => {
      const filterKey = filter.key as 'serviceNamespace' | 'technology';
      if (!SERVICES_FILTERS_KEYS.includes(filterKey)) {
        return;
      }

      if (filterKey) {
        newServices = newServices.filter((service) => {
          const filterableProps = {
            serviceNamespace: service.serviceNamespace,
            technology: this.metadataService.getTechnology(service.job),
          };

          // When no value selected, we shouldn't take filter into account
          if (filter.value.length === 0) {
            return true;
          }

          const includesValue = filter.value.includes(filterableProps[filterKey]);
          return filter.operator === '=' ? includesValue : filter.operator === '!=' ? !includesValue : false;
        });
      }
    });

    if (sortFilter) {
      newServices = this.sortServices(sortFilter.id, sortFilter.desc, newServices);
    }

    return newServices;
  }

  private buildServicesInventory(frames: DataFrame[]): ServicesInventory {
    const instrumentedServices = new Set();

    return frames.reduce<ServicesInventory>((acc, frame) => {
      if (!frame.fields || frame.fields.length === 0) {
        return acc;
      }

      const { job, labels } = this.parseFrame(frame);
      let service = acc[job]; // Uncover what acc[job] is.
      const existingService = this.state.allServices?.find((s) => s.job === job);
      const parsedJob = parseJob(job);

      const encodedJob = parsedJob.encodedServiceNamespace
        ? `${parsedJob.encodedServiceNamespace}---${parsedJob.encodedServiceName}`
        : parsedJob.encodedServiceName;

      switch (frame.refId) {
        case 'servicesItems':
          {
            if (!service) {
              acc[job] = {
                // We can't utilize service variable here to create associated value because it will then point to a different memory from acc[job]
                job,
                encodedJob,
                ...parsedJob,
                data: {
                  rate: EMPTY_SPARKLINE_DATA_ITEM,
                  errors: EMPTY_SPARKLINE_DATA_ITEM,
                  duration: EMPTY_SPARKLINE_DATA_ITEM,
                  ...existingService?.data,
                },
              };

              instrumentedServices.add(parsedJob.encodedServiceName);

              // Reset the metadata service for the job as we got new data
              this.metadataService.addService(job, true);
            }

            this.metadataService.addLabels(job, true, frame);
          }
          break;
        case 'servicesSpanKind': {
          if (labels.span_kind && isValidSpanKind(labels.span_kind)) {
            this.metadataService.addSpanKind(job, labels.span_kind);
          }
          break;
        }
        case 'uninstrumentedServicesLabels':
          {
            const isInstrumented = instrumentedServices.has(parsedJob.encodedServiceName);

            // If the service has connection_type, it means it's uninstrumented.
            // This check only makes sense after checking if we receive the service in the
            // servicesItems list.
            // We follow this order to never mark an instrumented service as uninstrumented.
            if (!isInstrumented && labels['connection_type'] !== undefined) {
              if (!service) {
                acc[job] = {
                  job: job,
                  encodedJob,
                  ...parsedJob,
                  data: {
                    rate: EMPTY_SPARKLINE_DATA_ITEM,
                    errors: EMPTY_SPARKLINE_DATA_ITEM,
                    duration: EMPTY_SPARKLINE_DATA_ITEM,
                    ...existingService?.data,
                  },
                };

                service = acc[job]; // Initialize uninstrumented service

                // Reset the metadata service for the job as we got new data
                this.metadataService.addService(job, false);
              }
              this.metadataService.addLabels(job, false, frame);
            }
          }
          break;
        case BASELINE_CONSTANT_REF_ID:
          this.metadataService.setIsBaselineEnabled(frame.length > 0);
          break;
        default:
          return acc;
      }

      return acc;
    }, {});
  }

  private buildFilterLabels(services: Service[]): Record<string, string[]> {
    const filterLabels = services.reduce<Record<string, string[]>>(
      (acc, service) => {
        if (!acc.serviceNamespace.includes(service.serviceNamespace)) {
          acc.serviceNamespace.push(service.serviceNamespace);
        }

        const technology = this.metadataService.getTechnology(service.job);
        if (!acc.technology.includes(technology)) {
          acc.technology.push(technology);
        }

        const isInstrumented = this.metadataService.getIsInstrumented(service.job) ? 'Yes' : 'No';
        if (!acc.isInstrumented.includes(isInstrumented)) {
          acc.isInstrumented.push(isInstrumented);
        }

        return acc;
      },
      {
        serviceNamespace: [],
        technology: [],
        isInstrumented: [],
      }
    );

    filterLabels.technology = sortBy(filterLabels.technology);
    filterLabels.serviceNamespace = sortBy(filterLabels.serviceNamespace);
    return filterLabels;
  }

  // add RED sparkline data to current results
  private enrichREDMetrics(data: PanelData | undefined) {
    if (data && data.request) {
      this.manageFetchState();
    }

    if (!data || data.state !== LoadingState.Done) {
      return;
    }

    const serviceMap = this.state.services.reduce<Record<string, Service>>((acc, service) => {
      acc[service.job] = service;
      service.data.rate = EMPTY_SPARKLINE_DATA_ITEM;
      service.data.errors = EMPTY_SPARKLINE_DATA_ITEM;
      service.data.duration = EMPTY_SPARKLINE_DATA_ITEM;
      return acc;
    }, {});

    if (data.series.length > 0) {
      const { timeRange } = data;

      data.series.forEach((frame) => {
        if (!frame.fields || frame.fields.length === 0) {
          return;
        }
        const { job, timeField, labels, valuesField } = this.parseFrame(frame);
        const timestamps = timeField.values;
        const values = valuesField.values;
        const service = serviceMap[job];
        if (!service) {
          return;
        }
        switch (frame.refId) {
          case 'servicesMetadata':
            this.metadataService.refreshService(job, true, [frame]);
            this.metadataService.setClientInfo(job);
            break;
          case 'servicesRate':
          case 'servicesErrors':
          case 'servicesDuration':
            {
              if (service) {
                const key = frame.refId.replace('services', '').toLowerCase() as keyof ServiceData;
                service.data[key] = makeSparklineData(timestamps, values, timeRange, timeField.config.interval!);
              }
            }
            break;
          case 'servicesClientRate':
          case 'servicesClientErrors':
          case 'servicesClientDuration':
            {
              if (service && this.metadataService.getIsClientOnly(job)) {
                const key = frame.refId.replace('servicesClient', '').toLowerCase() as keyof ServiceData;
                service.data[key] = makeSparklineData(timestamps, values, timeRange, timeField.config.interval!);
              }
            }
            break;

          case 'traceGraphServicesRate':
          case 'traceGraphServicesErrors':
          case 'traceGraphServicesDuration':
            {
              // If the service has connection_type, it means it's uninstrumented.
              // This check only makes sense after checking if we receive the service in the
              // servicesItems list.
              // We follow this order to never mark an instrumented service as uninstrumented.
              if (labels['connection_type'] !== undefined) {
                if (service) {
                  // Populate data into service
                  const key = frame.refId.replace('traceGraphServices', '').toLowerCase() as keyof ServiceData;
                  service.data[key] = makeSparklineData(timestamps, values, timeRange, timeField.config.interval!);
                }
              }
            }
            break;
          default:
            break;
        }

        serviceMap[job] = {
          ...service,
          data: {
            ...service.data,
          },
        };
      });
    }

    // mark sparklines in CURRENT PAGE as not loading, even if no data was received for them
    this.state.services.forEach(({ job }) => {
      const service = serviceMap[job];
      if (service) {
        serviceMap[job] = {
          ...service,
          data: {
            rate: {
              ...service.data.rate,
              loading: false,
            },
            errors: {
              ...service.data.errors,
              loading: false,
            },
            duration: {
              ...service.data.duration,
              loading: false,
            },
          },
        };
      }
    });

    // this is awkward
    this.setState({
      allServices: this.state.allServices.map((service) => serviceMap[service.job] ?? service),
      filteredServices: this.state.filteredServices.map((service) => serviceMap[service.job] ?? service),
      services: this.state.services.map((service) => serviceMap[service.job] ?? service),
    });
  }

  private parseFrame(frame: DataFrame): { job: string; labels: Labels; valuesField: Field; timeField: Field } {
    const [timeField, valuesField] = frame.fields;
    const labels = valuesField.labels ?? {};
    const job =
      labels.job ??
      (labels.server_service_namespace ? `${labels.server}/${labels.server_service_namespace}` : labels.server) ??
      '';
    return { job, labels, valuesField, timeField };
  }

  // fill in errors and isDataLoading state props based on both data sources
  private manageFetchState() {
    const data1 = this.state.$data.state.data;
    const data2 = this.state.redQueryRunner.state.data;

    const state =
      STATE_PRIORITIES.indexOf(data1?.state) > STATE_PRIORITIES.indexOf(data2?.state) ? data1?.state : data2?.state;

    const newState: Partial<ServicesSceneState> = {
      isDataLoading: state === LoadingState.Loading,
      errors: [],
    };

    if (state === LoadingState.Error) {
      newState.page = 1;
      newState.errors = [...(data1?.errors ?? []), ...(data2?.errors ?? [])].reduce<string[]>((acc, error) => {
        if (error.message && !acc.includes(error.message)) {
          acc.push(error.message);
        }

        return acc;
      }, []);

      if (!this.state.errors.length) {
        trackServicesQueryErrrors(newState.errors);
      }
    }

    if (this.state.isDataLoading !== newState.isDataLoading || this.state.errors !== newState.errors) {
      this.setState(newState);
    }
  }

  private buildServices(data: PanelData | undefined) {
    if (!data) {
      return;
    }

    this.manageFetchState();
    if (data.state !== LoadingState.Done) {
      return;
    }

    const newState: Partial<ServicesSceneState> = Object.assign({}, initialState, {
      page: this.state.page,
      sortFilter: this.state.sortFilter,
      servicesSearch: this.state.servicesSearch,
      servicesFilters: this.state.servicesFilters,
    });

    const servicesInventory = this.buildServicesInventory(data.series);
    newState.allServices = Object.values(servicesInventory);

    if (newState.allServices.length && !this.state.stackHasData) {
      this.setState({ stackHasData: true });

      // Update stack to be marked as already having received data
      getPluginConfigService().updateStackHasData();
    }

    newState.filteredServices = this.filterServices(
      this.state.servicesSearch,
      this.state.instrumentedFilter,
      this.state.servicesFilters,
      this.state.sortFilter,
      newState.allServices
    );

    newState.filterLabels = this.buildFilterLabels(newState.allServices);

    this.getFilterByVariable().setState({
      getTagValuesProvider: (_, { key }) => {
        if (SERVICES_FILTERS_KEYS.includes(key)) {
          const values: MetricFindValue[] =
            newState.filterLabels?.[key].map((option) => ({
              text: option === '' ? EMPTY_FILTER_LABEL : option,
              value: option,
            })) || [];

          // Move (empty) option last in the list
          const emptyValues = remove(values, ({ text, value }) => text === EMPTY_FILTER_LABEL && value === '');
          values.push(...emptyValues);

          return Promise.resolve({ replace: true, values });
        }

        return Promise.resolve({ replace: false, values: [] });
      },
    });

    newState.pages = this.calculateNumberOfPages(this.state.pageSize, newState.filteredServices);
    newState.page = Math.min(newState.pages, this.state.page);

    newState.services = this.selectServicesForPage(newState.page, this.state.pageSize, newState.filteredServices);

    const { technologyMap, uninstrumentedCount, namespacesCount } = newState.allServices.reduce<{
      technologyMap: Partial<Record<TECHNOLOGY, number>>;
      uninstrumentedCount: number;
      namespacesCount: 0;
    }>(
      (acc, service) => {
        const technology = this.metadataService.getTechnology(service.job);
        const isInstrumented = this.metadataService.getIsInstrumented(service.job);

        acc.technologyMap[technology] = (acc.technologyMap?.[technology] ?? 0) + 1;

        if (!isInstrumented) {
          acc.uninstrumentedCount++;
        }

        if (service.serviceNamespace) {
          acc.namespacesCount++;
        }

        return acc;
      },
      {
        technologyMap: {},
        uninstrumentedCount: 0,
        namespacesCount: 0,
      }
    );

    const datasource = sceneGraph.interpolate(this, PROMETHEUS_DS.uid);
    trackServicesList(
      newState.allServices.length,
      technologyMap,
      uninstrumentedCount,
      newState.filteredServices.length,
      namespacesCount,
      isProvisionedDataSource(datasource, PROMETHEUS_DS.type as DS_TYPE)
    );

    this.setState(newState);
  }

  private getFilterByVariable(): FilterByVariable {
    return sceneGraph.lookupVariable(FILTER_BY_NAME, this) as FilterByVariable;
  }
}

function ServicesSceneRenderer({ model }: SceneComponentProps<ServicesScene>) {
  const styles = useStyles2(getStyles);

  const {
    errors,
    isDataLoading,
    isDatasourcesSelected,
    page,
    pages,
    pageSize,
    services,
    servicesSearch,
    environmentFilter,
    filterByScene,
    stackHasData,
    instrumentedFilter,
    sortFilter,
    servicesFilters,
  } = model.useState();

  if (!isDatasourcesSelected) {
    return <p>Prometheus data source required.</p>;
  }

  const errorAlert =
    errors.length > 0 ? (
      <Alert className={styles.errorAlert} severity="error" title="Failed to load services">
        <ul data-cy="error-messages">
          {errors.length > 0 &&
            errors.map((message, idx) => (
              <li key={idx} className={styles.errorItem}>
                <Text variant="code">{message}</Text>
              </li>
            ))}
        </ul>
      </Alert>
    ) : null;

  if (!stackHasData) {
    if (errorAlert) {
      return errorAlert;
    }
    if (!isDataLoading && !services.length) {
      // We finished loading and no services have been detected
      // We show the empty state with a button to add new services
      return <EmptyState mode="noServicesYet" />;
    }

    if (isDataLoading && !services.length) {
      return <LoadingPlaceholder text="Loading services..." />;
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        <div className={styles.inputWrapper} data-cy="manualFilters">
          <Input
            onChange={(evt) => {
              model.changeSearch(evt.currentTarget.value);
            }}
            value={servicesSearch}
            prefix={<Icon name="search" />}
            placeholder="Search by service name"
            data-cy="services-search"
            data-fs-element="App o11y - Search by service name"
          />

          <RadioButtonGroup
            options={[
              { label: 'Instrumented', value: 'instrumented', ariaLabel: 'Instrumented services' },
              { label: 'Uninstrumented', value: 'uninstrumented', ariaLabel: 'Uninstrumented services' },
              { label: 'All', value: 'all', ariaLabel: 'All services' },
            ]}
            value={instrumentedFilter}
            onChange={(value: 'instrumented' | 'uninstrumented' | 'all') => {
              model.changeInstrumentedFilter(value);
            }}
          />
        </div>
        <div className={styles.filters}>
          {environmentFilter && <environmentFilter.Component model={environmentFilter} />}

          {filterByScene && <filterByScene.Component model={filterByScene} />}
        </div>
      </div>

      {isDataLoading && services.length === 0 && <ServicesTablePlaceholder />}

      {errorAlert}

      {!isDataLoading && services.length === 0 && (
        <div className={styles.emptyState}>
          <EmptyState mode="noResults" />
        </div>
      )}

      {services.length > 0 && (
        <div className={styles.table}>
          {isDataLoading ? <LoadingBar width={100} /> : <div className={styles.loadingPlaceholder} />}
          <ServicesTable
            filters={servicesFilters}
            sortData={(id, desc) => model.changeSortFilter(id, desc)}
            services={services}
            handleServiceNamespaceClick={(serviceNamespace) => model.addFilter('serviceNamespace', serviceNamespace)}
            initialSort={sortFilter}
          />

          <div className={styles.paginationContainer} data-cy="services-footer">
            <label htmlFor="page-size-select" className={styles.pageSizeSelector}>
              Rows per page:
              <Select
                inputId="page-size-select"
                data-testid="page-size"
                width="auto"
                options={SELECTABLE_PAGE_SIZE_OPTIONS}
                value={pageSize}
                onChange={(v) => model.changePageSize(v.value ?? DEFAULT_PAGE_SIZE)}
              />
            </label>

            <Pagination
              currentPage={page}
              numberOfPages={pages}
              onNavigate={(page) => model.changePage(page)}
              hideWhenSinglePage
            />
          </div>
        </div>
      )}
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    errorAlert: css`
      height: fit-content;
    `,
    errorItem: css`
      margin-left: ${theme.spacing(2)};
    `,
    container: css`
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow-x: auto;

      // Account for input outline
      margin: -4px;
      & > div {
        padding: 4px;
      }
    `,
    controls: css`
      padding-bottom: ${theme.spacing(1)};
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(1)};
    `,
    inputWrapper: css`
      display: flex;
      gap: ${theme.spacing(1)};
    `,
    table: css`
      flex: 1;
      display: flex;
      flex-direction: column;
    `,
    loadingPlaceholder: css`
      margin-top: 1px;
    `,
    paginationContainer: css`
      width: 100%;
      margin-top: ${theme.spacing(4)};
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    `,
    pageSizeSelector: css`
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: ${theme.spacing(1)};
    `,
    filters: css`
      display: flex;
      gap: ${theme.spacing(2)};
      align-items: center;
      flex-wrap: wrap;
    `,
    emptyState: css`
      margin-top: ${theme.spacing(6)};
      flex-grow: 1;
    `,
  };
}
