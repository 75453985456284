// eslint-disable-next-line import/no-unresolved
import appEvents from 'grafana/app/core/app_events';
import React, { useEffect } from 'react';

import { AppEvents, DataSourceInstanceSettings } from '@grafana/data';
import { getDataSourceSrv } from '@grafana/runtime';
import { SceneComponentProps, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import { DataSourceJsonData } from '@grafana/schema';
import { Button, Checkbox, Field, Modal, Switch, Text, useStyles2 } from '@grafana/ui';

import { PROMETHEUS_DS_TYPE } from 'constants/variables';
import { baselineService } from 'services/BaselineService';
import { getDataSourceService } from 'services/DataSourceService';
import {
  trackBaselineAcknowledge,
  trackBaselineActivate,
  trackBaselineDeactivate,
  trackBaselineModalCancel,
  trackBaselineModalOpen,
} from 'utils/tracking';

import { getCommonStyles } from './styles';

export interface BaselineConfigState extends SceneObjectState {
  isLoading: boolean;
  isSaving?: boolean;
  isActivated?: boolean;
  datasource?: DataSourceInstanceSettings<DataSourceJsonData>;
}

export class BaselineConfigScene extends SceneObjectBase<BaselineConfigState> {
  static Component = BaselineConfigRenderer;

  constructor() {
    super({
      isLoading: true,
    });

    this.addActivationHandler(() => this.onActivate());
  }

  private onActivate() {
    const datasourceUid = getDataSourceService().getSelectedDataSourceUID(PROMETHEUS_DS_TYPE);
    if (datasourceUid) {
      baselineService.isBaselineActivated(datasourceUid).then((isActivated) => {
        this.setState({ isLoading: false, isActivated });
      });
      this.setState({
        datasource: getDataSourceSrv()
          .getList()
          .find((ds) => ds.uid === datasourceUid),
      });
    }
  }

  activateBaseline() {
    if (this.state.datasource) {
      this.setState({ isSaving: true });
      trackBaselineActivate();
      baselineService
        .pushBaselineRules(this.state.datasource.uid)
        .then(() => {
          appEvents.emit(AppEvents.alertSuccess, ['Baseline generation activated']);
          this.setState({ isSaving: false, isActivated: true });
        })
        .catch((e) => {
          appEvents.emit(AppEvents.alertError, ['Failed to activate baseline generation']);
          this.setState({ isSaving: false, isActivated: false });
        });
    }
  }

  deactivateBaseline() {
    if (this.state.datasource) {
      this.setState({ isSaving: true });
      trackBaselineDeactivate();
      baselineService
        .deactivateBaseline(this.state.datasource.uid)
        .then(() => {
          appEvents.emit(AppEvents.alertSuccess, ['Baseline generation deactivated']);
          this.setState({ isSaving: false, isActivated: false });
        })
        .catch(() => {
          appEvents.emit(AppEvents.alertError, ['Failed to deactivate baseline generation']);
          this.setState({ isSaving: false, isActivated: true });
        });
    }
  }
}

export function BaselineConfigRenderer({ model }: SceneComponentProps<BaselineConfigScene>) {
  const commonStyles = useStyles2(getCommonStyles);
  const { isSaving, isActivated, datasource } = model.useState();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isAcknowledged, setIsAcknowledged] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const onClickSwitch = () => {
    if (isActivated) {
      model.deactivateBaseline();
    } else {
      trackBaselineModalOpen();
      setIsModalOpen(true);
    }
  };

  const onClickEnable = () => {
    if (!isAcknowledged) {
      setIsError(true);
      return;
    }
    if (!isActivated) {
      setIsError(false);
      model.activateBaseline();
    }
  };

  useEffect(() => {
    if (isActivated && isModalOpen) {
      setIsModalOpen(false);
    }
  }, [isActivated, isModalOpen]);

  if (!datasource) {
    return null;
  }

  return (
    <>
      <hr className={commonStyles.separator} />

      <h4 className={commonStyles.sectionTitle}>Automatic baseline</h4>

      <Field
        label="Compare your metrics to a baseline"
        description={
          <>
            Automatic baseline enables you to compare RED metrics for services and operations against historic upper and
            lower thresholds. To learn more about automatic baseline, consult the{' '}
            <a
              target="_blank"
              className={commonStyles.link}
              href="https://grafana.com/docs/grafana-cloud/monitor-applications/application-observability/manual/automatic-baseline/"
              rel="noreferrer"
            >
              documentation
            </a>
            .
          </>
        }
      >
        <Switch
          disabled={isSaving}
          value={isActivated}
          onClick={onClickSwitch}
          label="Automatic baseline toggle"
          id="automatic-baseline-toggle"
        />
      </Field>

      <Modal
        title="Enable automatic baselining"
        isOpen={isModalOpen}
        closeOnBackdropClick
        closeOnEscape
        onDismiss={() => {
          trackBaselineModalCancel();
          setIsModalOpen(false);
        }}
      >
        <p>
          Enabling automatic baseline creates recording rules that incur additional costs. Automatic baseline is
          available from the dashboards 24 hours after being enabled.
        </p>
        <div
          className={commonStyles.acknowledgeContainer}
          onClick={() => {
            setIsAcknowledged(!isAcknowledged);
            setIsError(false);
            if (!isAcknowledged) {
              trackBaselineAcknowledge();
            }
          }}
          data-cy="ack-field"
        >
          <Field horizontal validationMessageHorizontalOverflow invalid={isError} error="We need your consent">
            <Checkbox
              value={isAcknowledged}
              className={commonStyles.acknowledgeCheckbox}
              data-cy="acknowledge-deactivate-checkbox"
              onClick={(evt) => evt.stopPropagation()}
            />
          </Field>
          <Text color="secondary">
            I recognize that enabling automatic baseline feature will incur additional usage towards my bill based on
            Grafana&apos;s{' '}
            <a
              onClick={(e) => e.stopPropagation()}
              href="https://grafana.com/docs/grafana-cloud/cost-management-and-billing/understand-your-invoice/metrics-invoice/?pg=pricing&plcmt=metrics-details"
              target="_blank"
              className={commonStyles.link}
              rel="noreferrer"
            >
              regular pricing
            </a>
            .
          </Text>
        </div>
        <div className={commonStyles.buttonsContainer}>
          <Button
            variant="secondary"
            onClick={() => setIsModalOpen(false)}
            disabled={isSaving}
            data-cy="close-activate-button"
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant="primary"
            disabled={isSaving}
            onClick={onClickEnable}
            className={commonStyles.activateButton}
            data-cy="perform-activate-button"
            icon={isSaving ? 'spinner' : undefined}
          >
            Enable
          </Button>
        </div>
      </Modal>
    </>
  );
}
