import React, { ReactElement } from 'react';

import { TimeRange } from '@grafana/data';
import { ComponentSize } from '@grafana/ui';

import { usePluginComponent } from 'hooks/usePluginComponent';

interface EntityAssertionsWidgetProps {
  query: {
    entityName?: string;
    entityType?: string;
    start: number;
    end: number;
    scope:
      | {
          env?: string;
          site?: string;
          namespace?: string;
        }
      | undefined;
  };
  size: ComponentSize;
  source?: string;
}

export type EntityAssertionsWidgetExternal = (props: EntityAssertionsWidgetProps) => ReactElement | null;

interface Props {
  name: string;
  namespace: string;
  range: TimeRange;
}

export function EntityAssertionsWidget({ name, range, namespace }: Props) {
  const { isLoading, component: EntityAssertionsWidgetExternal } = usePluginComponent<EntityAssertionsWidgetProps>(
    'grafana-asserts-app/entity-assertions-widget/v1'
  );

  if (isLoading || !EntityAssertionsWidgetExternal) {
    return null;
  }

  return (
    <EntityAssertionsWidgetExternal
      size="md"
      source="Application"
      query={{
        start: range.from.valueOf(),
        end: range.to.valueOf(),
        entityName: name,
        entityType: 'Service',
        scope: {
          namespace,
        },
      }}
    />
  );
}
