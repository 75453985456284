import { SceneQueryRunner } from '@grafana/scenes';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { PROMETHEUS_DS } from 'constants/datasources';
import { DEFAULT_FIELD_CONFIG } from 'constants/panels';

export function makeServiceJvmThreadsPanel(): ExplorablePanel {
  return new ExplorablePanel({
    description: 'Number of currently executing (also called "live") threads',

    title: 'Threads',

    trackingSection: 'service',
    trackingPage: 'jvm',
    trackingPanel: 'threads',

    fieldConfig: {
      defaults: {
        ...DEFAULT_FIELD_CONFIG,
        unit: 'none',
      },
      overrides: [],
    },

    $data: new SceneQueryRunner({
      datasource: PROMETHEUS_DS,
      maxDataPointsFromWidth: true,
      queries: [
        {
          expr: 'sum by (instance) ({__name__=~"jvm_thread_count|process_runtime_jvm_threads_count", job="${job}", instance=~"${instance:regex}"})\nor\nsum by (instance) (jvm_threads_live{job="${job}", instance=~"${instance:regex}"})',
          legendFormat: '{{instance}}',
          refId: 'serviceJvmThreads',
        },
      ],
    }),
  });
}
