import { EmbeddedScene, SceneFlexLayout, SceneRouteMatch } from '@grafana/scenes';

import { EnvironmentFilterScene } from 'components/EnvironmentFilter/EnvironmentFilterScene';
import { FilterByScene } from 'components/FilterBy/FilterByScene';
import { makeVariables } from 'utils/variables';

import { SERVICE_MAP_VIEW_NAME } from './makeServiceMapPage';
import { ServiceMapScene } from './panels/ServiceMapScene';

export function makeServiceMapScene(): (routeMatch: SceneRouteMatch<any>) => EmbeddedScene {
  return () =>
    new EmbeddedScene({
      $variables: makeVariables({
        usePrometheus: true,
        useTempo: true,
        useEnvironmentFilter: true,
        useFilterBy: true,
        viewName: SERVICE_MAP_VIEW_NAME,
      }),
      body: new SceneFlexLayout({
        direction: 'column',
        children: [
          new SceneFlexLayout({
            direction: 'row',
            wrap: 'wrap',
            children: [new EnvironmentFilterScene(), new FilterByScene()],
          }),
          new ServiceMapScene(),
        ],
      }),
    });
}
