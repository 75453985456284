import React from 'react';

import {
  CustomVariable,
  MultiValueVariable,
  SceneComponentProps,
  VariableCustomFormatterFn,
  VariableValue,
  VariableValueOption,
} from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';

import { GROUP_BY_NAME } from 'constants/variables';
import { normalizeLabel } from 'utils/format';
import { EMPTY_GROUP_BY_VALUE, getGroupByVariableOptions } from 'utils/groupByFilterBy';

import { GroupByComponent } from './GroupBy/GroupByComponent';

export class GroupByVariable extends CustomVariable {
  static Component = (props: SceneComponentProps<MultiValueVariable>) => (
    <GroupByComponent {...(props as unknown as SceneComponentProps<GroupByVariable>)} />
  );

  constructor() {
    super({
      description: 'The attribute that reflects which value to group by',
      hide: VariableHide.hideVariable,
      isMulti: true,
      label: 'Group by',
      name: GROUP_BY_NAME,
      options: [],
      query: getGroupByVariableOptions(),
    });
  }

  setEmpty() {
    this.changeValueTo([EMPTY_GROUP_BY_VALUE]);
  }

  override getValue(): VariableValue {
    const value = (Array.isArray(this.state.value) ? this.state.value : [this.state.value]) as string[];
    if (value.includes(EMPTY_GROUP_BY_VALUE)) {
      return {
        formatter: (formatNameOrFn: string | VariableCustomFormatterFn) => {
          // handle default passed as formatter
          if (typeof formatNameOrFn === 'string' && formatNameOrFn.startsWith('legend')) {
            return formatNameOrFn.split(':')[1] ?? '';
          }
          return '';
        },
      };
    }

    return {
      formatter: (formatNameOrFn: string | VariableCustomFormatterFn) => {
        const formatted = value.join(', ');

        if (value.length > 0) {
          if (typeof formatNameOrFn === 'string' && formatNameOrFn.startsWith('legend')) {
            const prefix = formatNameOrFn.startsWith('legenddownstream') ? 'client_' : '';
            if (value.length === 1) {
              return `{{ ${prefix}${value[0]} }}`;
            }
            return value.map((item) => `${normalizeLabel(item)}={{ ${prefix}${item} }}`).join(', ');
          } else if (formatNameOrFn === 'append') {
            return `, ${formatted}`;
          } else if (formatNameOrFn === 'appenddownstream') {
            return `, ${value.map((v) => `client_${v}`).join(', ')}`;
          } else if (formatNameOrFn === 'prepend') {
            return `${formatted}, `;
          } else {
            return formatted;
          }
        }

        return formatted;
      },
    };
  }

  override getOptionsForSelect(): VariableValueOption[] {
    const value = (Array.isArray(this.state.value) ? this.state.value : [this.state.value]) as string[];

    return value.length > 0
      ? this.state.options.filter(({ value }) => value !== EMPTY_GROUP_BY_VALUE)
      : this.state.options;
  }

  isEmpty() {
    const value = this.state.value as string[];

    return value.length === 0 || (value.length === 1 && value.at(0) === EMPTY_GROUP_BY_VALUE);
  }

  updateQuery() {
    this.setState({ query: getGroupByVariableOptions() });
    this.validateAndUpdate().subscribe();
  }
}
