import { css } from '@emotion/css';
import React, { forwardRef, PropsWithChildren } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

export interface FilterLabelProps {
  dataCy?: string;
}

export const FilterLabel = forwardRef<HTMLLabelElement, PropsWithChildren<FilterLabelProps>>(
  ({ children, dataCy }, ref) => {
    const styles = useStyles2(getStyles);

    return (
      <label ref={ref} className={styles.label} htmlFor="groupBySelector" data-cy={dataCy ?? 'filterLabel'}>
        {children}
      </label>
    );
  }
);

FilterLabel.displayName = 'FilterLabel';

function getStyles(theme: GrafanaTheme2) {
  return {
    label: css({
      background: theme.isDark ? theme.colors.background.primary : theme.colors.background.secondary,
      display: `flex`,
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.bodySmall.fontSize,
      height: theme.spacing(theme.components.height.md),
      lineHeight: theme.spacing(theme.components.height.md),
      borderRadius: theme.shape.borderRadius(1),
      border: `1px solid ${theme.components.input.borderColor}`,
      position: 'relative',
      // To make the border line up with the input border
      right: -1,
      whiteSpace: 'nowrap',
    }),
  };
}
