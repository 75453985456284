import { css, cx } from '@emotion/css';
import { isEqual } from 'lodash';
import React from 'react';
import { concatMap, map, tap } from 'rxjs';

import { DataFrame, GrafanaTheme2, LoadingState } from '@grafana/data';
import {
  PanelBuilders,
  SceneComponentProps,
  SceneDataTransformer,
  sceneGraph,
  SceneObjectBase,
  SceneObjectRef,
  SceneObjectState,
  SceneQueryRunner,
  VariableDependencyConfig,
  VizPanel,
} from '@grafana/scenes';
import { useStyles2 } from '@grafana/ui';

import { EmptyState } from 'components/EmptyState';
import { FilterByVariable } from 'components/FilterByVariable';
import { TEMPO_DS } from 'constants/datasources';
import { ENVIRONMENT_ATTRIBUTE_NAME, ENVIRONMENT_VALUE_NAME, FILTER_BY_NAME, TEMPO_DS_NAME } from 'constants/variables';
import { getPluginConfigService } from 'services/PluginConfigService';
import { addLinkToNodes, filterOutUser, getServiceMapQuery } from 'utils/serviceMap';

export interface ServiceMapSceneState extends SceneObjectState {
  queryRunnerRef?: SceneObjectRef<SceneQueryRunner>;
  serviceMapPanel?: VizPanel;
  state: LoadingState;
  items?: number;
  stackHasData?: boolean;
}

export class ServiceMapScene extends SceneObjectBase<ServiceMapSceneState> {
  static Component = ServiceMapSceneRenderer;

  protected _variableDependency = new VariableDependencyConfig(this, {
    variableNames: [TEMPO_DS_NAME, FILTER_BY_NAME, ENVIRONMENT_ATTRIBUTE_NAME, ENVIRONMENT_VALUE_NAME],
  });

  constructor() {
    super({
      state: LoadingState.Loading,
      stackHasData: getPluginConfigService().checkStackHasData(),
    });

    this.addActivationHandler(() => {
      const queryRunner = new SceneQueryRunner({
        datasource: TEMPO_DS,
        queries: [getServiceMapQuery(this)],
      });

      const serviceMapPanel = PanelBuilders.nodegraph()
        .setTitle('')
        .setData(
          new SceneDataTransformer({
            transformations: [
              () => (source) => source.pipe(map(filterOutUser)),
              () => (source) => source.pipe(concatMap(addLinkToNodes)),
              () => (source) => source.pipe(tap(this.setAvailableItems.bind(this))),
            ],

            $data: queryRunner,
          })
        )
        .build();

      this.setState({
        serviceMapPanel: serviceMapPanel,
        queryRunnerRef: queryRunner.getRef(),
      });

      const unsubscribable = (sceneGraph.lookupVariable(FILTER_BY_NAME, this) as FilterByVariable).subscribeToState(
        (newState, oldState) => {
          if (!isEqual(newState.filterExpression, oldState.filterExpression)) {
            const queryRunner = this.state.queryRunnerRef?.resolve();
            queryRunner?.setState({ queries: [getServiceMapQuery(this)] });
            queryRunner?.cancelQuery();
            queryRunner?.runQueries();
          }
        }
      );

      return () => unsubscribable.unsubscribe();
    });
  }

  private setAvailableItems(frames: DataFrame[]) {
    const loadingState = this.state.queryRunnerRef?.resolve().state.data?.state;
    const newState: Partial<ServiceMapSceneState> = { state: loadingState };

    if (loadingState === LoadingState.Done) {
      newState.items = frames.reduce((acc, frame) => (acc += frame.length), 0);

      if (newState.items > 0 && !this.state.stackHasData) {
        newState.stackHasData = true;
        getPluginConfigService().updateStackHasData();
      }
    }

    this.setState(newState);
  }
}

function ServiceMapSceneRenderer({ model }: SceneComponentProps<ServiceMapScene>) {
  const styles = useStyles2(getStyles);
  const { serviceMapPanel, state, items, stackHasData } = model.useState();
  const isEmpty = state === LoadingState.Done && items === 0;

  return (
    <>
      {isEmpty && (
        <div className={styles.emptyState}>
          <EmptyState mode={stackHasData ? 'noResults' : 'noServicesYet'} />
        </div>
      )}

      <div className={cx(styles.panel, { [styles.hidePanel]: isEmpty })}>
        {serviceMapPanel && <serviceMapPanel.Component model={serviceMapPanel} />}
      </div>
    </>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    panel: css`
      display: initial;
      width: 100%;
      height: 100%;
    `,
    hidePanel: css`
      label: hidePanel;
      display: none;
    `,
    emptyState: css`
      height: 100%;
    `,
  };
}
