import { isEqual } from 'lodash';

import {
  TRACEQL_SEARCH_ENVIRONMENT_ID,
  TRACEQL_SEARCH_OPERATION_ID,
  TRACEQL_SEARCH_SCOPE,
  TRACEQL_SEARCH_SERVICE_NAME_ID,
  TRACEQL_SEARCH_SERVICE_NAMESPACE_ID,
  TRACEQL_SEARCH_TYPE,
} from 'constants/query';
import { ALL_VARIABLE_REGEX } from 'constants/variables';
import { TempoQuery, TraceqlFilter } from 'types/queries';

export const TRACKED_IDS = Object.freeze([
  TRACEQL_SEARCH_SERVICE_NAMESPACE_ID,
  TRACEQL_SEARCH_ENVIRONMENT_ID,
  TRACEQL_SEARCH_SERVICE_NAME_ID,
]);

export function isValidQuery(query: TempoQuery | undefined | null, queryScope: TempoQuery): boolean {
  if (!query || !query.query) {
    return false;
  }

  const trackedIds = [...TRACKED_IDS];
  // only require operation (span name) if it's in scope (operation page)
  if (queryScope.filters?.find(({ id }) => id === TRACEQL_SEARCH_OPERATION_ID)) {
    trackedIds.push(TRACEQL_SEARCH_OPERATION_ID);
  }

  const queryFilters = query.filters?.filter(({ id }) => trackedIds.includes(id));
  const scopeFilters = queryScope.filters?.filter(({ id }) => trackedIds.includes(id));
  if (scopeFilters?.length !== queryFilters?.length) {
    return false;
  }

  if (query.queryType === TRACEQL_SEARCH_TYPE) {
    return (
      scopeFilters?.every((filter) => {
        const queryFilter = (query.filters ?? []).find((queryFilter) => queryFilter.id === filter.id);
        return isEqual(filter, queryFilter);
      }) ?? false
    );
  }

  const queryStringToMatch = query.query.match(/{(.*)?}/gm);
  if (!queryStringToMatch || queryStringToMatch.length === 0) {
    return false;
  }

  // We only want to check the scope at the beginning of the query
  const queryString = queryStringToMatch.at(0);

  return (scopeFilters || []).every(({ scope, tag, value, operator }) => {
    const resource = scope === TRACEQL_SEARCH_SCOPE.SPAN ? tag : `${scope}.${tag}`;
    const hasValue = Array.isArray(value)
      ? value.every((v) => query.query?.includes(v))
      : query.query?.includes(value || '');

    const hasMatchWithoutSpace = queryString?.includes(`${resource}${operator}`);
    const hasMatchWithSpace = queryString?.includes(`${resource} ${operator}`);

    return (hasMatchWithSpace || hasMatchWithoutSpace) && hasValue;
  });
}

export function removeAllValueFromQuery(query: string | undefined, environmentAttribute: string) {
  // In traceql, setting All means we need to remove the deployment attribute completely
  return (query || '').replace(` && resource.${environmentAttribute}=~"${ALL_VARIABLE_REGEX}"`, '');
}

export function removeAllVariableFromFilters(filters: TraceqlFilter[] | undefined) {
  // In traceql, setting All means we need to remove the deployment attribute completely
  return (filters || []).reduce((acc, filter) => {
    if (filter.id === TRACEQL_SEARCH_ENVIRONMENT_ID) {
      if (isEqual(filter.value, [ALL_VARIABLE_REGEX])) {
        return acc;
      }
    }

    return [...acc, filter];
  }, [] as TraceqlFilter[]);
}
