import { EmbeddedScene, SceneFlexLayout, SceneRouteMatch } from '@grafana/scenes';

import { EnvironmentFilterScene } from 'components/EnvironmentFilter/EnvironmentFilterScene';
import { ServiceEmbeddedScene } from 'modules/service/components/ServiceEmbeddedScene';
import { TracesScene } from 'modules/service/components/TracesScene/TracesScene';
import { makeVariables } from 'utils/variables';

export function makeServiceTracesScene(
  job: string,
  encodedJob: string
): (routeMatch: SceneRouteMatch<any>) => EmbeddedScene {
  return () =>
    new ServiceEmbeddedScene({
      $variables: makeVariables({ useTempo: true }),
      body: new SceneFlexLayout({
        direction: 'column',
        children: [new EnvironmentFilterScene(), new TracesScene({ encodedJob, job })],
      }),
    }) as unknown as EmbeddedScene;
}
