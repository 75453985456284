import { map } from 'rxjs';

import { DataLink, MutableDataFrame } from '@grafana/data';
import { PanelBuilders, SceneDataProvider, SceneDataTransformer, VizPanel } from '@grafana/scenes';

import { ROUTES } from 'constants/routing';
import { TempoQuery } from 'types/queries';

export function makeTracesTablePanel(
  $data: SceneDataProvider,
  encodedJob: string,
  encodedOperation: string | undefined,
  getQuery: () => TempoQuery | undefined
): VizPanel<any, any> {
  let baseUrl = ROUTES.overview(encodedJob);

  if (encodedOperation) {
    baseUrl = `${baseUrl}/operations/${encodedOperation}`;
  }

  baseUrl = `${baseUrl}/traces?\${__url_time_range}&\${__all_variables}`;

  const spanIdDataLink: DataLink = {
    title: 'Span: ${__value.raw}',
    url: '',
    onBuildUrl: () => {
      const tempoQuery = getQuery();
      const url = baseUrl + '&traceId=${__data.fields.traceIdHidden}&spanId=${__value.raw}';
      if (tempoQuery) {
        return url + `&tracesQuery=${encodeURIComponent(JSON.stringify(tempoQuery))}`;
      }
      return url;
    },
  };

  const transformer = new SceneDataTransformer({
    $data,
    transformations: [
      () => (source) =>
        source.pipe(
          map((frames) =>
            (frames ?? [])
              // Hide streaming progress as it prevents the traces table from rendering
              .filter((frame) => frame.refId !== 'streaming-progress')
              // add links to nested span fields
              .map((frame) => ({
                ...frame,
                fields: frame.fields.filter((field) => {
                  if (field.name === 'nested') {
                    field.values.forEach((frames: MutableDataFrame[]) => {
                      frames.forEach((frame) => {
                        const spanIdField = frame.fields?.find((f) => f.name === 'spanID');
                        if (spanIdField) {
                          spanIdField.config.links = [spanIdDataLink];
                        }
                      });
                    });
                  }
                  return true;
                }),
              }))
          )
        ),
    ],
  });

  return PanelBuilders.table()
    .setTitle('Traces')
    .setOption('footer', { enablePagination: true })
    .setOverrides((builder) => {
      builder.matchFieldsWithName('traceID').overrideLinks([
        {
          title: 'Trace: ${__value.raw}',
          url: '',
          onBuildUrl: () => {
            const url = baseUrl + '&traceId=${__value.raw}';
            const tempoQuery = getQuery();
            if (tempoQuery) {
              return url + `&tracesQuery=${encodeURIComponent(JSON.stringify(tempoQuery))}`;
            }
            return url;
          },
        },
      ]);
      builder.matchFieldsWithName('spanID').overrideLinks([spanIdDataLink]);
    })
    .setData(transformer)
    .build();
}
