import { css } from '@emotion/css';
import React, { useState } from 'react';

import { AppEvents, GrafanaTheme2 } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Checkbox, Field, Form, Modal, Spinner, Text, TextLink, useStyles2 } from '@grafana/ui';

import { ACCESS_CONTROL } from 'constants/accessControl';
import { getFaro } from 'faro/instance';
import { getOverridesService } from 'services/OverridesService';
import { trackOptinAcknowledge, trackOptinActivate } from 'utils/tracking';

export interface InitializeModalProps {
  isOpened: boolean;
  page: 'initialize' | 'config';
  onClose: () => void;
  onInitialize: () => void;
}

export const InitializeModal = ({ isOpened, page, onClose, onInitialize }: InitializeModalProps) => {
  const styles = useStyles2(getStyles);

  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [isInitializing, setIsInitializing] = useState(false);

  const toggleModal = () => {
    setIsAcknowledged(false);
    onClose();
  };

  const activate = async () => {
    setIsInitializing(true);

    try {
      await getOverridesService().initializeOverrides();

      getAppEvents().publish({
        type: AppEvents.alertSuccess.name,
        payload: ['Application Observability was enabled'],
      });

      trackOptinActivate(page);
      onInitialize();
    } catch (err) {
      getFaro()?.api.pushError(err instanceof Error ? err : new Error('Could not activate Application Observability'));

      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: ['Could not activate Application Observability'],
      });
    } finally {
      setIsInitializing(false);
    }
  };

  return (
    <Modal
      title="Enable metrics generation for the selected data source"
      isOpen={isOpened}
      closeOnBackdropClick={!isInitializing}
      closeOnEscape={!isInitializing}
      onDismiss={() => {
        if (isInitializing) {
          return;
        }

        toggleModal();
      }}
    >
      <div className={styles.description}>
        <Text color="secondary">
          To use Application Observability with the selected data source, metrics need to be automatically generated
          from the traces you have already sent to Grafana Cloud Traces. These metrics will count towards your current
          usage and bill for this stack. There is no additional cost for Grafana Cloud Free accounts.
        </Text>

        <Text color="secondary">
          Warning: if you have applied custom dimensions as labels via Grafana Support, enabling metrics generation will
          overwrite that configuration.
        </Text>
      </div>

      <Form
        className={styles.form}
        onSubmit={() => {
          if (!isAcknowledged) {
            return;
          }

          activate();
        }}
      >
        {({ register, errors }) => (
          <>
            <div
              className={styles.acknowledgeContainer}
              onClick={() => {
                setIsAcknowledged(!isAcknowledged);

                if (!isAcknowledged) {
                  trackOptinAcknowledge('initialize');
                }
              }}
              data-cy="activate-modal"
            >
              <Field
                horizontal
                validationMessageHorizontalOverflow
                invalid={!!errors.acknowledge && !isAcknowledged}
                error="We need your consent"
              >
                <Checkbox
                  {...register('acknowledge', { validate: (value) => !!value || isAcknowledged })}
                  value={isAcknowledged}
                  className={styles.acknowledgeCheckbox}
                  data-cy="acknowledge-price-checkbox"
                  onClick={(evt) => evt.stopPropagation()}
                />
              </Field>

              <Text color="secondary">
                I recognize that enabling automatic metrics generation will incur additional usage towards my bill based
                on Grafana&lsquo;s{' '}
                <TextLink
                  href="https://grafana.com/pricing"
                  external
                  inline={false}
                  onClick={(e) => e.stopPropagation()}
                >
                  regular pricing
                </TextLink>
              </Text>
            </div>

            <div className={styles.buttonsContainer}>
              <Button
                variant="secondary"
                onClick={toggleModal}
                disabled={isInitializing}
                data-cy="close-activate-button"
              >
                Cancel
              </Button>

              <Button
                type="submit"
                variant="primary"
                disabled={isInitializing || !ACCESS_CONTROL.PLUGIN.INITIALIZE}
                className={styles.activateButton}
                data-cy="perform-activate-button"
              >
                {isInitializing ? (
                  <>
                    <Spinner className={styles.activateButtonIcon} inline />
                    Activating
                  </>
                ) : (
                  <>Activate</>
                )}
              </Button>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  form: css`
    max-width: initial;
  `,

  acknowledgeContainer: css`
    cursor: pointer;
    user-select: none;

    display: flex;
    gap: ${theme.spacing(1)};
  `,

  acknowledgeCheckbox: css`
    margin-right: ${theme.spacing(1)};
  `,

  pricingLink: css`
    text-decoration: underline;
  `,

  buttonsContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: ${theme.spacing(2)} 0 0 0;
  `,

  activateButton: css`
    margin: 0 0 0 ${theme.spacing(1)};
  `,
  activateButtonIcon: css`
    margin-right: ${theme.spacing(0.5)};
  `,

  description: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};

    margin-bottom: ${theme.spacing(3)};
  `,
});
