import { EmbeddedScene, SceneFlexItem, SceneFlexLayout, SceneRouteMatch } from '@grafana/scenes';

import { EnvironmentFilterScene } from 'components/EnvironmentFilter/EnvironmentFilterScene';
import { FilterByScene } from 'components/FilterBy/FilterByScene';
import { ServiceEmbeddedScene } from 'modules/service/components/ServiceEmbeddedScene';
import { ServiceOverviewServiceMapScene } from 'modules/service/overview/panels/ServiceOverviewServiceMapScene';
import { getMetadataService } from 'services/MetadataService';
import { makeVariables } from 'utils/variables';

export const SERVICE_OVERVIEW_SERVICE_MAP = 'service-overview-service-map';

export function makeServiceServiceMapScene(job: string): (routeMatch: SceneRouteMatch<any>) => EmbeddedScene {
  return () =>
    new ServiceEmbeddedScene({
      $variables: makeVariables({
        useTempo: true,
        job,
        isInstrumented: getMetadataService().getIsInstrumented(job),
        useFilterBy: true,
        viewName: SERVICE_OVERVIEW_SERVICE_MAP,
      }),
      body: new SceneFlexLayout({
        direction: 'column',
        children: [
          new SceneFlexItem({
            body: new ServiceOverviewServiceMapScene({ job }),
          }),
          new SceneFlexLayout({
            direction: 'row',
            wrap: 'wrap',
            children: [new EnvironmentFilterScene(), new FilterByScene()],
          }),
          new ServiceOverviewServiceMapScene({ job }),
        ],
      }),
    }) as unknown as EmbeddedScene;
}
