import { css, cx } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Text, useStyles2 } from '@grafana/ui';

import logo from 'img/logos/applicationObservability.svg';
import openTelemetryLogo from 'img/logos/opentelemetry.svg';
import prometheusLogo from 'img/logos/prometheus.svg';
import plusSign from 'img/plus.svg';

import { getCommonStyles } from './commonStyles';

export interface TitleProps {
  onInitialize: () => void;
}

export const Title = ({ onInitialize }: TitleProps) => {
  const commonStyles = useStyles2(getCommonStyles);
  const styles = useStyles2(getStyles);

  return (
    <>
      <div className={commonStyles.containerCenter}>
        <img height={81} width={81} src={logo} alt="Application Observability logo" />

        <h1 className={cx(commonStyles.title, styles.title)}>
          Observe and monitor the performance of your organisation&lsquo;s applications.
        </h1>

        <div className={styles.text}>
          <Text element="h5" variant="h5" color="secondary">
            Powered by Grafana Cloud, OpenTelemetry semantic conventions, and the Prometheus data-model. Detect
            anomalies, identify root causes, and fix problems.
          </Text>
        </div>

        <div className={styles.logoContainer}>
          <img src={openTelemetryLogo} alt="OpenTelemetry logo" className={styles.logo} />
          <img src={plusSign} alt="Plus sign" className={styles.plus} />
          <img src={prometheusLogo} alt="Prometheus logo" className={styles.logo} />
        </div>
      </div>

      <hr className={commonStyles.hr} />
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  title: css`
    max-width: 520px;
    margin: ${theme.spacing(3)} 0;
  `,

  text: css`
    max-width: 520px;
  `,

  logoContainer: css`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: ${theme.spacing(3)};

    margin-top: ${theme.spacing(3)};
  `,

  logo: css`
    height: 58px;
  `,

  plus: css`
    height: 28px;
  `,
});
