import React from 'react';

import { SceneAppPage, SceneAppPageLike, SceneRouteMatch } from '@grafana/scenes';

import { PRESERVE_URL_KEYS, ROUTES } from 'constants/routing';
import { TrackedSceneAppPage } from 'faro/TrackedSceneAppPage';
import { makeCommonControls } from 'utils/controls';
import { isPanelType, panelTypeToTitle } from 'utils/maxPanel';
import { makeTimeRange } from 'utils/timeRange';
import { makeVariables } from 'utils/variables';

import { makeMaxPanelScene } from './makeMaxPanelScene';
import { MaxPanelTitle } from './maxPanelTitle';

export function makeMaxPanelPage(
  job: string,
  encodedJob: string
): (routeMatch: SceneRouteMatch<any>, parent: SceneAppPageLike) => SceneAppPage {
  return (routeMatch, parent) => {
    const {
      params: { panel },
    } = routeMatch;
    const title = isPanelType(panel) ? panelTypeToTitle[panel] : '';

    return new TrackedSceneAppPage({
      title,
      renderTitle: () => <MaxPanelTitle title={title} job={job} encodedJob={encodedJob} />,
      viewName: 'maxpanel',
      url: ROUTES.maxPanel(encodedJob, panel),
      preserveUrlKeys: PRESERVE_URL_KEYS,

      $variables: makeVariables({
        usePrometheus: true,
        useTempo: true,
        useEnvironmentFilter: true,
        job,
        useGroupByFilter: true,
        useFilterBy: true,
      }),

      $timeRange: makeTimeRange(),

      controls: makeCommonControls(true),

      getParentPage: () => parent,
      getScene: makeMaxPanelScene(job, undefined, panel),
    });
  };
}
