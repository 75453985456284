import React from 'react';

import { SceneAppPageLike, SceneAppPageState, SceneObjectRef, SceneRouteMatch } from '@grafana/scenes';

import { DeclareIncidentControl } from 'components/DeclareIncidentControl';
import { HostModalScene } from 'components/HostModal';
import { MenuControlScene } from 'components/MenuControl';
import { NotificationScene } from 'components/Notification';
import { ServicePageSubtitle } from 'components/ServicePageSubtitle';
import { PRESERVE_URL_KEYS, ROUTE_DEFINITIONS, ROUTES } from 'constants/routing';
import { TrackedSceneAppPage } from 'faro/TrackedSceneAppPage';
import { makeServiceMetadataLoaderScene } from 'modules/service/components/ServiceMetadataLoaderScene';
import { getMetadataService } from 'services/MetadataService';
import { makeCommonControls } from 'utils/controls';
import { extractOperationFromRoute } from 'utils/services';
import { makeTimeRange } from 'utils/timeRange';
import { getTitleFactory, onRefreshMetadataBehavior } from 'utils/title';
import { makeVariables } from 'utils/variables';

import { makeOperationOverviewPage } from './overview/makeOperationOverviewPage';
import { makeOperationTracesPage } from './traces/makeOperationTracesPage';
import { makeOperationMaxPanelPage } from '../maxPanel/makeOperationMaxPanelPage';

export function makeOperationPage(
  job: string,
  encodedJob: string,
  notificationRef: SceneObjectRef<NotificationScene>,
  hostModalRef: SceneObjectRef<HostModalScene>
): (routeMatch: SceneRouteMatch<any>, parent: SceneAppPageLike) => TrackedSceneAppPage {
  return (routeMatch, parent) => {
    const { encodedOperation, operation } = extractOperationFromRoute(routeMatch);
    const tab = ['operations', 'panels'].includes(routeMatch.params.operationTab)
      ? undefined
      : routeMatch.params.operationTab;

    const metadataService = getMetadataService();
    const getTabs = () => [
      makeOperationOverviewPage(job, encodedJob, operation, encodedOperation),
      makeOperationTracesPage(job, encodedJob, operation, encodedOperation),
    ];
    const tabs = metadataService.isServiceStored(job) && metadataService.hasIsInstrumented(job) ? getTabs() : undefined;
    const url =
      tab && !tabs
        ? ROUTES.operationWithTab(encodedJob, encodedOperation, tab)
        : ROUTES.operation(encodedJob, encodedOperation);

    const page: TrackedSceneAppPage = new TrackedSceneAppPage({
      viewName: 'operation',
      title: operation,
      url,
      renderTitle: () => getTitleFactory({ job, operation }),
      subTitle: (
        <ServicePageSubtitle notificationRef={notificationRef} hostModalRef={hostModalRef} job={job} model={parent} />
      ),
      preserveUrlKeys: PRESERVE_URL_KEYS,
      getParentPage: () => parent,
      getScene: makeServiceMetadataLoaderScene(job),

      $variables: makeVariables({
        usePrometheus: true,
        useEnvironmentFilter: true,
        job,
        operation,
        useGroupByFilter: true,
        useFilterBy: true,
      }),

      $timeRange: makeTimeRange(),

      controls: [
        ...makeCommonControls(true),
        new DeclareIncidentControl(job, operation),
        new MenuControlScene({ job, operation }),
      ],

      drilldowns: [
        {
          routePath: ROUTE_DEFINITIONS.maxOperationPanel,
          getPage: makeOperationMaxPanelPage(job, encodedJob, operation, encodedOperation),
        },
      ],
      tabs,
      $behaviors: [
        onRefreshMetadataBehavior<SceneAppPageState>(() => ({
          renderTitle: () => getTitleFactory({ job, operation }),
          tabs: page.state.tabs ?? getTabs(),
          url: ROUTES.operation(encodedJob, encodedOperation),
        })),
      ],
    });

    return page;
  };
}
