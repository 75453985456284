import { css } from '@emotion/css';
import React, { useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { EmbeddedScene } from '@grafana/scenes';
import { ConfirmModal, Field, RadioButtonGroup, Switch, Text, useStyles2 } from '@grafana/ui';

import { LogQueryMode } from 'constants/logs';
import { getPluginConfigService } from 'services/PluginConfigService';
import { getDefaultLogQueryValues, userHasDefaultQueries } from 'utils/logs';

import { LogsQueryForm } from './LogsQueryForm';

export interface LogsQueryTabProps {
  getScene: () => EmbeddedScene;
}

type ConfirmModalState = { isOpen: false; value: null } | { isOpen: true; value: LogQueryMode };

export const LogsQueryTab = (_props: LogsQueryTabProps) => {
  const styles = useStyles2(getStyles);
  const [
    {
      logsQueryFormatting,
      logsQueryWithNamespace,
      logsQueryWithoutNamespace,
      logsFilterByTraceId,
      logsFilterBySpanId,
      defaultLogQueryMode = LogQueryMode.json,
    },
    setState,
  ] = useState(getPluginConfigService().getPluginConfig());

  const [currentFilterByTraceId, setCurrentFilterByTraceId] = useState(logsFilterByTraceId ?? false);
  const [currentFilterBySpanId, setCurrentFilterBySpanId] = useState(logsFilterBySpanId ?? false);

  const [isSaving, setIsSaving] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<ConfirmModalState>({ isOpen: false, value: null });

  const wrap = <T extends any[], U>(fn: (...args: T) => Promise<U>) => {
    return async (...args: T): Promise<U> => {
      try {
        setIsSaving(true);
        return await fn(...args);
      } finally {
        setIsSaving(false);
      }
    };
  };

  const setLogsQueryWithoutNamespace = wrap(async (value: string | undefined) => {
    if (value === '') {
      return;
    }

    await getPluginConfigService().savePluginConfig({
      logsQueryWithoutNamespace: value?.trim(),
    });

    setState(getPluginConfigService().getPluginConfig());
  });

  const setLogsQueryWithNamespace = wrap(async (value: string | undefined) => {
    if (value === '') {
      return;
    }

    await getPluginConfigService().savePluginConfig({
      logsQueryWithNamespace: value?.trim(),
    });

    setState(getPluginConfigService().getPluginConfig());
  });

  const setLogsQueryFormatting = wrap(async (value: string | undefined) => {
    await getPluginConfigService().savePluginConfig({
      logsQueryFormatting: value?.trim(),
    });

    setState(getPluginConfigService().getPluginConfig());
  });

  const setLogsFilterByTraceId = wrap(async (value: boolean) => {
    setCurrentFilterByTraceId(value);
    await getPluginConfigService().savePluginConfig({
      logsFilterByTraceId: value,
    });
  });

  const setLogsFilterbySpanId = wrap(async (value: boolean) => {
    setCurrentFilterBySpanId(value);
    await getPluginConfigService().savePluginConfig({ logsFilterBySpanId: value });
  });

  const updateDefaultLogQueryMode = wrap(async (value: LogQueryMode) => {
    const defaults = getDefaultLogQueryValues(value);

    await getPluginConfigService().savePluginConfig({
      defaultLogQueryMode: value,
      logsQueryFormatting: defaults.defaultLogQueryFormatting,
      logsQueryWithNamespace: defaults.defaultQueryWithNamespace,
      logsQueryWithoutNamespace: defaults.defaultQueryWithoutNamespace,
    });

    setState(getPluginConfigService().getPluginConfig());
  });

  return (
    <>
      <Field
        data-cy="logQueryFormatField"
        label="Default log query format"
        description={
          <>
            <Text element="p" color="secondary" variant="bodySmall">
              Depending on your logs ingestion path, you might want to set a different type of default query:
            </Text>

            <ul className={styles.formatOptions}>
              <li>
                <Text color="secondary" variant="bodySmall">
                  Use Loki exporter query if you are exporting logs directly to Loki using `loki-exporter`.
                </Text>
              </li>
              <li>
                <Text color="secondary" variant="bodySmall">
                  Use OTLP gateway / native Loki otlp query if you are exporting logs via Grafana OTLP gateway or Loki
                  native OTLP endpoint
                </Text>
              </li>
            </ul>
          </>
        }
      >
        <RadioButtonGroup
          disabled={isSaving}
          options={[
            { label: 'Loki exporter query', value: LogQueryMode.json },
            { label: 'OTLP gateway / native Loki otlp query', value: LogQueryMode.otlp },
          ]}
          value={defaultLogQueryMode}
          onChange={(event) => {
            if (
              userHasDefaultQueries(
                defaultLogQueryMode,
                logsQueryFormatting,
                logsQueryWithNamespace,
                logsQueryWithoutNamespace
              )
            ) {
              updateDefaultLogQueryMode(event);
            } else {
              // Only show confirmation when the user has updated the default queries
              setIsConfirmModalOpen({ isOpen: true, value: event });
            }
          }}
        />
      </Field>

      <ConfirmModal
        isOpen={isConfirmModalOpen.isOpen}
        title="Update default log query format"
        body={
          <>
            <Text element="p" variant="body" color="secondary">
              Grafana OTLP gateway has been updated and log format has changed. If you are exporting logs via OTLP
              gateway, you may need to update your queries.
            </Text>
            <br />
            <Text element="p" variant="body" color="secondary">
              Updating the default log query format will overwrite changes you have made to your default queries below.
              Copy any queries you would like to keep before updating.
            </Text>
            <br />
            <Text element="p" variant="body" color="secondary">
              Are you sure you want to proceed?
            </Text>
          </>
        }
        confirmText={'Yes, update default format'}
        confirmButtonVariant="destructive"
        onConfirm={() => {
          if (isConfirmModalOpen.value) {
            updateDefaultLogQueryMode(isConfirmModalOpen.value);
          }
          setIsConfirmModalOpen({ isOpen: false, value: null });
        }}
        onDismiss={() => {
          setIsConfirmModalOpen({ isOpen: false, value: null });
        }}
      />

      <hr />

      <LogsQueryForm
        key={`${defaultLogQueryMode}-withoutNamespace`}
        onSubmit={setLogsQueryWithoutNamespace}
        isSaving={isSaving}
        required={true}
        defaultValue={getDefaultLogQueryValues(defaultLogQueryMode).defaultQueryWithoutNamespace}
        current={
          logsQueryWithoutNamespace ?? getDefaultLogQueryValues(defaultLogQueryMode).defaultQueryWithoutNamespace
        }
        label="Logs query, without namespace"
        placeholder=""
        description='Logs query to execute in the logs tab if "service.namespace" resource attribute is not provided. Available variables: $job, $serviceName, $traceIDFilter, $spanIDFilter'
      />

      <hr />

      <LogsQueryForm
        key={`${defaultLogQueryMode}-withNamespace`}
        onSubmit={setLogsQueryWithNamespace}
        required={true}
        isSaving={isSaving}
        defaultValue={getDefaultLogQueryValues(defaultLogQueryMode).defaultQueryWithNamespace}
        current={logsQueryWithNamespace ?? getDefaultLogQueryValues(defaultLogQueryMode).defaultQueryWithNamespace}
        label="Logs query, with namespace"
        placeholder=""
        description='Logs query to execute in the logs tab if "service.namespace" resource attribute is provided. Available variables: $job, $serviceName, $serviceNamespace, $traceIDFilter, $spanIDFilter'
      />

      <hr />

      <LogsQueryForm
        key={`${defaultLogQueryMode}-formatting`}
        onSubmit={setLogsQueryFormatting}
        required={false}
        isSaving={isSaving}
        defaultValue={getDefaultLogQueryValues(defaultLogQueryMode).defaultLogQueryFormatting}
        current={logsQueryFormatting ?? getDefaultLogQueryValues(defaultLogQueryMode).defaultLogQueryFormatting}
        label="Logs query formatting"
        placeholder="No formatting will be applied"
        description="Formatting applied by default to the logs queries to make them more readable"
      />

      <hr />

      <Field label="Filter by trace id" description="When jumping to logs from trace view, add line filter on trace id">
        <Switch
          disabled={isSaving}
          value={currentFilterByTraceId}
          onChange={(event) => {
            setLogsFilterByTraceId(event?.currentTarget.checked ?? false);
          }}
        />
      </Field>

      <Field label="Filter by span id" description="When jumping to logs from trace view, add line filter on span id">
        <Switch
          disabled={isSaving}
          value={currentFilterBySpanId}
          onChange={(event) => {
            setLogsFilterbySpanId(event?.currentTarget.checked ?? false);
          }}
        />
      </Field>
    </>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    formatOptions: css`
      margin-left: ${theme.spacing(1.5)};
    `,
  };
}
