import { css } from '@emotion/css';
import React, { useEffect, useState } from 'react';

import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { SceneComponentProps, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import {
  Alert,
  Checkbox,
  ConfirmModal,
  Field,
  Icon,
  Stack,
  Switch,
  Text,
  TextLink,
  Toggletip,
  useStyles2,
} from '@grafana/ui';

import { getFaro } from 'faro/instance';
import { getOverridesService } from 'services/OverridesService';
import { getPluginConfigService } from 'services/PluginConfigService';
import { savePluginConfig } from 'utils/config';

import { getCommonStyles } from './styles';

export interface ClientOnlyServicesConfigState extends SceneObjectState {
  isLoading: boolean;
  isSaving: boolean;
  isActivated: boolean;
  isUsingTempoMetricsGen: boolean;
}

export class ClientOnlyServicesConfigScene extends SceneObjectBase<ClientOnlyServicesConfigState> {
  static Component = ClientOnlyServicesRenderer;

  constructor() {
    const pluginConfigService = getPluginConfigService();
    const { isClientOnlyServicesEnabled } = pluginConfigService.getPluginConfig();

    super({
      isLoading: false,
      isSaving: false,
      isActivated: !!isClientOnlyServicesEnabled,
      isUsingTempoMetricsGen: getOverridesService().hasInitializationOverrides(),
    });
  }

  async activateClientOnlyServices() {
    this.setState({ isSaving: true });

    const saved = await savePluginConfig({ isClientOnlyServicesEnabled: true });
    if (!saved) {
      return;
    }

    if (this.state.isUsingTempoMetricsGen) {
      const success = await this.safelyUpdateOverrides(true);
      if (!success) {
        this.setState({ isSaving: false });
        return;
      }
    }

    this.setState({ isActivated: true, isSaving: false });
  }

  async deactivateClientOnlyServices() {
    this.setState({ isSaving: true });

    const saved = await savePluginConfig({ isClientOnlyServicesEnabled: false });
    if (!saved) {
      return;
    }

    if (this.state.isUsingTempoMetricsGen) {
      const success = await this.safelyUpdateOverrides(false);
      if (!success) {
        this.setState({ isSaving: false });
        return;
      }
    }

    this.setState({ isActivated: false, isSaving: false });
  }

  private async safelyUpdateOverrides(withClient: boolean) {
    try {
      await getOverridesService().updateOverrides(withClient);
    } catch (err) {
      const error = err instanceof Error ? err : new Error('Could not update overrides value, try again later');
      getFaro()?.api.pushError(error);

      const appEvents = getAppEvents();
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [error?.message && 'Could not save overrides value, try again later'],
      });

      await savePluginConfig({ isClientOnlyServicesEnabled: !withClient });
      return false;
    }

    return true;
  }
}

function ClientOnlyServicesRenderer({ model }: SceneComponentProps<ClientOnlyServicesConfigScene>) {
  const commonStyles = useStyles2(getCommonStyles);
  const styles = useStyles2(getStyles);
  const { isSaving, isActivated, isUsingTempoMetricsGen } = model.useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [isError, setIsError] = useState(false);

  const onClickSwitch = () => {
    if (isActivated) {
      model.deactivateClientOnlyServices();
      return;
    }

    if (isUsingTempoMetricsGen) {
      setIsModalOpen(true);
    } else {
      model.activateClientOnlyServices();
    }
  };

  const onClickEnable = () => {
    if (isAcknowledged) {
      model.activateClientOnlyServices();
    } else {
      setIsError(true);
    }
  };

  useEffect(() => {
    if (isActivated && isModalOpen) {
      setIsModalOpen(false);
    }
  }, [isActivated, isModalOpen]);

  return (
    <>
      <hr className={commonStyles.separator} />

      <Stack direction="row" justifyContent="space-between">
        <h4 className={commonStyles.sectionTitle}>Include Webapps and Mobile Devices</h4>

        <Toggletip
          content={
            <>
              <Text element="p">
                {isActivated ? 'Disable' : 'Enable'} this setting to{' '}
                {isActivated ? 'not process metrics' : 'see and generate metrics'} from spans with a{' '}
                <Text variant="code">CLIENT</Text> and <Text variant="code">PRODUCER</Text> SpanKind. If you generate
                your own metrics, ensure you {isActivated ? 'stop' : 'send'} sending the additional spans.
              </Text>

              <Text element="p">
                Learn more about how Application Observability generates metrics from traces in the{' '}
                <TextLink
                  href="https://grafana.com/docs/grafana-cloud/monitor-applications/application-observability/manual/configure/#include-webapps-and-mobile-devices"
                  external
                  inline={false}
                >
                  documentation
                </TextLink>
              </Text>
            </>
          }
        >
          <span>
            <Icon name="info-circle" className={commonStyles.iconToTextMarginRight} />
            <span className={commonStyles.needHelpIcon}>Need help?</span>
          </span>
        </Toggletip>
      </Stack>

      {!isUsingTempoMetricsGen && isActivated && (
        <Alert severity="info" title={'Configuration'}>
          Looks like you&apos;re not using Tempo metrics generation. In order to generate metrics from client spans, you
          will need to update your span metrics connector configuration.
        </Alert>
      )}

      <Field
        label={''}
        description={
          <Text>
            {isActivated ? 'Disable' : 'Enable'} support for additional services such as client-only services, webapps,
            mobile devices, and load generators by accepting and processing spans with a{' '}
            <Text variant="code">CLIENT</Text> and <Text variant="code">PRODUCER</Text>{' '}
            <TextLink href="https://opentelemetry.io/docs/specs/otel/trace/api/#spankind" external inline={false}>
              SpanKind
            </TextLink>
            .
          </Text>
        }
      >
        <Switch
          disabled={isSaving}
          value={isActivated}
          onClick={onClickSwitch}
          label="Client only services toggle"
          id="client-only-services-toggle"
        />
      </Field>

      <ConfirmModal
        modalClass={styles.confirmModal}
        isOpen={isModalOpen}
        title="Include Webapps and Mobile Devices"
        onDismiss={() => setIsModalOpen(false)}
        confirmText="Enable"
        body={
          <Stack gap={2} direction="column">
            <Text element="p">
              Activating this setting will cause Tempo to start generating metrics for client services. This might incur
              additional costs.
            </Text>

            <div
              className={commonStyles.acknowledgeContainer}
              onClick={() => {
                setIsAcknowledged(!isAcknowledged);
                setIsError(false);
              }}
              data-cy="ack-field"
            >
              <Field horizontal validationMessageHorizontalOverflow invalid={isError} error="We need your consent">
                <Checkbox
                  value={isAcknowledged}
                  className={commonStyles.acknowledgeCheckbox}
                  data-cy="acknowledge-deactivate-checkbox"
                  onClick={(evt) => evt.stopPropagation()}
                />
              </Field>
              <Text color="secondary" element="p">
                I recognize that enabling the client only services feature will incur additional usage towards my bill
                based on Grafana&apos;s{' '}
                <a
                  onClick={(e) => e.stopPropagation()}
                  href="https://grafana.com/docs/grafana-cloud/cost-management-and-billing/understand-your-invoice/metrics-invoice/?pg=pricing&plcmt=metrics-details"
                  target="_blank"
                  className={commonStyles.link}
                  rel="noreferrer"
                >
                  regular pricing
                </a>
                .
              </Text>
            </div>
          </Stack>
        }
        onConfirm={() => {
          onClickEnable();
        }}
      />
    </>
  );
}

function getStyles() {
  return {
    confirmModal: css`
      width: 750px;
    `,
  };
}
