import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { lastValueFrom } from 'rxjs';

import { getBackendSrv } from '@grafana/runtime';
import { Alert, Select } from '@grafana/ui';

import { AUTOMATIC_DEFAULT_ENVIRONMENT } from 'constants/semantics';
import { ALL_VARIABLE_TEXT, ALL_VARIABLE_VALUE, NONE_VARIABLE_TEXT, NONE_VARIABLE_VALUE } from 'constants/variables';
import { dotToDash } from 'utils/format';

export interface DefaultEnvironmentValueSelectProps {
  defaultEnvironmentValue: string;
  dsUid: string | undefined;
  environmentAttribute: string;
  isSubmitting: boolean;
  onChange: (value: string) => void;
}

const AUTOMATIC_DETECTION_OPTION = { label: 'Automatically detected', value: AUTOMATIC_DEFAULT_ENVIRONMENT };
const ALL_OPTION = { label: ALL_VARIABLE_TEXT, value: ALL_VARIABLE_VALUE };
const NONE_OPTION = { label: NONE_VARIABLE_TEXT, value: NONE_VARIABLE_VALUE };

export const DefaultEnvironmentValueSelect = ({
  defaultEnvironmentValue,
  dsUid,
  environmentAttribute,
  isSubmitting,
  onChange,
}: DefaultEnvironmentValueSelectProps) => {
  const { status, data: labelValues = [] } = useQuery({
    queryKey: ['labelValues', environmentAttribute, dsUid],
    queryFn: async () => {
      const dashedEnvironmentAttribute = dotToDash(environmentAttribute);

      const response = await lastValueFrom(
        getBackendSrv().fetch<{ data: string[] }>({
          url: `/api/datasources/uid/${dsUid}/resources/api/v1/label/${dashedEnvironmentAttribute}/values`,
          showErrorAlert: false,
          showSuccessAlert: false,
        })
      );

      return [
        AUTOMATIC_DETECTION_OPTION,
        ALL_OPTION,
        NONE_OPTION,
        ...response.data.data.map((value) => ({ label: value, value })),
      ];
    },
  });

  if (status === 'error') {
    return <Alert title={'Oops something went wrong'}></Alert>;
  }

  const selectedValue =
    labelValues.find(({ value }) => value === defaultEnvironmentValue) || AUTOMATIC_DETECTION_OPTION;

  return (
    <Select
      disabled={status === 'pending' || isSubmitting}
      options={labelValues}
      value={selectedValue}
      onChange={({ value }) => {
        if (!value) {
          return;
        }

        onChange(value);
      }}
    />
  );
};
