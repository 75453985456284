import { PRESERVE_URL_KEYS, ROUTES } from 'constants/routing';
import { TrackedSceneAppPage } from 'faro/TrackedSceneAppPage';

import { makeLandingScene } from './makeLandingScene';

export function makeLandingPage(): TrackedSceneAppPage {
  return new TrackedSceneAppPage({
    viewName: 'landing',
    title: '',
    url: ROUTES.landing(),
    preserveUrlKeys: PRESERVE_URL_KEYS,
    hideFromBreadcrumbs: true,
    getScene: makeLandingScene(),
  });
}
