import { lastValueFrom } from 'rxjs';

import { getBackendSrv } from '@grafana/runtime';

import { CLOUD_HOME_APP_PLUGIN_ID } from 'constants/plugin';
import { Org } from 'types/instance';

const orgUrl = `api/plugin-proxy/${CLOUD_HOME_APP_PLUGIN_ID}/grafanacom-api/orgs`;

export interface OrgReponse {
  ok: boolean;
  data: Org | undefined;
  error?: Error;
}

export async function getOrgInfo(orgId: string): Promise<OrgReponse> {
  return await lastValueFrom(
    getBackendSrv().fetch({
      url: `${orgUrl}/${orgId}`,
      showErrorAlert: true,
      showSuccessAlert: true,
    })
  );
}
