import { CLOUD } from 'constants/cloud';
import { CLOUD_PREFIX } from 'constants/services';

export function determineCloud(cloud: string | undefined | null): CLOUD {
  if (cloud?.startsWith(CLOUD_PREFIX)) {
    cloud = cloud.replace(CLOUD_PREFIX, '');
  }

  switch (cloud) {
    case 'alibaba_cloud':
      return CLOUD.ALIBABA;
    case 'aws':
      return CLOUD.AWS;
    case 'azure':
      return CLOUD.AZURE;
    case 'gcp':
      return CLOUD.GCP;
    case 'heroku':
      return CLOUD.HEROKU;
    case 'ibm':
      return CLOUD.IBM;
    case 'tencent_cloud':
      return CLOUD.TENCENT;
    default:
      return CLOUD.UNKNOWN;
  }
}
