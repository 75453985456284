import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, CellProps, Tooltip, useStyles2 } from '@grafana/ui';

import { AttributeFilter } from 'components/FilterByVariable';
import { ShimmerPlaceholder } from 'components/ShimmerPlaceholder';
import { Service } from 'types/services';

export const NamespaceCell = (handleClick: (serviceNamespace: string) => void, filters: AttributeFilter[] = []) =>
  function NamespaceCellComponent({
    row: {
      original: { serviceNamespace, loading },
    },
  }: CellProps<Service, void>) {
    const styles = useStyles2(getStyles);

    if (loading) {
      return <ShimmerPlaceholder style={{ width: '140px' }} />;
    }

    if (!serviceNamespace) {
      return null;
    }

    const hasNamespaceFilter = filters.some(
      (filter) => filter.key === 'serviceNamespace' && filter.value.includes(serviceNamespace)
    );

    const button = (
      <Button
        className={styles.unstyledButton}
        onClick={() => handleClick(serviceNamespace)}
        variant="secondary"
        fill="text"
        tooltip="Click to filter by this namespace"
        data-fs-element="App o11y - Filter by namespace"
      >
        {serviceNamespace}
      </Button>
    );

    return hasNamespaceFilter ? button : <Tooltip content="Click to filter by this namespace">{button}</Tooltip>;
  };

function getStyles(theme: GrafanaTheme2) {
  return {
    unstyledButton: css`
      border: none;
      color: inherit;
      font-weight: normal;
      margin: 0;
      padding: 0;

      &:hover {
        background: none;
      }
    `,
  };
}
