import { useCallback } from 'react';

import { buildRoute } from 'utils/routing';

import { useQueryParams } from './useQueryParams';

export function useLinkBuilder() {
  useQueryParams();
  return useCallback((path: string, extraQueryParams = {}): string => {
    return buildRoute(path, extraQueryParams);
  }, []);
}
