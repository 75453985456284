import { css, cx } from '@emotion/css';
import React, { useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, LinkButton, Text, useStyles2 } from '@grafana/ui';

import { InitializeModal } from 'components/InitializeModal';
import { ACCESS_CONTROL } from 'constants/accessControl';
import grot from 'img/logos/grot.svg';
import { getPluginConfigService } from 'services/PluginConfigService';
import { trackOptinModalCancel, trackOptinModalOpen } from 'utils/tracking';

import { getCommonStyles } from './commonStyles';
import { GetStartedStep } from './GetStartedStep';

export interface GetStartedProps {
  onInitialize: () => void;
}

export const GetStarted = ({ onInitialize }: GetStartedProps) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const { disableOptIn } = getPluginConfigService()?.getPluginConfig() ?? {};

  const handleModalToggle = (value: boolean) => {
    setIsModalOpened(value);

    if (value) {
      trackOptinModalOpen('initialize');
    } else {
      trackOptinModalCancel('initialize');
    }
  };

  const commonStyles = useStyles2(getCommonStyles);
  const styles = useStyles2(getStyles);

  return (
    <>
      <div className={commonStyles.containerCenter}>
        <div className={styles.titleContainer}>
          <img width={60} height={60} src={grot} alt="Grafana's mascot, Grot" />

          <h3 className={styles.title}>Get started now</h3>
        </div>

        <div className={cx(commonStyles.element, styles.stepsContainer)}>
          <GetStartedStep
            no={1}
            title="Instrument your application"
            description="Set up an SDK and send telemetry data to a Grafana Cloud data source"
            button={
              <LinkButton
                className={styles.button}
                size="lg"
                href="https://grafana.com/docs/grafana-cloud/monitor-applications/application-observability/setup/"
                target="_blank"
                icon="external-link-alt"
                variant="secondary"
                fullWidth
              >
                Read the setup docs
              </LinkButton>
            }
          />

          <GetStartedStep
            no={2}
            title="Enable metrics generation"
            description="Select a traces data source to generate metrics from"
            button={
              <>
                <Button
                  className={styles.button}
                  disabled={!ACCESS_CONTROL.PLUGIN.INITIALIZE || disableOptIn}
                  size="lg"
                  fullWidth
                  onClick={() => handleModalToggle(true)}
                  data-cy="open-activate-button"
                  tooltip={!ACCESS_CONTROL.PLUGIN.INITIALIZE ? 'Only Admin users can activate the plugin.' : undefined}
                >
                  Enable metrics generation
                </Button>
                {disableOptIn ? (
                  <p className={styles.notice}>
                    Application Observability cannot be enabled at this time. Please reach out to{' '}
                    <a
                      href="https://grafana.com/profile/org#support"
                      target="_blank"
                      rel="noreferrer"
                      className={commonStyles.link}
                    >
                      Grafana Cloud support
                    </a>
                    .
                  </p>
                ) : null}
              </>
            }
          />
        </div>

        <InitializeModal
          onInitialize={onInitialize}
          isOpened={isModalOpened}
          onClose={() => handleModalToggle(false)}
          page="initialize"
        />

        <Text color="secondary">
          If you want to generate your own metrics, check the{' '}
          <a
            href="https://grafana.com/docs/grafana-cloud/monitor-applications/application-observability/setup/collector"
            target="_blank"
            rel="noreferrer"
            className={commonStyles.link}
          >
            advanced setup
          </a>
        </Text>
      </div>

      <hr className={commonStyles.hr} />
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  titleContainer: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(3)};
  `,

  title: css`
    margin-bottom: 0;
  `,

  stepsContainer: css`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: ${theme.spacing(8)};
    row-gap: ${theme.spacing(5)};

    margin-bottom: ${theme.spacing(5)};

    ${theme.breakpoints.down('md')} {
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
  `,

  notice: css`
    margin: ${theme.spacing(2)} 0 0 0;
  `,

  button: css`
    font-weight: ${theme.typography.fontWeightRegular};
  `,
});
