import { EmbeddedScene, SceneFlexItem, SceneFlexLayout, SceneRouteMatch } from '@grafana/scenes';

import { WarnBannerScene } from 'components/WarnBanner';
import { MIN_PANEL_HEIGHT } from 'constants/styles';
import { TECHNOLOGY } from 'constants/technology';
import { ServiceRuntimeEmbeddedScene } from 'modules/service/components/ServiceRuntimeEmbeddedScene';
import { makeVariables } from 'utils/variables';

import { makeServiceGoCgoCallsPanel } from './panels/makeServiceGoCgoCallsPanel';
import { makeServiceGoGCCyclesPanel } from './panels/makeServiceGoGCCyclesPanel';
import { makeServiceGoGoroutinesPanel } from './panels/makeServiceGoGoroutinesPanel';
import { makeServiceGoLiveObjectsPanel } from './panels/makeServiceGoLiveObjectsPanel';
import { makeServiceGoMemoryHeapPanel } from './panels/makeServiceGoMemoryHeapPanel';
import { makeServiceGoRateObjectsAllocatedPanel } from './panels/makeServiceGoRateObjectsAllocatedPanel';
import { makeServiceGoRatePointerDereferencesPanel } from './panels/makeServiceGoRatePointerDereferencesPanel';

export function makeServiceGoScene(job: string): (routeMatch: SceneRouteMatch<any>) => EmbeddedScene {
  return () => {
    const liveObjectsPanel = makeServiceGoLiveObjectsPanel();
    const memoryHeapPanel = makeServiceGoMemoryHeapPanel();
    const ratePointerDereferencesPanel = makeServiceGoRatePointerDereferencesPanel();
    const rateObjectsAllocatedPanel = makeServiceGoRateObjectsAllocatedPanel();
    const goroutinesPanel = makeServiceGoGoroutinesPanel();
    const gcCyclesPanel = makeServiceGoGCCyclesPanel();
    const gcoCallsPanel = makeServiceGoCgoCallsPanel();

    const panels = [
      liveObjectsPanel,
      memoryHeapPanel,
      ratePointerDereferencesPanel,
      rateObjectsAllocatedPanel,
      goroutinesPanel,
      gcCyclesPanel,
      gcoCallsPanel,
    ];

    return new ServiceRuntimeEmbeddedScene({
      technology: TECHNOLOGY.GOLANG,

      panelsRefs: panels.map((panel) => panel.getRef()),

      $variables: makeVariables({ job }),

      body: new SceneFlexLayout({
        direction: 'column',
        children: [
          new SceneFlexLayout({
            direction: 'row',
            ySizing: 'content',
            children: [
              new SceneFlexItem({
                body: new WarnBannerScene({
                  panelsRefs: panels.map((panel) => panel.getRef()),
                  link: 'https://opentelemetry.io/docs/instrumentation/go/getting-started/',
                }),
              }),
            ],
          }),

          new SceneFlexLayout({
            direction: 'row',
            minHeight: MIN_PANEL_HEIGHT,
            ySizing: 'content',
            children: [
              new SceneFlexItem({
                minHeight: MIN_PANEL_HEIGHT,
                body: liveObjectsPanel,
              }),

              new SceneFlexItem({
                minHeight: MIN_PANEL_HEIGHT,
                body: memoryHeapPanel,
              }),
            ],
          }),

          new SceneFlexLayout({
            direction: 'row',
            minHeight: MIN_PANEL_HEIGHT,
            ySizing: 'content',
            children: [
              new SceneFlexItem({
                minHeight: MIN_PANEL_HEIGHT,
                body: ratePointerDereferencesPanel,
              }),

              new SceneFlexItem({
                minHeight: MIN_PANEL_HEIGHT,
                body: rateObjectsAllocatedPanel,
              }),
            ],
          }),

          new SceneFlexLayout({
            direction: 'row',
            minHeight: MIN_PANEL_HEIGHT,
            ySizing: 'content',
            children: [
              new SceneFlexItem({
                minHeight: MIN_PANEL_HEIGHT,
                body: goroutinesPanel,
              }),

              new SceneFlexItem({
                minHeight: MIN_PANEL_HEIGHT,
                body: gcCyclesPanel,
              }),
            ],
          }),

          new SceneFlexLayout({
            direction: 'row',
            minHeight: MIN_PANEL_HEIGHT,
            ySizing: 'content',
            children: [
              new SceneFlexItem({
                minHeight: MIN_PANEL_HEIGHT,
                body: gcoCallsPanel,
              }),
            ],
          }),
        ],
      }),
    }) as unknown as EmbeddedScene;
  };
}
