import { getThemeById } from '@grafana/data';

const theme = getThemeById('dark');

export const GRID_GAP = theme.spacing.gridSize;

export const MIN_PANEL_HEIGHT = 300;

export const MIN_PANEL_HEIGHT_PX = `${MIN_PANEL_HEIGHT}px`;

export const MIN_TABLE_PANEL_HEIGHT = 320;
