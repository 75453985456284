import { Faro } from '@grafana/faro-react';

let faro: Faro;

export function setFaro(newFaro: Faro) {
  faro = newFaro;
}

export function getFaro(): Faro | undefined {
  return faro;
}
