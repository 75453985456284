import { isEqual } from 'lodash';

import { ConfigOverrideRule, FieldConfigProperty, FieldConfigSource, FieldMatcherID } from '@grafana/data';
import { EmbeddedScene, sceneGraph, SceneTimeRangeCompare, VizPanelState } from '@grafana/scenes';
import { FieldColorModeId } from '@grafana/schema';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { GroupByVariable } from 'components/GroupByVariable';
import { APP_O11Y_PALETTE_ID, DEFAULT_FILL_OPACITY } from 'constants/panels';
import { GROUP_BY_NAME } from 'constants/variables';
import { TrackedSceneAppPage } from 'faro/TrackedSceneAppPage';
import { updateOpacity } from 'utils/colors';

export const updateColorsBehaviour = (panel: ExplorablePanel, color: string) => {
  const variable = sceneGraph.lookupVariable(GROUP_BY_NAME, panel) as GroupByVariable | undefined;
  let ancestor: TrackedSceneAppPage | EmbeddedScene;

  // Support component being used in our plugin, or as an extension
  try {
    ancestor = sceneGraph.getAncestor(panel, TrackedSceneAppPage);
  } catch (error) {
    ancestor = sceneGraph.getAncestor(panel, EmbeddedScene);
  }
  const timeWindowComparison = ancestor.state.controls?.find((control) => control instanceof SceneTimeRangeCompare);

  const getFieldConfigState = () =>
    getNewFieldConfig({
      state: panel.state,
      twcIsEmpty: timeWindowComparison?.state.compareWith === undefined,
      groupByIsEmpty: variable !== undefined && variable.isEmpty(),
      color,
    });

  panel.setState({ fieldConfig: getFieldConfigState() });

  const unsubscribable = variable?.subscribeToState((newState, oldState) => {
    if (!isEqual(newState.value, oldState.value)) {
      panel.setState({ fieldConfig: getFieldConfigState() });
    }
  });

  const unsubscribable2 = timeWindowComparison?.subscribeToState((newState, oldState) => {
    if (
      (newState.compareWith === undefined || oldState.compareWith === undefined) &&
      newState.compareWith !== oldState.compareWith
    ) {
      panel.setState({ fieldConfig: getFieldConfigState() });
    }
  });

  return () => {
    unsubscribable?.unsubscribe();
    unsubscribable2?.unsubscribe();
  };
};

function getNewFieldConfig({
  state,
  twcIsEmpty,
  groupByIsEmpty,
  color,
}: {
  state: VizPanelState;
  twcIsEmpty: boolean;
  groupByIsEmpty: boolean;
  color: string;
}): FieldConfigSource {
  // 4 states:
  //  1. TWC and groupby are OFF      -> We use specific colors
  //  2. TWC is ON and groupby is OFF -> We use specific colors
  //  3. TWC is OFF and groupby is ON -> We use app o11y palette
  //  4. TWC and groupby are ON       -> We use app o11y palette
  if (groupByIsEmpty) {
    const config = {
      ...state.fieldConfig,
      defaults: {
        ...state.fieldConfig.defaults,
        color: {
          mode: FieldColorModeId.Fixed,
          fixedColor: color,
        },
        custom: {
          ...state.fieldConfig.defaults.custom,
          fillOpacity: DEFAULT_FILL_OPACITY,
        },
      },
    };

    if (!twcIsEmpty) {
      config.defaults.custom.fillOpacity = 0;
      config.overrides = updateOverridesColorValue(state.fieldConfig.overrides, {
        mode: FieldColorModeId.Fixed,
        fixedColor: updateOpacity(color, '0.6'),
      });
    }

    return config;
  }

  return {
    ...state.fieldConfig,
    defaults: {
      ...state.fieldConfig.defaults,
      color: {
        mode: APP_O11Y_PALETTE_ID,
      },
      custom: {
        ...state.fieldConfig.defaults.custom,
        fillOpacity: 0,
      },
    },
    overrides: updateOverridesColorValue(state.fieldConfig.overrides, { mode: APP_O11Y_PALETTE_ID }),
  };
}

function updateOverridesColorValue(overrides: ConfigOverrideRule[], colorValue: any): ConfigOverrideRule[] {
  return overrides.map((override) => {
    const { matcher, properties } = override;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    if (matcher.id === FieldMatcherID.byFrameRefID && matcher.options.includes('-compare')) {
      return {
        ...override,
        properties: properties.map((property) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
          if (property.id === FieldConfigProperty.Color) {
            return {
              ...property,
              value: colorValue,
            };
          }

          return property;
        }),
      };
    }

    return override;
  });
}
