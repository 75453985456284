import { ConstantVariable, CustomVariableValue, VariableCustomFormatterFn } from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';

import { ENVIRONMENT_ATTRIBUTE_NAME } from 'constants/variables';
import { getEnvironmentAttribute } from 'utils/environmentFilter';
import { dotToDash, normalizeLabel } from 'utils/format';

export class EnvironmentAttributeVariable extends ConstantVariable {
  constructor() {
    super({
      name: ENVIRONMENT_ATTRIBUTE_NAME,
      value: getEnvironmentAttribute(),
      hide: VariableHide.hideVariable,
    });
  }

  getValue(): CustomVariableValue {
    return {
      formatter: (formatNameOrFn: string | VariableCustomFormatterFn) => {
        const value = this.state.value as string | undefined;

        if (!value) {
          return '';
        }

        switch (formatNameOrFn) {
          case 'dotted':
            return normalizeLabel(value);

          default:
            return dotToDash(value);
        }
      },
    };
  }

  updateValue(value: string | undefined) {
    this.setState({ value });
  }
}
