import { UrlQueryMap, urlUtil } from '@grafana/data';

import { PLUGIN_ID } from 'constants/plugin';
import { PRESERVE_URL_KEYS } from 'constants/routing';

export function getPluginRouteRoot(pluginId = PLUGIN_ID): string {
  return `/a/${pluginId}`;
}

export function prefixRoute(route: string, pluginId = PLUGIN_ID): string {
  const separator = route.startsWith('/') ? '' : '/';
  return `${getPluginRouteRoot(pluginId)}${separator}${route}`;
}

export function buildRouteParams(extraQueryParams?: UrlQueryMap): UrlQueryMap {
  return {
    ...Object.entries(urlUtil.getUrlSearchParams()).reduce<UrlQueryMap>((acc, [key, value]) => {
      if (PRESERVE_URL_KEYS.includes(key)) {
        acc[key] = value;
      }

      return acc;
    }, {}),
    ...extraQueryParams,
  };
}

export function buildRoute(path: string, extraQueryParams?: UrlQueryMap): string {
  return urlUtil.renderUrl(path, buildRouteParams(extraQueryParams));
}

export function encodeParameter(parameter: string): string {
  return encodeURIComponent(parameter.replace(/\//g, '---'));
}

// Ensure we're encoding all values, similar to what encodeuri interpolation format does
// Ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent#encoding_for_rfc3986
export function encodeURIParameter(parameter: string): string {
  return encodeParameter(parameter).replace(/[!'()*]/g, (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`);
}

export function decodeParameter(parameter: string): string {
  return decodeURIComponent(parameter).replace(/---/g, '/');
}
