import { css } from '@emotion/css';
import { sortBy } from 'lodash';
import React, { useMemo, useReducer } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Text, TextLink, useStyles2 } from '@grafana/ui';

import { normalizeLabel } from 'utils/format';

export interface MetadataTooltipValueProps {
  entries: Array<{ label: string; link?: string | undefined }>;
  label: string;
}

export const MetadataTooltipValue = ({ entries: rawEntries, label }: MetadataTooltipValueProps) => {
  const entries = useMemo(() => {
    const uniqueEntriesObject = rawEntries.reduce<
      Record<string, { label: string; link?: string | undefined; count: number }>
    >((acc, entry) => {
      acc[entry.label] = { ...entry, count: acc[entry.label] === undefined ? 1 : acc[entry.label].count + 1 };

      return acc;
    }, {});

    return sortBy(Object.values(uniqueEntriesObject), (value) => value.label);
  }, [rawEntries]);
  // Default to displaying values (open) if only a few values
  const [shouldShowAllEntries, showAllEntries] = useReducer(() => true, entries.length <= 3);

  const renderEntries = !shouldShowAllEntries ? entries.slice(0, 3) : entries;

  const styles = useStyles2(getStyles);
  const normalizedLabel = useMemo(() => normalizeLabel(label), [label]);

  return (
    <div className={styles.container}>
      <Text variant="body" color="secondary" data-cy="metadataTooltipValueLabel">
        {normalizedLabel}
      </Text>

      <div className={styles.entries}>
        {renderEntries.map((entry, idx) =>
          entry.link ? (
            <div data-cy="metadataTooltipValueContent" key={idx} className={styles.link}>
              <TextLink inline={false} href={entry.link} color="link">
                {entry.label}
              </TextLink>
              <Text variant="body">{entry.count > 1 ? ` (x${entry.count})` : ''}</Text>
            </div>
          ) : (
            <Text data-cy="metadataTooltipValueContent" variant="body" key={idx}>
              {entry.label}
            </Text>
          )
        )}
      </div>

      {!shouldShowAllEntries && (
        <div className={styles.showMore}>
          <Text variant="body" onClick={() => showAllEntries()}>
            Show {renderValueCount(entries.length - 3)} more
          </Text>
        </div>
      )}
    </div>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    container: css`
      display: flex;
      flex-direction: column;
    `,
    link: css`
      a {
        display: inline;
      }
    `,
    entries: css`
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(1)};
    `,

    showMore: css`
      margin-top: ${theme.spacing(1)};

      span {
        cursor: pointer;
        text-decoration: underline;
      }
    `,
  };
}

export function renderValueCount(count: number): string {
  if (count > 99) {
    return ' 99+';
  } else {
    return ` ${count}`;
  }
}
