import { config } from '@grafana/runtime';

const origin = window.location.origin;
const env = config.bootData.settings.buildInfo.env;

const isDev = origin.includes('.grafana-dev.net');
const isOps = !isDev && origin.includes('.grafana-ops.net');
const isLocal = (!isDev || !isOps) && (env === 'development' || env === 'dev');
const isProd = !isDev && !isOps && !isLocal;

export const ENV = {
  DEV: isDev,
  OPS: isOps,
  PROD: isProd,
  LOCAL: isLocal,
} as const;
