import { isEqual, sortBy } from 'lodash';

import { AdHocVariableFilter, MetricFindValue, SelectableValue } from '@grafana/data';
import { getDataSourceSrv } from '@grafana/runtime';
import {
  EmbeddedScene,
  sceneGraph,
  SceneObjectBase,
  SceneQueryRunner,
  SceneVariable,
  SceneVariableState,
  SceneVariableValueChangedEvent,
  VariableCustomFormatterFn,
  VariableValue,
} from '@grafana/scenes';
import { DataSourceRef, VariableHide } from '@grafana/schema';

import { PROMETHEUS_DS } from 'constants/datasources';
import {
  DOES_NOT_MATCH_REGEX_OPERATOR,
  ENDS_WITH_OPERATOR,
  EQUALS_OPERATOR,
  NOT_EQUALS_OPERATOR,
  REGEX_OPERATOR,
  SERVICES_FILTERS_KEYS,
  STARTS_WITH_OPERATOR,
} from 'constants/filterBy';
import { filterByStorageKey } from 'constants/sessionStorage';
import { FILTER_BY_NAME } from 'constants/variables';
import { TrackedSceneAppPage } from 'faro/TrackedSceneAppPage';
import { BaselineCompareUpdate, FilterByVariableUpdate } from 'modules/service/events';
import { DataQueryExtended } from 'types/queries';
import { dashToDot } from 'utils/format';
import { getFilterByOptions, isRegexOperator, RESOURCE_PREFIX } from 'utils/groupByFilterBy';

import { AdHocFilterSetRenderer } from './FilterBy/AdHocFilterSetRenderer';
import { FilterByVariableUrlSyncHandler } from './FilterByVariableUrlSyncHandler';
import { BASELINE_COMPARER_VALUE, TimeRangeCompareWithBaseline } from './TimeRangeCompareWithBaseline';

export type AttributeFilter = Pick<AdHocVariableFilter, 'condition' | 'key' | 'operator'> & {
  value: string[];
};

export interface FilterByVariableState extends Partial<FilterByVariableInternalState> {
  job?: string;
  operation?: string;
  isInstrumented?: boolean;
}

export interface FilterByVariableInternalState extends SceneVariableState {
  /** Optional text to display on the 'add filter' button */
  addFilterButtonText?: string;
  /** The visible filters */
  filters: AttributeFilter[];
  /** Base filters to always apply when looking up keys*/
  baseFilters?: AttributeFilter[];
  /** Datasource to use for getTagKeys and getTagValues and also controls which scene queries the filters should apply to */
  datasource: DataSourceRef | null;
  /** Controls if the filters can be changed */
  readOnly?: boolean;
  /**
   * Filter out the keys that do not match the regex.
   */
  tagKeyRegexFilter?: RegExp;
  /**
   * Extension hook for customizing the key lookup.
   * Return replace: true if you want to override the default lookup
   * Return replace: false if you want to combine the results with the default lookup
   */
  getTagKeysProvider?: (
    variable: FilterByVariable,
    currentKey: string | null
  ) => Promise<{ replace?: boolean; values: MetricFindValue[] }>;
  /**
   * Extension hook for customizing the value lookup.
   * Return replace: true if you want to override the default lookup.
   * Return replace: false if you want to combine the results with the default lookup
   */
  getTagValuesProvider?: (
    variable: FilterByVariable,
    filter: AttributeFilter
  ) => Promise<{ replace?: boolean; values: MetricFindValue[] }>;

  /**
   * Optionally provide an array of static keys that override getTagKeys
   */
  defaultKeys?: MetricFindValue[];

  /**
   * This is the expression that the filters resulted in. Defaults to
   * Prometheus / Loki compatible label filter expression
   */
  filterExpression?: string;

  /**
   * The default builder creates a Prometheus/Loki compatible filter expression,
   * this can be overridden to create a different expression based on the current filters.
   */
  expressionBuilder?: (filters: AttributeFilter[]) => string;

  /**
   * @internal state of the new filter being added
   */
  _wip?: AttributeFilter;
}

export class FilterByVariable
  extends SceneObjectBase<FilterByVariableInternalState>
  implements SceneVariable<FilterByVariableInternalState>
{
  static Component = AdHocFilterSetRenderer;

  private _scopedVars = { __sceneObject: { value: this } };
  private _dataSourceSrv = getDataSourceSrv();

  protected _urlSync = new FilterByVariableUrlSyncHandler(this);
  queryLabelPrefix?: string;

  isEmpty() {
    return this.state.filters.length === 0;
  }

  constructor({ job, operation, isInstrumented = true, ...rest }: FilterByVariableState) {
    let filters: AttributeFilter[] = [];

    try {
      const savedFilters = sessionStorage.getItem(filterByStorageKey);
      if (savedFilters) {
        const parsedFilters = JSON.parse(savedFilters);
        if (Array.isArray(parsedFilters)) {
          filters = parsedFilters;
        }
      }
    } catch {
      sessionStorage.removeItem(filterByStorageKey);
    }

    super({
      type: 'adhoc',
      hide: VariableHide.hideVariable,
      datasource: PROMETHEUS_DS,
      name: FILTER_BY_NAME,
      baseFilters: [
        ...(job ? [{ key: isInstrumented ? 'job' : 'server', operator: '=', value: [job], condition: '' }] : []),
        ...(operation ? [{ key: 'span_name', operator: '=', value: [operation], condition: '' }] : []),
      ],
      filters,
      getTagKeysProvider: () => {
        const attributes = getFilterByOptions();

        return Promise.resolve({
          replace: true,
          values: sortBy(attributes, (value) => value.text),
        });
      },
      ...rest,
    });

    this.queryLabelPrefix = isInstrumented ? '' : 'client_';

    this.addActivationHandler(() => {
      this.cleanFilters();

      const ancestor = this.getAncestor();
      this._subs.add(
        ancestor.subscribeToEvent(BaselineCompareUpdate, () => {
          this.setEmpty();
        })
      );

      // We need to manually check if the baseline is enabled on activation, since we won't receive the event at this point
      const timeRangeCompare = ancestor.state.controls?.find(
        (control) => control instanceof TimeRangeCompareWithBaseline
      );

      if (timeRangeCompare?.state.compareWith === BASELINE_COMPARER_VALUE) {
        this.setEmpty();
      }

      this._subs.add(
        this.subscribeToState((newState, prevState) => {
          if (newState.filters !== prevState.filters) {
            this._updateFilterExpression(newState, true);
            sessionStorage.setItem(filterByStorageKey, JSON.stringify(newState.filters));
          }
        })
      );

      sessionStorage.setItem(filterByStorageKey, JSON.stringify(this.state.filters));
      this._updateFilterExpression(this.state, false);
    });
  }

  cleanFilters() {
    // If we're coming from the service inventory, we need to remove the service filters that are only available there
    const filters = this.state.filters;
    const hasServicesFilter = filters.some((filter) => SERVICES_FILTERS_KEYS.includes(filter.key));

    if (hasServicesFilter) {
      this.setState({
        filters: this.removeLocalOnlyFilters(filters),
      });
    }
  }

  getValue(): VariableValue {
    const filters = this.mapStartsWithEndsWithOperators(this.removeLocalOnlyFilters(this.state.filters));
    const hasFilters = filters.length > 0 && !filters.every(({ value }) => value.length === 0);

    return {
      formatter: (formatNameOrFn: string | VariableCustomFormatterFn) => {
        if (!hasFilters) {
          if (formatNameOrFn === 'targetInfo') {
            return 'job!=""';
          }

          return '';
        }

        switch (formatNameOrFn) {
          case 'serviceMapUpstream': {
            // We do not want to remove service.namespace from filters for service map
            const serviceMapFilters = this.mapStartsWithEndsWithOperators(this.state.filters);
            return this.renderFilters(this.mapToUpstream(serviceMapFilters));
          }
          case 'serviceMapDownstream': {
            // We do not want to remove service.namespace from filters for service map
            const serviceMapFilters = this.mapStartsWithEndsWithOperators(this.state.filters);
            return this.renderFilters(this.mapToDownstream(serviceMapFilters));
          }
          case 'upstream':
            return this.renderFilters(this.mapToUpstream(filters));
          case 'downstream':
            return this.renderFilters(this.mapToDownstream(filters));
          case 'upstreamAppend': {
            const upstreamFilters = this.mapToUpstream(filters);

            return `, ${this.renderFilters(upstreamFilters)}`;
          }
          case 'downstreamAppend': {
            const downstreamFilters = this.mapToDownstream(filters);

            return `, ${this.renderFilters(downstreamFilters)}`;
          }
          case 'resource': {
            const resourceFilters = this.mapToResource(filters);

            return this.renderFilters(resourceFilters, '&&');
          }
          case 'append':
            return `, ${this.renderFilters(filters)}`;
          case 'targetInfo':
            // targetInfo does not have the service_name label, it does have job
            // so we need to update the expression to filter by job=~"(.*service_name)"
            return this.renderFilters(this.mapJobFilter(filters));
          default:
            return this.state.filterExpression!;
        }
      },
    };
  }

  setEmpty() {
    if (this.state.filters.length) {
      this.setState({ filters: [] });
    }
  }

  _updateFilterExpression(state: Partial<FilterByVariableInternalState>, publishEvent: boolean) {
    const filters = this.mapStartsWithEndsWithOperators(this.removeLocalOnlyFilters(state.filters || []));
    const expr = this.renderFilters(filters);

    if (expr === this.state.filterExpression) {
      return;
    }

    this.setState({ filterExpression: expr });

    if (publishEvent) {
      if (expr.length !== 0) {
        this.publishEvent(new FilterByVariableUpdate(), true);
      }

      this.publishEvent(new SceneVariableValueChangedEvent(this), true);
    }
  }

  _addWip() {
    this.setState({ _wip: { key: '', value: [], operator: '=', condition: '' } });
  }

  async _getKeys(currentKey: string | null): Promise<Array<SelectableValue<string>>> {
    const override = await this.state.getTagKeysProvider?.(this, currentKey);

    if (override && override.replace) {
      return override.values.map(toSelectableValue);
    }

    if (this.state.defaultKeys) {
      return this.state.defaultKeys.map(toSelectableValue);
    }

    const ds = await this._dataSourceSrv.get(this.state.datasource, this._scopedVars);
    if (!ds || !ds.getTagKeys) {
      return [];
    }

    const otherFilters = this.state.filters.filter((f) => f.key !== currentKey).concat(this.state.baseFilters ?? []);
    const queries = this._getSceneQueries();
    let keys = await ds.getTagKeys({ filters: prepareFilters(otherFilters), queries });
    if (keys && !Array.isArray(keys)) {
      keys = keys.data;
    }

    if (override) {
      keys = keys.concat(override.values);
    }

    const tagKeyRegexFilter = this.state.tagKeyRegexFilter;
    if (tagKeyRegexFilter) {
      keys = keys.filter((f) => f.text.match(tagKeyRegexFilter));
    }

    return keys.map(toSelectableValue);
  }

  async _getValuesFor(filter: AttributeFilter): Promise<Array<SelectableValue<string>>> {
    const override = await this.state.getTagValuesProvider?.(this, filter);

    if (override && override.replace) {
      return override.values.map(this.toSelectableValue);
    }

    const ds = await this._dataSourceSrv.get(this.state.datasource, this._scopedVars);

    if (!ds || !ds.getTagValues) {
      return [];
    }

    const prefix = (filter: AttributeFilter) => {
      return {
        ...filter,
        key: (this.queryLabelPrefix ?? '') + filter.key,
      };
    };

    const otherFilters = this.state.filters
      // .filter((f) => !SERVICES_FILTERS_KEYS.includes(f.key))
      .filter((f) => f.key !== filter.key)
      .map(prefix)
      .concat(this.state.baseFilters!);

    const timeRange = sceneGraph.getTimeRange(this).state.value;
    let values = await ds.getTagValues({ key: prefix(filter).key, filters: prepareFilters(otherFilters), timeRange });
    if (values && !Array.isArray(values)) {
      values = values.data;
    }

    if (override) {
      values = values.concat(override.values);
    }

    return values.map(this.toSelectableValue);
  }

  _getOperators(): Array<SelectableValue<string>> {
    return [
      { label: EQUALS_OPERATOR, value: EQUALS_OPERATOR, description: 'Equals' },
      { label: NOT_EQUALS_OPERATOR, value: NOT_EQUALS_OPERATOR, description: 'Not equals' },
      { label: REGEX_OPERATOR, value: REGEX_OPERATOR, description: 'Matches regex' },
      {
        label: DOES_NOT_MATCH_REGEX_OPERATOR,
        value: DOES_NOT_MATCH_REGEX_OPERATOR,
        description: 'Does not match regex',
      },
      { label: 'starts with', value: STARTS_WITH_OPERATOR },
      { label: 'ends with', value: ENDS_WITH_OPERATOR },
    ];
  }

  _updateFilter(filter: AttributeFilter, prop: keyof AttributeFilter, value: string | string[] | undefined | null) {
    if (value == null) {
      return;
    }

    const { filters, _wip } = this.state;

    if (filter === _wip) {
      // If we set value we are done with this "work in progress" filter and we can add it
      if (prop === 'value') {
        this.setState({
          filters: [...filters, { ..._wip, value: Array.isArray(value) ? value : [value] }],
          _wip: undefined,
        });
      } else {
        this.setState({ _wip: { ...filter, [prop]: value } });
      }
      return;
    }

    const updatedFilters = this.state.filters.map((f) => {
      if (isEqual(f, filter)) {
        return { ...f, [prop]: value };
      }
      return f;
    });

    this.setState({ filters: updatedFilters });
  }

  private getAncestor() {
    let ancestor: TrackedSceneAppPage | EmbeddedScene;

    // Support component being used in our plugin, or as an extension
    try {
      ancestor = sceneGraph.getAncestor(this, TrackedSceneAppPage);
    } catch (error) {
      ancestor = sceneGraph.getAncestor(this, EmbeddedScene);
    }

    return ancestor;
  }

  private _getSceneQueries(): DataQueryExtended[] {
    const runners = sceneGraph.findAllObjects(
      this.getRoot(),
      (o) => o instanceof SceneQueryRunner
    ) as SceneQueryRunner[];

    const applicableRunners = runners.filter((r) => r.state.datasource?.uid === this.state.datasource?.uid);

    if (applicableRunners.length === 0) {
      return [];
    }

    const result: DataQueryExtended[] = [];
    applicableRunners.forEach((r) => {
      result.push(...r.state.queries);
    });

    return result;
  }

  _removeFilter(filter: AttributeFilter) {
    if (filter === this.state._wip) {
      this.setState({ _wip: undefined });
      return;
    }

    this.setState({ filters: this.state.filters.filter((f) => f !== filter) });
  }

  removeLocalOnlyFilters(filters: AttributeFilter[]): AttributeFilter[] {
    return filters.filter((filter) => !SERVICES_FILTERS_KEYS.includes(filter.key));
  }

  private mapToUpstream(filters: AttributeFilter[]): AttributeFilter[] {
    return filters.map((filter) => ({ ...filter, key: `server_${filter.key}` }));
  }

  private mapToDownstream(filters: AttributeFilter[]): AttributeFilter[] {
    return filters.map((filter) => ({ ...filter, key: `client_${filter.key}` }));
  }

  private mapToResource(filters: AttributeFilter[]): AttributeFilter[] {
    return filters.map((filter) => ({ ...filter, key: `${RESOURCE_PREFIX}${dashToDot(filter.key)}` }));
  }

  private mapStartsWithEndsWithOperators(filters: AttributeFilter[]): AttributeFilter[] {
    return filters.map((rawFilter) => {
      const filter = { ...rawFilter };

      if (filter.operator === STARTS_WITH_OPERATOR) {
        filter.operator = REGEX_OPERATOR;
        filter.value = filter.value.map((v) => `${v}.*`);
      } else if (filter.operator === ENDS_WITH_OPERATOR) {
        filter.operator = REGEX_OPERATOR;
        filter.value = filter.value.map((v) => `.*${v}`);
      }

      return filter;
    });
  }

  private mapJobFilter(filters: AttributeFilter[]): AttributeFilter[] {
    const serviceName = 'service_name';
    const serviceNameFilters = filters
      .filter(({ key }) => key === serviceName)
      .map(({ value }) => value.map((v) => `.*/${v}`))
      .flat();

    const jobFilter: AttributeFilter | null =
      serviceNameFilters.length > 0
        ? {
            key: 'job',
            condition: '',
            operator: REGEX_OPERATOR,
            value: serviceNameFilters,
          }
        : null;

    const newFilters = filters.filter(({ key }) => key !== serviceName);
    return jobFilter == null ? newFilters : newFilters.concat(jobFilter);
  }

  private toSelectableValue({ text, value }: MetricFindValue): SelectableValue<string> {
    return { label: text, value: String(value ?? text) };
  }

  private renderFilters(filters: AttributeFilter[], separator: string = ',') {
    return filters
      .filter(({ value }) => value.length !== 0)
      .map((filter) => {
        if (isRegexOperator(filter.operator) || filter.value.length <= 1) {
          return filter;
        }

        // If we have multiple values, we need to make sure to update the operator to the proper one
        if (filter.operator === EQUALS_OPERATOR) {
          filter.operator = REGEX_OPERATOR;
        } else if (filter.operator === NOT_EQUALS_OPERATOR) {
          filter.operator = DOES_NOT_MATCH_REGEX_OPERATOR;
        }

        return filter;
      })
      .map((filter) => this.renderFilter(filter))
      .join(separator);
  }

  private renderFilter(filter: AttributeFilter) {
    // based on the openmetrics-documentation, the 3 symbols we have to handle are:
    // - \n ... the newline character
    // - \  ... the backslash character
    // - "  ... the double-quote character
    const value = prepareFilter(filter.value).replace(/\\/g, '\\\\').replace(/\n/g, '\\n').replace(/"/g, '\\"');

    return `${filter.key}${filter.operator}"${value}"`;
  }
}

function toSelectableValue({ text, value }: MetricFindValue): SelectableValue<string> {
  return {
    label: text,
    value: String(value ?? text),
  };
}

export function prepareFilters(filters: AttributeFilter[]): AdHocVariableFilter[] {
  return filters.map((filter) => {
    return {
      ...filter,
      value: prepareFilter(filter.value),
    };
  });
}

export function prepareFilter(value: string | string[]): string {
  if (Array.isArray(value)) {
    value = value.length === 1 ? value.at(0)! : value.length === 0 ? '' : `(${value.join('|')})`;
  }

  return value;
}
