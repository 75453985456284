import { getInstanceInfo } from 'api/instance';
import { getFaro } from 'faro/instance';
import { Instance } from 'types/instance';

let defaultInstanceService: InstanceService;

class InstanceService {
  private instance?: Instance;

  async fetch(): Promise<Instance | undefined> {
    if (this.instance) {
      return this.instance;
    }

    const { ok, data, error } = await getInstanceInfo();
    if (ok) {
      this.instance = data;
      return this.instance;
    } else {
      getFaro()?.api.pushError(
        error instanceof Error ? error : new Error('Something went wrong while fetching instance info')
      );

      return undefined;
    }
  }

  getInstance(): Instance | undefined {
    return this.instance;
  }

  getCreatedAtDate(): string | undefined {
    return this.instance?.createdAt;
  }

  getIsInTrialPeriod(): boolean {
    const { trial, trialExpiresAt } = this.instance || {};

    if (!(trial && trialExpiresAt)) {
      return false;
    }

    return new Date(trialExpiresAt) > new Date();
  }
}

export function getInstanceService(): InstanceService {
  return defaultInstanceService;
}

export async function initializeInstanceService(): Promise<InstanceService> {
  if (!defaultInstanceService) {
    const service = new InstanceService();
    await service.fetch();
    defaultInstanceService = service;
  }

  return defaultInstanceService;
}
