import { map, Observable } from 'rxjs';

import { CustomTransformOperator, DataFrame } from '@grafana/data';

import { isComparisonDataFrame } from 'utils/data';

// in case of time window comparison includes alternative series indexing into
// field config for use by app o11y color palette
// to make sure that comparison fields have the same index as fields they compre to
export const addTWCFieldSeriesIndexTransformation: CustomTransformOperator = () => {
  return (source: Observable<DataFrame[]>) =>
    source.pipe(
      map((frames) => {
        if (frames.find(isComparisonDataFrame)) {
          let seriesIndex = -1;
          frames.forEach((frame) => {
            if (!isComparisonDataFrame(frame)) {
              seriesIndex++;
            }
            frame.fields.forEach((field) => {
              if (!field.config.custom) {
                field.config.custom = {};
              }
              field.config.custom.twcSeriesIndex = seriesIndex;
            });
          });
        }
        return frames;
      })
    );
};
