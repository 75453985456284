import {
  GRAFANACLOUD_USAGE_NAME,
  LOKI_DS_NAME,
  LOKI_DS_TYPE,
  PROMETHEUS_DS_NAME,
  PROMETHEUS_DS_TYPE,
  PYROSCOPE_DS_NAME,
  PYROSCOPE_DS_TYPE,
  TEMPO_DS_NAME,
  TEMPO_DS_TYPE,
} from './variables';

export const MIN_INTERVAL = '1m';

export const PROMETHEUS_DS = { uid: `\${${PROMETHEUS_DS_NAME}}`, type: PROMETHEUS_DS_TYPE };
export const LOKI_DS = { uid: `\${${LOKI_DS_NAME}}`, type: LOKI_DS_TYPE };
export const TEMPO_DS = { uid: `\${${TEMPO_DS_NAME}}`, type: TEMPO_DS_TYPE };
export const PYROSCOPE_DS = { uid: `\${${PYROSCOPE_DS_NAME}}`, type: PYROSCOPE_DS_TYPE };
export const USAGE_DS = { uid: GRAFANACLOUD_USAGE_NAME, type: PROMETHEUS_DS_TYPE };

export const PROVISIONED_DS_UIDS = {
  [PROMETHEUS_DS_TYPE]: 'grafanacloud-prom',
  [LOKI_DS_TYPE]: 'grafanacloud-logs',
  [TEMPO_DS_TYPE]: 'grafanacloud-traces',
  [PYROSCOPE_DS_TYPE]: 'grafanacloud-profiles',
} as const;
