import { map } from 'rxjs';

import { CustomTransformOperator, Field } from '@grafana/data';

const unwantedLabels = ['service', 'service_name', 'namespace', 'service_namespace', 'job', 'le', 'source'];
const unwantedStartsWithLabels = ['__', 'asserts_'];

export const cleanUpExemplarsTransformation: CustomTransformOperator = () => {
  return (source) =>
    source.pipe(
      map((frames) =>
        frames.map((frame) => {
          // Remove unnecesary labels
          frame.fields = frame.fields.reduce<Field[]>((acc, field) => {
            if (
              unwantedLabels.includes(field.name.toLowerCase()) ||
              unwantedStartsWithLabels.some((start) => field.name.startsWith(start))
            ) {
              return acc;
            }

            acc.push(field);
            return acc;
          }, []);

          // Add proper unit to value field
          const valueField = frame.fields.find(({ name }) => name.toLowerCase() === 'value');
          if (!valueField) {
            return frame;
          }

          valueField.config = {
            unit: 's',
          };
          return frame;
        })
      )
    );
};
