import { rangeUtil, simpleCountUnit } from '@grafana/data';

import { getMetadataService } from 'services/MetadataService';

// Rounds a number to a given precision
export function roundToPrecision(value: number, precision: number): number {
  const factor = Math.pow(10, precision);
  return Math.round(value * factor) / factor;
}

const reqps = simpleCountUnit('req/s');
const percent = simpleCountUnit('%');

export function formatRate(rawRate: number): string {
  let precision = 1;
  let rate = roundToPrecision(rawRate, precision);

  // Get bigger precision for rates different than zero that get rounded to zero
  // With only one decimal
  if (rate === 0 && rawRate !== 0) {
    precision = 2;
    rate = roundToPrecision(rawRate, precision);
  }

  const f = reqps(rate, precision);
  return `${f.prefix ?? ''}${f.text}${f.suffix ?? ''}`;
}

export function formatPercent(value: number): string {
  value = isNaN(value) ? 0 : value;
  const f = percent(value * 100, 1);
  return `${f.text}%`;
}

export function formatDuration(sec: number): string {
  if (sec === 0 || sec == null || isNaN(sec)) {
    return '-';
  }

  sec = roundToPrecision(sec, 1);

  const text = rangeUtil.secondsToHms(sec);

  if (text === 'less than a millisecond') {
    return '< 1ms';
  }

  return text;
}

export function escapeBackslash(value: string | undefined): string {
  if (!value) {
    return '';
  }

  return value.replaceAll('\\', '\\\\');
}

export function escapeDoubleQuotes(value: string | undefined): string {
  if (!value) {
    return '';
  }

  return value.replace(/"/g, '\\"');
}

export function dotToDash(value: string): string {
  return value.replaceAll('.', '_');
}

export function dashToDot(value: string): string {
  return value.replaceAll('_', '.');
}

const LABEL_STARTS = [
  'k8s_cluster_',
  'k8s_container_',
  'k8s_deployment_',
  'k8s_namespace_',
  'k8s_cronjob_',
  'k8s_daemonset_',
  'k8s_job_',
  'k8s_node_',
  'k8s_pod_',
  'k8s_replicaset_',
  'k8s_statefulset_',
  'k8s_',

  'cloud_account_',
  'cloud_',

  'process_runtime_',
];

export function normalizeLabel(label: string): string {
  const originalLabel = getMetadataService().getOriginalLabelName(label);
  if (originalLabel !== undefined) {
    return originalLabel;
  }

  const start = LABEL_STARTS.find((start) => label.startsWith(start));

  return start ? label.replace(start, dashToDot(start)) : dashToDot(label);
}
