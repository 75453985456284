import { SceneQueryRunner } from '@grafana/scenes';
import { PointVisibility } from '@grafana/ui';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { PROMETHEUS_DS } from 'constants/datasources';
import { DEFAULT_FIELD_CONFIG } from 'constants/panels';

export function makeServiceGoLiveObjectsPanel(): ExplorablePanel {
  return new ExplorablePanel({
    title: 'Number of Live Objects',

    trackingSection: 'service',
    trackingPage: 'go',
    trackingPanel: 'live-objects',

    fieldConfig: {
      defaults: {
        ...DEFAULT_FIELD_CONFIG,
        custom: {
          showPoints: PointVisibility.Never,
        },
      },
      overrides: [],
    },

    options: {
      legend: {
        showLegend: false,
      },
      tooltip: {
        mode: 'multi',
      },
    },

    $data: new SceneQueryRunner({
      datasource: PROMETHEUS_DS,
      maxDataPointsFromWidth: true,
      queries: [
        {
          expr: 'process_runtime_go_mem_live_objects{job="${job}"}',
          refId: 'serviceGoLiveObjects',
        },
      ],
    }),
  });
}
