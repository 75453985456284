import { catchError, Observable, of, switchMap } from 'rxjs';

import { getBackendSrv } from '@grafana/runtime';

import { PLUGIN_ID } from 'constants/plugin';
import { OverridesPayload, OverridesResponse } from 'types/overrides';

const overridesUrl = `/api/plugin-proxy/${PLUGIN_ID}/overrides`;

const emptyErrorPayload: OverridesResponse = {
  ok: false,
  data: undefined,
  version: '0',
};

export async function getOverrides(): Promise<OverridesResponse> {
  return new Promise((resolve, reject) => {
    try {
      getBackendSrv()
        .fetch({
          url: overridesUrl,
          showErrorAlert: false,
        })
        .pipe(
          switchMap((response): Observable<OverridesResponse> => {
            if (!response.ok) {
              return of({
                ...emptyErrorPayload,
                ok: response.status === 404,
              });
            }

            try {
              const version = response.headers.get('Etag') ?? '0';
              const data = response.data as OverridesPayload;

              const responseData = { ok: true, data, version };

              return of(responseData);
            } catch (err) {
              return of(emptyErrorPayload);
            }
          }),
          catchError(
            (err): Observable<OverridesResponse> =>
              of({
                ...emptyErrorPayload,
                ok: err.status === 404,
              })
          )
        )
        .subscribe((response) => {
          if (response.ok) {
            resolve(response);
          } else {
            reject(response);
          }
        });
    } catch (err) {
      reject(emptyErrorPayload);
    }
  });
}

export async function setOverrides(data: OverridesPayload, version: string): Promise<unknown> {
  return getBackendSrv().post(overridesUrl, data, {
    headers: {
      'If-Match': version,
    },
  });
}
