import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2, LoadingState, PanelData } from '@grafana/data';
import { SceneComponentProps, sceneGraph, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import {
  Alert,
  Field,
  Icon,
  LoadingPlaceholder,
  Select,
  TextLink,
  Toggletip,
  useStyles2,
  VerticalGroup,
} from '@grafana/ui';

import { AUTOMATIC_DEFAULT_ENVIRONMENT } from 'constants/semantics';
import { PROMETHEUS_DS_NAME, PROMETHEUS_DS_TYPE } from 'constants/variables';
import { baselineService } from 'services/BaselineService';
import { getPluginConfigService } from 'services/PluginConfigService';
import { getResourceAttributesFromPanelData, safelySaveAttributes, savePluginConfig } from 'utils/config';
import { getDataSourceUidFromText } from 'utils/datasources';
import { getEnvironmentAttribute } from 'utils/environmentFilter';
import { dashToDot } from 'utils/format';
import { makeTimeRange } from 'utils/timeRange';

import { DefaultEnvironmentValueSelect } from './DefaultEnvironmentValueSelect';
import { getCommonStyles } from './styles';

export interface EnvironmentFilterConfigState extends SceneObjectState {
  attributes: Array<{
    label: string;
    value: string;
  }>;

  defaultEnvironmentValue: string;
  isLoading: boolean;
  isSubmitting: boolean;
  isValid: boolean;

  environmentAttribute?: string;
  dsUid?: string;
}

export class EnvironmentFilterConfigScene extends SceneObjectBase<EnvironmentFilterConfigState> {
  static Component = EnvironmentFilterConfigRenderer;

  constructor() {
    const { defaultEnvironmentValue = AUTOMATIC_DEFAULT_ENVIRONMENT } = getPluginConfigService().getPluginConfig();

    super({
      attributes: [],
      environmentAttribute: getEnvironmentAttribute(),
      isLoading: true,
      isSubmitting: false,
      isValid: false,
      defaultEnvironmentValue,
      $timeRange: makeTimeRange(),
    });

    this.addActivationHandler(() => {
      const dsName = sceneGraph.lookupVariable(PROMETHEUS_DS_NAME, this)?.getValueText?.() ?? undefined;
      this.setState({ dsUid: getDataSourceUidFromText(dsName, PROMETHEUS_DS_TYPE) });

      const unsubscribable = sceneGraph.getData(this).subscribeToState(({ data }) => {
        this.handleData(data);
      });

      return () => unsubscribable.unsubscribe();
    });
  }

  async onSave(environmentAttribute: string) {
    this.setState({ isSubmitting: true });
    const success = await safelySaveAttributes(
      {
        newConfig: { environmentAttribute },
        oldConfig: { environmentAttribute: this.state.environmentAttribute },
      },
      {
        newAttributes: [environmentAttribute],
        oldAttributes: this.state.environmentAttribute ? [this.state.environmentAttribute] : [],
      }
    );

    // update baseline rules when env var changes
    await baselineService.pushBaselineRulesIfActivated();

    const newState: Partial<EnvironmentFilterConfigState> = { isSubmitting: false, isValid: success };
    if (success) {
      newState.environmentAttribute = environmentAttribute;
    }

    this.setState(newState);
  }

  async onUpdateDefaultEnvironmentValue(value: string) {
    this.setState({ isSubmitting: true });

    await savePluginConfig({ defaultEnvironmentValue: value });

    this.setState({ isSubmitting: false, defaultEnvironmentValue: value });
  }

  private handleData(data: PanelData | undefined) {
    const isLoading = !data || data.state !== LoadingState.Done;
    const isValid = !isLoading && !data.errors;

    if (!isValid) {
      this.setState({ isValid, isLoading });
      return;
    }

    const environmentAttribute = getEnvironmentAttribute();
    const attributes = getResourceAttributesFromPanelData(data);

    if (environmentAttribute && !attributes.some(({ value }) => value === environmentAttribute)) {
      // Manually add the currently selected attribute
      // This can happen because we allow users filter on attributes not yet
      // Available in their data
      const attribute = dashToDot(environmentAttribute);
      attributes.push({ label: attribute, value: attribute });
    }

    this.setState({ isLoading, isValid, attributes, environmentAttribute });
  }
}

function EnvironmentFilterConfigRenderer({ model }: SceneComponentProps<EnvironmentFilterConfigScene>) {
  const commonStyles = useStyles2(getCommonStyles);
  const styles = useStyles2(getStyles);

  const { attributes, defaultEnvironmentValue, dsUid, environmentAttribute, isLoading, isSubmitting, isValid } =
    model.useState();

  if (isLoading) {
    return <LoadingPlaceholder text="Loading configuration" />;
  }

  return (
    <div className={commonStyles.container}>
      <h4 className={commonStyles.sectionTitle}>Environment</h4>

      {!isValid && (
        <Alert title="Something went wrong">
          <VerticalGroup>
            <div>Please try reloading the page</div>
          </VerticalGroup>
        </Alert>
      )}

      <div className={styles.title}>
        <h6 className={commonStyles.title}>Deployment environment attribute</h6>

        <Toggletip
          content={
            <div>
              <h5 className={styles.center}>
                <Icon name="question-circle" className={commonStyles.iconToTextMarginRight} />
                Deployment environment attribute
              </h5>

              <p className={styles.fixLinkFontSize}>
                Select the name of the resource attribute used to distinguish deployment environments (e.g.
                &quot;production&quot;, &quot;staging&quot;, &quot;dev&quot;...).
                <br />
                For a consistent experience, it is required to populate this resource attribute on all traces, logs, and
                metrics.
                <br />
                The default value, recommended by the{' '}
                <TextLink
                  href="https://opentelemetry.io/docs/concepts/semantic-conventions/"
                  external={true}
                  inline={false}
                >
                  OpenTelemetry Semantic Conventions
                </TextLink>
                , is{' '}
                <TextLink
                  href="https://github.com/open-telemetry/semantic-conventions/blob/main/docs/resource/deployment-environment.md#deployment"
                  external={true}
                  inline={false}
                >
                  deployment.environment
                </TextLink>
                .
              </p>
            </div>
          }
          closeButton
        >
          <span>
            <Icon name="info-circle" className={commonStyles.iconToTextMarginRight} />
            <span className={commonStyles.needHelpIcon}>Need help?</span>
          </span>
        </Toggletip>
      </div>

      <Field label="Select the name of the resource attribute of the traces, metrics, and logs used to identified the deployment environments (e.g. 'production', 'staging', 'dev')">
        <Select
          disabled={!isValid || isSubmitting}
          options={attributes}
          value={environmentAttribute}
          placeholder="Select an attribute name"
          onChange={({ value }) => {
            if (value) {
              model.onSave(value);
            }
          }}
          allowCustomValue
          onCreateOption={(value) => {
            attributes.push({ label: value, value });

            model.onSave(value);
          }}
        />
      </Field>
      {environmentAttribute && (
        <>
          <h6>Default environment</h6>

          <Field className={styles.lastField} label="Select default environment">
            <DefaultEnvironmentValueSelect
              environmentAttribute={environmentAttribute}
              dsUid={dsUid}
              defaultEnvironmentValue={defaultEnvironmentValue}
              isSubmitting={isSubmitting}
              onChange={(value) => {
                model.onUpdateDefaultEnvironmentValue(value);
              }}
            />
          </Field>
        </>
      )}
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    title: css`
      display: flex;
      justify-content: space-between;
    `,
    center: css`
      display: flex;
      align-items: center;
    `,
    iconToTextMarginLeft: css`
      margin-left: ${theme.spacing(1)};
    `,
    lastField: css`
      margin-bottom: 0;
    `,
    fixLinkFontSize: css`
      > a {
        font-size: inherit;
      }
    `,
  };
}
