import {
  LOWER_ANOMALY_THRESHOLD_LEGEND_NAME,
  LOWER_ANOMALY_THRESHOLD_REF_ID,
  UPPER_ANOMALY_THRESHOLD_LEGEND_NAME,
  UPPER_ANOMALY_THRESHOLD_REF_ID,
} from 'constants/query';
import { DataQueryExtended, QueryWithThresholds } from 'types/queries';

export function queriesWithAnomalyThresholds(
  query: DataQueryExtended,
  thresholds: QueryWithThresholds
): DataQueryExtended[] {
  const result: DataQueryExtended[] = [query];
  if (thresholds.lowerThreshold) {
    result.push({
      refId: LOWER_ANOMALY_THRESHOLD_REF_ID,
      expr: thresholds.lowerThreshold,
      legendFormat: LOWER_ANOMALY_THRESHOLD_LEGEND_NAME,
      timeRangeCompare: false,
    });
  }
  if (thresholds.upperThreshold) {
    result.push({
      refId: UPPER_ANOMALY_THRESHOLD_REF_ID,
      expr: thresholds.upperThreshold,
      legendFormat: UPPER_ANOMALY_THRESHOLD_LEGEND_NAME,
      timeRangeCompare: false,
    });
  }
  return result;
}
