import React from 'react';

import { rangeUtil, urlUtil } from '@grafana/data';
import { locationService } from '@grafana/runtime';
import { SceneComponentProps, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import { LinkButton } from '@grafana/ui';

import { useQueryParams } from 'hooks/useQueryParams';
import { paramsToTimeRange } from 'utils/timeRange';

export interface DeclareIncidentControlState extends SceneObjectState {
  title?: string;
}

export class DeclareIncidentControl extends SceneObjectBase<DeclareIncidentControlState> {
  static Component = DeclareIncidentControlRenderer;

  constructor(job?: string, operation?: string) {
    super({
      title: operation ?? job,
    });
  }
}

function DeclareIncidentControlRenderer({ model }: SceneComponentProps<DeclareIncidentControl>) {
  const [params] = useQueryParams();
  const { title } = model.useState();
  if (!title) {
    return null;
  }

  const rawTimeRange = paramsToTimeRange(params);
  const timeRange = rangeUtil.convertRawToRange(rawTimeRange);
  const incidentBacklinkUrl = urlUtil.renderUrl(window.location.origin + locationService.getLocation().pathname, {
    ...params,
    from: timeRange.from.toISOString(),
    to: timeRange.to.toISOString(),
  });

  return (
    <LinkButton
      variant="secondary"
      icon="fire"
      href={`/a/grafana-incident-app/incidents?caption=${encodeURIComponent(
        `${title} service overview`
      )}&url=${encodeURIComponent(incidentBacklinkUrl)}&title=${encodeURIComponent(title)}&declare=new`}
      data-fs-element="App o11y - Declare incident"
    ></LinkButton>
  );
}
