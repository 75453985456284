import React from 'react';

import { Field, LinkModel, locationUtil, TraceSpanRow, UrlQueryMap } from '@grafana/data';
import { locationService } from '@grafana/runtime';
import { PanelBuilders, sceneGraph, SceneObject, SceneQueryRunner, VizPanel } from '@grafana/scenes';
import { Button, Icon } from '@grafana/ui';

import { ROUTES } from 'constants/routing';
import { buildRoute, encodeParameter } from 'utils/routing';
import { stringifyJob } from 'utils/services';

enum SpanLinkType {
  Logs = 'log',
  Traces = 'trace',
  Metrics = 'metric',
  Profiles = 'profile',
  Session = 'session',
  Unknown = 'unknown',
}

type SpanLinkDef = {
  href: string;
  onClick?: (event: unknown) => void;
  content: React.ReactNode;
  title?: string;
  field: Field;
  type: SpanLinkType;
};

type SpanLinkFunc = (span: TraceSpanRow) => SpanLinkDef[] | undefined;

const spanLinkFunc: SpanLinkFunc = (span) => {
  const serviceName = span.serviceName;
  const serviceNamespace = span.serviceTags.find((tag) => tag.key === 'service.namespace')?.value;
  const job = stringifyJob(serviceName, serviceNamespace);
  const params: UrlQueryMap = {};
  if (span.traceID) {
    params.traceID = span.traceID;
  }
  if (span.spanID) {
    params.spanID = span.spanID;
  }

  const encodedJob = encodeParameter(job);
  const logsHref = buildRoute(ROUTES.logs(encodedJob), params);
  const profilesHref = buildRoute(ROUTES.profiles(encodedJob), params);
  const shouldAddProfilesButton = span.tags != null && span.tags.some((tag) => tag.key === 'pyroscope.profile.id');

  const feo11AppId = span.serviceTags.find((tag) => tag.key === 'gf.feo11y.app.id')?.value;
  const feo11ySessionId = span.tags?.find((tag) => tag.key === 'session_id' || tag.key === 'session.id')?.value;
  const shouldAddSessionButton = feo11AppId && feo11ySessionId;

  return [
    {
      title: 'Service logs',
      href: logsHref,
      onClick: () => {
        locationService.push(logsHref);
      },
      content: <Icon name="gf-logs" title="Explore logs for this service" />,
      field: {} as Field,
      type: SpanLinkType.Logs,
    },
    ...(shouldAddProfilesButton
      ? [
          {
            title: 'Related profiles',
            href: profilesHref,
            onClick: () => {
              locationService.push(profilesHref);
            },
            content: <Icon name="gf-logs" title="Explore profiles for this service" />,
            field: {} as Field,
            type: SpanLinkType.Profiles,
          },
        ]
      : []),
    ...(shouldAddSessionButton
      ? [
          {
            title: 'Session for this span',
            href: `/a/grafana-kowalski-app/apps/${feo11AppId}/sessions/${feo11ySessionId}`,
            content: <Icon name="frontend-observability" title="Session for this span" />,
            field: {} as Field,
            type: SpanLinkType.Session,
          },
        ]
      : []),
  ];
};

export function makeTracesTracePanel(
  model: SceneObject,
  encodedJob: string,
  encodedOperation: string | undefined,
  queryRunner: SceneQueryRunner,
  closeTab: () => void,
  spanId?: string
): VizPanel<any, any> {
  let baseUrl = ROUTES.overview(encodedJob);

  if (encodedOperation) {
    baseUrl = `${baseUrl}/operations/${encodedOperation}`;
  }

  baseUrl = `${baseUrl}/traces?\${__url_time_range}&\${__all_variables}`;

  const panel = PanelBuilders.traces()
    .setTitle('Trace')
    .setOption('createSpanLink' as any, spanLinkFunc)
    .setOption('createFocusSpanLink' as any, (traceId: string, spanId: string): LinkModel<Field> => {
      return {
        title: 'Deep link to this span',
        href: locationUtil.assureBaseUrl(
          sceneGraph.interpolate(model, baseUrl + `&traceId=${traceId}&spanId=${spanId}`)
        ),
        origin: {} as Field,
        target: '_self',
      };
    })
    .setHeaderActions(
      <Button data-cy="closeTraceButton" icon="times" size="sm" variant="secondary" onClick={() => closeTab()} />
    )
    .setData(queryRunner);
  if (spanId) {
    panel.setOption('focusedSpanId' as any, spanId as any);
  }

  return panel.build();
}
