export function mergeArraysUnique<T = unknown>(arr1: T[], arr2: T[]): T[] {
  return arr2.reduce<T[]>(
    (acc, val) => {
      if (!acc.includes(val)) {
        acc.push(val);
      }

      return acc;
    },
    [...arr1]
  );
}

export function arrIncludesArr<T = unknown>(arr1: T[], arr2: T[]): boolean {
  return arr2.every((val) => arr1.includes(val));
}
