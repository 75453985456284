import { ExplorablePanel } from 'components/ExplorablePanel';
import { GROUP_BY_NAME } from 'constants/variables';
import { makeRedQueriesInstrumented } from 'queries/makeRedQueriesInstrumented';
import { makeRedQueriesUninstrumented } from 'queries/makeRedQueriesUninstrumented';

import { DurationPanel } from './DurationPanel';
import { makeErrorsPanel } from './makeErrorsPanel';
import { makeRatePanel } from './makeRatePanel';

export function makeRedPanels(
  job: string,
  operation: string | undefined,
  isInstrumented: boolean,
  serviceType?: 'client' | 'server'
): [() => ExplorablePanel, () => ExplorablePanel, () => ExplorablePanel] {
  const module = operation ? 'operation' : 'service';

  const { rate, errors, p99, p95, avg } = isInstrumented
    ? makeRedQueriesInstrumented({
        withJob: !!job,
        withOperation: !!operation,
        groupBy: `job$\{${GROUP_BY_NAME}:append}`,
        serviceType,
      })
    : makeRedQueriesUninstrumented({
        withJob: !!job,
        groupBy: `server$\{${GROUP_BY_NAME}:appenddownstream}`,
      });

  return [
    () => makeRatePanel({ module, query: rate, isInstrumented, job, operation }),
    () => makeErrorsPanel({ module, query: errors, isInstrumented, job, operation }),
    () => new DurationPanel({ module, p99, p95, avg, isInstrumented, job, operation }),
  ];
}
