import { EmbeddedScene, SceneFlexLayout, SceneObject, SceneRouteMatch } from '@grafana/scenes';

import { TimeRangeCompareWithBaseline } from 'components/TimeRangeCompareWithBaseline';

export function makeEmptyScene(): (routeMatch: SceneRouteMatch) => EmbeddedScene {
  return () =>
    new EmbeddedScene({
      body: new SceneFlexLayout({
        direction: 'column',
        children: [],
      }),
    });
}

export function getTimeCompare(model: SceneObject): TimeRangeCompareWithBaseline | undefined {
  if (!model.parent) {
    return undefined;
  }
  return getClosest(model.parent, (s) => {
    let found = undefined;
    if (s instanceof TimeRangeCompareWithBaseline) {
      return s;
    }
    s.forEachChild((child) => {
      if (child instanceof TimeRangeCompareWithBaseline) {
        found = child;
      }
    });

    return found;
  });
}

export function getClosest<T>(sceneObject: SceneObject, extract: (s: SceneObject) => T | undefined): T | undefined {
  let curSceneObject: SceneObject | undefined = sceneObject;
  let extracted: T | undefined = undefined;

  while (curSceneObject && !extracted) {
    extracted = extract(curSceneObject);
    curSceneObject = curSceneObject.parent;
  }

  return extracted;
}
