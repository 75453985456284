import { map, Observable } from 'rxjs';

import { CustomTransformOperator, DataFrame } from '@grafana/data';

import { UPPER_ANOMALY_HIGHLIGHT_LINE_REF_ID, UPPER_ANOMALY_THRESHOLD_REF_ID } from 'constants/query';

/*
  For highlighting upper anomalies:
  Creates a copy of value series with 0 line width so it's normally invisible and color red.
  Then adds "fill to lower" to upper anomaly threshold, so it will only fill areas exceeding upper anomaly threshold,
  with different color than normal value series line.
*/
export const baselineAnomalyHighlightTransformation: CustomTransformOperator = () => {
  return (source: Observable<DataFrame[]>) =>
    source.pipe(
      map((frames) => {
        const returned = [...frames];
        if (frames.find((f) => f.refId === UPPER_ANOMALY_THRESHOLD_REF_ID)) {
          const queryFrame = frames.find((frame) => frame.refId?.endsWith('Query'));
          if (queryFrame) {
            returned.push({
              ...queryFrame,
              refId: UPPER_ANOMALY_HIGHLIGHT_LINE_REF_ID,
            });
          }
        }
        return returned;
      })
    );
};
