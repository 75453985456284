import { css } from '@emotion/css';
import React from 'react';

import {
  DataSourceVariable,
  EmbeddedScene,
  SceneComponentProps,
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  SceneRouteMatch,
} from '@grafana/scenes';
import { Alert, LoadingPlaceholder, useStyles2, VerticalGroup } from '@grafana/ui';

import { TEMPO_DS_NAME, TEMPO_DS_TYPE } from 'constants/variables';
import { getFaro } from 'faro/instance';
import { initializeLanguageProviderService } from 'services/LanguageProviderService';
import { initializeOverridesService } from 'services/OverridesService';
import { getPluginConfigService } from 'services/PluginConfigService';
import { MetricsMode } from 'types/settings';
import { getDataSourceUidFromText, isProvisionedDataSource } from 'utils/datasources';
import { isDefaultTargetInfoMetricName } from 'utils/semantics';
import { makeVariables } from 'utils/variables';

import { InitializationCompleteBusEvent, ShowLandingPageBusEvent } from './initializeEvents';

enum InitState {
  LOADING = 'loading',
  ERROR = 'error',
  DONE = 'done',
}

interface InitializeSceneState extends SceneObjectState {
  state?: InitState;
}

class InitializeScene extends SceneObjectBase<InitializeSceneState> {
  static Component = InitializeSceneRenderer;

  constructor() {
    super({
      state: InitState.LOADING,
    });

    this.addActivationHandler(() => {
      const init = async () => {
        const tempoVar = (
          sceneGraph.lookupVariable(TEMPO_DS_NAME, this) as DataSourceVariable | undefined
        )?.getValueText();
        const dsUid = tempoVar ? getDataSourceUidFromText(tempoVar, TEMPO_DS_TYPE) : undefined;
        const isProvisionedTempo = dsUid && isProvisionedDataSource(dsUid, TEMPO_DS_TYPE);
        try {
          await initializeLanguageProviderService();
          const overridesService = await initializeOverridesService();
          const configService = getPluginConfigService();

          this.setState({ state: InitState.DONE });

          // either overrides initialized or don't need to be initialized - proceed to the app
          const canSkipInitialization =
            !isProvisionedTempo ||
            configService.getMetricsMode() !== MetricsMode.tempoMetricsGen ||
            !isDefaultTargetInfoMetricName ||
            overridesService.hasInitializationOverrides();

          if (canSkipInitialization) {
            // not redirecting because original URL is still valid
            this.publishEvent(new InitializationCompleteBusEvent({ redirect: false }), true);
            // need to initialize - proceed to the landing page
          } else {
            this.publishEvent(new ShowLandingPageBusEvent(), true);
          }
        } catch {
          this.setState({ state: InitState.ERROR });

          getFaro()?.api.pushError(new Error('Could not initialize application'));
        }
      };
      init();
    });
  }
}

function InitializeSceneRenderer(props: SceneComponentProps<InitializeScene>) {
  const { state } = props.model.useState();
  const styles = useStyles2(getStyles);

  switch (state) {
    case InitState.LOADING:
      return (
        <div className={styles.container}>
          <LoadingPlaceholder text="Loading application..." />
        </div>
      );
    case InitState.ERROR:
      return (
        <div className={styles.container}>
          <Alert title="Something went wrong while initializing the app">
            <VerticalGroup>
              <div>Please try reloading the page</div>
            </VerticalGroup>
          </Alert>
        </div>
      );
    case InitState.DONE:
      return (
        <div className={styles.container}>
          <p>Init successful!</p>
        </div>
      );
    default:
      throw new Error('should not happen');
  }
}

export function makeInitializeScene(): (routeMatch: SceneRouteMatch<any>) => EmbeddedScene {
  return () =>
    new EmbeddedScene({
      $variables: makeVariables({ useTempo: true }),
      body: new InitializeScene(),
    });
}

function getStyles() {
  return {
    container: css`
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 500px;
      margin: 0 auto;
    `,
  };
}
