import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

export interface BadgeProps {
  text: string;
}

// Use this component until Badge from grafana-ui has the gray color available
export const Badge = ({ text }: BadgeProps) => {
  const styles = useStyles2(getStyles);

  return <div className={styles.container}>{text}</div>;
};

function getStyles(theme: GrafanaTheme2) {
  return {
    container: css`
      padding: 1px 4px;
      border-radius: 2px;
      color: ${theme.colors.text.secondary};
      border: 1px solid ${theme.colors.border.strong};
      border-radius: ${theme.shape.radius.default};
    `,
  };
}
