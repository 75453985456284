import { map } from 'rxjs';

import { SceneDataProvider, SceneDataTransformer, VizPanel } from '@grafana/scenes';

import { ROUTES } from 'constants/routing';

export function makeServiceLogsPanel($data: SceneDataProvider, encodedJob: string): VizPanel<any, any> {
  return new VizPanel({
    title: 'Logs',

    pluginId: 'logs',

    options: {
      showTime: true,
    },

    $data: new SceneDataTransformer({
      $data,

      transformations: [
        () => (source) =>
          source.pipe(
            map((frames) =>
              frames?.map((frame) => {
                const traceIdField = frame.fields.find((field) => field.name.toLowerCase() === 'traceid');

                if (traceIdField) {
                  traceIdField.config.links = [
                    {
                      title: 'View trace',
                      url: `${ROUTES.traces(
                        encodedJob
                      )}?\${__url_time_range}&\${__all_variables}&traceId=\${__value.raw}`,
                    },
                  ];
                }

                return frame;
              })
            )
          ),
      ],
    }),
  });
}
