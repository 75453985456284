import { LogQueryMode } from 'constants/logs';
import {
  DEFAULT_LOKI_OTLP_QUERY_FORMATTING,
  DEFAULT_LOKI_OTLP_QUERY_WITH_NAMESPACE,
  DEFAULT_LOKI_OTLP_QUERY_WITHOUT_NAMESPACE,
  DEFAULT_LOKI_QUERY_FORMATTING,
  DEFAULT_LOKI_QUERY_WITH_NAMESPACE,
  DEFAULT_LOKI_QUERY_WITHOUT_NAMESPACE,
} from 'constants/query';

export const getDefaultLogQueryValues = (mode: LogQueryMode) => {
  return mode === LogQueryMode.json
    ? {
        defaultQueryWithNamespace: DEFAULT_LOKI_QUERY_WITH_NAMESPACE,
        defaultQueryWithoutNamespace: DEFAULT_LOKI_QUERY_WITHOUT_NAMESPACE,
        defaultLogQueryFormatting: DEFAULT_LOKI_QUERY_FORMATTING,
      }
    : {
        defaultQueryWithNamespace: DEFAULT_LOKI_OTLP_QUERY_WITH_NAMESPACE,
        defaultQueryWithoutNamespace: DEFAULT_LOKI_OTLP_QUERY_WITHOUT_NAMESPACE,
        defaultLogQueryFormatting: DEFAULT_LOKI_OTLP_QUERY_FORMATTING,
      };
};

export const userHasDefaultQueries = (
  logFormat: LogQueryMode,
  formattingQuery: string | undefined,
  withNamespaceQuery: string | undefined,
  withoutNamespaceQuery: string | undefined
): boolean => {
  const { defaultLogQueryFormatting, defaultQueryWithNamespace, defaultQueryWithoutNamespace } =
    getDefaultLogQueryValues(logFormat);

  return (
    formattingQuery === defaultLogQueryFormatting &&
    withNamespaceQuery === defaultQueryWithNamespace &&
    withoutNamespaceQuery === defaultQueryWithoutNamespace
  );
};
