import { AppPluginMeta, KeyValue } from '@grafana/data';
import { BackendSrvRequest, getBackendSrv } from '@grafana/runtime';

import { PLUGIN_ID } from 'constants/plugin';
import { PluginJsonData } from 'types/settings';

export async function savePluginSettings(
  meta: AppPluginMeta,
  jsonData: Partial<PluginJsonData>,
  options?: Partial<BackendSrvRequest>
) {
  const response = await getBackendSrv().post(
    `/api/plugins/${PLUGIN_ID}/settings`,
    {
      ...meta,
      jsonData: {
        ...meta?.jsonData,
        ...jsonData,
      },
    },
    options
  );

  return response;
}

export async function saveProvisionedPluginSettings(
  meta: AppPluginMeta,
  jsonData: Partial<PluginJsonData>,
  options?: Partial<BackendSrvRequest>
) {
  const response = await getBackendSrv().post(
    `/api/plugin-proxy/${PLUGIN_ID}/provisioned-plugin-settings`,
    {
      jsonData: escapeVariables({
        ...meta?.jsonData,
        ...jsonData,
      }),
    },
    options
  );

  return response;
}

// When plugin is provisioned, settings are interpolated for variable expansion.
// prevent this by escape $ to $$
function escapeVariables(obj: KeyValue<any>): KeyValue<any> {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      if (typeof value === 'string') {
        return [key, value.replace(/\$/g, '$$$$')];
      }
      return [key, value];
    })
  );
}
