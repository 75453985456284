export const PROMETHEUS_DS_NAME = 'prometheus';
export const LOKI_DS_NAME = 'loki';
export const TEMPO_DS_NAME = 'tempo';
export const PYROSCOPE_DS_NAME = 'pyroscope';
export const GRAFANACLOUD_USAGE_NAME = 'grafanacloud-usage';

export const PROMETHEUS_DS_TYPE = 'prometheus';
export const LOKI_DS_TYPE = 'loki';
export const TEMPO_DS_TYPE = 'tempo';
export const PYROSCOPE_DS_TYPE = 'grafana-pyroscope-datasource';

export const JOB_NAME = 'job';
export const SERVICE_NAME_NAME = 'serviceName';
export const SERVICE_NAMESPACE_NAME = 'serviceNamespace';
export const OPERATION_NAME = 'operation';

export const ENVIRONMENT_ATTRIBUTE_NAME = 'environmentAttribute';
export const ENVIRONMENT_VALUE_NAME = 'environmentValue';

export const GROUP_BY_NAME = 'groupBy';

export const FILTER_BY_NAME = 'filterBy';

export const METRICS_MODE_NAME = 'metricName';

export const ALL_VARIABLE_VALUE = '$__all';
export const ALL_VARIABLE_TEXT = 'All';
export const ALL_VARIABLE_REGEX = '.*';

export const NONE_VARIABLE_VALUE = '$__none';
export const NONE_VARIABLE_TEXT = 'None set';
export const NONE_VARIABLE_REGEX = '';
export const NONE_VARIABLE_REGEX_LOKI = '^$';
