import { SceneQueryRunner } from '@grafana/scenes';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { PROMETHEUS_DS } from 'constants/datasources';
import { DEFAULT_FIELD_CONFIG } from 'constants/panels';

export function makeServiceGoCgoCallsPanel(): ExplorablePanel {
  return new ExplorablePanel({
    title: 'cgo Calls',

    trackingSection: 'service',
    trackingPage: 'go',
    trackingPanel: 'cgo-calls',

    fieldConfig: {
      defaults: DEFAULT_FIELD_CONFIG,
      overrides: [],
    },

    options: {
      legend: {
        showLegend: false,
      },
      tooltip: {
        mode: 'multi',
      },
    },

    $data: new SceneQueryRunner({
      datasource: PROMETHEUS_DS,
      maxDataPointsFromWidth: true,
      queries: [
        {
          expr: 'process_runtime_go_cgo_calls{job="${job}"}',
          refId: 'serviceGoCgoCalls',
        },
      ],
    }),
  });
}
