import { getFaro } from 'faro/instance';

export function log(msg: string) {
  getFaro()?.api.pushLog([msg]);
  console.log(msg);
}

export function logError(e: any) {
  getFaro()?.api.pushError(e);
  console.error('errorLog', e);
}
