import { DataSourceInstanceSettings, DataSourceJsonData } from '@grafana/data';
import { DataSourceWithBackend, getDataSourceSrv } from '@grafana/runtime';

import { TEMPO_DS_TYPE } from 'constants/variables';
import { DS_TYPE } from 'types/datasources';
import { DefaultDataSources } from 'types/settings';
import { isProvisionedDataSource } from 'utils/datasources';

import { getPluginConfigService } from './PluginConfigService';

let defaultDataSourceService: DataSourceService;

class DataSourceService {
  getDefaultDataSources(): DefaultDataSources {
    return getPluginConfigService().getPluginConfig().defaultDataSources ?? {};
  }

  getSelectedDataSource(type: DS_TYPE): DataSourceInstanceSettings<DataSourceJsonData> | undefined {
    return getDataSourceSrv()
      .getList()
      .find((ds) => ds.type === type && isProvisionedDataSource(ds.uid, type));
  }

  getSelectedDataSourceUID(type: DS_TYPE): string | undefined {
    const saved: string | undefined = this.getDefaultDataSources()[type];
    const savedUid = saved ? this.resolveUid(saved) : undefined;

    return savedUid ?? this.getSelectedDataSource(type)?.uid;
  }

  async saveDataSourceSelection(type: DS_TYPE, value: string): Promise<void> {
    const defaults = this.getDefaultDataSources();
    if (defaults[type] !== value) {
      await getPluginConfigService().savePluginConfig({
        defaultDataSources: {
          ...defaults,
          [type]: value,
        },
      });
    }
  }

  isValidDatasource(dsNameOrUid: string): boolean {
    return !!this.resolveUid(dsNameOrUid);
  }

  async getAllAttributes() {
    const currentDs = this.getSelectedDataSourceUID(TEMPO_DS_TYPE);
    const tempoDs = (await getDataSourceSrv().get(currentDs)) as DataSourceWithBackend & {
      labelNamesQuery: () => Promise<Array<{ text: string }>>;
    };

    if (typeof tempoDs.labelNamesQuery === 'undefined') {
      return [];
    }

    return tempoDs.labelNamesQuery();
  }

  private resolveUid(dsNameOrUid: string): string | undefined {
    const datasources = getDataSourceSrv().getList();
    if (datasources.find(({ uid }) => uid === dsNameOrUid)) {
      return dsNameOrUid;
    }

    const dsByName = datasources.find(({ name }) => name === dsNameOrUid);

    return dsByName?.uid;
  }
}

export function getDataSourceService(): DataSourceService {
  return defaultDataSourceService;
}

export function initializeDataSourceService(): void {
  if (!defaultDataSourceService) {
    defaultDataSourceService = new DataSourceService();
  }
}
