import { SceneFlexItem, SceneFlexLayout, SceneLayoutChildOptions, SceneObject } from '@grafana/scenes';

export function layout(
  direction: 'row' | 'column',
  placement: SceneLayoutChildOptions,
  objs: SceneObject[] | SceneObject
) {
  return new SceneFlexLayout({
    direction,
    children: (Array.isArray(objs) ? objs : [objs]).map((body) => {
      return new SceneFlexItem({
        ...placement,
        body,
      });
    }),
  });
}

export function row(placement: SceneLayoutChildOptions, objs: SceneObject[] | SceneObject) {
  return layout('row', placement, objs);
}

export function column(placement: SceneLayoutChildOptions, objs: SceneObject[] | SceneObject) {
  return layout('column', placement, objs);
}
