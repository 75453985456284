import { AUTOMATIC_DEFAULT_ENVIRONMENT } from 'constants/semantics';
import { ALL_VARIABLE_VALUE, NONE_VARIABLE_VALUE } from 'constants/variables';
import { getPluginConfigService } from 'services/PluginConfigService';

let defaultEnvironmentValueService: DefaultEnvironmentValueService;

export class DefaultEnvironmentValueService {
  isManuallyChanged = false;

  private pluginConfigService = getPluginConfigService();

  get defaultValue(): string {
    return this.pluginConfigService.getPluginConfig().defaultEnvironmentValue ?? AUTOMATIC_DEFAULT_ENVIRONMENT;
  }

  get defaultIsAutomatic(): boolean {
    return this.defaultValue === AUTOMATIC_DEFAULT_ENVIRONMENT;
  }

  findDefaultValue(values: string[]): string | undefined {
    const defaultValue = this.defaultValue;

    if (defaultValue === ALL_VARIABLE_VALUE || defaultValue === NONE_VARIABLE_VALUE) {
      return defaultValue;
    }

    const defaultIsAutomatic = this.defaultIsAutomatic;

    const value = values.find((value) =>
      defaultIsAutomatic ? value.startsWith('p') || value.includes('pr') : value === defaultValue
    );

    return value;
  }
}

export function getDefaultEnvironmentValueService(): DefaultEnvironmentValueService {
  return defaultEnvironmentValueService;
}

export function initializeDefaultEnvironmentValueService(): void {
  if (!defaultEnvironmentValueService) {
    defaultEnvironmentValueService = new DefaultEnvironmentValueService();
  }
}
