import { locationService } from '@grafana/runtime';
import { SceneAppPage } from '@grafana/scenes';

import { getPluginRouteRoot, prefixRoute } from 'utils/routing';
import { makeEmptyScene } from 'utils/scenes';

export function makeRedirectPage(to: string) {
  return new SceneAppPage({
    title: '',
    url: getPluginRouteRoot(),
    getScene: makeEmptyScene(),
    $behaviors: [
      () => {
        locationService.push(prefixRoute(to));
      },
    ],
  });
}
