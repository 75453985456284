import React, { useState } from 'react';

import { Button, Checkbox, Field, Modal, Text, useStyles2 } from '@grafana/ui';

import { getCommonStyles } from '../styles';

export interface SetTempoSourceModalProps {
  isOpen: boolean;
  selectedSource?: string;

  onConfirm: () => Promise<void>;
  onDismiss: () => void;
}

export const SetTempoSourceModal = ({ isOpen, selectedSource, onConfirm, onDismiss }: SetTempoSourceModalProps) => {
  const commonStyles = useStyles2(getCommonStyles);
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  return (
    <Modal
      closeOnBackdropClick
      closeOnEscape
      onDismiss={onDismiss}
      className={commonStyles.modal}
      title="Update span metrics source"
      isOpen={isOpen}
    >
      <Text element="p">You&lsquo;re updating the span metrics source to: {selectedSource}</Text>
      <br />
      <Text element="p">
        Using Tempo will enable Tempo metrics generation. Metrics need to be automatically generated from the traces you
        have already sent to Grafana Cloud Traces. These metrics will count towards your current usage and bill for this
        stack. There is no additional cost for Grafana Cloud Free accounts.
      </Text>
      <br />

      <div
        className={commonStyles.acknowledgeContainer}
        onClick={() => {
          setIsAcknowledged(!isAcknowledged);
          setIsError(false);
        }}
        data-cy="ack-field"
      >
        <Field horizontal validationMessageHorizontalOverflow invalid={isError} error="We need your consent">
          <Checkbox
            value={isAcknowledged}
            className={commonStyles.acknowledgeCheckbox}
            data-cy="acknowledge-deactivate-checkbox"
            onClick={(evt) => evt.stopPropagation()}
          />
        </Field>
        <Text color="secondary">
          I recognize that enabling automatic baseline feature will incur additional usage towards my bill based on
          Grafana&apos;s{' '}
          <a
            onClick={(e) => e.stopPropagation()}
            href="https://grafana.com/docs/grafana-cloud/cost-management-and-billing/understand-your-invoice/metrics-invoice/?pg=pricing&plcmt=metrics-details"
            target="_blank"
            className={commonStyles.link}
            rel="noreferrer"
          >
            regular pricing
          </a>
          .
        </Text>
      </div>

      <div className={commonStyles.buttonsContainer}>
        <Button variant="secondary" onClick={onDismiss} disabled={isSaving} data-cy="close-activate-button">
          Cancel
        </Button>

        <Button
          type="submit"
          variant="primary"
          disabled={isSaving}
          onClick={() => {
            if (!isAcknowledged) {
              setIsError(true);
              return;
            }

            setIsSaving(true);
            onConfirm().finally(() => {
              setIsSaving(false);
              onDismiss();
            });
          }}
          className={commonStyles.activateButton}
          data-cy="perform-activate-button"
          icon={isSaving ? 'spinner' : undefined}
        >
          Enable
        </Button>
      </div>
    </Modal>
  );
};
