import { SceneAppPage, SceneAppPageState } from '@grafana/scenes';

import { getFaro } from './instance';

export interface TrackedSceneAppPageState extends SceneAppPageState {
  viewName: string;
}

export class TrackedSceneAppPage extends SceneAppPage {
  constructor({ viewName, ...state }: TrackedSceneAppPageState) {
    super(state);

    this.addActivationHandler(() => {
      getFaro()?.api.setView({
        name: viewName,
      });
    });
  }
}
