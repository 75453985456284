import { SceneDataTransformer } from '@grafana/scenes';
import {
  FieldColorModeId,
  GraphDrawStyle,
  GraphGradientMode,
  LineInterpolation,
  TooltipDisplayMode,
} from '@grafana/schema';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { PromSceneQueryRunner } from 'components/PromSceneQueryRunner';
import { MIN_INTERVAL, PROMETHEUS_DS } from 'constants/datasources';
import { DEFAULT_FILL_OPACITY, PanelType } from 'constants/panels';
import { GROUP_BY_NAME } from 'constants/variables';
import { MaximizePanelButton } from 'panels/maximizePanelButton';
import { makeAnomalyHighlightOverrides, makeDrilldownLinks, makeOverrides } from 'panels/red/panelsConfig';
import { QueryWithThresholds } from 'types/queries';
import { ERROR_COLOR } from 'utils/colors';
import { queriesWithAnomalyThresholds } from 'utils/queries';

import { ErrorTracesButton } from './ErrorTracesButton';
import { showAnomalyThresholdsBehavior } from './showAnomalyThresholdsBehavior';
import { showLegendBehavior } from './showLegendBehavior';
import { addDefaultSeriesNameTransformation } from './transformations/addDefaultSeriesNameTransformation';
import { addTWCFieldSeriesIndexTransformation } from './transformations/addTWCFieldSeriesIndexTransformation';
import { baselineAnomalyHighlightTransformation } from './transformations/baselineAnomalyHighlightTransformation';
import { sortFramesTransformation } from './transformations/sortFramesTransformation';
import { updateColorsBehaviour } from './updateColorsBehaviour';

export function makeErrorsPanel({
  job,
  operation,
  module,
  query,
  isInstrumented,
}: {
  job: string;
  operation?: string;
  module: 'operation' | 'service';
  query: QueryWithThresholds;
  isInstrumented: boolean;
}): ExplorablePanel {
  const refId = `${module}ErrorsQuery`;

  const queries = queriesWithAnomalyThresholds(
    {
      expr: query.query,
      legendFormat: `$\{${GROUP_BY_NAME}:legend${isInstrumented ? '' : 'downstream'}:Errors}`,
      refId,
    },
    query
  );

  const queryRunner = new PromSceneQueryRunner({
    datasource: PROMETHEUS_DS,
    queries,
    queriesWithThresholds: queries,
    maxDataPointsFromWidth: true,
    minInterval: MIN_INTERVAL,
  });

  const panel: ExplorablePanel = new ExplorablePanel({
    title: 'Errors',

    trackingSection: module,
    trackingPage: 'overview',
    trackingPanel: 'errors',

    fieldConfig: {
      defaults: {
        color: {
          mode: FieldColorModeId.Fixed,
          fixedColor: ERROR_COLOR,
        },
        unit: 'percentunit',
        max: 1,
        min: 0,
        custom: {
          fillOpacity: DEFAULT_FILL_OPACITY,
          spanNulls: true,
          drawStyle: GraphDrawStyle.Line,
          gradientMode: GraphGradientMode.Opacity,
          lineInterpolation: LineInterpolation.Smooth,
        },
        links: makeDrilldownLinks({
          withError: true,
          withOperation: module === 'operation',
          getScene: () => panel,
          isInstrumented,
        }),
      },
      overrides: [...makeOverrides(refId, ERROR_COLOR, true), ...makeAnomalyHighlightOverrides()],
    },

    headerActions: [
      new MaximizePanelButton(PanelType.ERRORS, job, operation),
      ...(isInstrumented ? [new ErrorTracesButton()] : []),
    ],

    options: {
      tooltip: {
        mode: TooltipDisplayMode.Multi,
      },
    },

    $behaviors: [
      showAnomalyThresholdsBehavior(queryRunner),
      showLegendBehavior(),
      (panel: ExplorablePanel) => updateColorsBehaviour(panel, ERROR_COLOR),
    ],

    $data: new SceneDataTransformer({
      transformations: [
        sortFramesTransformation,
        addTWCFieldSeriesIndexTransformation,
        addDefaultSeriesNameTransformation,
        baselineAnomalyHighlightTransformation,
      ],
      $data: queryRunner,
    }),
  });

  return panel;
}
