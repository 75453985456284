import React from 'react';

import { SceneComponentProps } from '@grafana/scenes';

import { FilterByVariable } from 'components/FilterByVariable';

import { AdHocFilterBuilder } from './AdHocFilterBuilder';
import { AdHocFilterRenderer } from './AdHocFilterRenderer';

export function AdHocFilterSetRenderer({ model }: SceneComponentProps<FilterByVariable>) {
  const { filters } = model.useState();

  return (
    <>
      {filters.map((filter, idx) => (
        <React.Fragment key={idx}>
          <AdHocFilterRenderer filter={filter} model={model} />
        </React.Fragment>
      ))}

      <AdHocFilterBuilder model={model} key="'builder" />
    </>
  );
}
