import { EmbeddedSceneState, sceneGraph, SceneObjectRef } from '@grafana/scenes';

import { TECHNOLOGY } from 'constants/technology';
import { trackServiceRuntimeDetail } from 'utils/tracking';

import { ServiceEmbeddedScene } from './ServiceEmbeddedScene';

export type ServiceRuntimeEmbeddedSceneState = EmbeddedSceneState & {
  panelsRefs: Array<SceneObjectRef<any>>;
  technology: TECHNOLOGY;
};

export class ServiceRuntimeEmbeddedScene extends ServiceEmbeddedScene {
  constructor(state: ServiceRuntimeEmbeddedSceneState) {
    super(state, true, true);

    this.addActivationHandler(() => {
      const performTracking = () => {
        const hasData = state.panelsRefs.reduce((acc, panelRef) => {
          if (acc) {
            return true;
          }

          const series = sceneGraph.getData(panelRef.resolve()).state.data?.series ?? [];

          const hasSeries = (series[0]?.length ?? 0) > 0;

          if (!hasSeries) {
            return false;
          }

          return series[0].fields.length > 0;
        }, false);

        trackServiceRuntimeDetail(state.technology, hasData);
      };

      const timeoutId = setTimeout(performTracking, 3000);

      return () => {
        clearTimeout(timeoutId);
        performTracking();
      };
    });
  }
}
