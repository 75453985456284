import { RawTimeRange } from '@grafana/data';

export interface PluginExtensionLinkServiceContext {
  timeRange: RawTimeRange;
  datasources: {
    prometheus?: { name: string };
    loki?: { name: string };
    tempo?: { name: string };
  };
  service: {
    job: string;
    name: string;
    namespace: string;
  };
  k8s: Array<Record<string, string>> | undefined;
  cloud: Array<Record<string, string>> | undefined;
}

export type PluginExtensionLinkOperationContext = PluginExtensionLinkServiceContext & {
  operation: string;
};

// Union type of all possible contexts with which a plugin extension link can be rendered.
export type PluginExtensionLinkContext = PluginExtensionLinkServiceContext | PluginExtensionLinkOperationContext;

// Extension points that other plugins can use to hook into the Application Observability app.
export enum PluginExtensionPoints {
  ServiceAction = 'plugins/grafana-app-observability-app/service/action',
  OperationAction = 'plugins/grafana-app-observability-app/operation/action',
}
