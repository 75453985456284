import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { CellProps, useStyles2 } from '@grafana/ui';

import { ShimmerPlaceholder } from 'components/ShimmerPlaceholder';
import { ROUTES } from 'constants/routing';
import { useLinkBuilder } from 'hooks/useLinkBuilder';
import { Service } from 'types/services';
import { trackServicesDetails } from 'utils/tracking';

export const NameCell = ({
  row: {
    original: { encodedJob, serviceName, serviceNamespace, loading },
  },
}: CellProps<Service, void>) => {
  const styles = useStyles2(getStyles);
  const buildLink = useLinkBuilder();

  if (loading) {
    return <ShimmerPlaceholder style={{ width: '140px' }} />;
  }

  const content = (dataCy: string) => (
    <div className={styles.linkWrapper} data-cy={dataCy}>
      <a
        className={styles.truncateLink}
        title={serviceName}
        href={buildLink(ROUTES.overview(encodedJob))}
        onClick={trackServicesDetails}
      >
        {serviceName}
      </a>
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>{content(`service-${serviceNamespace}-${serviceName}`)}</div>
      <div className={styles.hiddenContainer}>{content(`hover-service-${serviceNamespace}-${serviceName}`)}</div>
    </div>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    wrapper: css`
      position: relative;
      max-height: ${theme.spacing(5)};

      &:hover > div:nth-child(1) {
        visibility: hidden;
      }

      &:hover > div:nth-child(2) {
        display: flex;
        visibility: visible;

        text-decoration: underline;
        color: ${theme.colors.text.link};
        position: absolute;
        top: 0;
        margin: -${theme.spacing(1)} 0px;
        height: ${theme.spacing(7)};
        align-items: center;
        overflow: visible;
        width: max-content !important;
        white-space: no-wrap;
        z-index: ${theme.zIndex.tooltip};
      }
    `,

    hiddenContainer: css`
      display: none;
      visibility: hidden;
    `,
    container: css`
      display: flex;
      align-items: center;
      gap: ${theme.spacing(0.5)};
      height: ${theme.spacing(5)};
    `,
    linkWrapper: css`
      display: flex;
      align-items: center;
      gap: ${theme.spacing(1.5)};
      position: relative;
    `,
    truncateLink: css`
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;

      color: ${theme.colors.text.link};
      font-weight: ${theme.typography.fontWeightBold};
    `,
  };
}
