import { DataFrame, FieldCache, FieldColorModeId, FieldType, Labels, LogLevel } from '@grafana/data';
import { BarAlignment, GraphDrawStyle, StackingMode } from '@grafana/schema';

import { LOG_LEVER_COLOR } from 'constants/logs';

export function getLogVolumeFieldConfig(level: LogLevel, oneLevelDetected: boolean) {
  const name = oneLevelDetected && level === LogLevel.unknown ? 'logs' : level;

  const color = LOG_LEVER_COLOR[level];

  return {
    displayNameFromDS: name,
    color: {
      mode: FieldColorModeId.Fixed,
      fixedColor: color,
    },
    custom: {
      drawStyle: GraphDrawStyle.Bars,
      barAlignment: BarAlignment.Center,
      lineColor: color,
      pointColor: color,
      fillColor: color,
      lineWidth: 1,
      fillOpacity: 100,
      stacking: {
        mode: StackingMode.Normal,
      },
    },
  };
}

export function extractLevel(dataFrame: DataFrame): LogLevel {
  let valueField;

  try {
    valueField = new FieldCache(dataFrame).getFirstFieldOfType(FieldType.number);
  } catch (err) {
    return LogLevel.unknown;
  }

  return valueField?.labels ? getLogLevelFromLabels(valueField.labels) : LogLevel.unknown;
}

function getLogLevelFromLabels(labels: Labels): LogLevel {
  const levelLabel = 'level';
  return labels[levelLabel] ? getLogLevelFromKey(labels[levelLabel]) : LogLevel.unknown;
}

function getLogLevelFromKey(key: string | number): LogLevel {
  const level = (LogLevel as any)[key.toString().toLowerCase()];

  if (level) {
    return level;
  }

  return LogLevel.unknown;
}
