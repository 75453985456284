import { css } from '@emotion/css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { memo, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AppRootProps, GrafanaTheme2 } from '@grafana/data';
import { FaroErrorBoundary, FaroRoute } from '@grafana/faro-react';
import { useStyles2 } from '@grafana/ui';

import { initializeAccessControl } from 'constants/accessControl';
import { App } from 'modules/app/App';
import { initializeDataSourceService } from 'services/DataSourceService';
import { initializeDefaultEnvironmentValueService } from 'services/DefaultEnvironmentValueService';
import { initializeHostInfoService } from 'services/HostInfoService';
import { initializeInstanceService } from 'services/InstanceService';
import { initializeMetadataService } from 'services/MetadataService';
import { initializeOrgService } from 'services/OrgService';
import { initializePluginConfigService } from 'services/PluginConfigService';
import { initializeRenderService } from 'services/RenderService';
import { PluginJsonData } from 'types/settings';
import { getPluginRouteRoot, prefixRoute } from 'utils/routing';
import { getMetricName, setTargetInfoMetricName } from 'utils/semantics';

const queryClient = new QueryClient();

function RootComponent(props: AppRootProps<PluginJsonData>) {
  const styles = useStyles2(getStyles);

  initializeInstanceService();
  initializeHostInfoService();
  initializeRenderService();
  initializePluginConfigService(props.meta);
  initializeAccessControl();
  initializeDataSourceService();
  initializeMetadataService();
  initializeDefaultEnvironmentValueService();
  initializeOrgService();

  useEffect(() => {
    setTargetInfoMetricName(getMetricName('targetInfo'));
  }, []);

  return (
    <div id="app-o11y" className={`${styles.title} fs-unmask`}>
      <FaroErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <Switch>
            <FaroRoute path={getPluginRouteRoot()}>
              <Route
                path={prefixRoute('*')}
                render={(props) => {
                  let { url } = props.match;

                  const isServiceOverviewRoute = url.includes('services/');
                  const isCurrentServiceOverviewRouteDefinition = url.includes('services/service');
                  const isServiceMapRoute = url.includes('services/map');

                  if (isServiceOverviewRoute && !isCurrentServiceOverviewRouteDefinition && !isServiceMapRoute) {
                    // Update url with new path for service overview screens
                    url = url.replace(prefixRoute('services'), prefixRoute('services/service'));
                    // Ensure we're keeping all query params
                    url += props.location.search;

                    return <Redirect to={url} />;
                  }

                  const isOldServiceMapRoute = url.includes('map');
                  const isCurrentServiceMapRoute = url.includes('services/map');

                  if (isOldServiceMapRoute && !isCurrentServiceMapRoute) {
                    // Update url with new path for service overview screens
                    url = url.replace(prefixRoute('map'), prefixRoute('services/map'));
                    // Ensure we're keeping all query params
                    url += props.location.search;

                    return <Redirect to={url} />;
                  }

                  return;
                }}
              />

              <App />
            </FaroRoute>
          </Switch>
        </QueryClientProvider>
      </FaroErrorBoundary>
    </div>
  );
}

export const Root = memo(RootComponent);

function getStyles(theme: GrafanaTheme2) {
  return {
    title: css`
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 100%;

      div[class$='title-info-container'] {
        min-width: auto;
      }
    `,
    container: css`
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: ${theme.spacing(5)};
      width: 500px;
      margin: 0 auto;
    `,
  };
}
