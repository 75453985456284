import { InterpolateFunction } from '@grafana/data';
import { sceneGraph } from '@grafana/scenes';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { FilterByVariable } from 'components/FilterByVariable';
import { OVERRIDES_SERVICE_GRAPHS_PEER_ATTRIBUTES } from 'constants/overrides';
import { TRACEQL_SEARCH_TYPE, TRACEQL_TYPE } from 'constants/query';
import { FILTER_BY_NAME } from 'constants/variables';
import { removeAllValueFromQuery, removeAllVariableFromFilters } from 'modules/service/components/TracesScene/utils';
import { getTempoQuery, SERVICE_NAME_TRACEQL_FILTER } from 'queries/tempo';
import { getOverridesService } from 'services/OverridesService';
import { TempoQuery } from 'types/queries';

import { getEnvironmentAttribute } from './environmentFilter';

export function getFullInterpolatedTracesQuery({
  interpolate,
  job,
  operation,
  withError,
  minDuration,
  maxDuration,
  getScene,
}: {
  interpolate: InterpolateFunction | undefined;
  job: string;
  operation: string | undefined;
  withError: boolean | undefined;
  minDuration?: number | string;
  maxDuration?: number | string;
  getScene?: () => ExplorablePanel;
}) {
  const scene = getScene?.();
  const variable = scene ? (sceneGraph.lookupVariable(FILTER_BY_NAME, scene) as FilterByVariable) : undefined;
  const additionalFilters = variable
    ? {
        traceql: interpolate!(`$\{${FILTER_BY_NAME}:resource}`),
        search: variable.state.filters,
      }
    : undefined;
  const environmentName = getEnvironmentAttribute() || '';

  const tempoQuery = getTempoQuery(
    job,
    operation,
    TRACEQL_SEARCH_TYPE,
    withError,
    minDuration,
    maxDuration,
    additionalFilters
  );
  tempoQuery.query = removeAllValueFromQuery(interpolate?.(tempoQuery.query!), environmentName);
  tempoQuery.filters = removeAllVariableFromFilters(
    (tempoQuery.filters || []).map((filter) => ({
      ...filter,
      value: Array.isArray(filter.value) ? filter.value.map((v) => interpolate!(v)) : interpolate!(filter.value!),
      tag: interpolate!(filter.tag!),
    }))
  );

  return tempoQuery;
}

export function prepareTracesQueryForUninstrumentedExplore(tracesQuery: TempoQuery, interpolate: InterpolateFunction) {
  const serviceName = interpolate('${serviceName}');
  // We can't do OR (||) on search mode filters
  tracesQuery.filters = [];
  tracesQuery.queryType = TRACEQL_TYPE;

  // We need to update the query to match the fact that we are querying an uninstrumented service
  // We use peer attributes to filter for the service name
  let peerAttributes = getOverridesService().getPeerAttributes();
  peerAttributes = peerAttributes.length === 0 ? OVERRIDES_SERVICE_GRAPHS_PEER_ATTRIBUTES : peerAttributes;
  const uninstrumentedServiceName = peerAttributes
    .reduce<string[]>((acc, attribute) => {
      acc.push(`span.${attribute}="${serviceName}"`);
      return acc;
    }, [])
    .join(' || ');

  // Replace service name with list of possible attributes
  tracesQuery.query = tracesQuery.query?.replace(
    interpolate(SERVICE_NAME_TRACEQL_FILTER),
    `(${uninstrumentedServiceName})`
  );
}
