import React from 'react';

import { CellProps } from '@grafana/ui';

import { Service } from 'types/services';
import { DURATION_COLOR, ERROR_COLOR, RATE_COLOR } from 'utils/colors';
import { formatDuration, formatPercent, formatRate } from 'utils/format';

import { REDType } from './ServicesTable';
import { Sparkline } from '../Sparkline';

export const SparklineCell = (type: REDType) =>
  function SparklineCellComponent({
    row: {
      original: {
        data: { duration, errors, rate },
      },
    },
  }: CellProps<Service, void>) {
    switch (type) {
      case 'duration':
        return <Sparkline color={DURATION_COLOR} data={duration} formatValue={formatDuration} />;
      case 'errors':
        return <Sparkline color={ERROR_COLOR} data={errors} formatValue={formatPercent} />;
      case 'rate':
        return <Sparkline color={RATE_COLOR} data={rate} formatValue={formatRate} />;
    }
  };
