import React, { useState } from 'react';

import { SceneComponentProps, SceneObjectBase, SceneObjectRef, SceneObjectState } from '@grafana/scenes';
import { ButtonGroup, ToolbarButton, Tooltip } from '@grafana/ui';

import { ServiceLogsScene } from './ServiceLogsScene';

export interface LiveTailControlState extends SceneObjectState {
  logsSceneRef: SceneObjectRef<ServiceLogsScene>;
}

export class LiveTailControl extends SceneObjectBase<LiveTailControlState> {
  static Component = LiveTailControlRenderer;
}

function LiveTailControlRenderer({ model }: SceneComponentProps<LiveTailControl>) {
  const logsScene = model.useState().logsSceneRef.resolve();
  const queryRunner = logsScene?.useState().queryRunnerRef?.resolve();
  const { liveStreaming = false } = queryRunner?.state ?? {};

  const [isStreaming, setIsStreaming] = useState(liveStreaming);

  return (
    <ButtonGroup>
      <Tooltip
        content={liveStreaming ? <>Stop and exit the live stream</> : <>Start live stream your logs</>}
        placement="bottom"
      >
        <ToolbarButton
          data-cy="liveLogsButton"
          variant={liveStreaming ? 'active' : 'canvas'}
          icon={liveStreaming ? 'square-shape' : 'play'}
          onClick={() => {
            logsScene?.setLiveStreaming(!isStreaming);
            setIsStreaming(!isStreaming);
          }}
          data-fs-element={liveStreaming ? 'App o11y - Stop live query' : 'App o11y - Start live query'}
        >
          Live
        </ToolbarButton>
      </Tooltip>
    </ButtonGroup>
  );
}
