import React, { useCallback, useState } from 'react';

import { EmbeddedScene, sceneGraph } from '@grafana/scenes';
import { Field, Select } from '@grafana/ui';

import { MetricsModeVariable } from 'components/MetricsModeVariable';
import { METRIC_NAMES } from 'constants/metricNames';
import { DEFAULT_TARGET_INFO_METRIC_NAME } from 'constants/semantics';
import { METRICS_MODE_NAME } from 'constants/variables';
import { getFaro } from 'faro/instance';
import { baselineService } from 'services/BaselineService';
import { getOverridesService } from 'services/OverridesService';
import { getPluginConfigService } from 'services/PluginConfigService';
import { MetricsMode } from 'types/settings';
import { savePluginConfig } from 'utils/config';
import { setTargetInfoMetricName } from 'utils/semantics';

import { SetNonTempoSourceModal } from './SetNonTempoSourceModal';
import { SetTempoSourceModal } from './SetTempoSourceModal';

export interface SpanMetricsSourceProps {
  updateHasOverrides: (hasOverrides: boolean) => void;

  getScene: () => EmbeddedScene;
}

const labels: Record<MetricsMode, string> = {
  [MetricsMode.tempoMetricsGen]: 'Tempo >= 2.3 (Grafana Cloud)',
  [MetricsMode.otelCollector]: 'OTEL Collector >= 0.94, Grafana Alloy >= 1.0, Grafana Agent >= 0.40',
  [MetricsMode.legacy]: 'Legacy',
};

export const SpanMetricsSource = ({ getScene, updateHasOverrides }: SpanMetricsSourceProps) => {
  const [loading, setLoading] = useState(false);
  const [showDeactivationModal, setShowDeactivationModal] = useState(false);
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [newSelectedSource, setNewSelectedSource] = useState<MetricsMode | undefined>(undefined);
  const [metricsMode, setMetricsMode] = useState<MetricsMode>(
    getPluginConfigService().getPluginConfig().metricsMode ?? MetricsMode.tempoMetricsGen
  );

  const onSpanMetricsSourceChange = useCallback(async (value: MetricsMode, withDeactivation = false) => {
    const overridesService = getOverridesService();

    const saved = await savePluginConfig({ metricsMode: value }, { showSuccessAlert: false, showErrorAlert: true });
    if (!saved) {
      return;
    }

    try {
      if (value !== MetricsMode.tempoMetricsGen && withDeactivation) {
        await overridesService.removeOverrides();
        updateHasOverrides(false);
      }

      if (value === MetricsMode.tempoMetricsGen) {
        if (!overridesService.hasInitializationOverrides()) {
          await overridesService.initializeOverrides();
          updateHasOverrides(true);
        }
      }
    } catch (err) {
      const error = err instanceof Error ? err : new Error('Could not save span metrics source change');
      getFaro()?.api.pushError(error);

      await savePluginConfig({ metricsMode }, { showSuccessAlert: false });
      setShowDeactivationModal(false);
      setShowActivationModal(false);
      setLoading(false);
      return;
    }

    setMetricsMode(value);

    const { targetInfo } = METRIC_NAMES[value];
    setTargetInfoMetricName(targetInfo ?? DEFAULT_TARGET_INFO_METRIC_NAME);

    const variable = sceneGraph.lookupVariable(METRICS_MODE_NAME, getScene()) as MetricsModeVariable | undefined;
    variable?.update(value);
    baselineService.pushBaselineRulesIfActivated();

    setShowDeactivationModal(false);
    setShowActivationModal(false);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNewValueSelection = async (value: MetricsMode) => {
    setNewSelectedSource(value);
    const hasInitializationOverrides = getOverridesService().hasInitializationOverrides();

    if (value !== MetricsMode.tempoMetricsGen) {
      if (hasInitializationOverrides) {
        setShowDeactivationModal(true);
      } else {
        // Don't need to confirm with user, we can just change the source
        await onSpanMetricsSourceChange(value);
      }
    } else {
      if (hasInitializationOverrides) {
        // Don't need to confirm with user, we can just change the source
        await onSpanMetricsSourceChange(value);
      } else {
        setShowActivationModal(true);
      }
    }
  };

  return (
    <>
      <Field
        label="Span metrics source"
        description="Service that generates metrics from traces. Selection determines metric names to use for querying data."
        data-cy="span-metrics-source-selector"
      >
        <Select
          disabled={loading}
          value={metricsMode || MetricsMode.tempoMetricsGen}
          options={[
            { label: labels[MetricsMode.tempoMetricsGen], value: MetricsMode.tempoMetricsGen },
            {
              label: labels[MetricsMode.otelCollector],
              value: MetricsMode.otelCollector,
            },
            {
              label: labels[MetricsMode.legacy],
              value: MetricsMode.legacy,
              description: `For customers who use the OpenTelemetry Collector with metric names that match those used by the Tempo metrics generator`,
            },
          ]}
          onChange={async (selectableValue) => {
            const value = selectableValue.value || MetricsMode.tempoMetricsGen;
            if (value === metricsMode) {
              return;
            }

            setLoading(true);
            await onNewValueSelection(value);
          }}
        />
      </Field>

      <SetNonTempoSourceModal
        isOpen={showDeactivationModal}
        selectedSource={labels[newSelectedSource!]}
        onDismiss={() => {
          setShowDeactivationModal(false);
          setLoading(false);
        }}
        onConfirm={async () => {
          await onSpanMetricsSourceChange(newSelectedSource ?? MetricsMode.tempoMetricsGen, true);
        }}
        onConfirmSecondary={async () => {
          await onSpanMetricsSourceChange(newSelectedSource ?? MetricsMode.tempoMetricsGen);
        }}
      />

      <SetTempoSourceModal
        isOpen={showActivationModal}
        selectedSource={labels[newSelectedSource!]}
        onConfirm={async () => {
          await onSpanMetricsSourceChange(newSelectedSource ?? MetricsMode.tempoMetricsGen);
        }}
        onDismiss={() => {
          setShowActivationModal(false);
          setLoading(false);
        }}
      />
    </>
  );
};
