import { css } from '@emotion/css';
import React, { useCallback, useMemo } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Column, FetchDataArgs, InteractiveTable, useStyles2 } from '@grafana/ui';

import { AttributeFilter } from 'components/FilterByVariable';
import { Service } from 'types/services';
import { Sort } from 'types/sorting';

import { NameCell } from './NameCell';
import { NamespaceCell } from './NamespaceCell';
import { SparklineCell } from './SparklineCell';
import { TechnologyCell } from './TechnologyCell';

export interface ServicesTableProps {
  sortData: (id: string, desc: boolean) => void;
  services: Service[];
  handleServiceNamespaceClick: (serviceNamespace: string) => void;
  filters: AttributeFilter[];
  initialSort?: Sort;
}

export type REDType = 'duration' | 'errors' | 'rate';

export const ServicesTable = ({
  services,
  handleServiceNamespaceClick,
  sortData,
  initialSort,
  filters,
}: ServicesTableProps) => {
  const styles = useStyles2(getStyles);
  const columns = useMemo<Array<Column<Service>>>(
    () => [
      {
        id: 'serviceName',
        header: 'Name',
        cell: NameCell,
        sortType: 'string',
      },
      {
        id: 'serviceNamespace',
        header: 'Namespace',
        sortType: 'string',
        cell: NamespaceCell(handleServiceNamespaceClick, filters),
      },
      {
        id: 'technology',
        header: 'Technology',
        cell: TechnologyCell,
      },
      {
        id: 'duration',
        header: 'Duration, p95',
        cell: SparklineCell('duration'),
      },
      {
        id: 'errors',
        header: 'Errors',
        cell: SparklineCell('errors'),
      },
      {
        id: 'rate',
        header: 'Rate',
        cell: SparklineCell('rate'),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters]
  );
  const data = useMemo<Service[]>(() => services, [services]);
  const fetchData = useCallback(({ sortBy }: FetchDataArgs<Service>) => {
    const { id = 'serviceName', desc = false } = sortBy.at(0) ?? {};

    sortData(id, desc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialSortBy = useMemo(() => (initialSort ? [initialSort] : []), []);

  return (
    <div className={styles.container}>
      <InteractiveTable
        columns={columns}
        data={data}
        getRowId={({ serviceName, serviceNamespace }: Service) =>
          `${serviceNamespace ? `${serviceNamespace}/` : ''}${serviceName}`
        }
        headerTooltips={headerTooltips}
        fetchData={fetchData}
        initialSortBy={initialSortBy}
      />
    </div>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    container: css`
      table > thead > tr {
        th:nth-child(4),
        th:nth-child(5),
        th:nth-child(6) {
          text-align: end;
        }
      }
    `,
  };
}

const headerTooltips = {
  serviceName: {
    content:
      'The name of the service, inferred from the OpenTelemetry service.name & optionally service.namespace resource attributes',
  },
  serviceNamespace: { content: 'The namespace as specified by the OpenTelemetry service.namespace resource attribute' },
  technology: {
    content: 'The technology that this service uses, inferred from OpenTelemetry resource attributes',
  },
  duration: {
    content:
      'The 95th percentile of the duration that this service needs to process a request aggregated across all distinct operations',
  },
  errors: { content: 'The rate of errors occurring aggregated across all operations this service performs' },
  rate: { content: 'The rate of incoming requests, aggregated across all operations this service performs' },
};
