import { FILTER_BY_NAME } from 'constants/variables';
import { REDQueries } from 'types/queries';
import { hasEnvironmentAttribute } from 'utils/environmentFilter';
import { escapeRegExp } from 'utils/regexp';

export function makeRedLabelsUninstrumented(withJob: boolean, serverNames?: string[]): string {
  return [
    {
      name: 'server',
      operator: '=',
      value: withJob ? '${job}' : '',
    },
    {
      name: 'server',
      operator: '=~',
      value: serverNames?.map((x) => escapeRegExp(x, true)).join('|') ?? '',
    },
    ...(hasEnvironmentAttribute()
      ? [
          {
            name: 'client_${environmentAttribute}',
            operator: '=~',
            value: '${environmentValue:regex}',
          },
        ]
      : []),
  ]
    .reduce<string[]>((acc, label) => {
      if (label.value) {
        acc.push(`${label.name}${label.operator}"${label.value}"`);
      }

      return acc;
    }, [])
    .join(', ');
}
export function getHistogramQuantile(query: string, value: number, groupBy: string): string {
  return `histogram_quantile(0.${value}, ${query} by (${groupBy}${groupBy ? ', ' : ''}le))`;
}

export function makeRedQueriesUninstrumented({
  groupBy = '',
  withJob,
  serverNames,
}: {
  groupBy?: string;
  withJob?: boolean;
  serverNames?: string[];
}): REDQueries & { labels: string } {
  const labels = makeRedLabelsUninstrumented(!!withJob, serverNames);
  const filterBy = `$\{${FILTER_BY_NAME}:downstreamAppend}`;

  const latencyCountQuery = `sum(rate($\{metricName:serviceGraphRequestServerSecondsCount}{${labels}${filterBy}} [$__rate_interval]))`;
  const latencySumQuery = `sum(rate($\{metricName:serviceGraphRequestServerSecondsSum}{${labels}${filterBy}} [$__rate_interval]))`;
  const latencyBucketQuery = `sum(rate($\{metricName:serviceGraphRequestServerSecondsBucket}{${labels}${filterBy}} [$__rate_interval]))`;

  const rateQuery = `${latencyCountQuery} by (${groupBy})`;

  const errorsQuery = `sum(rate($\{metricName:serviceGraphFailedTotalRequests}{${labels}${filterBy}} [$__rate_interval])) by (${groupBy})`;

  return {
    rate: {
      query: rateQuery,
    },
    errors: {
      query: `(${errorsQuery} OR ${rateQuery} * 0) / ${rateQuery}`,
    },
    avg: {
      query: `${latencySumQuery} / ${latencyCountQuery} by (${groupBy})`,
    },
    p95: {
      query: getHistogramQuantile(latencyBucketQuery, 95, groupBy),
    },
    p99: {
      query: getHistogramQuantile(latencyBucketQuery, 99, groupBy),
    },
    labels: `group by(${groupBy}) ($\{metricName:serviceGraphRequestServerSecondsCount}{connection_type!=''${labels ? ',' : ''}${labels}${filterBy}})`,
  };
}
