import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export function getCommonStyles(theme: GrafanaTheme2) {
  const padding = theme.spacing(6);
  const pagePaddingDesktop = theme.spacing(4);
  const pagePaddingSmall = theme.spacing(2);

  return {
    hr: css`
      width: calc(100% + ${pagePaddingSmall} * 2);
      margin: 0 -${pagePaddingSmall} 0 -${pagePaddingSmall};

      ${theme.breakpoints.up('md')} {
        width: calc(100% + ${pagePaddingDesktop} * 2);
        margin: 0 -${pagePaddingDesktop} 0 -${pagePaddingDesktop};
      }
    `,

    containerCenter: css`
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding: ${padding};
      text-align: center;
      width: 100%;
    `,

    containerLeft: css`
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: ${padding};
      width: 100%;
    `,

    title: css`
      color: ${theme.colors.text.primary};
      font-size: ${theme.typography.h1.fontSize};
      margin-bottom: 0;
    `,

    element: css`
      margin: ${theme.spacing(4)} 0 0 0;
    `,

    link: css`
      display: inline-flex;
      gap: 4px;
      align-items: center;
      justify-content: center;

      color: ${theme.colors.text.link};

      &:hover {
        text-decoration: underline;
      }
    `,
  };
}
