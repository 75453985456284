export const INPUT_DEBOUNCE_MS = 500;
export const DEFAULT_PAGE_SIZE = 15;
export const PAGE_SIZE_OPTIONS = [5, 10, 15, 25, 50];
export const SELECTABLE_PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS.map((size) => ({
  value: size,
  label: size.toString(),
}));

export const SEGMENT_MIN_WIDTH = 90;

export const TIME_RANGE_SESSION_STORAGE = 'app-observability-time-range';
export const FILTERS_SESSION_STORAGE = 'app-observability-services-filters';
export const METRICS_MODE_LOCAL_STORAGE = 'app-observability-metrics-mode';
