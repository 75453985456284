import { getOrgInfo } from 'api/org';
import { getFaro } from 'faro/instance';
import { Org } from 'types/instance';

import { getPluginConfigService } from './PluginConfigService';

let defaultOrgService: OrgService;

class OrgService {
  private org?: Org;

  async fetch(): Promise<Org | undefined> {
    if (this.org) {
      return this.org;
    }

    const orgId = getPluginConfigService().getPluginConfig().orgId;
    if (!orgId) {
      // Shouldn't happen, we trust the info in the jsonData, but just in case
      return;
    }

    const { ok, data, error } = await getOrgInfo(orgId);
    if (ok) {
      this.org = data;
      return this.org;
    } else {
      getFaro()?.api.pushError(
        error instanceof Error ? error : new Error('Something went wrong while fetching org info')
      );

      return undefined;
    }
  }

  getOrg(): Org | undefined {
    return this.org;
  }
}

export function getOrgService(): OrgService {
  return defaultOrgService;
}

export async function initializeOrgService(): Promise<OrgService> {
  if (!defaultOrgService) {
    const service = new OrgService();
    await service.fetch();
    defaultOrgService = service;
  }

  return defaultOrgService;
}
