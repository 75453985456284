import React from 'react';

import { SceneObjectRef } from '@grafana/scenes';

import { HostModalScene } from './HostModal';
import { NotificationScene } from './Notification';

export interface SubtitleProps {
  notificationRef: SceneObjectRef<NotificationScene>;
  hostModalRef: SceneObjectRef<HostModalScene>;
}

export const Subtitle = ({ notificationRef, hostModalRef }: SubtitleProps) => {
  const notification = notificationRef.resolve();
  const hostModal = hostModalRef.resolve();

  return (
    <>
      {notification && <notification.Component model={notification} />}
      {hostModal && <hostModal.Component model={hostModal} />}
    </>
  );
};
