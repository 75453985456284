export enum SpanKind {
  CLIENT = 'SPAN_KIND_CLIENT',
  PRODUCER = 'SPAN_KIND_PRODUCER',
  SERVER = 'SPAN_KIND_SERVER',
  CONSUMER = 'SPAN_KIND_CONSUMER',
}

export const isValidSpanKind = (value: unknown): value is SpanKind => {
  return typeof value === 'string' && Object.values(SpanKind).includes(value as any);
};
