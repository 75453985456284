import React from 'react';

import { Service } from 'types/services';

import { ServicesTable } from './ServicesTable';

const LOADING_SERVICES: Service[] = [1, 2, 3].map((i) => ({
  job: `loading${i}`,
  encodedJob: `loading${i}`,
  serviceName: `loading${i}`,
  encodedServiceName: `loading${i}`,
  serviceNamespace: `loading${i}`,
  encodedServiceNamespace: `loading${i}`,
  loading: true,
  data: {
    rate: {
      vector: [],
      value: 0,
      loading: true,
    },
    errors: {
      vector: [],
      value: 0,
      loading: true,
    },
    duration: {
      vector: [],
      value: 0,
      loading: true,
    },
  },
}));

export const ServicesTablePlaceholder = (): JSX.Element => (
  <ServicesTable services={LOADING_SERVICES} handleServiceNamespaceClick={() => {}} sortData={() => {}} filters={[]} />
);
