export const DEFAULT_LOKI_QUERY_WITH_NAMESPACE =
  '{exporter="OTLP", job="${serviceNamespace}/${serviceName}"} | json | resources_${environmentAttribute}=~"${environmentValue}"';
export const DEFAULT_LOKI_QUERY_WITHOUT_NAMESPACE =
  '{exporter="OTLP", job="${serviceName}"} | json | resources_${environmentAttribute}=~"${environmentValue}"';

export const DEFAULT_LOKI_QUERY_FORMATTING =
  'line_format `\u001b[1m{{if .level }}{{alignRight 5 .level}}{{else if .severity}}{{alignRight 5 .severity}}{{end}}\u001b[0m \u001b[90m[{{alignRight 10 .resources_service_instance_id}}{{if .attributes_thread_name}}/{{alignRight 20 .attributes_thread_name}}{{else if eq "java" .resources_telemetry_sdk_language }}                    {{end}}]\u001b[0m \u001b[36m{{if .instrumentation_scope_name }}{{alignRight 40 .instrumentation_scope_name}}{{end}}\u001b[0m {{.body}} {{if .traceid}} \u001b[37m\u001b[3m[traceid={{.traceid}}]{{end}}`';

export const DEFAULT_LOKI_OTLP_QUERY_WITH_NAMESPACE =
  '{service_namespace="${serviceNamespace}", service_name="${serviceName}"} | ${environmentAttribute}=~"${environmentValue}"';
export const DEFAULT_LOKI_OTLP_QUERY_WITHOUT_NAMESPACE =
  '{service_name="${serviceName}"} | ${environmentAttribute}=~"${environmentValue}"';

export const DEFAULT_LOKI_OTLP_QUERY_FORMATTING =
  'line_format `\u001b[1m{{if .level }}{{alignRight 5 .level}}{{else if .severity_text}}{{alignRight 5 .severity_text}}{{end}}\u001b[0m \u001b[90m[{{alignRight 10 .service_instance_id}}{{if .thread_name}}/{{alignRight 20 .thread_name}}{{else if eq "java" .telemetry_sdk_language }}                    {{end}}]\u001b[0m \u001b[36m{{if .scope_name }}{{alignRight 40 .scope_name}}{{end}}\u001b[0m {{__line__}} {{if .trace_id}} \u001b[37m\u001b[3m[trace_id={{.trace_id}}]{{end}}`';

export const TRACEQL_TYPE = 'traceql';
export const TRACEQL_SEARCH_TYPE = 'traceqlSearch';
export const TRACEQL_SEARCH_SERVICE_NAME_ID = 'service-name';
export const TRACEQL_SEARCH_SERVICE_NAME_TAG = 'service.name';
export const TRACEQL_SEARCH_SERVICE_NAMESPACE_ID = 'resourceservicenamespace';
export const TRACEQL_SEARCH_SERVICE_NAMESPACE_TAG = 'service.namespace';
export const TRACEQL_SEARCH_OPERATION_ID = 'span-name';
export const TRACEQL_SEARCH_OPERATION_TAG = 'name';
export const TRACEQL_SEARCH_ENVIRONMENT_ID = 'environment';
export const TRACEQL_SEARCH_STATUS_ID = 'status';
export const TRACEQL_SEARCH_STATUS_TAG = 'status';
export const TRACEQL_SEARCH_MIN_DURATION_ID = 'min-duration';
export const TRACEQL_SEARCH_MAX_DURATION_ID = 'max-duration';
export const TRACEQL_SEARCH_DURATION_TAG = 'duration';

export enum TRACEQL_SEARCH_SCOPE {
  INTRINSIC = 'intrinsic',
  RESOURCE = 'resource',
  SPAN = 'span',
  UNSCOPED = 'unscoped',
}

export const TARGET_INFO_REF_ID = 'serviceTargetInfo';
export const SERVICE_GRAPH_RATE_REF_ID = 'serviceGraphRateQuery';
export const SERVICES_SPAN_KIND = 'servicesSpanKind';

export const UPPER_ANOMALY_THRESHOLD_REF_ID = 'upperAnomalyThreshold';
export const LOWER_ANOMALY_THRESHOLD_REF_ID = 'lowerAnomalyThreshold';
export const UPPER_ANOMALY_THRESHOLD_LEGEND_NAME = 'Upper Anomaly Threshold';
export const LOWER_ANOMALY_THRESHOLD_LEGEND_NAME = 'Lower Anomaly Threshold';

export const UPPER_ANOMALY_HIGHLIGHT_LINE_REF_ID = 'upperAnomalyHighlightLine';

export const AGGREGATED_LABEL_VALUE = '__aggregated__';

export const BASELINE_CONSTANT_REF_ID = 'baselineConstant';
