import { PRESERVE_URL_KEYS, ROUTE_DEFINITIONS, ROUTES } from 'constants/routing';
import { TrackedSceneAppPage } from 'faro/TrackedSceneAppPage';

import { makeServiceTracesScene } from './makeServiceTracesScene';

export const TRACES_VIEW_NAME = 'service-traces';

export function makeServiceTracesPage(job: string, encodedJob: string): TrackedSceneAppPage {
  return new TrackedSceneAppPage({
    viewName: TRACES_VIEW_NAME,
    title: 'Traces',
    routePath: ROUTE_DEFINITIONS.traces,
    url: ROUTES.traces(encodedJob),
    preserveUrlKeys: PRESERVE_URL_KEYS,
    getScene: makeServiceTracesScene(job, encodedJob),
  });
}
