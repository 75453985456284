import { SceneQueryRunner } from '@grafana/scenes';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { PROMETHEUS_DS } from 'constants/datasources';
import { DEFAULT_FIELD_CONFIG } from 'constants/panels';

export function makeServiceDotnetProcessMemoryUsagePanel(): ExplorablePanel {
  return new ExplorablePanel({
    title: 'Memory (Physical)',
    description: 'Amount of physical memory, in bytes, allocated for the process',

    trackingSection: 'service',
    trackingPage: 'dotnet',
    trackingPanel: 'process-memory-usage',

    fieldConfig: {
      defaults: {
        ...DEFAULT_FIELD_CONFIG,
        unit: 'bytes',
      },
      overrides: [],
    },

    $data: new SceneQueryRunner({
      datasource: PROMETHEUS_DS,
      maxDataPointsFromWidth: true,
      queries: [
        {
          expr: '{__name__=~"process_memory_usage|process_memory_usage_bytes", job="${job}", instance=~"${instance:regex}"}',
          refId: 'serviceDotnetProcessMemoryUsage',
          legendFormat: '{{instance}}',
        },
      ],
    }),
  });
}
