import { SceneRouteMatch } from '@grafana/scenes';

import { decodeParameter, encodeParameter } from './routing';

export function parseJob(job: string): {
  serviceName: string;
  encodedServiceName: string;
  serviceNamespace: string;
  encodedServiceNamespace: string;
} {
  // split on / but not on //
  // not doing it with lookback regex because it's not supported in safari <= 16.3
  const parts = job
    .replace(/\/\//g, '_SLASHSLASH_')
    .split('/')
    .map((y) => y.replace(/_SLASHSLASH_/g, '//'));

  if (parts.length === 1) {
    return {
      serviceName: job,
      encodedServiceName: encodeParameter(job),
      serviceNamespace: '',
      encodedServiceNamespace: '',
    };
  }

  const serviceNamespace = parts[0];
  const serviceName = parts.slice(1).join('/');

  return {
    serviceNamespace,
    encodedServiceNamespace: encodeParameter(serviceNamespace),
    serviceName,
    encodedServiceName: encodeParameter(serviceName),
  };
}

export function stringifyJob(serviceName: string, serviceNamespace?: string): string {
  if (serviceNamespace) {
    return `${serviceNamespace}/${serviceName}`;
  }
  return serviceName;
}

export function extractJobFromRoute(routeMatch: SceneRouteMatch<{ job: string }>): { job: string; encodedJob: string } {
  const encodedJob = routeMatch.params.job;
  const job = decodeParameter(encodedJob);

  return { encodedJob, job };
}

export function extractOperationFromRoute(routeMatch: SceneRouteMatch<{ job: string; operation: string }>): {
  operation: string;
  encodedOperation: string;
} {
  const encodedOperation = routeMatch.params.operation;
  const operation = decodeParameter(encodedOperation);

  return { encodedOperation, operation };
}

export function jobContainsNamespace(job: string): boolean {
  return !!parseJob(job).serviceNamespace;
}
