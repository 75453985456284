import { DataFrame, Field } from '@grafana/data';

export function isComparisonDataFrame(frame: DataFrame): boolean {
  return frame.refId?.includes('-compare') ?? false;
}

export function isComparisonField(field: Field): boolean {
  return field.state?.displayName?.includes('comparison') ?? false;
}

export function duplicateDataFrame(frame: DataFrame): DataFrame {
  return {
    ...frame,
    fields: frame.fields.map((field) => ({
      ...field,
      values: [],
    })),
  };
}
