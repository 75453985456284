import React from 'react';

import { SceneAppPage } from '@grafana/scenes';

import { ServicePageTitle } from 'modules/service/components/ServicePageTitle/ServicePageTitle';
import { NewMetadataFetchedEvent } from 'modules/service/events';
import { getMetadataService } from 'services/MetadataService';

export function getTitleFactory({ job, operation }: { job: string; operation?: string }): JSX.Element {
  const metadataService = getMetadataService();

  const isLoading = !(metadataService.isServiceStored(job) && metadataService.hasIsInstrumented(job));
  return <ServicePageTitle operation={operation} isLoading={isLoading} job={job} />;
}

export function onRefreshMetadataBehavior<T>(state: () => Partial<T>) {
  const metadataService = getMetadataService();

  return (page: SceneAppPage) => {
    const unsubscribable = page.subscribeToEvent(
      NewMetadataFetchedEvent,
      ({ payload: { isInstrumented, frames, job } }) => {
        const isRefreshed = metadataService.refreshService(job, isInstrumented, frames);
        if (isRefreshed) {
          page.setState(state());
        }
      }
    );

    return () => unsubscribable.unsubscribe();
  };
}
