import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export function getCommonStyles(theme: GrafanaTheme2) {
  return {
    container: css`
      display: flex;
      flex-direction: column;

      & label {
        color: ${theme.colors.text.secondary};
      }
    `,
    sectionTitle: css`
      margin-bottom: ${theme.spacing(3)};
    `,
    title: css`
      color: ${theme.colors.text.primary};
    `,
    description: css`
      color: ${theme.colors.text.secondary};
    `,
    separator: css`
      width: 100%;
    `,

    link: css`
      color: ${theme.colors.text.link};

      &:hover {
        text-decoration: underline;
      }
    `,
    acknowledgeContainer: css`
      cursor: pointer;
      user-select: none;
      display: flex;
      gap: ${theme.spacing(1)};
    `,
    acknowledgeCheckbox: css`
      margin-right: ${theme.spacing(1)};
    `,
    needHelpIcon: css`
      text-decoration: underline;
      cursor: pointer;
    `,
    iconToTextMarginRight: css`
      margin-right: ${theme.spacing(1)};
    `,

    modal: css`
      width: 750px;
    `,
    buttonsContainer: css`
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin: ${theme.spacing(2)} 0 0 0;
    `,

    activateButton: css`
      margin: 0 0 0 ${theme.spacing(1)};
    `,
  };
}
