import { css } from '@emotion/css';
import React, { useEffect, useMemo, useState } from 'react';

import { GrafanaTheme2, SelectableValue, toOption } from '@grafana/data';
import { Button, MultiSelect, Select, useStyles2 } from '@grafana/ui';

import { AttributeFilter, FilterByVariable } from 'components/FilterByVariable';
import { EMPTY_FILTER_LABEL, TECHNOLOGY_FILTER } from 'constants/filterBy';

interface Props {
  filter: AttributeFilter;
  model: FilterByVariable;
}

export function AdHocFilterRenderer({ filter, model }: Props) {
  const styles = useStyles2(getStyles);

  const [state, setState] = useState<{
    keys?: SelectableValue[];
    values?: SelectableValue[];
    isKeysLoading?: boolean;
    isValuesLoading?: boolean;
    isKeysOpen?: boolean;
    isValuesOpen?: boolean;
  }>({});

  const keyValue =
    filter.key !== '' ? (state?.keys?.find((key) => key.value === filter.key) ?? toOption(filter.key)) : null;

  const valueValue = filter.value ? filter.value.map((v) => toOption(v)) : [];

  const operators = useMemo(() => {
    const operators = model._getOperators();
    if ([TECHNOLOGY_FILTER].includes(filter.key)) {
      return operators.slice(0, 2);
    }

    return operators;
  }, [filter, model]);

  useEffect(() => {
    async function updateKeys() {
      setState({ ...state, isKeysLoading: true });
      const keys = await model._getKeys(filter.key);
      setState({ ...state, isKeysLoading: false, keys });
    }

    if (keyValue && state.keys === undefined && !state.isKeysLoading) {
      updateKeys();
    }
  }, [filter, keyValue, model, state]);

  const valueSelect = (
    <MultiSelect
      disabled={model.state.readOnly}
      className={state.isKeysOpen ? styles.widthWhenOpen : undefined}
      width="auto"
      value={valueValue}
      placeholder={'value'}
      options={state.values}
      onChange={(v) => {
        model._updateFilter(
          filter,
          'value',
          v.map(({ value }) => value)
        );
      }}
      isOpen={state.isValuesOpen}
      isLoading={state.isValuesLoading}
      onOpenMenu={async () => {
        setState({ ...state, isValuesLoading: true });
        const values = await model._getValuesFor(filter);
        setState({ ...state, isValuesLoading: false, isValuesOpen: true, values });
      }}
      onCloseMenu={() => {
        setState({ ...state, isValuesOpen: false });
      }}
      allowCustomValue
      onCreateOption={(value) => {
        model._updateFilter(filter, 'value', [...filter.value, value]);
      }}
      getOptionLabel={({ label, value }) => (label === '' && value === '' ? EMPTY_FILTER_LABEL : label)}
    />
  );

  const keySelect = (
    <Select
      disabled={model.state.readOnly}
      className={state.isKeysOpen ? styles.widthWhenOpen : undefined}
      width="auto"
      value={keyValue}
      placeholder={'Select label'}
      options={state.keys}
      onChange={(v) => model._updateFilter(filter, 'key', v.value)}
      autoFocus={filter.key === ''}
      isOpen={state.isKeysOpen}
      isLoading={state.isKeysLoading}
      onOpenMenu={async () => {
        setState({ ...state, isKeysLoading: true });
        const keys = await model._getKeys(filter.key);
        setState({ ...state, isKeysLoading: false, isKeysOpen: true, keys });
      }}
      onCloseMenu={() => {
        setState({ ...state, isKeysOpen: false });
      }}
      openMenuOnFocus={true}
    />
  );

  return (
    <div className={styles.wrapper} data-testid={`AdHocFilter-${filter.key}`}>
      {keySelect}
      <Select
        value={filter.operator}
        disabled={model.state.readOnly}
        options={operators}
        width="auto"
        onChange={(v) => {
          if (!v.value) {
            return;
          }

          model._updateFilter(filter, 'operator', v.value);
        }}
      />
      {valueSelect}
      <Button
        variant="secondary"
        aria-label="Remove filter"
        title="Remove filter"
        className={styles.removeButton}
        icon="times"
        data-testid={`AdHocFilter-remove-${filter.key ?? ''}`}
        onClick={() => model._removeFilter(filter)}
        data-fs-element="App o11y - Remove filter"
      />
    </div>
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  field: css({
    marginBottom: 0,
  }),
  wrapper: css({
    display: 'flex',
    '> *': {
      '&:not(:first-child)': {
        // Negative margin hides the double-border on adjacent selects
        marginLeft: -1,
      },

      '&:first-child': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },

      '&:last-child': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },

      '&:not(:first-child):not(:last-child)': {
        borderRadius: 0,
      },

      // Fix focus state zIndex issues
      position: 'relative',
      zIndex: 0,

      // Adjacent borders are overlapping, so raise children up when hovering etc
      // so all that child's borders are visible.
      '&:hover': {
        zIndex: 1,
      },

      '&:focus-within': {
        zIndex: 2,
      },
    },
  }),
  widthWhenOpen: css({
    minWidth: theme.spacing(16),
  }),
  removeButton: css({
    paddingLeft: theme.spacing(3 / 2),
    paddingRight: theme.spacing(3 / 2),
    borderLeft: 'none',
    // To not have button background and last select border intersect
    position: 'relative',
    left: '1px',
  }),
});
