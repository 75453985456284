import { css } from '@emotion/css';
import { isEqual } from 'lodash';
import React from 'react';
import { Unsubscribable } from 'rxjs';

import { GrafanaTheme2 } from '@grafana/data';
import {
  SceneComponentProps,
  sceneGraph,
  SceneObjectBase,
  SceneObjectRef,
  SceneObjectState,
  SceneObjectUrlSyncConfig,
  SceneObjectUrlValues,
  SceneQueryRunner,
  SplitLayout,
  VariableDependencyConfig,
  VizPanel,
} from '@grafana/scenes';
import { DataQuery } from '@grafana/schema';
import { useStyles2 } from '@grafana/ui';

import { EnvironmentValueVariable } from 'components/EnvironmentValueVariable';
import { TEMPO_DS } from 'constants/datasources';
import { TRACEQL_SEARCH_ENVIRONMENT_ID } from 'constants/query';
import {
  ENVIRONMENT_ATTRIBUTE_NAME,
  ENVIRONMENT_VALUE_NAME,
  JOB_NAME,
  SERVICE_NAME_NAME,
  SERVICE_NAMESPACE_NAME,
  TEMPO_DS_NAME,
} from 'constants/variables';
import {
  LogsTracesOpenInExploreButton,
  LogsTracesOpenInExploreButtonState,
} from 'modules/service/components/LogsTracesOpenInExploreButton';
import {
  isValidQuery,
  removeAllValueFromQuery,
  removeAllVariableFromFilters,
} from 'modules/service/components/TracesScene/utils';
import { getTempoQuery } from 'queries/tempo';
import { TempoQuery } from 'types/queries';
import { hasEnvironmentAttribute } from 'utils/environmentFilter';
import { escapeBackslash, escapeDoubleQuotes } from 'utils/format';
import { row } from 'utils/layout';
import { parseJob } from 'utils/services';

import { makeTracesTablePanel } from './panels/makeTracesTablePanel';
import { makeTracesTracePanel } from './panels/makeTracesTracePanel';
import { InternalTracesSearchState, TracesSearchEditor } from './TracesSearchEditor';

export interface TracesSceneState {
  encodedJob: string;
  job: string;

  encodedOperation?: string;
  operation?: string;
}

export interface InternalTracesSceneState extends SceneObjectState {
  encodedJob: string;
  initialized: boolean;
  job: string;
  serviceName: string;
  serviceNamespace: string;

  encodedOperation?: string;
  explore?: LogsTracesOpenInExploreButton;
  operation?: string;
  query?: TempoQuery;
  queryRunnerRef?: SceneObjectRef<SceneQueryRunner>;
  queryScope?: TempoQuery;
  searchEditor?: TracesSearchEditor;
  traceId?: string;
  spanId?: string;
  tracesViewPanelRef?: SceneObjectRef<VizPanel>;
  traceQueryRunnerRef?: SceneObjectRef<SceneQueryRunner>;
  splitView?: SplitLayout;
}

const URL_SYNC_KEYS = ['traceId', 'spanId', 'tracesQuery'];

export class TracesScene extends SceneObjectBase<InternalTracesSceneState> {
  static Component = TracesSceneRenderer;

  protected _urlSync = new SceneObjectUrlSyncConfig(this, { keys: URL_SYNC_KEYS });

  protected _variableDependency = new VariableDependencyConfig(this, {
    variableNames: [
      JOB_NAME,
      SERVICE_NAME_NAME,
      SERVICE_NAMESPACE_NAME,
      ENVIRONMENT_ATTRIBUTE_NAME,
      ENVIRONMENT_VALUE_NAME,
      TEMPO_DS_NAME,
    ],
  });

  constructor(state: TracesSceneState) {
    const { serviceName, serviceNamespace } = parseJob(state.job);

    super({
      ...state,
      initialized: false,
      serviceName: escapeDoubleQuotes(escapeBackslash(serviceName)),
      serviceNamespace: escapeDoubleQuotes(escapeBackslash(serviceNamespace)),
      operation: escapeDoubleQuotes(escapeBackslash(state.operation)),
    });

    this.addActivationHandler(() => {
      const unsubscribables: Unsubscribable[] = [];

      if (hasEnvironmentAttribute()) {
        const environmentValueVariable = sceneGraph.lookupVariable(
          ENVIRONMENT_VALUE_NAME,
          this
        ) as EnvironmentValueVariable;
        unsubscribables.push(
          environmentValueVariable?.subscribeToState((newState, oldState) => {
            if (!isEqual(newState.value, oldState.value)) {
              this.updateOrInitialize(this.state.query, this.state.traceId, this.state.spanId, false);
            }
          })
        );
      }

      this.updateOrInitialize(this.state.query, this.state.traceId, this.state.spanId, false);

      return () => {
        unsubscribables.forEach((unsubscribable) => unsubscribable.unsubscribe());
      };
    });
  }

  getUrlState() {
    return { traceId: this.state.traceId, spanId: this.state.spanId, tracesQuery: JSON.stringify(this.state.query) };
  }

  updateFromUrl(values: SceneObjectUrlValues) {
    // why is this so weird?
    // because values only contains properties that _changed_
    this.updateOrInitialize(
      !Object.prototype.hasOwnProperty.call(values, 'tracesQuery')
        ? this.state.query
        : typeof values.tracesQuery === 'string'
          ? JSON.parse(decodeURIComponent(values.tracesQuery))
          : undefined,
      !Object.prototype.hasOwnProperty.call(values, 'traceId')
        ? this.state.traceId
        : typeof values.traceId === 'string'
          ? decodeURIComponent(values.traceId)
          : undefined,
      !Object.prototype.hasOwnProperty.call(values, 'spanId')
        ? this.state.spanId
        : typeof values.spanId === 'string'
          ? decodeURIComponent(values.spanId)
          : undefined,
      false
    );
  }

  private updateOrInitialize(
    newQuery: TempoQuery | undefined | null,
    newTraceId: string | undefined,
    newSpanId: string | undefined,
    forceRun: boolean | undefined
  ) {
    const environmentAttribute = sceneGraph.interpolate(this, '${environmentAttribute:dotted}');

    const computedQuery = getTempoQuery(this.state.job, this.state.operation, newQuery?.queryType);
    computedQuery.query = removeAllValueFromQuery(
      sceneGraph.interpolate(this, computedQuery.query),
      environmentAttribute
    );

    computedQuery.filters = removeAllVariableFromFilters(
      computedQuery.filters?.map((filter) => ({
        ...filter,
        tag: sceneGraph.interpolate(this, filter.tag),
        value: Array.isArray(filter.value)
          ? filter.value.map((value) => sceneGraph.interpolate(this, value))
          : sceneGraph.interpolate(this, filter.value),
      }))
    );

    let query: TempoQuery;

    if (isValidQuery(newQuery, computedQuery)) {
      newQuery!.filters = removeAllVariableFromFilters(newQuery?.filters);
      newQuery!.query = removeAllValueFromQuery(this.updateQueryString(newQuery), environmentAttribute);

      query = newQuery as TempoQuery;
    } else if (
      this.state.queryScope &&
      !isEqual(this.state.queryScope, computedQuery) &&
      isValidQuery(newQuery, this.state.queryScope)
    ) {
      const computedFiltersMap = (computedQuery.filters ?? []).reduce(
        (acc, currentFilter) => ({
          ...acc,
          [currentFilter.id]: currentFilter,
        }),
        {}
      );

      const newFiltersMap = (newQuery?.filters ?? []).reduce(
        (acc, currentFilter) => ({
          ...acc,
          [currentFilter.id]: currentFilter,
        }),
        {}
      );

      Object.keys(computedFiltersMap).forEach((key) => {
        (newFiltersMap as any)[key] = (computedFiltersMap as any)[key];
      });

      // If we deleted the environment filter, we need to manually remove it
      // from the newQuery as well
      if (
        !(computedFiltersMap as any)[TRACEQL_SEARCH_ENVIRONMENT_ID] &&
        (newFiltersMap as any)[TRACEQL_SEARCH_ENVIRONMENT_ID]
      ) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete (newFiltersMap as any)[TRACEQL_SEARCH_ENVIRONMENT_ID];
      }

      query = {
        ...newQuery,
        query: removeAllValueFromQuery(this.updateQueryString(newQuery), environmentAttribute),
        filters: removeAllVariableFromFilters(Object.values(newFiltersMap)),
      } as TempoQuery;
    } else {
      computedQuery.filters = removeAllVariableFromFilters(computedQuery.filters);

      query = computedQuery;
    }

    const newTraceQuery = newTraceId
      ? { queryType: 'traceql', query: newTraceId, refId: 'trace', limit: 20 }
      : undefined;

    if (this.state.initialized) {
      setTimeout(() => this.update(query, computedQuery, newTraceId, newSpanId, newTraceQuery, forceRun));

      return;
    }

    this.initialize(query, computedQuery, newTraceId, newTraceQuery, newSpanId);
  }

  private updateQueryString(query: TempoQuery | undefined | null) {
    const attributeName = sceneGraph.interpolate(this, 'resource.${environmentAttribute:dotted}');
    const serviceName = sceneGraph.interpolate(this, 'resource.service.name="${serviceName}"');
    const fullEnvironment = sceneGraph.interpolate(
      this,
      'resource.${environmentAttribute:dotted}=~"${environmentValue:regex}"'
    );
    const regex = new RegExp(`${attributeName}=~".*?"`);

    return query?.query?.match(regex)
      ? query?.query?.replace(regex, fullEnvironment)
      : query?.query?.replace(serviceName, `${serviceName} && ${fullEnvironment}`);
  }

  private update(
    query: TempoQuery,
    queryScope: TempoQuery,
    traceId: string | undefined,
    spanId: string | undefined,
    traceQuery: TempoQuery | undefined,
    forceRun: boolean | undefined
  ) {
    const newState: Partial<InternalTracesSceneState> = {};

    // if trace id changed
    if (this.state.traceId !== traceId) {
      newState.traceId = traceId;
      if (traceQuery) {
        // if trace view is not open, open it
        if (!this.state.traceQueryRunnerRef) {
          const [traceQueryRunner, tracePanel] = this.makeTraceViewQueryRunnerAndPanel(traceQuery, spanId);
          this.setState({
            traceQueryRunnerRef: traceQueryRunner.getRef(),
            tracesViewPanelRef: tracePanel.getRef(),
          });
          this.state.splitView?.setState({
            secondary: row({}, tracePanel),
          });
          // if trace view is already open, issue new query and set focused span id if needed
        } else {
          this.state.traceQueryRunnerRef?.resolve().setState({ queries: [traceQuery] });
          this.state.traceQueryRunnerRef?.resolve().runQueries();
          if (this.state.spanId !== spanId && traceId) {
            this.state.tracesViewPanelRef?.resolve().onOptionsChange({
              focusedSpanId: spanId,
            });
          }
        }
      } else {
        // no trace id, close split panel
        if (this.state.splitView?.state.secondary) {
          this.state.splitView?.setState({ secondary: undefined });
          this.setState({
            traceQueryRunnerRef: undefined,
            tracesViewPanelRef: undefined,
          });
        }
      }
      // trace id did not change, only have to update focused span id
    } else if (this.state.spanId !== spanId && traceId) {
      this.state.tracesViewPanelRef?.resolve().onOptionsChange({
        focusedSpanId: spanId,
      });
    }

    if (!isEqual(query, this.state.query)) {
      newState.query = query;
    }

    if (!isEqual(queryScope, this.state.queryScope)) {
      newState.queryScope = queryScope;
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState);
    }

    const newExploreState: Partial<LogsTracesOpenInExploreButtonState> = {};

    if (!isEqual(this.state.explore?.state.rightQuery, traceQuery)) {
      newExploreState.rightQuery = traceQuery;
    }

    if (!isEqual(this.state.explore?.state.leftQuery, query)) {
      newExploreState.leftQuery = query;
    }

    if (Object.keys(newExploreState).length > 0) {
      this.state.explore?.setState(newExploreState);
    }

    if (!isEqual(query, this.state.queryRunnerRef?.resolve().state.queries[0]) || forceRun) {
      const queryRunner = this.state.queryRunnerRef?.resolve();
      queryRunner?.cancelQuery();
      queryRunner?.setState({ queries: [query] });
      queryRunner?.runQueries();
    }

    const newSearchEditorState: Partial<InternalTracesSearchState> = {};

    if (!isEqual(this.state.searchEditor?.state.query, query)) {
      newSearchEditorState.query = query;
      newSearchEditorState.isValid = true;
    }

    if (!isEqual(this.state.searchEditor?.state.queryScope, queryScope)) {
      newSearchEditorState.queryScope = queryScope;
      newSearchEditorState.isValid = true;
    }

    if (Object.keys(newSearchEditorState).length > 0) {
      clearTimeout(this.state.searchEditor?.state.pendingChanges);

      this.state.searchEditor?.setState(newSearchEditorState);
    }
  }

  private makeTraceViewQueryRunnerAndPanel(
    traceQuery: DataQuery,
    spanId: string | undefined
  ): [SceneQueryRunner, VizPanel] {
    const traceQueryRunner = new SceneQueryRunner({ datasource: TEMPO_DS, queries: [traceQuery] });
    const tracePanel = makeTracesTracePanel(
      this,
      this.state.encodedJob,
      this.state.encodedOperation,
      traceQueryRunner,
      () => this.updateOrInitialize(this.state.query, undefined, undefined, false),
      spanId
    );
    return [traceQueryRunner, tracePanel];
  }

  private initialize(
    query: TempoQuery,
    queryScope: TempoQuery,
    traceId: string | undefined,
    traceQuery: TempoQuery | undefined,
    spanId: string | undefined
  ) {
    const queryRunner = new SceneQueryRunner({ datasource: TEMPO_DS, queries: [query] });
    const tracesTable = row(
      {},
      makeTracesTablePanel(queryRunner, this.state.encodedJob, this.state.encodedOperation, () => this.state.query)
    );

    let traceQueryRunner: SceneQueryRunner | undefined;
    let tracePanel: VizPanel | undefined;

    if (traceQuery) {
      [traceQueryRunner, tracePanel] = this.makeTraceViewQueryRunnerAndPanel(traceQuery, spanId);
    }

    this.setState({
      explore: new LogsTracesOpenInExploreButton({
        dataSourceName: TEMPO_DS_NAME,
        leftQuery: query,
        pageName: 'traces',
        rightQuery: traceQuery,
        sectionName: this.state.operation ? 'operation' : 'service',
      }),
      initialized: true,
      query,
      queryRunnerRef: queryRunner.getRef(),
      queryScope,
      searchEditor: new TracesSearchEditor({
        query,
        queryScope,
        operation: this.state.operation,
        serviceName: this.state.serviceName,
        serviceNamespace: this.state.serviceNamespace,
        updateQuery: (query, forceRun) =>
          this.updateOrInitialize(query, this.state.traceId, this.state.spanId, forceRun),
      }),
      traceId,
      spanId,
      traceQueryRunnerRef: traceQueryRunner?.getRef(),
      tracesViewPanelRef: tracePanel?.getRef(),
      splitView: new SplitLayout({
        direction: 'row',
        primary: tracesTable,
        secondary: tracePanel ? row({}, tracePanel) : undefined,
      }),
    });
  }
}

function TracesSceneRenderer({ model }: SceneComponentProps<TracesScene>) {
  const styles = useStyles2(getStyles);

  const { explore, searchEditor, splitView, initialized } = model.useState();

  if (!initialized) {
    return null;
  }

  const SearchEditor = searchEditor?.Component;
  const ExploreButton = explore?.Component;
  const Panel = splitView?.Component;

  return (
    <div className={styles.container}>
      <div data-cy="query-editor-container" className={styles.queryEditorContainer}>
        {SearchEditor && <SearchEditor model={searchEditor} />}
      </div>

      <div className={styles.openInExploreContainer}>{ExploreButton && <ExploreButton model={explore} />}</div>
      <div data-cy="traces-table-container" className={`${styles.panelContainer} fs-mask`}>
        {Panel && <Panel model={splitView} />}
      </div>
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    container: css`
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: ${theme.spacing(1)};
    `,
    queryEditorContainer: css`
      display: flex;
      flex-direction: column;
    `,
    panelContainer: css`
      flex: 1;
      display: flex;

      // Get safe viewport height
      // Minus size of top bar
      // Minus the size for showing the Open in Explore button
      // Minus the padding around the Open in Explore button
      // Minus the padding at the bottom
      min-height: calc(100svh - 80px - 32px - 16px - 24px);
    `,
    openInExploreContainer: css`
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    `,
  };
}
