import { SceneTimeRange } from '@grafana/scenes';

import { TIME_RANGE_SESSION_STORAGE } from 'constants/misc';
import { DEFAULT_TIME_RANGE } from 'constants/timeRange';
import { getFaro } from 'faro/instance';

export function makeTimeRange(): SceneTimeRange {
  return new SceneTimeRange({
    ...getSessionTimeRangeOrDefault(),
    $behaviors: [
      (scene: SceneTimeRange) => {
        const unsubscribable = scene.subscribeToState(({ from, to }) => {
          sessionStorage.setItem(TIME_RANGE_SESSION_STORAGE, JSON.stringify({ from, to }));
        });

        return () => {
          unsubscribable.unsubscribe?.();
        };
      },
    ],
  });
}

function getSessionTimeRangeOrDefault(): { from: string; to: string } {
  const sessionTimeRange = sessionStorage.getItem(TIME_RANGE_SESSION_STORAGE) || '{}';
  const timeRange = DEFAULT_TIME_RANGE;

  try {
    const { from, to } = JSON.parse(sessionTimeRange);

    if (from) {
      timeRange.from = from;
    }

    if (to) {
      timeRange.to = to;
    }
  } catch (err) {
    // If we fail to parse it, we use default value
    const error = err instanceof Error ? err : new Error('Could not parse time range saved in session storage');
    getFaro()?.api.pushError(error);

    sessionStorage.removeItem(TIME_RANGE_SESSION_STORAGE);
  }

  return timeRange;
}

export function paramsToTimeRange(params: Record<string, unknown>): { from: string; to: string } {
  return params['from'] && params['to']
    ? { from: params['from'] as string, to: params['to'] as string }
    : DEFAULT_TIME_RANGE;
}
