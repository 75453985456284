import React from 'react';

import { locationService } from '@grafana/runtime';
import { SceneApp, useSceneApp } from '@grafana/scenes';

import { HostModalScene } from 'components/HostModal';
import { NotificationScene } from 'components/Notification';
import { ROUTES } from 'constants/routing';
import { makeConfigurationPage } from 'modules/config/makeConfigurationPage';
import { InitializationCompleteBusEvent, ShowLandingPageBusEvent } from 'modules/initialize/initializeEvents';
import { makeInitializePage } from 'modules/initialize/makeInitializePage';
import { makeLandingPage } from 'modules/initialize/makeLandingPage';
import { makeServicesPage } from 'modules/services/makeServicesPage';
import { trackAttributes, trackDatasources } from 'utils/tracking';

import { makeRedirectPage } from './RedirectPage';

function getApp(): SceneApp {
  const hostInfoModal = new HostModalScene().getRef();
  const notification = new NotificationScene().getRef();

  return new SceneApp({
    pages: [makeInitializePage(), makeConfigurationPage()],

    $behaviors: [
      (app: SceneApp) => {
        const unsub1 = app.subscribeToEvent(ShowLandingPageBusEvent, () => {
          app.setState({
            pages: [makeLandingPage(), makeConfigurationPage(), makeRedirectPage('landing')],
          });
        });

        const unsub2 = app.subscribeToEvent(InitializationCompleteBusEvent, (evt) => {
          app.setState({
            pages: [
              makeServicesPage(notification, hostInfoModal),
              makeConfigurationPage(),
              makeRedirectPage('services'),
            ],
          });
          if (evt.payload.redirect) {
            locationService.push(ROUTES.services());
          } else {
            trackAttributes();
            trackDatasources();
          }
        });

        return () => {
          unsub1.unsubscribe();
          unsub2.unsubscribe();
        };
      },
    ],
  });
}

export const App = () => {
  const app = useSceneApp(getApp);

  return <app.Component model={app} />;
};
