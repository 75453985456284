import { locationService } from '@grafana/runtime';

import { getPluginRouteRoot } from 'utils/routing';

let defaultRenderService: RenderService;

class RenderService {
  isRunningAsExtension(): boolean {
    const currentPath = locationService.getLocation().pathname;
    const pluginRoot = getPluginRouteRoot();

    return !currentPath.startsWith(pluginRoot);
  }
}

export function getRenderService(): RenderService {
  return defaultRenderService;
}

export function initializeRenderService(): void {
  if (!defaultRenderService) {
    defaultRenderService = new RenderService();
  }
}
