import { SceneQueryRunner } from '@grafana/scenes';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { PROMETHEUS_DS } from 'constants/datasources';
import { DEFAULT_FIELD_CONFIG } from 'constants/panels';

export function makeServiceDotnetGCHeapSizePanel(): ExplorablePanel {
  return new ExplorablePanel({
    title: 'GC Heap Size',

    description:
      "Count of bytes currently in use by objects in the garbage collection heap that haven't been collected yet. Fragmentation and other GC committed memory pools are excluded. This value is available before first garbage collection has occurred.",

    trackingSection: 'service',
    trackingPage: 'dotnet',
    trackingPanel: 'gc-heap-size',

    fieldConfig: {
      defaults: {
        ...DEFAULT_FIELD_CONFIG,
        unit: 'bytes',
      },
      overrides: [],
    },

    $data: new SceneQueryRunner({
      datasource: PROMETHEUS_DS,
      maxDataPointsFromWidth: true,
      queries: [
        {
          expr: '{__name__=~"process_runtime_dotnet_gc_objects_size|process_runtime_dotnet_gc_objects_size_bytes", job="${job}", instance=~"${instance:regex}"}',
          legendFormat: '{{generation}} {{instance}}',
          refId: 'serviceDotnetGcObjectsSizeBytes',
        },
      ],
    }),
  });
}
