import React from 'react';

import { Button, ComponentSize, LinkButton, Tooltip } from '@grafana/ui';

import { ACCESS_CONTROL } from 'constants/accessControl';
import { TRACKING_EVENTS } from 'constants/tracking';
import { trackEvent } from 'utils/tracking';

export interface AddServiceControlProps {
  eventName?: TRACKING_EVENTS;
  buttonSize?: ComponentSize;
  text?: string;
}

export const AddServiceControl = ({
  eventName = TRACKING_EVENTS.ADD_SERVICE_CLICK,
  buttonSize = 'md',
}: AddServiceControlProps) => {
  if (ACCESS_CONTROL.PLUGIN.ADD_SERVICE) {
    return (
      <LinkButton
        size={buttonSize}
        icon="plus"
        href="/connections/add-new-connection?search=opentelemetry"
        onClick={() => {
          trackEvent(eventName);
        }}
        data-fs-element="App o11y - Connect data"
      >
        Connect data
      </LinkButton>
    );
  }

  return (
    <Tooltip content="Only available for admin users">
      <div>
        <Button icon="plus" disabled>
          Connect data
        </Button>
      </div>
    </Tooltip>
  );
};
