import { DEFAULT_ENVIRONMENT_ATTRIBUTE } from 'constants/semantics';
import { getOverridesService } from 'services/OverridesService';
import { getPluginConfigService } from 'services/PluginConfigService';
import { dotToDash } from 'utils/format';

import { isDefaultTargetInfoMetricName, targetInfoMetricName } from './semantics';

export function getEnvironmentAttribute(): string | undefined {
  const { environmentAttribute } = getPluginConfigService().getPluginConfig();
  const overridesService = getOverridesService();

  if (!overridesService.hasInitializationOverrides() || !isDefaultTargetInfoMetricName) {
    return environmentAttribute ?? DEFAULT_ENVIRONMENT_ATTRIBUTE;
  }

  const dimensions = overridesService.getAllDimensions();

  // If environmentAttribute is in plugin config, and is a valid dimension, this is it
  if (environmentAttribute && dimensions.includes(environmentAttribute)) {
    return environmentAttribute;
  }

  // If not, but deployment.environment is in metric override extra dimensions, this it
  if (dimensions.includes(DEFAULT_ENVIRONMENT_ATTRIBUTE)) {
    return DEFAULT_ENVIRONMENT_ATTRIBUTE;
  }

  // Pick the first attribute in extra dimensions from overrides that isn't service.*
  const availableDimensions = dimensions.filter((d) => !d.startsWith('service.'));

  if (availableDimensions.length > 0) {
    return availableDimensions.at(0);
  }

  // All else fails, no environment attribute filter is selected
  return undefined;
}

export function hasEnvironmentAttribute(): boolean {
  return getEnvironmentAttribute() !== undefined;
}

export function getEnvironmentValueQuery(
  name: string | undefined,
  withJob: boolean,
  withOperation: boolean,
  isInstrumented: boolean
): string {
  if (isInstrumented) {
    let query = `label_values(${targetInfoMetricName}{`;

    if (withJob) {
      query += 'job="${job}"';
    }

    query += `}, ${dotToDash(name || '')})`;

    return query;
  }

  let query = `label_values($\{metricName:serviceGraphRequestServerSecondsCount}{`;
  if (withJob) {
    // assuming uninstrumented is not gonna have a namespace
    query += 'server="${serviceName}" }';
  }
  if (name) {
    query += `, client_${dotToDash(name || '')})`;
  }
  return query;
}
