import { PanelType } from 'constants/panels';

export const panelTypeToTitle: Record<PanelType, string> = {
  [PanelType.ERRORS]: 'Errors',
  [PanelType.DURATION]: 'Duration',
  [PanelType.RATE]: 'Rate',
  [PanelType.DURATION_DISTRIBUTION]: 'Duration distribution',
  [PanelType.OPERATIONS]: 'Operations',
  [PanelType.DOWNSTREAM]: 'Downstream',
  [PanelType.UPSTREAM]: 'Upstream',
};

export function isPanelType(value: unknown): value is PanelType {
  return typeof value === 'string' && Object.values(PanelType).includes(value as any);
}

export function isMaxPanelRoute(pathname: string): boolean {
  return (pathname.match(new RegExp('/services/.*/panels/.*')) || []).length > 0;
}
