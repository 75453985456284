import { SceneQueryRunner } from '@grafana/scenes';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { PROMETHEUS_DS } from 'constants/datasources';
import { DEFAULT_FIELD_CONFIG } from 'constants/panels';

export function makeServiceDotnetProcessThreadsPanel(): ExplorablePanel {
  return new ExplorablePanel({
    title: 'Thread Count',
    description: 'Gets the count of threads that are running in the process',

    trackingSection: 'service',
    trackingPage: 'dotnet',
    trackingPanel: 'process-threads',

    fieldConfig: {
      defaults: DEFAULT_FIELD_CONFIG,
      overrides: [],
    },

    $data: new SceneQueryRunner({
      datasource: PROMETHEUS_DS,
      maxDataPointsFromWidth: true,
      queries: [
        {
          expr: 'process_threads{job="${job}", instance=~"${instance:regex}"}\nor\nprocess_thread_count{job="${job}", instance=~"${instance:regex}"}',
          refId: 'serviceDotnetProcessThreads',
          legendFormat: '{{instance}}',
        },
      ],
    }),
  });
}
