import { getOverrides, setOverrides } from 'api/overrides';
import {
  COLLECTION_INTERVAL,
  OVERRIDES_HISTOGRAM_BUCKETS,
  OVERRIDES_PROCESSORS,
  OVERRIDES_SERVICE_GRAPHS_DIMENSIONS,
  OVERRIDES_SERVICE_GRAPHS_PEER_ATTRIBUTES,
  OVERRIDES_SPAN_METRICS_DIMENSIONS,
  OVERRIDES_SPANMETRICS_FILTER_POLICIES,
  TARGET_INFO_EXCLUDED_DIMENSIONS,
} from 'constants/overrides';
import { DEFAULT_ENVIRONMENT_ATTRIBUTE } from 'constants/semantics';
import { getFaro } from 'faro/instance';
import { OverridesPayload, OverridesResponse } from 'types/overrides';
import { arrIncludesArr, mergeArraysUnique } from 'utils/array';

import { getPluginConfigService } from './PluginConfigService';

let defaultOverridesService: OverridesService;

class OverridesService {
  private overrides: OverridesResponse | undefined;

  getOverrides() {
    return this.overrides;
  }

  getVersion() {
    return this.overrides?.version;
  }

  async fetch() {
    const result = await getOverrides();
    if (result.ok) {
      this.overrides = result;
      return this.overrides;
    } else {
      throw new Error('failed to fetch overrides');
    }
  }

  hasInitializationOverrides(): boolean {
    if (!this.overrides?.ok) {
      return false;
    }
    const { disable_collection, processors, processor } = this.overrides?.data?.metrics_generator ?? {};
    const { span_metrics } = processor ?? {};

    if (
      disable_collection ||
      !span_metrics?.enable_target_info ||
      !arrIncludesArr(processors ?? [], OVERRIDES_PROCESSORS)
    ) {
      return false;
    }

    return true;
  }

  getAllDimensions(): string[] {
    return Array.from(
      new Set([
        ...(this.overrides?.data?.metrics_generator?.processor?.span_metrics?.dimensions || []),
        ...(this.overrides?.data?.metrics_generator?.processor?.service_graphs?.dimensions || []),
      ])
    );
  }

  getPeerAttributes(): string[] {
    return this.overrides?.data?.metrics_generator?.processor?.service_graphs?.peer_attributes || [];
  }

  async initializeOverrides() {
    if (this.hasInitializationOverrides()) {
      throw new Error('Trying to initialize metrics gen overrides, but already initialized.');
    }

    getFaro()?.api.pushLog(['Initializing metrics gen overrides']);
    const withClient = getPluginConfigService().getPluginConfig().isClientOnlyServicesEnabled;
    await this.setOverrides(this.getInitializationOverrides(withClient));
  }

  async updateOverrides(withClient: boolean) {
    if (!this.hasInitializationOverrides()) {
      throw new Error('Trying to update metrics gen overrides, but not initialized.');
    }
    const version = this.getVersion();
    if (!version) {
      throw new Error('Trying to update metrics gen overrides, but no version found.');
    }
    getFaro()?.api.pushLog(['Updating metrics gen overrides for client services']);
    await this.setOverrides(this.getInitializationOverrides(withClient));
  }

  async removeOverrides() {
    if (!this.hasInitializationOverrides()) {
      throw new Error('Trying to remove metrics gen overrides, but not initialized.');
    }
    const version = this.getVersion();
    if (!version) {
      throw new Error('Trying to remove metrics gen overrides, but no version found.');
    }
    getFaro()?.api.pushLog(['Disabling metrics gen.']);
    await setOverrides(
      {
        ...this.overrides?.data,
        metrics_generator: {
          ...this.overrides?.data?.metrics_generator,
          disable_collection: true,
        },
      },
      version
    );
    getFaro()?.api.pushLog(['Metrics gen disabled successfully, refetching overrides.']);

    await this.fetch();
  }

  async updateExtraDimensions(dimensionsToAdd: string[], dimensionsToRemove: string[]) {
    if (!this.hasInitializationOverrides()) {
      throw new Error('Trying to update extra dimensions, but not initialized.');
    }
    const dimensions = Array.from(
      new Set([
        ...(this.overrides?.data?.metrics_generator?.processor?.span_metrics?.dimensions || []).filter(
          (dimension) => !dimensionsToRemove.includes(dimension)
        ),
        ...dimensionsToAdd,
      ])
    );
    await this.setOverrides(this.getWithUpdatedDimensions(dimensions));
  }

  private async setOverrides(overrides: OverridesPayload) {
    const version = this.getVersion();
    if (!version) {
      throw new Error('Trying to set  metrics gen overrides, but no version found.');
    }
    getFaro()?.api.pushLog(['Setting metrics gen overrides']);
    await setOverrides(overrides, version);
    getFaro()?.api.pushLog(['Set successful, refetching overrides']);
    await this.fetch();
  }

  private getInitializationOverrides(withClient?: boolean): OverridesPayload {
    const environmentAttribute =
      getPluginConfigService().getPluginConfig().environmentAttribute || DEFAULT_ENVIRONMENT_ATTRIBUTE;

    return {
      ...this.overrides?.data,
      metrics_generator: {
        ...this.overrides?.data?.metrics_generator,
        disable_collection: false,
        collection_interval: COLLECTION_INTERVAL,
        processors: mergeArraysUnique(this.overrides?.data?.metrics_generator?.processors ?? [], OVERRIDES_PROCESSORS),
        processor: {
          ...this.overrides?.data?.metrics_generator?.processor,
          span_metrics: {
            ...this.overrides?.data?.metrics_generator?.processor?.span_metrics,
            enable_target_info: true,
            dimensions: mergeArraysUnique(
              this.overrides?.data?.metrics_generator?.processor?.span_metrics?.dimensions ?? [],
              [...OVERRIDES_SPAN_METRICS_DIMENSIONS, environmentAttribute]
            ),
            histogram_buckets: OVERRIDES_HISTOGRAM_BUCKETS,
            filter_policies: OVERRIDES_SPANMETRICS_FILTER_POLICIES(withClient),
            target_info_excluded_dimensions: TARGET_INFO_EXCLUDED_DIMENSIONS,
          },
          service_graphs: {
            ...this.overrides?.data?.metrics_generator?.processor?.service_graphs,
            enable_client_server_prefix: true,
            dimensions: mergeArraysUnique(
              this.overrides?.data?.metrics_generator?.processor?.service_graphs?.dimensions ?? [],
              [...OVERRIDES_SERVICE_GRAPHS_DIMENSIONS, environmentAttribute]
            ),
            peer_attributes: mergeArraysUnique(
              this.overrides?.data?.metrics_generator?.processor?.service_graphs?.peer_attributes ?? [],
              OVERRIDES_SERVICE_GRAPHS_PEER_ATTRIBUTES
            ),
            histogram_buckets: OVERRIDES_HISTOGRAM_BUCKETS,
          },
        },
      },
    };
  }

  private getWithUpdatedDimensions(dimensions: string[]) {
    return {
      ...this.overrides?.data,
      metrics_generator: {
        ...this.overrides?.data?.metrics_generator,
        processor: {
          ...this.overrides?.data?.metrics_generator?.processor,
          span_metrics: {
            ...this.overrides?.data?.metrics_generator?.processor?.span_metrics,
            dimensions,
          },
          service_graphs: {
            ...this.overrides?.data?.metrics_generator?.processor?.service_graphs,
            dimensions,
          },
        },
      },
    };
  }
}

export function getOverridesService(): OverridesService {
  return defaultOverridesService;
}

export async function initializeOverridesService() {
  if (!defaultOverridesService) {
    defaultOverridesService = new OverridesService();
    await defaultOverridesService.fetch();
  }
  return defaultOverridesService;
}
