import { map } from 'rxjs';

import {
  DataTransformerID,
  FieldConfigProperty,
  FieldMatcherID,
  FieldType,
  getFieldDisplayName,
  rangeUtil,
  ReducerID,
} from '@grafana/data';
import { SceneDataTransformer } from '@grafana/scenes';
import { FieldColorModeId, GraphDrawStyle, TooltipDisplayMode, VisibilityMode, VizOrientation } from '@grafana/schema';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { PromSceneQueryRunner } from 'components/PromSceneQueryRunner';
import { MIN_INTERVAL, PROMETHEUS_DS } from 'constants/datasources';
import { PanelType } from 'constants/panels';
import { FILTER_BY_NAME } from 'constants/variables';
import { getMetadataService } from 'services/MetadataService';
import { DURATION_COLOR, DURATION_DISTRIBUTION_COLOR_TWC } from 'utils/colors';
import { hasEnvironmentAttribute } from 'utils/environmentFilter';

import { MaximizePanelButton } from './maximizePanelButton';
import { makeDrilldownLinks } from './red/panelsConfig';
import { showLegendBehavior } from './red/showLegendBehavior';

export function makeDurationDistributionPanel({
  job,
  operation,
  isInstrumented,
}: {
  job: string;
  operation?: string;
  isInstrumented: boolean;
}): ExplorablePanel {
  const module = operation ? 'operation' : 'service';
  const metadataService = getMetadataService();

  const labels = [
    ...(hasEnvironmentAttribute()
      ? [
          {
            name: '${environmentAttribute}',
            operator: '=~',
            value: '${environmentValue:regex}',
          },
        ]
      : []),
    {
      name: 'span_kind',
      operator: '=~',
      value: metadataService.getIsClientOnly(job)
        ? 'SPAN_KIND_CLIENT|SPAN_KIND_PRODUCER'
        : 'SPAN_KIND_SERVER|SPAN_KIND_CONSUMER',
    },
    {
      name: 'job',
      operator: '=',
      value: '${job}',
    },
    ...(operation
      ? [
          {
            name: 'span_name',
            operator: '=',
            value: '${operation}',
          },
        ]
      : []),
  ]
    .reduce<string[]>((acc, label) => {
      if (label.value) {
        acc.push(`${label.name}${label.operator}"${label.value}"`);
      }

      return acc;
    }, [])
    .join(', ')
    .concat(`$\{${FILTER_BY_NAME}:append}`);

  const panel: ExplorablePanel = new ExplorablePanel({
    title: 'Duration distribution',

    trackingSection: module,
    trackingPage: 'overview',
    trackingPanel: 'duration-distribution',

    pluginId: 'barchart',

    headerActions: [new MaximizePanelButton(PanelType.DURATION_DISTRIBUTION, job, operation)],

    fieldConfig: {
      defaults: {
        decimals: 0,
        unit: 'short',
        color: {
          mode: FieldColorModeId.Fixed,
          fixedColor: DURATION_COLOR,
        },
        custom: {
          fillOpacity: 75,
          drawStyle: GraphDrawStyle.Line,
        },
        links: makeDrilldownLinks({
          withMinDuration: true,
          isDurationDistribution: true,
          withOperation: !!operation,
          getScene: () => panel,
          isInstrumented,
        }),
      },
      overrides: [
        {
          matcher: {
            id: FieldMatcherID.byName,
            options: '0',
          },
          properties: [
            {
              id: FieldConfigProperty.DisplayName,
              value: 'Requests',
            },
          ],
        },
        {
          matcher: {
            id: FieldMatcherID.byName,
            options: '1',
          },
          properties: [
            {
              id: FieldConfigProperty.DisplayName,
              value: 'Requests (comparison)',
            },
            {
              id: FieldConfigProperty.Color,
              value: {
                mode: 'fixed',
                fixedColor: DURATION_DISTRIBUTION_COLOR_TWC,
              },
            },
            {
              id: 'custom.fillOpacity',
              value: 60,
            },
          ],
        },
        {
          matcher: {
            id: FieldMatcherID.byName,
            options: 'Field',
          },
          properties: [
            {
              id: FieldConfigProperty.DisplayName,
              value: 'Bucket',
            },
          ],
        },
      ],
    },
    options: {
      orientation: VizOrientation.Vertical,
      showValue: VisibilityMode.Never,
      fullHighlight: true,
      xTickLabelSpacing: 40,
      tooltip: {
        mode: TooltipDisplayMode.Multi,
      },
    },

    $behaviors: [showLegendBehavior({ checkGroupBy: false })],

    $data: new SceneDataTransformer({
      transformations: [
        () => (source) =>
          source.pipe(
            map((frames) => {
              return frames.map((frame) => ({
                ...frame,
                meta: {
                  ...frame.meta,
                  // Remove time compare logic
                  // We're reducing and handling it ourselves anyway
                  timeCompare: undefined,
                },
                fields: frame.fields.map((field) => {
                  const displayName = getFieldDisplayName(field, frame);
                  const numberDisplayName = parseFloat(displayName);
                  let renameTo = isNaN(numberDisplayName) ? displayName : rangeUtil.secondsToHms(numberDisplayName);
                  renameTo = renameTo.replace(' (comparison)', '');

                  return {
                    ...field,
                    config: {
                      ...field.config,
                      displayName: renameTo,
                    },
                    state: {
                      ...field.state,
                      displayName: renameTo,
                    },
                  };
                }),
              }));
            })
          ),
        {
          id: DataTransformerID.reduce,
          options: {
            reducers: [ReducerID.lastNotNull],

            labelsToFields: false,
            mode: 'seriesToRows',
            includeTimeField: false,
          },
        },
        {
          id: DataTransformerID.groupBy,
          options: {
            fields: {
              Field: {
                aggregations: [],
                operation: 'groupby',
              },
              'Last *': {
                aggregations: ['allValues'],
                operation: 'aggregate',
              },
            },
          },
        },
        {
          id: DataTransformerID.extractFields,
          options: { source: 'Last * (allValues)' },
        },
        {
          id: DataTransformerID.filterFieldsByName,
          options: {
            include: {
              names: ['Field', '0', '1'],
            },
          },
        },
        // mark fields as time window comparison enabled if applicable
        // cant use addTWCMarkerTransformation because other transforms erase field config and refIds
        () => (source) =>
          source.pipe(
            map((frames) => {
              frames.forEach((frame) => {
                if (frame.fields.filter((f) => f.type === FieldType.number).length > 1) {
                  frame.fields.forEach((field) => {
                    if (!field.config.custom) {
                      field.config.custom = {};
                    }
                    field.config.custom.twcSeriesIndex = 0;
                  });
                }
              });
              return frames;
            })
          ),
        () => (source) =>
          source.pipe(
            map((frames) =>
              frames.map((frame) => {
                const field = frame.fields.find(({ name, type }) => name === 'Field' && type === FieldType.string);

                if (!field) {
                  return frame;
                }

                field.values = field.values.map((value) => (value === 'less than a millisecond' ? '<1ms' : value));
                return frame;
              })
            )
          ),
        () => (source) =>
          source.pipe(
            map((frames) =>
              frames.map((frame) => {
                // At this point, we have two fields: Field and 0, and we need to add the values from Field to 0
                // so that we get them back when clicking on the column
                const buckets = frame.fields.find(({ name }) => name === 'Field');
                if (!buckets) {
                  return frame;
                }

                const values = frame.fields.find(({ name }) => name === '0' || name === '1');
                if (!values) {
                  return frame;
                }

                values.config.custom = { ...values.config.custom, buckets: buckets.values };
                return frame;
              })
            )
          ),
      ],
      $data: new PromSceneQueryRunner({
        datasource: PROMETHEUS_DS,
        queries: [
          {
            expr: `sum by (le) (increase($\{metricName:spanMetricsBucket}{${labels}} [$__range]))`,
            format: 'heatmap',
            legendFormat: '{{le}}',
            refId: `${module}OverviewLatencyDistribution`,
          },
        ],
        minInterval: MIN_INTERVAL,
      }),
    }),
  });

  return panel;
}
