import { map, Observable } from 'rxjs';

import { CustomTransformOperator, DataFrame, FieldType } from '@grafana/data';

const EMPTY_MIDDLE_VALUE = '=, ';
const EMPTY_LAST_VALUE = '=';

/**
 * In the display name, we can find:
 *  - No value at all
 *  - In the middle of the displayName, we find the sequence "=, "
 *  - At the end, displayName ends with =
 */
export const addDefaultSeriesNameTransformation: CustomTransformOperator = () => {
  return (source: Observable<DataFrame[]>) =>
    source.pipe(
      map((frames) =>
        frames.map((frame) => ({
          ...frame,
          fields: frame.fields.map((field) => {
            if (field.type !== FieldType.number || field.name !== 'Value') {
              return field;
            }

            let displayNameFromDS = field.config.displayNameFromDS;

            if (!displayNameFromDS) {
              displayNameFromDS = 'None';
            }

            if (displayNameFromDS?.includes(EMPTY_MIDDLE_VALUE)) {
              displayNameFromDS = displayNameFromDS.replaceAll(EMPTY_MIDDLE_VALUE, '=None, ');
            }

            if (displayNameFromDS?.endsWith(EMPTY_LAST_VALUE)) {
              displayNameFromDS = `${displayNameFromDS}None`;
            }

            return {
              ...field,
              config: {
                ...field.config,
                displayNameFromDS,
              },
            };
          }),
        }))
      )
    );
};
