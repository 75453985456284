import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, Spinner, Tooltip, useStyles2 } from '@grafana/ui';

import { Badge } from 'components/Badge';
import { getMetadataService } from 'services/MetadataService';

export interface ServicePageTitleProps {
  job: string;

  isLoading?: boolean;
  operation?: string;
}

export function ServicePageTitle({ job, isLoading = false, operation }: ServicePageTitleProps) {
  const styles = useStyles2(getStyles);

  const metadataService = getMetadataService();
  const isInstrumented = metadataService.getIsInstrumented(job);
  const clientKind = metadataService.getClientKind(job);

  const title = operation ?? job;

  return (
    <div className={styles.container} data-cy="service-title">
      <h1>{title}</h1>

      {isLoading ? (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      ) : (
        <>
          {clientKind && <Badge text={clientKind} />}

          {!isInstrumented && (
            <div data-cy="uninstrumented-icon">
              <Tooltip
                content="Service is inferred from client spans of other services communicating with it."
                placement="top"
              >
                <Icon name="channel-add" />
              </Tooltip>
            </div>
          )}
        </>
      )}
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    container: css`
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: ${theme.spacing(1)};
    `,
    spinner: css`
      align-self: center;
    `,
  };
}
