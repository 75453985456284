import { DataQuery } from '@grafana/schema';

import { PyroscopeQuery } from 'types/queries';
import { jobContainsNamespace } from 'utils/services';

export function createPyroscopeQuery(job: string): PyroscopeQuery {
  const components = ['service_name="${serviceName}"'];

  const withNamespace = jobContainsNamespace(job);
  if (withNamespace) {
    components.push('namespace="${serviceNamespace}"');
  }

  const labelSelector = components.join(', ');
  return {
    refId: 'A',
    queryType: 'profile',
    query: `process_cpu:cpu:nanoseconds:cpu:nanoseconds{${labelSelector}}`,
    profileTypeId: 'process_cpu:cpu:nanoseconds:cpu:nanoseconds',
    groupBy: [],
    labelSelector: `{${labelSelector}}`,
  } as DataQuery;
}
