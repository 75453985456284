import { css } from '@emotion/css';
import React from 'react';

import { PanelData } from '@grafana/data';
import { SceneComponentProps, sceneGraph, SceneObjectBase, SceneObjectRef, SceneObjectState } from '@grafana/scenes';
import { LoadingState } from '@grafana/schema';
import { Alert, Icon, useStyles2 } from '@grafana/ui';

import { ExplorablePanel } from './ExplorablePanel';

export interface WarnBannerSceneState extends SceneObjectState {
  panelsRefs: Array<SceneObjectRef<ExplorablePanel>>;
  link: string;
  statuses: Array<{ isLoading: boolean; hasData: boolean }>;
}

export class WarnBannerScene extends SceneObjectBase<WarnBannerSceneState> {
  static Component = WarnBannerSceneRenderer;

  constructor(state: Partial<WarnBannerSceneState>) {
    const panels = (state.panelsRefs ?? []).map((panelRef) => panelRef.resolve());
    const statuses = panels.map((panel) => {
      const data = sceneGraph.getData(panel).state.data;

      return buildPanelStatus(data);
    });

    super({
      panelsRefs: [],
      link: '',
      statuses,
      ...state,
    });

    this.initServicesListener(panels);
  }

  private initServicesListener(panels: ExplorablePanel[]) {
    this.addActivationHandler(() => {
      const unsubscribables = panels.map((panel, idx) => {
        return sceneGraph.getData(panel).subscribeToState(({ data }) => {
          if (data) {
            const newStatuses = [...this.state.statuses];

            newStatuses[idx] = buildPanelStatus(data);

            this.setState({
              statuses: newStatuses,
            });
          }
        });
      });

      return () => unsubscribables.forEach((unsubscribable) => unsubscribable.unsubscribe());
    });
  }
}

function WarnBannerSceneRenderer({ model }: SceneComponentProps<WarnBannerScene>) {
  const styles = useStyles2(getStyles);

  const { link, statuses } = model.useState();

  const isLoading = statuses.some((status) => status.isLoading);
  const hasData = !isLoading && statuses.some((status) => status.hasData);

  if (isLoading || hasData) {
    return null;
  }

  return (
    <Alert title="It seems like there is no data available" severity="info">
      If you think this is a configuration issue, please follow the{' '}
      <a href={link} target="_blank" rel="noreferrer" className={styles.link}>
        How to instrument your application <Icon name="external-link-alt" />
      </a>{' '}
      page.
    </Alert>
  );
}

function buildPanelStatus(data: PanelData | undefined) {
  if (!data) {
    return {
      isLoading: true,
      hasData: false,
    };
  }

  const loadingState: LoadingState = data.state;

  return {
    isLoading: loadingState === LoadingState.Loading || loadingState === LoadingState.NotStarted,
    hasData: !!data.series?.some((dataFrame) => dataFrame.length > 0),
  };
}

function getStyles() {
  return {
    link: css`
      &:hover {
        text-decoration: underline;
      }
    `,
  };
}
