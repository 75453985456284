import { SceneQueryRunner } from '@grafana/scenes';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { PROMETHEUS_DS } from 'constants/datasources';
import { DEFAULT_FIELD_CONFIG } from 'constants/panels';

export function makeServiceJvmCPUUtilizationPanel(): ExplorablePanel {
  return new ExplorablePanel({
    description: 'CPU utilization for the whole system',

    title: 'CPU Utilization',

    trackingSection: 'service',
    trackingPage: 'jvm',
    trackingPanel: 'cpu-utilization',

    fieldConfig: {
      defaults: {
        ...DEFAULT_FIELD_CONFIG,
        unit: 'percentunit',
      },
      overrides: [],
    },

    options: {
      tooltip: {
        mode: 'multi',
        sort: 'desc',
      },
    },

    $data: new SceneQueryRunner({
      datasource: PROMETHEUS_DS,
      maxDataPointsFromWidth: true,
      queries: [
        {
          expr: '{__name__=~"jvm_cpu_recent_utilization|jvm_cpu_recent_utilization_ratio|process_runtime_jvm_system_cpu_utilization|process_runtime_jvm_system_cpu_utilization_ratio", job="${job}", instance=~"${instance:regex}"}\nor\nsystem_cpu_usage{job="${job}", instance=~"${instance:regex}"}',
          legendFormat: '{{instance}}',
          refId: 'serviceJvmCpuUtilizationSystem',
        },
      ],
    }),
  });
}
