import { K8S_PLUGIN_ID } from 'constants/k8s';

import { prefixRoute } from './routing';

export function makeK8sPluginLinks(
  serviceName: string,
  serviceNamespace: string,
  metadata: Record<string, string>,
  prometheusDsName?: string | undefined
): Record<string, string> {
  const { k8s_cluster_name: clusterName } = metadata;
  if (!clusterName) {
    return {};
  }

  const suffix = prometheusDsName ? `?var-datasource=${String(prometheusDsName)}` : '';

  // Rename variables to ensure we know we are working with string arrays
  const {
    k8s_pod_name: podName,
    k8s_node_name: nodeName,
    k8s_cronjob_name: cronjobName,
    k8s_daemonset_name: daemonsetName,
    k8s_deployment_name: deploymentName,
    k8s_job_name: jobName,
    k8s_replicaset_name: replicasetName,
    k8s_statefulset_name: statefulsetName,
  } = metadata;

  const links: Record<string, string> = {};
  links.k8s_cluster_name = prefixRoute(`/navigation/cluster/${clusterName}`, K8S_PLUGIN_ID);

  if (nodeName) {
    links.k8s_node_name = prefixRoute(`/navigation/nodes/${clusterName}/${nodeName}`, K8S_PLUGIN_ID);
  }

  const namespaceName = metadata.k8s_namespace_name ?? serviceNamespace;
  links.k8s_namespace_name = prefixRoute(`/navigation/namespace/${clusterName}/${namespaceName}`, K8S_PLUGIN_ID);
  let worloadLink;

  if (cronjobName) {
    worloadLink = `${links.k8s_namespace_name}/cronjob/${cronjobName}`;
    links.k8s_cronjob_name = worloadLink;
  }

  if (daemonsetName) {
    worloadLink = `${links.k8s_namespace_name}/daemonset/${daemonsetName}`;
    links.k8s_daemonset_name = worloadLink;
  }

  if (deploymentName) {
    worloadLink = `${links.k8s_namespace_name}/deployment/${deploymentName}`;
    links.k8s_deployment_name = worloadLink;
  }

  if (replicasetName) {
    worloadLink = `${links.k8s_namespace_name}/replicaset/${replicasetName}`;
    links.k8s_replicaset_name = worloadLink;
  }

  if (statefulsetName) {
    worloadLink = `${links.k8s_namespace_name}/statefulset/${statefulsetName}`;
    links.k8s_statefulset_name = worloadLink;
  }

  if (jobName) {
    worloadLink = `${links.k8s_namespace_name}/job/${jobName}`;
    links.k8s_job_name = worloadLink;
  }

  if (worloadLink && podName) {
    links.k8s_pod_name = `${worloadLink}/${podName}`;
  }

  return Object.fromEntries(Object.entries(links).map(([key, value]) => [key, `${value}${suffix}`]));
}
