import { sortBy } from 'lodash';

import { SERVICE_NAMESPACE_FILTER } from 'constants/filterBy';
import { getFilterByOptions } from 'utils/groupByFilterBy';
import { fetchAvailableJobs } from 'utils/serviceMap';
import { parseJob } from 'utils/services';

import { AttributeFilter, FilterByVariable } from './FilterByVariable';

export type ServiceMapFilterByVariableState = ConstructorParameters<typeof FilterByVariable>[0];

export const SERVICE_NAMESPACE_VALUE_KEY = 'service_namespace';

export class ServiceMapFilterByVariable extends FilterByVariable {
  constructor(state: ServiceMapFilterByVariableState) {
    super({
      ...state,
      getTagKeysProvider: () => {
        // Remove service.namespace as we add our own for the frontend
        const attributes = getFilterByOptions().filter((item) => item.value !== 'service_namespace');
        attributes.push({ text: 'service.namespace', value: SERVICE_NAMESPACE_VALUE_KEY });

        return Promise.resolve({
          replace: true,
          values: sortBy(attributes, (value) => value.text),
        });
      },
    });

    this.addActivationHandler(() => {
      this.populateAvailableNamespaces();

      // If we're coming from service inventory, replace its namespace filter
      // with the service_namespace filter
      const hasServiceNamespaceFilter = this.state.filters.find((filter) => filter.key === SERVICE_NAMESPACE_FILTER);
      if (hasServiceNamespaceFilter) {
        this.setState({
          filters: this.state.filters.map((filter) => {
            if (filter.key === SERVICE_NAMESPACE_FILTER) {
              filter.key = SERVICE_NAMESPACE_VALUE_KEY;
            }

            return filter;
          }),
        });
      }
    });
  }

  private async populateAvailableNamespaces() {
    const availableJobs = await fetchAvailableJobs();
    const availableNamespaces = Array.from(
      availableJobs.reduce((acc, job) => {
        const { serviceNamespace } = parseJob(job);
        if (serviceNamespace) {
          acc.add(serviceNamespace);
        }

        return acc;
      }, new Set<string>())
    );

    this.setState({
      getTagValuesProvider: (_, { key }) => {
        if (key === SERVICE_NAMESPACE_VALUE_KEY) {
          return Promise.resolve({
            replace: true,
            values: availableNamespaces.map((option) => ({ text: option, value: option })) || [],
          });
        }

        return Promise.resolve({ replace: false, values: [] });
      },
    });
  }

  override cleanFilters(): void {
    const filters = this.state.filters;
    const hasServicesFilter = filters.some((filter) => filter.key === 'technology');

    if (hasServicesFilter) {
      this.setState({
        filters: this.removeLocalOnlyFilters(filters),
      });
    }
  }

  override removeLocalOnlyFilters(filters: AttributeFilter[]): AttributeFilter[] {
    return filters.filter((filter) => filter.key !== 'technology');
  }
}
