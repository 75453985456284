import React from 'react';

import { SceneComponentProps, sceneGraph, SceneObjectBase, VariableDependencyConfig } from '@grafana/scenes';

import { FilterByVariable } from 'components/FilterByVariable';
import { FILTER_BY_NAME } from 'constants/variables';

export class FilterByScene extends SceneObjectBase {
  static Component = FilterByRenderer;

  protected _variableDependency = new VariableDependencyConfig(this, {
    variableNames: [FILTER_BY_NAME],
  });

  constructor() {
    super({});
  }
}

function FilterByRenderer({ model }: SceneComponentProps<FilterByScene>) {
  const variable = sceneGraph.lookupVariable(FILTER_BY_NAME, model) as FilterByVariable | undefined;

  if (!variable) {
    return null;
  }

  return <variable.Component model={variable} />;
}
