import { Field, FieldColorMode, fieldColorModeRegistry } from '@grafana/data';
import { Threshold } from '@grafana/schema';

import { APP_O11Y_PALETTE_ID } from 'constants/panels';

import { isComparisonField } from './data';

export const DURATION_COLOR = 'rgba(248, 117, 44, 100)';
export const DURATION_DISTRIBUTION_COLOR_TWC = 'rgba(248, 117, 44, 0.6)';
export const ERROR_COLOR = 'rgba(219, 24, 82, 100)';
export const RATE_COLOR = 'rgba(35, 186, 177, 100)';

export const ANOMALY_HIGHLIGHT_COLOR = '#FF2E00';

export function updateOpacity(color: string, opacity: string) {
  return color.replace(/[\d.]+\)$/g, opacity + ')');
}

const APP_O11Y_COLORS = [
  '#7EB26D',
  '#EAB839',
  '#6ED0E0',
  '#EF843C',
  '#E24D42',
  '#1F78C1',
  '#BA43A9',
  '#705DA0',
  '#508642',
  '#CCA300',
  '#447EBC',
  '#C15C17',
  '#890F02',
  '#0A437C',
  '#6D1F62',
  '#584477',
  '#B7DBAB',
  '#F4D598',
  '#70DBED',
  '#F9BA8F',
  '#F29191',
  '#82B5D8',
  '#E5A8E2',
  '#AEA2E0',
  '#629E51',
  '#E5AC0E',
  '#64B0C8',
  '#E0752D',
  '#BF1B00',
  '#0A50A1',
  '#962D82',
  '#614D93',
  '#9AC48A',
  '#F2C96D',
  '#65C5DB',
  '#F9934E',
  '#EA6460',
  '#5195CE',
  '#D683CE',
  '#806EB7',
  '#3F6833',
  '#967302',
  '#2F575E',
  '#99440A',
  '#58140C',
  '#052B51',
  '#511749',
  '#3F2B5B',
  '#E0F9D7',
  '#FCEACA',
  '#CFFAFF',
  '#F9E2D2',
  '#FCE2DE',
  '#BADFF4',
  '#F9D9F9',
  '#DEDAF7',
];

function hexToRGB(hex: string) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, 0.6)`;
}

// Same colors but at 60% opacity
const TIME_WINDOW_COMPARISON_COLORS = APP_O11Y_COLORS.map(hexToRGB);

const appO11yPalette: FieldColorMode = {
  id: APP_O11Y_PALETTE_ID,
  name: 'App O11y Palette',
  description: 'Color palette for App o11y plugin',
  excludeFromPicker: true,
  getCalculator: (field: Field) => {
    // twcSeriesIndex injected by addTWCFieldSeriesIndexTransformation to make sure that
    // comparison field has the same index as original field
    const seriesIndex = field.config.custom.twcSeriesIndex ?? field.state?.seriesIndex ?? 0;

    return (_: number, _percent: number, _threshold?: Threshold) => {
      if (isComparisonField(field)) {
        return TIME_WINDOW_COMPARISON_COLORS[seriesIndex % TIME_WINDOW_COMPARISON_COLORS.length];
      }

      return APP_O11Y_COLORS[seriesIndex % APP_O11Y_COLORS.length];
    };
  },
  getColors: () => APP_O11Y_COLORS,
};

export function registerPalette() {
  fieldColorModeRegistry.register(appO11yPalette);
}
