import { map } from 'rxjs';

import { FieldType } from '@grafana/data';
import { SceneDataProvider, SceneDataTransformer, VizPanel } from '@grafana/scenes';
import { GraphDrawStyle, GraphFieldConfig } from '@grafana/schema';

import { extractLevel, getLogVolumeFieldConfig } from 'utils/logsVolume';

export function makeServiceLogsVolumePanel($data: SceneDataProvider): VizPanel<any, any> {
  return new VizPanel<Record<string, any>, GraphFieldConfig>({
    title: '',

    fieldConfig: {
      defaults: {
        custom: {
          drawStyle: GraphDrawStyle.Bars,
        },
      },
      overrides: [],
    },

    $data: new SceneDataTransformer({
      $data,
      transformations: [
        // Format logs volume levels
        () => (source) =>
          source.pipe(
            map((frames) =>
              frames?.map((frame) => {
                frame.fields = frame.fields.map((field) => {
                  if (field.type === FieldType.number) {
                    const oneLevelDetected = frame.length === 1;
                    field.config = {
                      ...field.config,
                      ...getLogVolumeFieldConfig(extractLevel(frame), oneLevelDetected),
                    };
                  }

                  return field;
                });

                return frame;
              })
            )
          ),
      ],
    }),
  });
}
