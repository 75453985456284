import React from 'react';

import { CLOUD_ICON } from 'constants/cloud';
import { TECHNOLOGY_ICON } from 'constants/technology';
import k8sIcon from 'img/icons/k8s.svg';
import feO11yIcon from 'img/logos/feo11y.svg';
import { getPluginConfigService } from 'services/PluginConfigService';
import { useMetadata } from 'utils/metadata';

import { MetadataValue } from './MetadataValue';

export interface MetadataProps {
  job: string;
}

export function Metadata({ job }: MetadataProps) {
  const pluginConfig = getPluginConfigService().getPluginConfig();
  const { clientInfoEntries, cloud, k8s, runtimeEntries, technology } = useMetadata(job);

  return (
    <>
      <MetadataValue
        title="Runtime"
        text="Runtime"
        entries={runtimeEntries}
        icon={technology ? TECHNOLOGY_ICON[technology] : TECHNOLOGY_ICON.unknown}
        iconAlt="Runtime"
      />

      <MetadataValue
        title="Kubernetes Monitoring"
        text="Kubernetes"
        entries={k8s.entries}
        icon={k8sIcon}
        iconAlt="Kubernetes"
        link={k8s.link}
      />

      <MetadataValue
        title="Cloud"
        text="Cloud"
        entries={cloud.entries}
        icon={CLOUD_ICON[cloud.provider]}
        iconAlt="Cloud"
      />

      {pluginConfig.isClientOnlyServicesEnabled && (
        <MetadataValue
          title="Frontend Observability"
          text="Frontend"
          icon={feO11yIcon}
          iconAlt="Frontend Observability"
          entries={clientInfoEntries}
        />
      )}
    </>
  );
}

export function genericProcessor(metadata: Array<Record<string, string>>): Record<
  string,
  Array<{
    label: string;
    link?: string | undefined;
  }>
> {
  const categories = Array.from(new Set(metadata.flatMap((labels) => Object.keys(labels)))).sort();

  return Object.fromEntries(
    categories.map((category) => [
      category,
      metadata.map((entry) => ({
        label: entry[category] ?? '-',
      })),
    ])
  );
}
