import { AbstractQuery, LanguageProvider } from '@grafana/data';
import { getDataSourceSrv } from '@grafana/runtime';

import { LOKI_DS_TYPE } from 'constants/variables';
import { LokiQuery } from 'types/queries';

import { getDataSourceService } from './DataSourceService';

interface LokiLanguageProvider extends LanguageProvider {
  exportToAbstractQuery(query: LokiQuery): AbstractQuery;
}

export class LanguageProviderService {
  lokiLanguageProvider: LokiLanguageProvider;
  constructor(lokiLanguageProvider: LokiLanguageProvider) {
    this.lokiLanguageProvider = lokiLanguageProvider;
  }
}

let languageProviderService: LanguageProviderService;

export function getLanguageProviderService(): LanguageProviderService {
  return languageProviderService;
}

export async function initializeLanguageProviderService() {
  const lokiDSAPI = await getDataSourceSrv().get(getDataSourceService().getSelectedDataSourceUID(LOKI_DS_TYPE));
  languageProviderService = new LanguageProviderService(lokiDSAPI.languageProvider);
}
