import { css } from '@emotion/css';
import React from 'react';
import { useParams } from 'react-router-dom';

import { GrafanaTheme2 } from '@grafana/data';
import { EmbeddedScene } from '@grafana/scenes';
import { Tab, TabContent, TabsBar, useStyles2 } from '@grafana/ui';

import { ROUTES } from 'constants/routing';

import { DataSourceTab } from './tabs/DataSourceTab';
import { LogsQueryTab } from './tabs/LogsQueryTab';
import { SettingsTab } from './tabs/SettingsTab';
import { SystemTab } from './tabs/SystemTab';

enum TAB {
  dataSource = 'data-source',
  logsQuery = 'logs',
  system = 'system',
  semantics = 'settings',
}

export interface ConfigProps {
  getScene: () => EmbeddedScene;
}

export const Config = ({ getScene }: ConfigProps) => {
  const tabs = [
    {
      key: TAB.dataSource,
      href: ROUTES.dataSourceConfiguration(),
      label: 'Data source',
      icon: 'database',
      content: <DataSourceTab getScene={getScene} />,
    },
    {
      key: TAB.semantics,
      href: ROUTES.settingsConfiguration(),
      label: 'Settings',
      icon: 'cog',
      content: <SettingsTab getScene={getScene} />,
    },
    {
      key: TAB.logsQuery,
      href: ROUTES.logsQueryConfiguration(),
      label: 'Logs query',
      icon: 'gf-logs',
      content: <LogsQueryTab getScene={getScene} />,
    },
    {
      key: TAB.system,
      href: ROUTES.systemConfiguration(),
      label: 'System',
      icon: 'monitor',
      content: <SystemTab getScene={getScene} />,
    },
  ] as const;
  const validTabs: string[] = tabs.map(({ key }) => key);

  const styles = useStyles2(getStyles);
  let { tab = '' } = useParams<{ tab?: string }>();

  if (!validTabs.includes(tab)) {
    tab = TAB.dataSource;
  }

  return (
    <div>
      <TabsBar>
        {tabs.map(({ href, key, label, icon }) => (
          <Tab key={key} href={href} active={(tab as TAB) === key} label={label} icon={icon} />
        ))}
      </TabsBar>

      <div className={styles.contentContainer}>
        <TabContent>{tabs.find(({ key }) => key === (tab as TAB))?.content}</TabContent>
      </div>
    </div>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    contentContainer: css`
      margin-top: ${theme.spacing(3)};
      max-width: 600px;
    `,
  };
}
