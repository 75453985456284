import { css } from '@emotion/css';
import React, { useMemo } from 'react';

import { DataSourceApi, GrafanaTheme2 } from '@grafana/data';
import { getDataSourceSrv } from '@grafana/runtime';
import {
  SceneComponentProps,
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { Alert, Stack, useStyles2 } from '@grafana/ui';

import { LOKI_DS } from 'constants/datasources';
import { INPUT_DEBOUNCE_MS } from 'constants/misc';
import { LOKI_DS_NAME } from 'constants/variables';
import { isValidQuery } from 'modules/service/logs/utils';
import { getLanguageProviderService } from 'services/LanguageProviderService';
import { LokiQuery } from 'types/queries';
import { getSelectedDataSourceName } from 'utils/datasources';

export interface ServiceLogsSearchEditorState {
  baseExpr: string;
  query: LokiQuery;
  updateQuery: (query: LokiQuery) => void;
}

export type InternalServiceLogsSearchEditorState = SceneObjectState &
  ServiceLogsSearchEditorState & {
    dataSourceId: string;
    editor: HTMLDivElement | null;
    isValid: boolean;
    pendingChanges: NodeJS.Timeout | undefined;
  };

export class ServiceLogsSearchEditor extends SceneObjectBase<InternalServiceLogsSearchEditorState> {
  static Component = ServiceLogsSearchEditorRenderer;

  protected _variableDependency = new VariableDependencyConfig(this, {
    statePaths: ['dataSourceId'],
    onVariableUpdateCompleted: this.loadDataSource.bind(this),
  });

  dataSource: DataSourceApi | undefined;

  loadingDataSource = false;

  constructor(state: ServiceLogsSearchEditorState) {
    super({ ...state, dataSourceId: LOKI_DS.uid, editor: null, isValid: true, pendingChanges: undefined });

    this.addActivationHandler(this.loadDataSource.bind(this));

    this.onChange = this.onChange.bind(this);
    this.propagateQuery = this.propagateQuery.bind(this);
  }

  onChange(query: LokiQuery): void {
    clearTimeout(this.state.pendingChanges);

    this.setState({
      query,
      pendingChanges: setTimeout(() => this.propagateQuery(), INPUT_DEBOUNCE_MS),
    });
  }

  propagateQuery(): void {
    this.state.editor?.focus();

    clearTimeout(this.state.pendingChanges);

    const isValid = isValidQuery(this.state.query, this.state.baseExpr);

    if (isValid) {
      this.state.updateQuery(this.state.query);
    }
    if (isValid !== this.state.isValid) {
      this.setState({ isValid });
    }
  }

  private loadDataSource() {
    if (!sceneGraph.hasVariableDependencyInLoadingState(this) && !this.loadingDataSource) {
      this.loadingDataSource = true;

      getDataSourceSrv()
        .get(getSelectedDataSourceName(this, LOKI_DS_NAME))
        .then((ds) => {
          this.dataSource = ds;
          this.forceRender();
        });
    }
  }
}

export function ServiceLogsSearchEditorRenderer({ model }: SceneComponentProps<ServiceLogsSearchEditor>) {
  const styles = useStyles2(getStyles);

  const { baseExpr, editor, isValid, query } = model.useState();

  const scope = useMemo(() => {
    const languageProvider = getLanguageProviderService().lokiLanguageProvider;
    const abstractQuery = languageProvider.exportToAbstractQuery({ expr: baseExpr, refId: 'foo' });
    if (abstractQuery.labelMatchers.length) {
      return abstractQuery.labelMatchers.map((matcher) => `${matcher.name}="${matcher.value}"`).join(', ');
    }
    return baseExpr;
  }, [baseExpr]);

  if (model.dataSource && query) {
    const Editor = model.dataSource.components?.QueryEditor;

    if (Editor) {
      return (
        <div
          className={styles.editor}
          ref={(elem) => {
            if (elem !== editor) {
              model.setState({ editor: elem });
            }
          }}
        >
          <Editor
            datasource={model.dataSource}
            query={query}
            onChange={model.onChange}
            onRunQuery={model.propagateQuery}
          />

          {!isValid && (
            <Alert className={styles.alert} title="Invalid query" severity="warning">
              <Stack dir="column">
                <div className={styles.alertMessage}>
                  Query must use label filters <code>{scope}</code>
                </div>
              </Stack>
            </Alert>
          )}
        </div>
      );
    }
  }

  return <p>Loading logs search...</p>;
}

function getStyles(theme: GrafanaTheme2) {
  return {
    editor: css`
      & > div:first-child > button {
        display: none;
    `,
    alert: css`
      margin-top: 10px;
    `,
    alertMessage: css`
      margin-bottom: ${theme.spacing(1)};
    `,
  };
}
