import { css, cx } from '@emotion/css';
import React, { useMemo, useReducer } from 'react';
import { useSessionStorage } from 'react-use';

import { DataFrame, FieldType, GrafanaTheme2, LoadingState } from '@grafana/data';
import {
  SceneComponentProps,
  sceneGraph,
  SceneObjectBase,
  SceneObjectRef,
  SceneObjectState,
  SceneQueryRunner,
} from '@grafana/scenes';
import { Alert, Button, Text, TextLink, Tooltip, useStyles2 } from '@grafana/ui';

import { USAGE_DS } from 'constants/datasources';
import { USER } from 'constants/user';
import { getInstanceService } from 'services/InstanceService';
import { getPluginConfigService } from 'services/PluginConfigService';
import { MetricsMode } from 'types/settings';
import { makeTimeRange } from 'utils/timeRange';

const metricsGeneratorSeriesDroppedId = 'metricsGeneratorSeriesDropped';
const labelsDroppedId = 'labelsDropped';
const traceTooLargeId = 'traceTooLarge';
const rateLimitedId = 'rateLimited';
const outsideSlackIngestionTimeId = 'outsideSlackIngestionTime';

export interface NotificationSceneState extends SceneObjectState {
  hasDroppedSeries: boolean;
  hasDroppedLabels: boolean;
  hasTraceTooLarge: boolean;
  hasBeenRateLimited: boolean;
  hasOutsideSlackIngestionTime: boolean;
}

// Component for showing notifications about metrics generation issues, or checks that we want to run only once on load
export class NotificationScene extends SceneObjectBase<NotificationSceneState> {
  static Component = NotificationRenderer;

  private sceneQueryRunnerRef: SceneObjectRef<SceneQueryRunner>;

  constructor() {
    const sceneQueryRunner = new SceneQueryRunner({
      $timeRange: makeTimeRange(),
      datasource: USAGE_DS,
      queries: [],
    });

    super({
      $data: sceneQueryRunner,
      hasDroppedSeries: false,
      hasDroppedLabels: false,
      hasTraceTooLarge: false,
      hasBeenRateLimited: false,
      hasOutsideSlackIngestionTime: false,
    });

    this.sceneQueryRunnerRef = sceneQueryRunner.getRef();

    this.addActivationHandler(() => {
      if (!this.areNotificationsEnabled()) {
        return;
      }
      this.getData();

      const unsubscribable = sceneGraph.getData(this).subscribeToState(({ data }) => {
        if (!data || data?.state !== LoadingState.Done) {
          return;
        }

        const hasDroppedSeries = this.seriesHasValues(data.series, metricsGeneratorSeriesDroppedId);
        const hasDroppedLabels = this.seriesHasValues(data.series, labelsDroppedId);
        const hasTraceTooLarge = this.seriesHasValues(data.series, traceTooLargeId);
        const hasBeenRateLimited = this.seriesHasValues(data.series, rateLimitedId);
        const hasOutsideSlackIngestionTime = this.seriesHasValues(data.series, outsideSlackIngestionTimeId);

        this.setState({
          hasDroppedSeries,
          hasDroppedLabels,
          hasTraceTooLarge,
          hasBeenRateLimited,
          hasOutsideSlackIngestionTime,
        });
      });

      return () => unsubscribable.unsubscribe();
    });
  }

  private areNotificationsEnabled(): boolean {
    // can be explicitly disabled via plugin config
    if (getPluginConfigService().getPluginConfig().disablePipelineNotifications) {
      return false;
    }

    // only show for admins
    if (!USER.ROLE_FLAG.ADMIN) {
      return false;
    }
    return true;
  }

  private getData() {
    const instance = getInstanceService()?.getInstance();

    const traceTooLargeQuery = () =>
      `grafanacloud_traces_instance_discarded_spans_total:rate5m{org_id="${instance?.orgId}", id="${instance?.htInstanceId}", reason="trace_too_large"}`;
    const rateLimitedQuery = () =>
      `grafanacloud_traces_instance_discarded_spans_total:rate5m{org_id="${instance?.orgId}", id="${instance?.htInstanceId}", reason="rate_limited"}`;
    const metricsDroppedQuery = () =>
      `grafanacloud_traces_instance_metrics_generator_series_dropped_per_second{org_id="${instance?.orgId}", id="${instance?.htInstanceId}"}`;
    const labelsDroppedQuery = () =>
      `grafanacloud_instance_samples_discarded_per_second{org_id="${instance?.orgId}", id="${instance?.hmInstancePromId}", reason="max_label_names_per_series"}`;
    const outsideSlackIngestionTimeQuery = () =>
      `grafanacloud_traces_instance_metrics_generator_discarded_spans_per_second{org_id="${instance?.orgId}", id="${instance?.htInstanceId}", reason="outside_metrics_ingestion_slack"}`;

    const queries = [
      ...(instance?.htInstanceId ? [{ expr: metricsDroppedQuery(), refId: metricsGeneratorSeriesDroppedId }] : []),
      ...(instance?.hmInstancePromId ? [{ expr: labelsDroppedQuery(), refId: labelsDroppedId }] : []),
      ...(instance?.htInstanceId ? [{ expr: traceTooLargeQuery(), refId: traceTooLargeId }] : []),
      ...(instance?.htInstanceId ? [{ expr: rateLimitedQuery(), refId: rateLimitedId }] : []),
      ...(instance?.htInstanceId
        ? [{ expr: outsideSlackIngestionTimeQuery(), refId: outsideSlackIngestionTimeId }]
        : []),
    ];

    if (queries.length > 0) {
      this.sceneQueryRunnerRef.resolve().setState({ queries });
      this.sceneQueryRunnerRef.resolve().runQueries();
    }
  }

  private seriesHasValues(series: DataFrame[], id: string): boolean {
    return series
      .filter(({ refId }) => refId === id)
      .some(({ fields }) => {
        const numberFields = fields.find(({ type }) => type === FieldType.number);
        return numberFields?.values.some((value) => value > 0);
      });
  }
}

const contactSupportLink = (
  <TextLink external href="https://grafana.com/profile/org/tickets/new">
    Contact Support
  </TextLink>
);

function NotificationRenderer(props: SceneComponentProps<NotificationScene>) {
  const styles = useStyles2(getStyles);
  const [closed, setClosed] = useSessionStorage('app-observability.notifications.closed', false);

  return (
    <div className={cx(styles.wrapper, { [styles.expanded]: !closed, [styles.closed]: closed })}>
      <ErrorRenderer {...props} closed={closed} setClosed={setClosed} />
    </div>
  );
}

function ErrorRenderer({
  model,
  closed,
  setClosed,
}: SceneComponentProps<NotificationScene> & { closed: boolean; setClosed: (value: boolean) => void }) {
  const styles = useStyles2(getStyles);
  const { hasDroppedLabels, hasDroppedSeries, hasTraceTooLarge, hasBeenRateLimited, hasOutsideSlackIngestionTime } =
    model.useState();

  const [expanded, toggleExpanded] = useReducer((prev) => !prev, false);

  const allErrors = useMemo(
    () => [
      {
        shouldShow: hasDroppedSeries,
        title: 'Metrics limit exceeded',
        message: (
          <Text>
            The limit for active metrics series generated from traces has been reached. Some series are being dropped.{' '}
            {contactSupportLink}
          </Text>
        ),
      },
      {
        shouldShow: hasDroppedLabels,
        title: 'Metric series are being dropped',
        message: (
          <Text>
            You have exceeded the labels per series limit and metric series are being dropped. Consult your
            OpenTelemetry Collector logs to investigate the issue, or {contactSupportLink} for assitance.
          </Text>
        ),
      },
      {
        shouldShow: hasTraceTooLarge,
        title: 'Trace is too large',
        message: <Text>Some traces are too large and cannot be ingested. {contactSupportLink}</Text>,
      },
      {
        shouldShow: hasBeenRateLimited,
        title: "You're being rate limited",
        message: <Text>The ingestion rate limit has been exceeded. {contactSupportLink}</Text>,
      },
      {
        shouldShow:
          hasOutsideSlackIngestionTime && MetricsMode.tempoMetricsGen === getPluginConfigService().getMetricsMode(),
        title: 'Spans are arriving too late',
        message: (
          <>
            <Text element="p">
              We have determined that some spans are arriving in a time period that prevents the generation of a small
              number of metrics.
            </Text>

            <Text element="p">
              If this is unexpected, you should consider altering the configuration for your collector infrastructure or
              application to reduce the delay between trace spans being generated and sent, or contact Grafana Cloud
              Support to increase the ingestion slack time to accommodate the delay. {contactSupportLink}
            </Text>
          </>
        ),
      },
    ],
    [hasDroppedLabels, hasTraceTooLarge, hasBeenRateLimited, hasDroppedSeries, hasOutsideSlackIngestionTime]
  );
  const errors = useMemo(() => allErrors.filter(({ shouldShow }) => shouldShow), [allErrors]);

  if (errors.length === 0) {
    return <></>;
  }

  if (closed) {
    return (
      <div className={styles.container}>
        <Tooltip content={errors.length > 1 ? 'Show all errors' : 'Show error'} placement="bottom">
          <Button data-testid="notifications-button" fill="text" variant="destructive" onClick={() => setClosed(false)}>
            Errors ({errors.length})
          </Button>
        </Tooltip>
      </div>
    );
  }

  if (errors.length === 1) {
    const error = errors.at(0);

    return error ? (
      <Alert
        data-testid="single-notification-alert"
        className={styles.container}
        title={error.title}
        onRemove={() => setClosed(true)}
        severity={hasOutsideSlackIngestionTime ? 'info' : 'error'}
      >
        {error.message}
      </Alert>
    ) : null;
  }

  return (
    <Alert
      data-testid="notifications-alert"
      className={styles.container}
      title={`${errors.length} errors detected, this may result in degraded application observability
            experience.`}
      onRemove={() => setClosed(true)}
    >
      {expanded && (
        <ul className={styles.errorList}>
          {errors.map(({ message, title }, idx) => (
            <li className={styles.errorItem} key={idx}>
              <Text weight="bold">{title}:</Text>
              <br />
              {message}
            </li>
          ))}
        </ul>
      )}

      {!expanded && (
        <>
          <ul className={styles.errorList}>
            <li className={styles.errorItem}>
              <Text weight="bold">{errors.at(0)?.title}:</Text> {errors.at(0)?.message}
            </li>
          </ul>

          {errors.length >= 2 && (
            <Button
              data-testid="show-more-button"
              className={styles.moreButton}
              fill="text"
              icon="angle-right"
              size="sm"
              onClick={toggleExpanded}
            >
              {errors.length - 1} more
            </Button>
          )}
        </>
      )}
    </Alert>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    expanded: css`
      grid-area: expandedNotifications;
    `,
    closed: css`
      grid-area: notifications;
    `,
    wrapper: css`
      display: flex;
      flex-direction: row;
      gap: ${theme.spacing(1)};
    `,
    container: css`
      margin-bottom: 0;
    `,
    errorList: css`
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(1)};
    `,
    errorItem: css`
      margin-left: ${theme.spacing(2)};
    `,
    moreButton: css`
      margin-top: ${theme.spacing(1)};
      padding-left: 0;
    `,
  };
}
