import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import type { ComponentProps } from 'react';

import { TextArea } from '@grafana/ui';

import { INPUT_DEBOUNCE_MS } from 'constants/misc';

export type InputProps = ComponentProps<typeof TextArea>;

export type DebouncedTextAreaProps = InputProps & {
  debounceMs?: number;
};

export function DebouncedTextArea({ debounceMs, onChange, value, ...inputProps }: DebouncedTextAreaProps) {
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const [val, setVal] = useState(value);

  useEffect(() => setVal(value), [value]);

  const onChangeInternal = onChange
    ? (event: ChangeEvent<HTMLTextAreaElement>) => {
        setVal(event.target.value);

        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => onChange(event), debounceMs ?? INPUT_DEBOUNCE_MS);
      }
    : undefined;

  return <TextArea value={val} onChange={onChangeInternal} {...inputProps} />;
}
