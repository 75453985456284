import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { SceneComponentProps, SceneObjectBase, SceneObjectRef, SceneObjectState } from '@grafana/scenes';
import { Icon, LinkButton, RadioButtonGroup, Stack, Text, TextLink, Toggletip, useStyles2 } from '@grafana/ui';

import { LogQueryMode } from 'constants/logs';
import { ROUTES } from 'constants/routing';
import { USER } from 'constants/user';
import { getPluginConfigService } from 'services/PluginConfigService';
import { buildRoute } from 'utils/routing';
import { trackDefaultLogMode, trackLogModeSwitch } from 'utils/tracking';

import { ServiceLogsScene } from './ServiceLogsScene';

export interface LogModeSwitchProps {
  logsSceneRef: SceneObjectRef<ServiceLogsScene>;
}

export interface LogModeSwitchState extends SceneObjectState, LogModeSwitchProps {
  logQueryMode: LogQueryMode;
}

export class LogModeSwitch extends SceneObjectBase<LogModeSwitchState> {
  static Component = LogModeSwitchRenderer;

  constructor(state: LogModeSwitchProps) {
    const { defaultLogQueryMode = LogQueryMode.json } = getPluginConfigService().getPluginConfig();

    super({
      logQueryMode: defaultLogQueryMode,
      logsSceneRef: state.logsSceneRef,
    });

    this.addActivationHandler(() => {
      trackDefaultLogMode();
    });
  }

  onChange(value: LogQueryMode) {
    this.setState({ logQueryMode: value });

    this.state.logsSceneRef.resolve().setLogQueryMode(value);
  }
}

const options = [
  { label: 'Loki exporter query', value: LogQueryMode.json },
  { label: 'OTLP gateway / native Loki otlp query', value: LogQueryMode.otlp },
];

function LogModeSwitchRenderer({ model }: SceneComponentProps<LogModeSwitch>) {
  const styles = useStyles2(getStyles);
  const { logQueryMode } = model.useState();

  return (
    <div className={styles.container}>
      <Stack dir="row" gap={1} data-cy="logModeSwitch">
        <RadioButtonGroup
          onClick={(value) => {
            trackLogModeSwitch(value);
          }}
          options={options}
          value={logQueryMode}
          onChange={(value) => model.onChange(value)}
        />
        <Toggletip
          placement="auto-end"
          title={
            <Text variant="h5" element="h5">
              Query type
            </Text>
          }
          content={
            <>
              <Text color="secondary" element="p">
                Select the log query format that matches your logs ingestion method{' '}
                <TextLink color="link" href="https://grafana.com/docs/loki/next/send-data/otel/" external>
                  (Documentation)
                </TextLink>{' '}
                :
              </Text>
              <ul className={styles.formatOptions}>
                <li>
                  <Text color="secondary" variant="body">
                    Use Loki exporter query if you are exporting logs directly to Loki using `loki-exporter`
                  </Text>
                </li>
                <li>
                  <Text color="secondary" variant="body">
                    Use OTLP gateway / native Loki otlp query if you are exporting logs via Grafana OTLP gateway or Loki
                    native OTLP endpoint
                  </Text>
                </li>
              </ul>
            </>
          }
          footer={
            USER.ROLE_FLAG.ADMIN ? (
              <div className={styles.configButton}>
                <LinkButton href={buildRoute(ROUTES.logsQueryConfiguration())} variant="secondary" icon="cog">
                  Configuration
                </LinkButton>
              </div>
            ) : (
              <></>
            )
          }
          closeButton
        >
          <div className={styles.help}>
            <Stack dir="row" alignItems="center" gap={0.5}>
              <Text>Help</Text>
              <Icon name="info-circle"></Icon>
            </Stack>
          </div>
        </Toggletip>
      </Stack>
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    container: css`
      min-height: ${theme.spacing(4)};
    `,
    help: css`
      display: flex;
      cursor: pointer;
      :hover {
        text-decoration: underline;
      }
    `,
    options: css`
      padding-left: ${theme.spacing(2)};
      color: ${theme.colors.text.secondary};
      font-size: ${theme.typography.body.fontSize};
    `,
    configButton: css`
      a {
        text-decoration: none !important;
      }
    `,
    formatOptions: css`
      margin-left: ${theme.spacing(1.5)};
    `,
  };
}
