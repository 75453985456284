import { PRESERVE_URL_KEYS } from 'constants/routing';
import { TrackedSceneAppPage } from 'faro/TrackedSceneAppPage';
import { getPluginRouteRoot } from 'utils/routing';

import { makeInitializeScene } from './makeInitializeScene';

export function makeInitializePage(): TrackedSceneAppPage {
  const page: TrackedSceneAppPage = new TrackedSceneAppPage({
    viewName: 'initialize',
    title: '',
    url: getPluginRouteRoot(),
    preserveUrlKeys: PRESERVE_URL_KEYS,
    hideFromBreadcrumbs: false,
    getScene: makeInitializeScene(),
    getFallbackPage: () => page,
  });
  return page;
}
