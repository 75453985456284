import { EmbeddedScene, SceneAppPage, SceneReactObject } from '@grafana/scenes';

import { ROUTE_DEFINITIONS } from 'constants/routing';
import { makeVariables } from 'utils/variables';

import { Config, ConfigProps } from './Config';

export function makeConfigurationPage() {
  const page = new SceneAppPage({
    hideFromBreadcrumbs: true,
    title: 'Configuration',
    url: ROUTE_DEFINITIONS.configuration,
    getScene: () => {
      const es: EmbeddedScene = new EmbeddedScene({
        $variables: makeVariables(),
        body: new SceneReactObject({
          component: Config,
          props: {
            getScene: () => es,
          } as ConfigProps,
        }),
      });

      return es;
    },
    getFallbackPage: (): SceneAppPage => page,
  });
  return page;
}
