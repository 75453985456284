import { behaviors, EmbeddedScene, SceneFlexItem, SceneFlexLayout, sceneGraph, SceneRouteMatch } from '@grafana/scenes';
import { DashboardCursorSync } from '@grafana/schema';

import { EnvironmentFilterScene } from 'components/EnvironmentFilter/EnvironmentFilterScene';
import { FilterByScene } from 'components/FilterBy/FilterByScene';
import { GroupByScene } from 'components/GroupBy/GroupByScene';
import { CLOUD } from 'constants/cloud';
import { PROMETHEUS_DS, TEMPO_DS } from 'constants/datasources';
import { GRID_GAP, MIN_PANEL_HEIGHT, MIN_TABLE_PANEL_HEIGHT } from 'constants/styles';
import { ServiceEmbeddedScene } from 'modules/service/components/ServiceEmbeddedScene';
import { getServiceOverviewPanels } from 'panels/getServiceOverviewPanels';
import { getMetadataService } from 'services/MetadataService';
import { DS_TYPE } from 'types/datasources';
import { determineCloud } from 'utils/cloud';
import { isProvisionedDataSource } from 'utils/datasources';
import { jobContainsNamespace } from 'utils/services';
import { trackServiceDetail } from 'utils/tracking';
import { makeVariables } from 'utils/variables';

export function makeServiceOverviewScene(
  job: string,
  encodedJob: string,
  isInstrumented: boolean
): (routeMatch: SceneRouteMatch<any>) => EmbeddedScene {
  return () => {
    const withNamespace = jobContainsNamespace(job);

    const panels = getServiceOverviewPanels(job);
    const operationsPanel = panels.operations();
    const downstreamPanel = panels.downstream();
    const upstreamPanel = panels.upstream();

    const metadataService = getMetadataService();

    return new ServiceEmbeddedScene(
      {
        $variables: makeVariables({
          useFilterBy: true,
          job,
          isInstrumented,
        }),
        $behaviors: [
          new behaviors.CursorSync({ key: 'sync1', sync: DashboardCursorSync.Tooltip }),
          (scene) => {
            const operationsPanelData = sceneGraph.getData(operationsPanel);
            const downstreamPanelData = sceneGraph.getData(downstreamPanel);
            const upstreamPanelData = sceneGraph.getData(upstreamPanel);
            let tracked = false;

            const performTracking = () => {
              if (tracked) {
                return;
              }
              tracked = true;
              const operations = operationsPanelData.state.data?.series?.[0]?.length;
              const upstreams = upstreamPanelData.state.data?.series?.[0]?.length;
              const downstreams = downstreamPanelData.state.data?.series?.[0]?.length;
              const technology = metadataService.getTechnology(job);
              const cloud = metadataService.getCloud(job).reduceRight((acc, cloudAttributes) => {
                if (acc !== CLOUD.UNKNOWN) {
                  return acc;
                }

                return determineCloud(cloudAttributes.provider);
              }, CLOUD.UNKNOWN);
              const hasK8s = metadataService
                .getK8s(job)
                .reduceRight((acc, k8sAttributes) => acc || Object.keys(k8sAttributes).length > 0, false);

              const promDatasource = sceneGraph.interpolate(scene, PROMETHEUS_DS.uid);
              const tempoDatasource = sceneGraph.interpolate(scene, TEMPO_DS.uid);

              trackServiceDetail(
                operations,
                upstreams,
                downstreams,
                technology,
                cloud,
                hasK8s,
                withNamespace,
                isProvisionedDataSource(promDatasource, PROMETHEUS_DS.type as DS_TYPE),
                isProvisionedDataSource(tempoDatasource, TEMPO_DS.type as DS_TYPE)
              );
            };

            const timeoutId = setTimeout(performTracking, 10000);

            return () => {
              clearTimeout(timeoutId);
              performTracking();
            };
          },
        ],

        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneFlexLayout({
              direction: 'row',
              wrap: 'wrap',
              children: [new GroupByScene(), new EnvironmentFilterScene(), new FilterByScene()],
            }),

            new SceneFlexLayout({
              direction: 'row',
              minHeight: MIN_PANEL_HEIGHT,
              children: [
                new SceneFlexItem({ body: panels.duration() }),
                new SceneFlexItem({ body: panels.errors() }),
                new SceneFlexItem({ body: panels.rate() }),
              ],
            }),

            new SceneFlexLayout({
              direction: 'row',
              children: isInstrumented
                ? [
                    new SceneFlexLayout({
                      direction: 'column',
                      width: '100%',
                      children: [
                        new SceneFlexItem({
                          minHeight: MIN_TABLE_PANEL_HEIGHT + GRID_GAP,
                          md: {
                            minHeight: MIN_PANEL_HEIGHT,
                          },
                          body: panels.durationDistribution(),
                        }),

                        new SceneFlexItem({ minHeight: MIN_TABLE_PANEL_HEIGHT, body: operationsPanel }),
                      ],
                    }),

                    new SceneFlexLayout({
                      direction: 'column',
                      width: '100%',
                      children: [
                        new SceneFlexItem({
                          minHeight: MIN_TABLE_PANEL_HEIGHT + GRID_GAP,
                          body: downstreamPanel,
                        }),

                        new SceneFlexItem({ minHeight: MIN_TABLE_PANEL_HEIGHT, body: upstreamPanel }),
                      ],
                    }),
                  ]
                : [
                    new SceneFlexLayout({
                      direction: 'column',
                      width: '100%',
                      children: [
                        new SceneFlexItem({
                          minHeight: MIN_TABLE_PANEL_HEIGHT,
                          body: panels.downstream(),
                        }),

                        new SceneFlexItem({
                          minHeight: MIN_TABLE_PANEL_HEIGHT,
                          body: panels.upstream(),
                        }),
                      ],
                    }),
                  ],
            }),
          ],
        }),
      },
      isInstrumented
    ) as unknown as EmbeddedScene;
  };
}
