import { isEqual } from 'lodash';
import { Unsubscribable } from 'rxjs';

import { sceneGraph } from '@grafana/scenes';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { FilterByVariable } from 'components/FilterByVariable';
import { GroupByVariable } from 'components/GroupByVariable';
import { PromSceneQueryRunner } from 'components/PromSceneQueryRunner';
import { BASELINE_COMPARER_VALUE } from 'components/TimeRangeCompareWithBaseline';
import { LOWER_ANOMALY_THRESHOLD_REF_ID, UPPER_ANOMALY_THRESHOLD_REF_ID } from 'constants/query';
import { FILTER_BY_NAME, GROUP_BY_NAME } from 'constants/variables';
import { getTimeCompare } from 'utils/scenes';

export const showAnomalyThresholdsBehavior = (queryRunner: PromSceneQueryRunner) => (panel: ExplorablePanel) => {
  const groupByVariable = sceneGraph.lookupVariable(GROUP_BY_NAME, panel) as GroupByVariable;
  const filterByVariable = sceneGraph.lookupVariable(FILTER_BY_NAME, panel) as FilterByVariable;
  const comparer = getTimeCompare(panel);

  const update = () => {
    const { queriesWithThresholds } = queryRunner.state;
    if (queriesWithThresholds) {
      // hide anomaly thresholds if group by or filter by are engaged
      const shouldShowAnomalyThresholds =
        comparer?.state.compareWith === BASELINE_COMPARER_VALUE &&
        groupByVariable.isEmpty() &&
        filterByVariable.isEmpty();
      const nextQueries = shouldShowAnomalyThresholds
        ? queriesWithThresholds
        : queriesWithThresholds.filter(
            (q) => ![UPPER_ANOMALY_THRESHOLD_REF_ID, LOWER_ANOMALY_THRESHOLD_REF_ID].includes(q.refId)
          );
      if (queryRunner.state.queries.length !== nextQueries.length) {
        queryRunner.setState({ queries: nextQueries });
      }
    }
  };

  const unsubscribables: Unsubscribable[] = [];
  unsubscribables.push(
    groupByVariable.subscribeToState((newState, oldState) => {
      if (!isEqual(newState.value, oldState.value)) {
        update();
      }
    })
  );

  unsubscribables.push(
    filterByVariable.subscribeToState((newState, oldState) => {
      if (!isEqual(newState.filters, oldState.filters)) {
        update();
      }
    })
  );

  unsubscribables.push(
    queryRunner.subscribeToState((newState, oldState) => {
      if (!isEqual(newState.queriesWithThresholds, oldState.queriesWithThresholds)) {
        update();
      }
    })
  );
  if (comparer) {
    unsubscribables.push(
      comparer.subscribeToState((newState, oldState) => {
        if (!isEqual(newState.compareWith, oldState.compareWith)) {
          update();
        }
      })
    );
  }

  update();

  return () => {
    unsubscribables.forEach((u) => u.unsubscribe());
  };
};
