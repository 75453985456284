import { css, cx } from '@emotion/css';
import React, { useMemo, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Modal, Tab, TabContent, TabsBar, Text, useStyles2 } from '@grafana/ui';

import serviceInventory from 'img/screenshots/serviceInventory.png';
import serviceLogs from 'img/screenshots/serviceLogs.png';
import serviceMap from 'img/screenshots/serviceMap.png';
import serviceOverview from 'img/screenshots/serviceOverview.png';
import serviceProfiles from 'img/screenshots/serviceProfiles.png';
import serviceRuntime from 'img/screenshots/serviceRuntime.png';
import serviceTraces from 'img/screenshots/serviceTraces.png';

import { getCommonStyles } from './commonStyles';

const tabs = [
  {
    id: 'serviceInventory',
    label: 'Service inventory',
    description: 'List, filter, search, and get an overview of RED metrics for all your services.',
    img: serviceInventory,
    imgAlt: 'Screenshot of the service inventory',
  },
  {
    id: 'serviceOverview',
    label: 'Service overview',
    description:
      'View detailed RED metrics, operations performed, and related upstream and downstream services for a service.',
    img: serviceOverview,
    imgAlt: 'Screenshot of a service overview',
  },
  {
    id: 'serviceTraces',
    label: 'Tailored traces',
    description: 'View and filter traces for a service, and dig deeper with the built-in trace and span explorer.',
    img: serviceTraces,
    imgAlt: 'Screenshot of service traces',
  },
  {
    id: 'serviceLogs',
    label: 'Log exploration',
    description: 'View and filter logs for a service.',
    img: serviceLogs,
    imgAlt: 'Screenshot of service logs',
  },
  {
    id: 'serviceMap',
    label: 'Service map',
    description: 'View a graph of connected services and monitor overall system health and RED metrics.',
    img: serviceMap,
    imgAlt: 'Screenshot of a service map',
  },
  {
    id: 'profiles',
    label: 'Performance profiles',
    description: 'View flamegraphs for various resource profile types for your service.',
    img: serviceProfiles,
    imgAlt: 'Screenshot of the performance profiles tab',
  },
  {
    id: 'runtime',
    label: 'Runtime information',
    description: 'View runtime information for your service.',
    img: serviceRuntime,
    imgAlt: 'Screenshot of a service map',
  },
];

export const ExploreData = () => {
  const commonStyles = useStyles2(getCommonStyles);
  const styles = useStyles2(getStyles);

  const [activeTabId, setActiveTabId] = useState(tabs[0].id);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const activeTab = useMemo(() => tabs.find((tab) => tab.id === activeTabId)!, [activeTabId]);

  return (
    <>
      <div className={commonStyles.containerLeft}>
        <Text element="h3" variant="h3" textAlignment="center">
          All you need to monitor your applications
        </Text>

        <div className={styles.tabsContainer}>
          <TabsBar>
            {tabs.map((tab) => (
              <Tab
                key={tab.id}
                label={tab.label}
                active={tab === activeTab}
                onChangeTab={() => setActiveTabId(tab.id)}
              />
            ))}
          </TabsBar>
        </div>

        <TabContent className={cx(commonStyles.element, styles.tabContainer)}>
          <div className={styles.tabContent}>
            <Text textAlignment="center">{activeTab.description}</Text>
            <img
              src={activeTab.img}
              alt={activeTab.imgAlt}
              className={styles.tabImg}
              onClick={() => setIsModalOpened(true)}
            />
          </div>
        </TabContent>
      </div>

      <Modal
        title={activeTab.label}
        isOpen={isModalOpened}
        className={styles.modalContainer}
        closeOnBackdropClick
        closeOnEscape
        onDismiss={() => setIsModalOpened(false)}
      >
        <img src={activeTab.img} alt={activeTab.imgAlt} className={styles.modalImg} />
      </Modal>
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  tabsContainer: css`
    margin: ${theme.spacing(5)} 0 ${theme.spacing(1)} 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  tabContainer: css`
    display: flex;
    justify-content: center;
    background: transparent;
  `,

  tabContent: css`
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
  `,

  tabImg: css`
    cursor: pointer;
    width: 100%;
    max-width: 800px;
    margin-top: ${theme.spacing(6)};
    box-shadow: 0px 8px 56px 0px #010409b2;
    border-radius: ${theme.shape.radius.default};
  `,

  modalContainer: css`
    width: 100%;
  `,

  modalImg: css`
    width: 100%;
  `,
});
