// Only surface keys we know we're going to work with
export type Instance = {
  orgId?: number;
  hmInstancePromId?: number;
  hmInstancePromName?: string;
  htInstanceId?: number;
  createdAt: string;
  trial: number;
  trialExpiresAt?: string | null;
  plan?: string;
} & Record<string, unknown>;

export const TYPE_NONE = 0;
export const TYPE_SELF_SERVE = 1;
export const TYPE_CONTRACTED = 2;

export const CODE_NONE = 'none';
export const CODE_SELF_SERVE = 'self_serve';
export const CODE_CONTRACTED = 'contracted';

export const TYPES = {
  [CODE_NONE]: {
    id: TYPE_NONE,
    name: 'None',
  },
  [CODE_SELF_SERVE]: {
    id: TYPE_SELF_SERVE,
    name: 'Self-Serve',
  },
  [CODE_CONTRACTED]: {
    id: TYPE_CONTRACTED,
    name: 'Contracted',
  },
} as const;

export type Code = keyof typeof TYPES;
export type IdType = (typeof TYPES)[Code]['id'];

export type Org = {
  contractType: Code;
  contractTypeId: IdType;
} & Record<string, unknown>;
