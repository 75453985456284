import { OrgRole } from '@grafana/data';
import { config } from '@grafana/runtime';

const role = config.bootData?.user?.orgRole;
const isNotEmptyRole = role !== '';
const isAdmin = isNotEmptyRole && role === OrgRole.Admin;
const isEditor = !isAdmin && isNotEmptyRole && role === OrgRole.Editor;
const isViewer = !isEditor && isNotEmptyRole && role === OrgRole.Viewer;
const isNone = !isViewer && isNotEmptyRole && role === OrgRole.None;

const bootUser = config.bootData?.user ?? {};

const themeName = bootUser.theme;
const isLightTheme = themeName === 'light';
const isDarkTheme = !isLightTheme;

export const USER = {
  ID: bootUser.id,
  NAME: bootUser.name,
  ORG_ID: bootUser.orgId,
  ORG_NAME: bootUser.orgName,
  IS_SIGNED_IN: bootUser.isSignedIn,
  ROLE: role,
  ROLE_FLAG: {
    ADMIN: isAdmin,
    EDITOR: isEditor,
    VIEWER: isViewer,
    NONE: isNone,
  },
  THEME_NAME: bootUser.theme,
  THEME_FLAG: {
    LIGHT: isLightTheme,
    DARK: isDarkTheme,
  },
} as const;
