import { css } from '@emotion/css';
import { isEqual } from 'lodash';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import {
  SceneComponentProps,
  sceneGraph,
  SceneLayout,
  SceneObjectBase,
  SceneObjectRef,
  SceneObjectState,
  SceneObjectUrlSyncConfig,
  SceneObjectUrlValues,
  SceneQueryRunner,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { useStyles2 } from '@grafana/ui';

import { PYROSCOPE_DS } from 'constants/datasources';
import { MIN_PANEL_HEIGHT, MIN_PANEL_HEIGHT_PX } from 'constants/styles';
import { PYROSCOPE_DS_NAME, SERVICE_NAME_NAME, SERVICE_NAMESPACE_NAME } from 'constants/variables';
import { createPyroscopeQuery } from 'queries/pyroscope';
import { PyroscopeQuery } from 'types/queries';
import { row } from 'utils/layout';

import { makeServiceProfilesPanel } from './panels/makeServiceProfilesPanel';
import { InternalProfilesSearchEditorState, ProfilesSearchEditor } from './ProfilesSearchEditor';
import { isValidQuery } from './utils';

export interface ProfilesSceneState {
  job: string;
}

export interface InternalProfilesSceneState extends SceneObjectState {
  job: string;
  initialized: boolean;
  operation?: string;
  spanID?: string;

  query?: PyroscopeQuery;
  queryRunnerRef?: SceneObjectRef<SceneQueryRunner>;

  searchEditor?: ProfilesSearchEditor;
  profiles?: SceneLayout;
}

export class ProfilesScene extends SceneObjectBase<InternalProfilesSceneState> {
  static Component = ProfilesSceneRenderer;

  protected _urlSync = new SceneObjectUrlSyncConfig(this, {
    keys: ['profilesQuery', 'spanID'],
  });

  protected _variableDependency = new VariableDependencyConfig(this, {
    variableNames: [SERVICE_NAME_NAME, SERVICE_NAMESPACE_NAME, PYROSCOPE_DS_NAME],
  });

  constructor(state: ProfilesSceneState) {
    super({
      ...state,
      initialized: false,
    });

    this.addActivationHandler(() => {
      this.updateOrInitialize(this.state.query, this.state.spanID);
    });
  }

  getUrlState() {
    return { profilesQuery: JSON.stringify(this.state.query), spanID: this.state.spanID };
  }

  updateFromUrl(values: SceneObjectUrlValues): void {
    this.updateOrInitialize(
      !Object.prototype.hasOwnProperty.call(values, 'profilesQuery')
        ? this.state.query
        : typeof values.profilesQuery === 'string'
          ? JSON.parse(decodeURIComponent(values.profilesQuery))
          : undefined,
      !Object.prototype.hasOwnProperty.call(values, 'spanID')
        ? this.state.spanID
        : typeof values.spanID === 'string'
          ? decodeURIComponent(values.spanID)
          : undefined
    );
  }

  private updateOrInitialize(newQuery: PyroscopeQuery | undefined | null, spanID?: string): void {
    let query: PyroscopeQuery;

    const { isValid } = isValidQuery(newQuery, this.state.job);
    if (isValid) {
      query = newQuery as PyroscopeQuery;
    } else {
      query = createPyroscopeQuery(this.state.job);
      query.query = sceneGraph.interpolate(this, query.query);
      query.labelSelector = sceneGraph.interpolate(this, query.labelSelector);
      if (spanID) {
        query.spanSelector = [spanID];
      }
    }

    if (this.state.initialized) {
      setTimeout(() => this.update(query));
      return;
    }

    this.initialize(query);
  }

  private initialize(query: PyroscopeQuery): void {
    const queryRunner = new SceneQueryRunner({
      datasource: PYROSCOPE_DS,
      queries: [query],
      liveStreaming: false,
    });

    this.setState({
      ...this.state,

      initialized: true,

      query,
      queryRunnerRef: queryRunner.getRef(),

      searchEditor: new ProfilesSearchEditor({
        query,
        job: this.state.job,
        updateQuery: (query) => this.updateOrInitialize(query),
      }),
      profiles: row({ minHeight: MIN_PANEL_HEIGHT }, makeServiceProfilesPanel(queryRunner.getRef())),
    });
  }

  private update(query: PyroscopeQuery): void {
    const newState: Partial<InternalProfilesSceneState> = {};

    if (!isEqual(query, this.state.query)) {
      newState.query = query;
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState);
    }

    if (!isEqual(this.state.queryRunnerRef?.resolve().state.queries[0], query)) {
      const queryRunner = this.state.queryRunnerRef?.resolve();
      queryRunner?.cancelQuery();
      queryRunner?.setState({ queries: [query] });
      queryRunner?.runQueries();
    }

    const newSearchEditorState: Partial<InternalProfilesSearchEditorState> = {};

    if (!isEqual(this.state.searchEditor?.state.query, query)) {
      newSearchEditorState.query = query;
      newSearchEditorState.isValid = true;
    }

    if (Object.keys(newSearchEditorState).length > 0) {
      clearTimeout(this.state.searchEditor?.state.pendingChanges);
      this.state.searchEditor?.setState(newSearchEditorState);
    }
  }
}

function ProfilesSceneRenderer({ model }: SceneComponentProps<ProfilesScene>) {
  const styles = useStyles2(getStyles);

  const { initialized, searchEditor, profiles } = model.useState();
  if (!initialized) {
    return null;
  }

  const SearchEditor = searchEditor?.Component;
  const Profiles = profiles?.Component;

  return (
    <div className={styles.container}>
      <div className={styles.queryEditorContainer}>{SearchEditor && <SearchEditor model={searchEditor} />}</div>
      <div className={`${styles.panelContainer} fs-mask`}>{Profiles && <Profiles model={profiles} />}</div>
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    container: css`
      display: flex;
      flex: 1;
      flex-direction: column;
      width: 100%;
      gap: ${theme.spacing(1)};
    `,
    queryEditorContainer: css`
      display: flex;
      flex-direction: column;
    `,
    panelContainer: css`
      flex: 1;
      display: flex;
      min-height: ${MIN_PANEL_HEIGHT_PX};
      padding-top: ${theme.spacing(2)};

      & [class$='-horizontalContainer'] {
        min-height: 100%;
      }
    `,
    openInExploreContainer: css`
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    `,
  };
}
