import { useMemo } from 'react';

import { UrlQueryMap } from '@grafana/data';

import { IS_K8S_PLUGIN_INSTALLED } from 'constants/k8s';
import { PROMETHEUS_DS_NAME } from 'constants/variables';
import { useQueryParams } from 'hooks/useQueryParams';
import { genericProcessor } from 'modules/service/components/ServicePageTitle/Metadata';
import { getMetadataService } from 'services/MetadataService';

import { determineCloud } from './cloud';
import { makeFeO11yLinks } from './feO11y';
import { makeK8sPluginLinks } from './k8s';
import { parseJob } from './services';

export function useMetadata(job: string) {
  const [queryParamsMap] = useQueryParams();

  return useMemo(() => {
    const metadataService = getMetadataService();

    const cloud = metadataService.getCloud(job);
    const telemetrySdk = metadataService.getTelemetrySdk(job);
    const processRuntime = metadataService.getProcessRuntime(job);
    const clientInfo = metadataService.getClientInfo(job);
    const technology = metadataService.getTechnology(job);

    const [k8sEntries, k8sLink] = getK8sEntries(job, queryParamsMap);

    return {
      cloud: {
        entries: genericProcessor(cloud),
        provider: determineCloud(cloud[cloud.length - 1]?.cloud_provider),
      },
      runtimeEntries: { ...genericProcessor(processRuntime), ...genericProcessor(telemetrySdk) },
      clientInfoEntries: makeFeO11yLinks(clientInfo),
      k8s: {
        entries: k8sEntries,
        link: k8sLink,
      },
      technology,
    };
  }, [job, queryParamsMap]);
}

function getK8sEntries(
  job: string,
  queryParamsMap: UrlQueryMap
): [Record<string, Array<{ label: string; link?: string | undefined }>>, string | undefined] {
  const metadataService = getMetadataService();
  const k8s = metadataService.getK8s(job);

  const prometheusDsName = queryParamsMap[`var-${PROMETHEUS_DS_NAME}`];

  const categories = Array.from(new Set(k8s.flatMap((labels) => Object.keys(labels)))).sort();
  const { serviceName, serviceNamespace } = parseJob(job);

  const links = !IS_K8S_PLUGIN_INSTALLED
    ? []
    : k8s.map((labels) =>
        makeK8sPluginLinks(
          serviceName,
          serviceNamespace,
          labels,
          prometheusDsName ? String(prometheusDsName) : undefined
        )
      );

  const lastEntry = links[links.length - 1];
  const link =
    lastEntry?.k8s_job_name ??
    lastEntry?.k8s_deployment_name ??
    lastEntry?.k8s_cronjob_name ??
    lastEntry?.k8s_daemonset_name ??
    lastEntry?.k8s_replicaset_name ??
    lastEntry?.k8s_statefulset_name;

  return [
    Object.fromEntries(
      categories.map((category) => [
        category,
        k8s.map((entry, idx) => ({
          label: entry[category] ?? '-',
          link: links[idx]?.[category],
        })),
      ])
    ),
    link,
  ];
}
