import { SceneQueryRunner } from '@grafana/scenes';
import { PointVisibility } from '@grafana/ui';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { PROMETHEUS_DS } from 'constants/datasources';
import { DEFAULT_FIELD_CONFIG } from 'constants/panels';

export function makeServiceGoMemoryHeapPanel(): ExplorablePanel {
  return new ExplorablePanel({
    title: 'Memory in Heap',

    trackingSection: 'service',
    trackingPage: 'go',
    trackingPanel: 'memory-heap',

    fieldConfig: {
      defaults: {
        ...DEFAULT_FIELD_CONFIG,
        custom: {
          showPoints: PointVisibility.Never,
        },
        unit: 'decbytes',
      },
      overrides: [],
    },

    options: {
      tooltip: {
        mode: 'multi',
      },
    },

    $data: new SceneQueryRunner({
      datasource: PROMETHEUS_DS,
      maxDataPointsFromWidth: true,
      queries: [
        {
          expr: 'process_runtime_go_mem_heap_sys{job="${job}"}',
          legendFormat: 'Bytes of heap memory obtained from the OS',
          refId: 'serviceGoMemoryHeapOS',
        },
        {
          expr: '{__name__=~"process_runtime_go_mem_heap_alloc|process_runtime_go_mem_heap_alloc_bytes", job="${job}"}',
          legendFormat: 'Bytes of allocated heap objects',
          refId: 'serviceGoMemoryHeapAllocated',
        },
        {
          expr: '{__name__=~"process_runtime_go_mem_heap_idle|process_runtime_go_mem_heap_idle_bytes", job="${job}"}',
          legendFormat: 'Bytes in idle (unused) spans',
          refId: 'serviceGoMemoryHeapIdleSpans',
        },
        {
          expr: '{__name__=~"process_runtime_go_mem_heap_inuse|process_runtime_go_mem_heap_inuse_bytes", job="${job}"}',
          legendFormat: 'Bytes in in-use spans',
          refId: 'serviceGoMemoryHeapInuseSpans',
        },
        {
          expr: '{__name__=~"process_runtime_go_mem_heap_released|process_runtime_go_mem_heap_released_bytes", job="${job}"}',
          legendFormat: 'Bytes of idle spans whose physical memory has been returned to the OS',
          refId: 'serviceGoMemoryHeapReleased',
        },
      ],
    }),
  });
}
