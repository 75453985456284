import { config } from '@grafana/runtime';

import { USER } from './user';

export const ACCESS_CONTROL = {
  ALERTING: {
    CREATE: false,
    DELETE: false,
    EDIT: false,
    READ: false,
  },
  DATASOURCES: {
    READ: false,
  },
  EXPLORE: {
    VIEW: false,
  },
  PLUGIN: {
    ADD_SERVICE: false,
    INITIALIZE: false,
  },
  BASELINE: {
    ENABLE: false,
  },
};

export function initializeAccessControl() {
  const bootPermissions = config.bootData.user.permissions ?? {};

  const canReadDatasources = bootPermissions['datasources:read'] ?? USER.ROLE_FLAG.EDITOR;

  ACCESS_CONTROL.DATASOURCES.READ = canReadDatasources;

  ACCESS_CONTROL.EXPLORE.VIEW =
    bootPermissions['datasources:explore'] ?? (USER.ROLE_FLAG.ADMIN || USER.ROLE_FLAG.EDITOR);

  ACCESS_CONTROL.PLUGIN.ADD_SERVICE = USER.ROLE_FLAG.ADMIN;
  ACCESS_CONTROL.PLUGIN.INITIALIZE = USER.ROLE_FLAG.ADMIN;
  ACCESS_CONTROL.BASELINE.ENABLE = USER.ROLE_FLAG.ADMIN;
}
