import { TimeRange } from '@grafana/data';

import { SparklineDataItem } from 'types/services';

export function makeSparklineData(
  timestamps: any[],
  values: any[],
  range: TimeRange,
  interval: number
): SparklineDataItem {
  const vectorValues: number[] = [];

  let idx = 0;
  const from = range.from.valueOf();
  const to = range.to.valueOf();
  let last = 0;

  for (let time = from; time <= to; time += interval) {
    let value = null;

    while (idx < timestamps.length && timestamps[idx] <= time) {
      value = values[idx];

      if (value != null && !isNaN(value)) {
        last = value;
      }

      idx++;
    }

    vectorValues.push(value === null || !isNaN(value) ? value : 0);
  }

  return {
    vector: vectorValues,
    value: last,
    loading: false,
  };
}
