import { css } from '@emotion/css';
import React, { useMemo } from 'react';

import { DataFrame, FieldConfig, FieldSparkline, FieldType, GrafanaTheme2 } from '@grafana/data';
import {
  FieldColorModeId,
  GraphDrawStyle,
  GraphGradientMode,
  LineInterpolation,
  VisibilityMode,
} from '@grafana/schema';
import { GraphFieldConfig, Sparkline as Sprk, UPlotConfigBuilder, useStyles2, useTheme2 } from '@grafana/ui';

import { ShimmerPlaceholder } from 'components/ShimmerPlaceholder';
import { FILL_OPACITY_INVENTORY } from 'constants/panels';
import { SparklineDataItem } from 'types/services';

export interface SparklineProps {
  color: string;
  data: SparklineDataItem;
  formatValue: (value: number) => string;

  name?: string;
}

const config: FieldConfig<GraphFieldConfig> = {
  custom: {
    drawStyle: GraphDrawStyle.Line,
    gradientMode: GraphGradientMode.Opacity,
    spanNulls: true,
    fillOpacity: FILL_OPACITY_INVENTORY,
    lineInterpolation: LineInterpolation.Smooth,
    showPoints: VisibilityMode.Never,
  },
};

// a hack to configure sparkline series with custom gradient,
// something current Sparkline impl does not allow
class EnhancedSparkline extends Sprk {
  prepareConfig(data: DataFrame): UPlotConfigBuilder {
    const builder = super.prepareConfig(data);
    const seriesProps = (builder as any).series[0].props;
    seriesProps.gradientMode = config.custom?.gradientMode;
    seriesProps.fillColor = config.custom?.fillColor;
    return builder;
  }
}

export function Sparkline(props: SparklineProps) {
  const { color, data, formatValue } = props;

  const styles = useStyles2(getStyles);

  const theme = useTheme2();

  const sparkline: FieldSparkline = useMemo(() => {
    const max = Math.max(...data.vector);
    return {
      y: {
        name: 'count',
        type: FieldType.number,
        config: {
          color: {
            mode: FieldColorModeId.Fixed,
            fixedColor: color,
          },
        },
        values: data.vector,
        state: {
          range: {
            min: 0,
            max,
            delta: max,
          },
        },
      },
    };
  }, [data.vector, color]);

  if (data.loading) {
    return (
      <div className={styles.container} data-cy="sparkline-loader">
        <ShimmerPlaceholder className={styles.loading} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        {props.name ? <div className={styles.stat}>{props.name}</div> : null}
        <div className={styles.stat}>{formatValue(data.value)}</div>
      </div>
      <div>
        <EnhancedSparkline config={config} sparkline={sparkline} width={160} height={40} theme={theme} />
      </div>
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    left: css`
      flex-grow: 1;
      text-align: right;
    `,
    stat: css`
      padding-right: ${theme.spacing(1)};
      color: ${theme.colors.text.secondary};
      font-weight: ${theme.typography.fontWeightBold};
      white-space: nowrap;
    `,
    container: css`
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: end;
      gap: ${theme.spacing(1)};
    `,
    loading: css`
      margin-bottom: 0;
      width: 160px;
      height: 40px;
    `,
  };
}
