import React from 'react';

import { SceneComponentProps, SceneObjectBase, SceneObjectRef, SceneObjectState } from '@grafana/scenes';
import { Dropdown, Menu, ToolbarButton } from '@grafana/ui';

import { ROUTES } from 'constants/routing';
import { USER } from 'constants/user';
import { trackHostUsageModalOpen } from 'utils/tracking';

import { ExtensionLinks } from './ExtensionLinks/ExtensionLinks';
import { HostUsageInfo } from './HostUsageInfo';

export interface MenuControlState extends SceneObjectState {
  job?: string;
  operation?: string;

  hostUsageInfoRef?: SceneObjectRef<HostUsageInfo>;
}

export class MenuControlScene extends SceneObjectBase<MenuControlState> {
  static Component = MenuControlRenderer;

  constructor(state: MenuControlState) {
    super({ ...state });

    this.addActivationHandler(() => {
      this.setState({
        hostUsageInfoRef: new HostUsageInfo({
          isOpen: false,
          setIsOpen: this.setHostUsageInfoModalOpen.bind(this),
        }).getRef(),
      });
    });
  }

  setHostUsageInfoModalOpen(isOpen: boolean) {
    this.state.hostUsageInfoRef?.resolve().setState({ isOpen });
  }
}

function MenuControlRenderer({ model }: SceneComponentProps<MenuControlScene>) {
  const { job, operation, hostUsageInfoRef } = model.useState();
  const hostInfoModal = USER.ROLE_FLAG.ADMIN ? hostUsageInfoRef?.resolve() : undefined;

  const menu = (
    <Menu>
      {USER.ROLE_FLAG.ADMIN && (
        <>
          <Menu.Item icon="cog" label="Configuration" role="link" url={ROUTES.configuration()} />
          <Menu.Item
            label="Usage info"
            icon="info-circle"
            onClick={() => {
              model.setHostUsageInfoModalOpen(true);
              trackHostUsageModalOpen();
            }}
          />
          <Menu.Divider />
        </>
      )}

      {job && <ExtensionLinks job={job} operation={operation} />}

      <Menu.Item
        icon="external-link-alt"
        label="Community Slack"
        target="_blank"
        url="https://grafana.slack.com/archives/C05E87XRK3J"
        role="link"
      />
      <Menu.Item
        icon="external-link-alt"
        label="Documentation"
        target="_blank"
        url="https://grafana.com/docs/grafana-cloud/monitor-applications/application-observability/"
        role="link"
      />
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu}>
        <ToolbarButton
          aria-label="Actions"
          variant="canvas"
          icon="ellipsis-h"
          iconOnly
          data-fs-element="App o11y - More options menu"
        />
      </Dropdown>

      {hostInfoModal && <hostInfoModal.Component model={hostInfoModal} />}
    </>
  );
}
