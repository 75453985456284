import { getLanguageProviderService } from 'services/LanguageProviderService';
import { LokiQuery } from 'types/queries';

export function isValidQuery(query: LokiQuery | undefined | null, baseExpr: string | undefined): boolean {
  if (!query || !query.expr) {
    return false;
  }

  const languageProvider = getLanguageProviderService().lokiLanguageProvider;

  const abstractQuery = languageProvider.exportToAbstractQuery(query);
  const baseAbstractQuery = languageProvider.exportToAbstractQuery({ expr: baseExpr, refId: 'foo' });

  // check that query has all the same stream selectors as base expr
  if (baseAbstractQuery.labelMatchers.length) {
    return baseAbstractQuery.labelMatchers.every(
      (baseMatcher) =>
        !!abstractQuery.labelMatchers.find(
          (matcher) =>
            matcher.name === baseMatcher.name &&
            matcher.value === baseMatcher.value &&
            matcher.operator === baseMatcher.operator
        )
    );
  }

  return false;
}
