import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Text, TextLink, useStyles2 } from '@grafana/ui';

import { AddServiceControl } from 'components/AddServiceControl';
import { TRACKING_EVENTS } from 'constants/tracking';
import grotHappy from 'img/logos/grot-happy.svg';
import grotSearch from 'img/logos/grot-search.svg';

export interface EmptyStateProps {
  mode:
    | 'noResults' // No results due to filtering, we don't show the add service button
    | 'noServicesYet'; // No results detected, we show the add service button
}

export function EmptyState({ mode }: EmptyStateProps) {
  const styles = useStyles2(getStyles);

  if (mode === 'noResults') {
    return (
      <div data-cy="services-not-found" className={styles.wrapper}>
        <div className={styles.container}>
          <img width={160} height={160} src={grotSearch} alt="Grafana's mascot, Grot" />

          <h2 className={styles.title}>No services found</h2>

          <Text variant="body" color="secondary" textAlignment="center">
            Try editing your applied filters and time range
          </Text>
        </div>
      </div>
    );
  }

  return (
    <div data-cy="no-services-added-yet" className={styles.wrapper}>
      <div className={styles.container}>
        <img width={160} height={160} src={grotHappy} alt="Grafana's mascot, Grot" />

        <h2 className={styles.title}>Connect your data</h2>

        <Text variant="body" color="secondary" textAlignment="center">
          Connect the data from your instrumented services to view them in Application Observability.{' '}
          <TextLink
            href="https://grafana.com/docs/grafana-cloud/monitor-applications/application-observability/"
            inline
            external
          >
            Documentation
          </TextLink>
        </Text>

        <AddServiceControl buttonSize="lg" eventName={TRACKING_EVENTS.EMPTY_SCREEN_ADD_SERVICE_CLICK} />
      </div>
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    wrapper: css`
      padding: ${theme.spacing(9)} 0;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    `,
    container: css`
      width: 100%;
      display: flex;
      gap: ${theme.spacing(3)};
      flex-direction: column;
      align-items: center;
      justify-content: center;

      max-width: 500px;
    `,
    title: css`
      margin-bottom: 0;
    `,
  };
}
