import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Unsubscribable } from 'rxjs';

import { useReturnToPrevious } from '@grafana/runtime';
import {
  SceneComponentProps,
  sceneGraph,
  SceneObjectBase,
  SceneObjectRef,
  SceneObjectState,
  SceneObjectUrlValues,
  SceneTimeRange,
} from '@grafana/scenes';
import { LinkButton } from '@grafana/ui';

import { ASSERTS_PLUGIN_ID } from 'constants/plugin';
import { ROUTES } from 'constants/routing';
import { ENVIRONMENT_VALUE_NAME, FILTER_BY_NAME, GROUP_BY_NAME } from 'constants/variables';
import { useLinkBuilder } from 'hooks/useLinkBuilder';
import { encodeURIParameter } from 'utils/routing';

export interface OpenInAppO11yControlState extends SceneObjectState {
  job: string;
  timeRange: SceneObjectRef<SceneTimeRange>;

  filterByUrlState?: SceneObjectUrlValues;
  groupByUrlState?: SceneObjectUrlValues;
  environmentUrlState?: SceneObjectUrlValues;
}

export class OpenInAppO11yControl extends SceneObjectBase<OpenInAppO11yControlState> {
  static Component = OpenInAppO11yControlRenderer;

  constructor({ job, timeRange }: OpenInAppO11yControlState) {
    super({ job: encodeURIParameter(job), timeRange });

    this.addActivationHandler(() => {
      const filterByVariable = sceneGraph.lookupVariable(FILTER_BY_NAME, this);
      const groupByVariable = sceneGraph.lookupVariable(GROUP_BY_NAME, this);
      const environmentVariable = sceneGraph.lookupVariable(ENVIRONMENT_VALUE_NAME, this);

      const unsubscribables: Array<Unsubscribable | undefined> = [
        filterByVariable?.subscribeToState(() => {
          this.setState({ filterByUrlState: filterByVariable?.urlSync?.getUrlState() });
        }),
        groupByVariable?.subscribeToState(() => {
          this.setState({ groupByUrlState: groupByVariable?.urlSync?.getUrlState() });
        }),
        environmentVariable?.subscribeToState(() => {
          this.setState({
            environmentUrlState: environmentVariable?.urlSync?.getUrlState(),
          });
        }),
      ];

      return () => {
        unsubscribables.forEach((unsub) => unsub?.unsubscribe());
      };
    });
  }
}

function OpenInAppO11yControlRenderer({ model }: SceneComponentProps<OpenInAppO11yControl>) {
  const { job, timeRange, filterByUrlState, groupByUrlState, environmentUrlState } = model.useState();
  const { from, to } = timeRange.resolve().useState();

  const linkBuilder = useLinkBuilder();
  const location = useLocation();
  const setReturnToPrevious = useReturnToPrevious();

  const onClick = useCallback(() => {
    const { pathname, search } = location;
    const baseAssertsPath = `/a/${ASSERTS_PLUGIN_ID}`;
    const routes: Record<string, string> = {
      [`${baseAssertsPath}/assertions`]: 'RCA workbench',
      [`${baseAssertsPath}/entities`]: 'Entities explorer',
      [`${baseAssertsPath}/top-insights`]: 'Top insights',
    };
    const title = routes[pathname] || 'Asserts';

    setReturnToPrevious(title, decodeURI(pathname + search));
  }, [location, setReturnToPrevious]);

  return (
    <LinkButton
      role="link"
      variant="secondary"
      href={linkBuilder(ROUTES.overview(job), {
        from,
        to,
        ...filterByUrlState,
        ...groupByUrlState,
        ...environmentUrlState,
      })}
      onClick={onClick}
    >
      Open in Application Observability
    </LinkButton>
  );
}
