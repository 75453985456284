import { DataQuery } from '@grafana/schema';

import { LokiQuery } from 'types/queries';

export function wrapLokiQueryForVolume(expr: string): string {
  return `sum by(level) (count_over_time(${expr} [$__interval]))`;
}

export function createLokiVolumeQueryFromBaseQuery(query: LokiQuery): LokiQuery {
  return {
    expr: wrapLokiQueryForVolume(query.expr!),
    queryType: 'range',
    refId: 'serviceLogsVolume',
  } as DataQuery;
}
