import { EmbeddedScene, SceneFlexLayout, SceneRouteMatch } from '@grafana/scenes';

import { ProfilesScene } from 'modules/service/components/ProfilesScene/ProfilesScene';
import { ServiceEmbeddedScene } from 'modules/service/components/ServiceEmbeddedScene';
import { makeVariables } from 'utils/variables';

export function makeServiceProfilesScene(job: string): (routeMatch: SceneRouteMatch<any>) => EmbeddedScene {
  return () =>
    new ServiceEmbeddedScene({
      $variables: makeVariables({ usePyroscope: true }),

      body: new SceneFlexLayout({
        direction: 'column',
        children: [new ProfilesScene({ job })],
      }),
    }) as unknown as EmbeddedScene;
}
