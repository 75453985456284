import { SceneQueryRunner } from '@grafana/scenes';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { PROMETHEUS_DS } from 'constants/datasources';
import { DEFAULT_FIELD_CONFIG } from 'constants/panels';

export function makeServiceJvmHeapMemoryPanel(): ExplorablePanel {
  return new ExplorablePanel({
    description: 'How much of the available heap memory is used',

    title: 'Heap Memory Utilization',

    trackingSection: 'service',
    trackingPage: 'jvm',
    trackingPanel: 'heap-memory',

    fieldConfig: {
      defaults: {
        ...DEFAULT_FIELD_CONFIG,
        unit: 'percentunit',
      },
      overrides: [],
    },

    options: {
      tooltip: {
        mode: 'multi',
      },
    },

    $data: new SceneQueryRunner({
      datasource: PROMETHEUS_DS,
      maxDataPointsFromWidth: true,
      queries: [
        {
          expr: '(sum by (instance) ({__name__=~"jvm_memory_used|jvm_memory_used_bytes", job="${job}", jvm_memory_type="heap", instance=~"${instance:regex}"}) / on (instance) sum by (instance) ({__name__=~"jvm_memory_limit|jvm_memory_limit_bytes", job="${job}", jvm_memory_type="heap", instance=~"${instance:regex}"}))\nor\n(sum by (instance) ({__name__=~"process_runtime_jvm_memory_usage|process_runtime_jvm_memory_usage_bytes", job="${job}", type="heap", instance=~"${instance:regex}"}) / on (instance) sum by (instance) ({__name__=~"process_runtime_jvm_memory_limit|process_runtime_jvm_memory_limit_bytes", job="${job}", type="heap", instance=~"${instance:regex}"}))\nor\n(sum by (instance) ({__name__=~"jvm_memory_used|jvm_memory_used_bytes", job="${job}", area="heap", instance=~"${instance:regex}"}) / on (instance) sum by (instance) ({__name__=~"jvm_memory_max|jvm_memory_max_bytes", job="${job}", area="heap", instance=~"${instance:regex}"}))',
          legendFormat: '{{instance}}',
          refId: 'serviceJvmHeapMemoryUsed',
        },
      ],
    }),
  });
}
