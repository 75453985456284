import { AttributeFilter } from 'components/FilterByVariable';
import { OVERRIDES_HISTOGRAM_BUCKETS } from 'constants/overrides';
import {
  TRACEQL_SEARCH_DURATION_TAG,
  TRACEQL_SEARCH_ENVIRONMENT_ID,
  TRACEQL_SEARCH_MAX_DURATION_ID,
  TRACEQL_SEARCH_MIN_DURATION_ID,
  TRACEQL_SEARCH_OPERATION_ID,
  TRACEQL_SEARCH_OPERATION_TAG,
  TRACEQL_SEARCH_SCOPE,
  TRACEQL_SEARCH_SERVICE_NAME_ID,
  TRACEQL_SEARCH_SERVICE_NAME_TAG,
  TRACEQL_SEARCH_SERVICE_NAMESPACE_ID,
  TRACEQL_SEARCH_SERVICE_NAMESPACE_TAG,
  TRACEQL_SEARCH_STATUS_ID,
  TRACEQL_SEARCH_STATUS_TAG,
  TRACEQL_SEARCH_TYPE,
} from 'constants/query';
import { TempoQuery } from 'types/queries';
import { hasEnvironmentAttribute } from 'utils/environmentFilter';
import { dashToDot } from 'utils/format';
import { jobContainsNamespace } from 'utils/services';

function formatDuration(duration: number | string): string {
  if (typeof duration === 'number') {
    // find largest bucket thats <= duration
    const minBucket = Math.max(...OVERRIDES_HISTOGRAM_BUCKETS.filter((bucket) => bucket <= duration));
    return `${minBucket}s`;
  }

  return duration;
}

export const SERVICE_NAME_TRACEQL_FILTER = 'resource.service.name="${serviceName}"';

export function createTraceQlQuery(
  withNamespace?: boolean,
  withOperation?: boolean,
  withEnvironmentFilter?: boolean,
  withError = false,
  minDuration?: number | string,
  maxDuration?: number | string,
  additionalFilters?: string
): string {
  const components = [SERVICE_NAME_TRACEQL_FILTER];

  if (withEnvironmentFilter) {
    components.push('resource.${environmentAttribute:dotted}=~"${environmentValue:regex}"');
  }

  if (withNamespace) {
    components.push('resource.service.namespace="${serviceNamespace}"');
  }

  if (withOperation) {
    components.push('name="${operation}"');
  }

  if (withError) {
    components.push('status=error');
  }

  if (minDuration !== undefined) {
    components.push(`duration >= ${formatDuration(minDuration)}`);
  }

  if (maxDuration !== undefined) {
    components.push(`duration <= ${formatDuration(maxDuration)}`);
  }

  return `{${components.join(' && ')}}${additionalFilters ? ` && {${additionalFilters}}` : ''}`;
}

export function getTempoQuery(
  job: string,
  operation: string | undefined,
  queryType = TRACEQL_SEARCH_TYPE,
  withError = false,
  minDuration?: number | string,
  maxDuration?: number | string,
  additionalFilters?: { traceql: string; search: AttributeFilter[] }
): TempoQuery {
  const withNamespace = jobContainsNamespace(job);
  const withOperation = !!operation;
  const withEnvironmentFilter = hasEnvironmentAttribute();

  return {
    queryType,
    refId: 'A',
    query: createTraceQlQuery(
      withNamespace,
      withOperation,
      withEnvironmentFilter,
      withError,
      minDuration,
      maxDuration,
      additionalFilters?.traceql
    ),
    limit: 20,
    filters: [
      {
        id: TRACEQL_SEARCH_SERVICE_NAME_ID,
        operator: '=',
        scope: TRACEQL_SEARCH_SCOPE.RESOURCE,
        tag: TRACEQL_SEARCH_SERVICE_NAME_TAG,
        value: ['${serviceName}'],
        valueType: 'string',
      },
      ...(withEnvironmentFilter
        ? [
            {
              id: TRACEQL_SEARCH_ENVIRONMENT_ID,
              operator: '=~',
              scope: TRACEQL_SEARCH_SCOPE.RESOURCE,
              tag: '${environmentAttribute:dotted}',
              value: ['${environmentValue:regex}'],
              valueType: 'string',
            },
          ]
        : []),
      ...(withNamespace
        ? [
            {
              id: TRACEQL_SEARCH_SERVICE_NAMESPACE_ID,
              operator: '=',
              scope: TRACEQL_SEARCH_SCOPE.RESOURCE,
              tag: TRACEQL_SEARCH_SERVICE_NAMESPACE_TAG,
              value: ['${serviceNamespace}'],
              valueType: 'string',
            },
          ]
        : []),
      ...(withOperation
        ? [
            {
              id: TRACEQL_SEARCH_OPERATION_ID,
              operator: '=',
              scope: TRACEQL_SEARCH_SCOPE.SPAN,
              tag: TRACEQL_SEARCH_OPERATION_TAG,
              value: ['${operation}'],
              valueType: 'string',
            },
          ]
        : []),
      ...(withError
        ? [
            {
              id: TRACEQL_SEARCH_STATUS_ID,
              tag: TRACEQL_SEARCH_STATUS_TAG,
              scope: TRACEQL_SEARCH_SCOPE.INTRINSIC,
              operator: '=',
              value: 'error',
              valueType: 'keyword',
            },
          ]
        : []),
      ...(minDuration
        ? [
            {
              id: TRACEQL_SEARCH_MIN_DURATION_ID,
              tag: TRACEQL_SEARCH_DURATION_TAG,
              operator: '>=',
              valueType: 'duration',
              value: formatDuration(minDuration),
            },
          ]
        : []),
      ...(maxDuration
        ? [
            {
              id: TRACEQL_SEARCH_MAX_DURATION_ID,
              tag: TRACEQL_SEARCH_DURATION_TAG,
              operator: '<=',
              valueType: 'duration',
              value: formatDuration(maxDuration),
            },
          ]
        : []),
      ...(additionalFilters?.search || []).map((filter, idx) => ({
        id: `${filter.key}.${idx}`,
        tag: dashToDot(filter.key),
        operator: filter.operator,
        valueType: 'string',
        value: filter.value,
        scope: TRACEQL_SEARCH_SCOPE.RESOURCE,
      })),
    ],
  };
}
