import React, { useState } from 'react';

import { Button, Modal, Text, useStyles2 } from '@grafana/ui';

import { getCommonStyles } from '../styles';

export interface SetNonTempoSourceModalProps {
  isOpen: boolean;
  selectedSource?: string;

  onConfirm: () => Promise<void>;
  onConfirmSecondary: () => Promise<void>;
  onDismiss: () => void;
}

export const SetNonTempoSourceModal = ({
  isOpen,
  selectedSource,
  onDismiss,
  onConfirm,
  onConfirmSecondary,
}: SetNonTempoSourceModalProps) => {
  const commonStyles = useStyles2(getCommonStyles);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoadingPrimary, setIsLoadingPrimary] = useState(false);
  const [isLoadingSecondary, setIsLoadingSecondary] = useState(false);

  return (
    <Modal
      closeOnBackdropClick
      closeOnEscape
      onDismiss={onDismiss}
      className={commonStyles.modal}
      title="Update span metrics source"
      isOpen={isOpen}
    >
      <Text element="p">You&lsquo;re updating the span metrics source to: {selectedSource}</Text>
      <br />
      <Text element="p">
        Changing away from Tempo span metrics source means Application Observability will no longer use Tempo metrics
        generation. You might want to deactivate it to avoid unnecessary costs.
      </Text>
      <br />

      <div className={commonStyles.buttonsContainer}>
        <Button
          variant="secondary"
          fill="outline"
          onClick={onDismiss}
          disabled={isSaving}
          data-cy="close-activate-button"
        >
          Cancel
        </Button>

        <Button
          type="submit"
          variant="secondary"
          disabled={isSaving}
          onClick={() => {
            setIsSaving(true);
            setIsLoadingSecondary(true);
            onConfirmSecondary().finally(() => {
              setIsSaving(false);
              setIsLoadingSecondary(false);
              onDismiss();
            });
          }}
          className={commonStyles.activateButton}
          data-cy="perform-activate-button"
          icon={isLoadingSecondary ? 'spinner' : undefined}
        >
          Update without deactivating
        </Button>

        <Button
          type="submit"
          variant="primary"
          disabled={isSaving}
          onClick={() => {
            setIsSaving(true);
            setIsLoadingPrimary(true);
            onConfirm().finally(() => {
              setIsSaving(false);
              setIsLoadingPrimary(false);
              onDismiss();
            });
          }}
          className={commonStyles.activateButton}
          data-cy="perform-activate-button"
          icon={isLoadingPrimary ? 'spinner' : undefined}
        >
          Update and deactivate
        </Button>
      </div>
    </Modal>
  );
};
