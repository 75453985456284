import {
  SceneObjectBase,
  SceneVariable,
  SceneVariableState,
  SceneVariableValueChangedEvent,
  VariableValue,
} from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';

import { isValidMetricName, METRIC_NAMES, MetricNamesDefinition } from 'constants/metricNames';
import { METRICS_MODE_NAME } from 'constants/variables';
import { MetricsMode } from 'types/settings';
import { getMetricsMode } from 'utils/metricsMode';

export interface MetricsModeState extends SceneVariableState {
  value: VariableValue;
}

export class MetricsModeVariable extends SceneObjectBase<MetricsModeState> implements SceneVariable<MetricsModeState> {
  constructor() {
    super({
      type: 'constant',
      name: METRICS_MODE_NAME,
      label: 'Metrics mode',
      hide: VariableHide.hideVariable,
      skipUrlSync: true,
      value: getMetricsMode(),
    });
  }

  getValue(): VariableValue {
    return {
      formatter: (formatNameOrFn: keyof MetricNamesDefinition[MetricsMode]) => {
        const value = this.state.value as MetricsMode | undefined;

        if (!value && !isValidMetricName(value)) {
          throw new Error('Invalid metric name');
        }

        return this.getMetricName(value, formatNameOrFn);
      },
    };
  }

  update(value: MetricsMode) {
    this.setState({ value });

    this.publishEvent(new SceneVariableValueChangedEvent(this), true);
  }

  private getMetricName(value: MetricsMode, metricName: keyof MetricNamesDefinition[MetricsMode]) {
    return METRIC_NAMES[value][metricName];
  }
}
