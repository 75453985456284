import { PRESERVE_URL_KEYS, ROUTE_DEFINITIONS, ROUTES } from 'constants/routing';
import { TrackedSceneAppPage } from 'faro/TrackedSceneAppPage';

import { makeOperationOverviewScene } from './makeOperationOverviewScene';

export function makeOperationOverviewPage(
  job: string,
  encodedJob: string,
  operation: string,
  encodedOperation: string
): TrackedSceneAppPage {
  return new TrackedSceneAppPage({
    viewName: 'operation-overview',
    title: 'Overview',
    routePath: ROUTE_DEFINITIONS.operation,
    url: ROUTES.operation(encodedJob, encodedOperation),
    preserveUrlKeys: PRESERVE_URL_KEYS,
    getScene: makeOperationOverviewScene(job, operation),
  });
}
