import { PRESERVE_URL_KEYS, ROUTE_DEFINITIONS, ROUTES } from 'constants/routing';
import { TrackedSceneAppPage } from 'faro/TrackedSceneAppPage';

import { makeServiceOverviewScene } from './makeServiceOverviewScene';

export function makeServiceOverviewPage(job: string, encodedJob: string, isInstrumented: boolean): TrackedSceneAppPage {
  return new TrackedSceneAppPage({
    viewName: 'service-overview',
    title: 'Overview',
    routePath: ROUTE_DEFINITIONS.overview,
    url: ROUTES.overview(encodedJob),
    preserveUrlKeys: PRESERVE_URL_KEYS,
    getScene: makeServiceOverviewScene(job, encodedJob, isInstrumented),
  });
}
