import { BusEventBase, BusEventWithPayload, DataFrame } from '@grafana/data';

export class NewMetadataFetchedEvent extends BusEventWithPayload<{
  isInstrumented: boolean;
  baselineEnabled: boolean;
  frames: DataFrame[];
  job: string;
}> {
  static type = 'app-o11y-new-metadata-fetched';
}

export class FilterByVariableUpdate extends BusEventBase {
  static type = 'filter-by-variable-update';
}

export class BaselineCompareUpdate extends BusEventBase {
  static type = 'baseline-compare-update';
}

export class TabChange extends BusEventWithPayload<{
  tab: 'traces' | 'logs';
  query: string | undefined;
  from?: number;
  to?: number;
}> {
  static type = 'tab-change';
}
