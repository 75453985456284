import React from 'react';

import { SceneObjectRef, SceneReactObject } from '@grafana/scenes';

import { AddServiceControl } from 'components/AddServiceControl';
import { HostModalScene } from 'components/HostModal';
import { MenuControlScene } from 'components/MenuControl';
import { NotificationScene } from 'components/Notification';
import { Subtitle } from 'components/Subtitle';
import { PRESERVE_URL_KEYS, ROUTE_DEFINITIONS, ROUTES } from 'constants/routing';
import { TrackedSceneAppPage } from 'faro/TrackedSceneAppPage';
import { makeServicePage } from 'modules/service/makeServicePage';
import { makeServiceMapPage } from 'modules/serviceMap/makeServiceMapPage';
import { makeCommonControls } from 'utils/controls';
import { makeTimeRange } from 'utils/timeRange';

import { makeServicesScene } from './makeServicesScene';

export const SERVICES_VIEW_NAME = 'services';

export function makeServicesPage(
  notificationRef: SceneObjectRef<NotificationScene>,
  hostModalRef: SceneObjectRef<HostModalScene>
): TrackedSceneAppPage {
  return new TrackedSceneAppPage({
    viewName: SERVICES_VIEW_NAME,
    title: 'Services',
    subTitle: <Subtitle notificationRef={notificationRef} hostModalRef={hostModalRef} />,
    url: ROUTES.services(),
    preserveUrlKeys: PRESERVE_URL_KEYS,
    getScene: makeServicesScene(),

    $timeRange: makeTimeRange(),

    controls: [
      ...makeCommonControls(false),
      new MenuControlScene({}),
      new SceneReactObject({ component: AddServiceControl }),
    ],

    tabs: [
      new TrackedSceneAppPage({
        viewName: 'services-view',
        title: 'Services',
        url: ROUTES.services(),
        hideFromBreadcrumbs: true,
        preserveUrlKeys: PRESERVE_URL_KEYS,
        getScene: makeServicesScene(),

        drilldowns: [
          {
            routePath: ROUTE_DEFINITIONS.overviewWithTab,
            getPage: (routeMatch, parent) => makeServicePage(routeMatch, parent, notificationRef, hostModalRef),
          },
          {
            routePath: ROUTE_DEFINITIONS.overview,
            getPage: (routeMatch, parent) => makeServicePage(routeMatch, parent, notificationRef, hostModalRef),
          },
        ],
      }),
      makeServiceMapPage(notificationRef, hostModalRef),
    ],
  });
}
