import { css } from '@emotion/css';
import React, { useCallback, useState } from 'react';

import { AppEvents, GrafanaTheme2 } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Checkbox, Modal, Spinner, useStyles2 } from '@grafana/ui';

import { ACCESS_CONTROL } from 'constants/accessControl';
import { getFaro } from 'faro/instance';
import { getOverridesService } from 'services/OverridesService';
import { trackOptoutAcknowledge, trackOptoutDeactivate } from 'utils/tracking';

export interface DeactivateModalProps {
  isOpened: boolean;
  onClose: () => void;
  onDeactivate: () => void;
}

export const DeactivateModal = ({ isOpened, onClose, onDeactivate }: DeactivateModalProps) => {
  const styles = useStyles2(getStyles);

  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [isDeactivating, setIsDeactivating] = useState(false);

  const toggleModal = () => {
    setIsAcknowledged(false);
    onClose();
  };

  const deactivate = useCallback(async () => {
    setIsDeactivating(true);

    try {
      await getOverridesService().removeOverrides();

      getAppEvents().publish({
        type: AppEvents.alertSuccess.name,
        payload: ['Tempo metrics generator overrides removed.'],
      });

      trackOptoutDeactivate();

      onDeactivate();
    } catch (err) {
      getFaro()?.api.pushError(
        err instanceof Error ? err : new Error('Could not deactivate Application Observability')
      );

      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [(err as Error)?.message ?? 'Could not remove overrides, try again later'],
      });
    } finally {
      setIsDeactivating(false);
    }
  }, [onDeactivate]);

  return (
    <Modal
      title="Deactivate Application Observability"
      isOpen={isOpened}
      closeOnBackdropClick={!isDeactivating}
      closeOnEscape={!isDeactivating}
      onDismiss={() => {
        if (isDeactivating) {
          return;
        }

        toggleModal();
      }}
    >
      <p>Are you sure you want to deactivate Application Observability?</p>

      <div
        className={styles.acknowledgeContainer}
        onClick={() => {
          setIsAcknowledged(!isAcknowledged);

          if (!isAcknowledged) {
            trackOptoutAcknowledge();
          }
        }}
        data-cy="deactivate-modal"
      >
        <Checkbox
          value={isAcknowledged}
          className={styles.acknowledgeCheckbox}
          data-cy="acknowledge-deactivate-checkbox"
          onClick={(evt) => evt.stopPropagation()}
        />{' '}
        I understand that this will stop generating metrics from traces in Grafana Cloud.
      </div>

      <div className={styles.buttonsContainer}>
        <Button variant="secondary" onClick={toggleModal} disabled={isDeactivating} data-cy="close-deactivate-button">
          Cancel
        </Button>

        <Button
          variant="destructive"
          icon={!isDeactivating ? 'angle-right' : undefined}
          disabled={isDeactivating || !ACCESS_CONTROL.PLUGIN.INITIALIZE || !isAcknowledged}
          className={styles.activateButton}
          data-cy="perform-deactivate-button"
          onClick={() => {
            deactivate();
          }}
        >
          {isDeactivating ? (
            <>
              <Spinner className={styles.activateButtonIcon} inline />
              Deactivating
            </>
          ) : (
            <>Deactivate</>
          )}
        </Button>
      </div>
    </Modal>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  acknowledgeContainer: css`
    cursor: pointer;
    user-select: none;
  `,

  acknowledgeCheckbox: css`
    margin-right: ${theme.spacing(1)};
  `,

  buttonsContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: ${theme.spacing(2)} 0 0 0;
  `,

  activateButton: css`
    min-width: 122px;
    margin: 0 0 0 ${theme.spacing(1)};
  `,
  activateButtonIcon: css`
    margin-right: ${theme.spacing(0.5)};
  `,
});
