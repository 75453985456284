import { css } from '@emotion/css';
import { isEqual } from 'lodash';
import React from 'react';
import { Unsubscribable } from 'rxjs';

import { GrafanaTheme2, ScopedVars } from '@grafana/data';
import {
  SceneComponentProps,
  sceneGraph,
  SceneLayout,
  SceneObjectBase,
  SceneObjectRef,
  SceneObjectState,
  SceneObjectUrlSyncConfig,
  SceneObjectUrlValues,
  SceneQueryRunner,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { Collapse, useStyles2 } from '@grafana/ui';

import { EnvironmentValueVariable } from 'components/EnvironmentValueVariable';
import { LOKI_DS } from 'constants/datasources';
import { LogQueryMode } from 'constants/logs';
import { MIN_PANEL_HEIGHT } from 'constants/styles';
import {
  ENVIRONMENT_ATTRIBUTE_NAME,
  ENVIRONMENT_VALUE_NAME,
  JOB_NAME,
  LOKI_DS_NAME,
  NONE_VARIABLE_REGEX,
  NONE_VARIABLE_REGEX_LOKI,
  SERVICE_NAME_NAME,
  SERVICE_NAMESPACE_NAME,
} from 'constants/variables';
import { LogsTracesOpenInExploreButton } from 'modules/service/components/LogsTracesOpenInExploreButton';
import { makeServiceLogsPanel } from 'modules/service/logs/panels/makeServiceLogsPanel';
import { makeServiceLogsVolumePanel } from 'modules/service/logs/panels/makeServiceLogsVolumePanel';
import { isValidQuery } from 'modules/service/logs/utils';
import { createLokiVolumeQueryFromBaseQuery } from 'queries/loki';
import { getPluginConfigService } from 'services/PluginConfigService';
import { LokiQuery } from 'types/queries';
import { hasEnvironmentAttribute } from 'utils/environmentFilter';
import { row } from 'utils/layout';
import { getDefaultLogQueryValues } from 'utils/logs';
import { jobContainsNamespace } from 'utils/services';

import { InternalServiceLogsSearchEditorState, ServiceLogsSearchEditor } from './ServiceLogsSearchEditor';

export interface ServiceLogsSceneState {
  job: string;
  encodedJob: string;
}

export interface InternalServiceLogsSceneState extends SceneObjectState {
  baseExpr: string;
  initialized: boolean;
  isVolumeOpened: boolean;
  job: string;
  encodedJob: string;
  defaultLogQueryMode: LogQueryMode;
  logQueryMode: LogQueryMode;

  traceID?: string;
  spanID?: string;
  explore?: LogsTracesOpenInExploreButton;
  logs?: SceneLayout;
  query?: LokiQuery;
  queryRunnerRef?: SceneObjectRef<SceneQueryRunner>;
  searchEditor?: ServiceLogsSearchEditor;
  volume?: SceneLayout;
  volumeQuery?: LokiQuery;
  volumeQueryRunnerRef?: SceneObjectRef<SceneQueryRunner>;
}

export class ServiceLogsScene extends SceneObjectBase<InternalServiceLogsSceneState> {
  static Component = ServiceLogsSceneRenderer;

  protected _urlSync = new SceneObjectUrlSyncConfig(this, { keys: ['logsQuery', 'traceID', 'spanID'] });

  protected _variableDependency = new VariableDependencyConfig(this, {
    variableNames: [
      JOB_NAME,
      SERVICE_NAME_NAME,
      SERVICE_NAMESPACE_NAME,
      ENVIRONMENT_ATTRIBUTE_NAME,
      ENVIRONMENT_VALUE_NAME,
      LOKI_DS_NAME,
    ],
  });

  constructor(state: ServiceLogsSceneState) {
    const { defaultLogQueryMode = LogQueryMode.json } = getPluginConfigService().getPluginConfig();

    super({
      ...state,
      baseExpr: '',
      initialized: false,
      isVolumeOpened: false,
      logQueryMode: defaultLogQueryMode,
      defaultLogQueryMode,
    });

    this.addActivationHandler(() => {
      const unsubscribables: Unsubscribable[] = [];
      if (hasEnvironmentAttribute()) {
        const environmentValueVariable = sceneGraph.lookupVariable(
          ENVIRONMENT_VALUE_NAME,
          this
        ) as EnvironmentValueVariable;

        unsubscribables.push(
          environmentValueVariable?.subscribeToState(() => {
            this.onEnvironmentVariableChanged();
          })
        );
      }
      this.updateOrInitialize(this.state.query);

      return () => {
        unsubscribables.forEach((unsubscribable) => unsubscribable.unsubscribe());
      };
    });
  }

  getUrlState() {
    return { logsQuery: JSON.stringify(this.state.query), traceID: this.state.traceID, spanID: this.state.spanID };
  }

  updateFromUrl(values: SceneObjectUrlValues) {
    this.setState({
      traceID: Object.hasOwnProperty.call(values, 'traceID')
        ? values.traceID
          ? String(values.traceID)
          : undefined
        : this.state.traceID,
      spanID: Object.hasOwnProperty.call(values, 'spanID')
        ? values.spanID
          ? String(values.spanID)
          : undefined
        : this.state.spanID,
    });
    this.updateOrInitialize(
      typeof values.logsQuery === 'string' ? JSON.parse(decodeURIComponent(values.logsQuery)) : undefined
    );
  }

  setLiveStreaming(liveStreaming: boolean) {
    const queryRunner = this.state.queryRunnerRef?.resolve();

    queryRunner?.setState({
      queries: this.state.query ? [this.state.query] : [],
      liveStreaming,
    });
    queryRunner?.runQueries();
  }

  setLogQueryMode(logQueryMode: LogQueryMode) {
    this.setState({ logQueryMode });

    this.updateOrInitialize(this.getComputedQuery());
  }

  private interpolate(value: string): string {
    const scopedVars: ScopedVars = {
      traceIDFilter: { value: this.state.traceID ? ` |="${this.state.traceID}" ` : '' },
      spanIDFilter: { value: this.state.spanID ? ` |="${this.state.spanID}" ` : '' },
    };

    return sceneGraph.interpolate(this, value, scopedVars, (values, legacyVariableModel) => {
      if (legacyVariableModel.name === ENVIRONMENT_VALUE_NAME) {
        if (values === NONE_VARIABLE_REGEX) {
          return NONE_VARIABLE_REGEX_LOKI;
        } else if (Array.isArray(values)) {
          return new RegExp(
            `${values
              .map((currentValue) => (currentValue === NONE_VARIABLE_REGEX ? NONE_VARIABLE_REGEX_LOKI : currentValue))
              .join('|')}`
          ).source;
        }
      }

      return String(values);
    });
  }

  private getBaseExpr(): string {
    const { defaultLogQueryMode, logQueryMode, job } = this.state;

    const { defaultQueryWithoutNamespace, defaultQueryWithNamespace } = getDefaultLogQueryValues(
      this.state.logQueryMode
    );

    let query = '';

    if (defaultLogQueryMode === logQueryMode) {
      query = this.interpolate(
        jobContainsNamespace(job)
          ? (getPluginConfigService().getPluginConfig().logsQueryWithNamespace ?? defaultQueryWithNamespace)
          : (getPluginConfigService().getPluginConfig().logsQueryWithoutNamespace ?? defaultQueryWithoutNamespace)
      );
    } else {
      query = this.interpolate(jobContainsNamespace(job) ? defaultQueryWithNamespace : defaultQueryWithoutNamespace);
    }

    if (!hasEnvironmentAttribute()) {
      // Replace invalid values with empty string
      query = query.replace('| resources_=~".*"', '');
      query = query.replace('| =~".*"', '');
    }

    return query;
  }

  private getFormattingExpr(): string {
    // logsQueryFormatting can be an empty string or undefined
    // when it's an empty string, it means that the user does not want to apply any formatting
    // when it's undefined, it means that the user did not alter, or they reset the configuration
    const { defaultLogQueryMode, logQueryMode } = this.state;
    const { defaultLogQueryFormatting } = getDefaultLogQueryValues(this.state.logQueryMode);

    if (defaultLogQueryMode === logQueryMode) {
      return this.interpolate(
        getPluginConfigService().getPluginConfig().logsQueryFormatting ?? defaultLogQueryFormatting
      );
    }

    return this.interpolate(defaultLogQueryFormatting);
  }

  private getSpanIdTraceIdFilter(logQueryMode = this.state.logQueryMode) {
    const pluginConfig = getPluginConfigService().getPluginConfig();

    let spanIdTraceIdFilter = '';
    if (this.state.traceID && pluginConfig.logsFilterByTraceId) {
      spanIdTraceIdFilter +=
        logQueryMode === LogQueryMode.json ? `|="${this.state.traceID}" ` : `| trace_id="${this.state.traceID}"`;
    }

    if (this.state.spanID && pluginConfig.logsFilterBySpanId) {
      spanIdTraceIdFilter +=
        logQueryMode === LogQueryMode.json ? `|="${this.state.spanID}" ` : `| span_id="${this.state.spanID}"`;
    }

    return spanIdTraceIdFilter;
  }

  private getComputedQuery(baseExpr = this.getBaseExpr()): LokiQuery {
    const formatting = this.getFormattingExpr();

    const spanIdTraceIdFilter = this.getSpanIdTraceIdFilter();
    return {
      expr: formatting ? `${baseExpr} ${spanIdTraceIdFilter}| ${formatting}` : baseExpr,
      queryType: 'range',
      refId: 'serviceLogs',
    };
  }

  private onEnvironmentVariableChanged() {
    const query = this.state.query;
    if (query) {
      const options = [
        '${environmentAttribute}=~"(\\w*|\\.\\*)"',
        '${environmentAttribute} =~ "(\\w*|\\.\\*)"',
        '${environmentAttribute}=~`(\\w*|\\.\\*)`',
        '${environmentAttribute} =~ `(\\w*|\\.\\*)`',
      ].map((option) => sceneGraph.interpolate(this, option));
      const toBeReplaced = options.find((option) => query?.expr?.match(new RegExp(option))) || '';

      const expression =
        toBeReplaced !== ''
          ? query?.expr?.replace(
              new RegExp(toBeReplaced),
              sceneGraph.interpolate(this, `$\{environmentAttribute}=~"$\{environmentValue}"`)
            )
          : query?.expr;
      if (expression) {
        this.updateOrInitialize({ ...query, expr: expression });
      }
    }
  }

  private updateOrInitialize(newQuery: LokiQuery | undefined | null) {
    const baseExpr = this.getBaseExpr();

    let query: LokiQuery;

    if (isValidQuery(newQuery, baseExpr)) {
      query = newQuery as LokiQuery;
    } else {
      // should not happen (tm) as invalid query shoud be prevented by the editor scene
      query = this.getComputedQuery(baseExpr);
    }

    const volumeQuery = createLokiVolumeQueryFromBaseQuery(query);

    if (this.state.initialized) {
      setTimeout(() => this.update(query, volumeQuery, baseExpr));

      return;
    }

    this.initialize(query, volumeQuery, baseExpr);
  }

  private update(query: LokiQuery, volumeQuery: LokiQuery, baseExpr: string): void {
    const newState: Partial<InternalServiceLogsSceneState> = {};

    if (!isEqual(query, this.state.query)) {
      newState.query = query;
    }

    if (!isEqual(volumeQuery, this.state.volumeQuery)) {
      newState.volumeQuery = volumeQuery;
    }

    if (this.state.baseExpr !== baseExpr) {
      newState.baseExpr = baseExpr;
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState);
    }

    if (!isEqual(this.state.explore?.state.leftQuery, query)) {
      this.state.explore?.setState({ leftQuery: query });
    }

    if (!isEqual(this.state.queryRunnerRef?.resolve().state.queries[0], query)) {
      const queryRunner = this.state.queryRunnerRef?.resolve();

      queryRunner?.cancelQuery();
      queryRunner?.setState({ queries: [query] });
      queryRunner?.runQueries();
    }

    if (!isEqual(this.state.volumeQueryRunnerRef?.resolve().state.queries[0], volumeQuery)) {
      const volumeQueryRunner = this.state.volumeQueryRunnerRef?.resolve();
      volumeQueryRunner?.cancelQuery();
      volumeQueryRunner?.setState({ queries: [volumeQuery] });
      volumeQueryRunner?.runQueries();
    }

    const newSearchEditorState: Partial<InternalServiceLogsSearchEditorState> = {};

    if (!isEqual(this.state.searchEditor?.state.query, query)) {
      newSearchEditorState.query = query;
      newSearchEditorState.isValid = true;
    }

    if (this.state.searchEditor?.state.baseExpr !== baseExpr) {
      newSearchEditorState.baseExpr = baseExpr;
      newSearchEditorState.isValid =
        newSearchEditorState.isValid ?? isValidQuery(this.state.searchEditor?.state.query, baseExpr);
    }

    if (Object.keys(newSearchEditorState).length > 0) {
      clearTimeout(this.state.searchEditor?.state.pendingChanges);

      this.state.searchEditor?.setState(newSearchEditorState);
    }
  }

  private initialize(query: LokiQuery, volumeQuery: LokiQuery, baseExpr: string): void {
    const queryRunner = new SceneQueryRunner({
      datasource: LOKI_DS,
      queries: [query],
      liveStreaming: false,
    });

    const volumeQueryRunner = new SceneQueryRunner({
      datasource: LOKI_DS,
      queries: [volumeQuery],
    });

    this.setState({
      ...this.state,
      baseExpr,
      explore: new LogsTracesOpenInExploreButton({
        dataSourceName: LOKI_DS_NAME,
        leftQuery: query,
        pageName: 'logs',
        sectionName: 'service',
      }),
      initialized: true,
      logs: row({ minHeight: MIN_PANEL_HEIGHT }, makeServiceLogsPanel(queryRunner, this.state.encodedJob)),
      query,
      queryRunnerRef: queryRunner.getRef(),
      searchEditor: new ServiceLogsSearchEditor({
        baseExpr,
        query,
        updateQuery: (query) => this.updateOrInitialize(query),
      }),
      volume: row({ height: 200 }, makeServiceLogsVolumePanel(volumeQueryRunner)),
      volumeQuery,
      volumeQueryRunnerRef: volumeQueryRunner.getRef(),
    });
  }
}

function ServiceLogsSceneRenderer({ model }: SceneComponentProps<ServiceLogsScene>) {
  const styles = useStyles2(getStyles);
  const { explore, initialized, isVolumeOpened, logs, searchEditor, volume } = model.useState();

  if (!initialized) {
    return null;
  }

  const SearchEditor = searchEditor?.Component;
  const OpenInExploreButton = explore?.Component;
  const Volume = volume?.Component;
  const Logs = logs?.Component;

  return (
    <div className={styles.container}>
      <div className={styles.searchEditorContainer}>{SearchEditor && <SearchEditor model={searchEditor} />}</div>

      <div className={styles.openInExploreContainer}>
        {OpenInExploreButton && <OpenInExploreButton model={explore} />}
      </div>

      {Volume && (
        <Collapse
          collapsible
          isOpen={isVolumeOpened}
          label="Logs volume"
          onToggle={() => model.setState({ isVolumeOpened: !isVolumeOpened })}
          className={styles.volumeCollapsibleContainer}
        >
          <div className={styles.volumeContainer} data-cy="logs-volume-container">
            <Volume model={volume} />
          </div>
        </Collapse>
      )}

      <div className={`${styles.logsContainer} fs-mask`} data-cy="logs-container">
        {Logs && <Logs model={logs} />}
      </div>
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    container: css`
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: ${theme.spacing(1)};
      height: 100%;
    `,
    searchEditorContainer: css`
      display: flex;
      flex-direction: column;
    `,
    openInExploreContainer: css`
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    `,
    volumeCollapsibleContainer: css`
      flex: 0 1 0;
    `,
    volumeContainer: css`
      display: flex;

      & [class$='panel-container'] {
        border: 0;

        & [class$='panel-header'] {
          display: none;
        }
      }
    `,
    logsContainer: css`
      flex: 1;
      display: flex;
    `,
  };
}
