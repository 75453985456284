import { getDataSourceSrv } from '@grafana/runtime';
import { sceneGraph, SceneObject, VariableValue } from '@grafana/scenes';

import { PROVISIONED_DS_UIDS } from 'constants/datasources';
import { DS_NAME, DS_TYPE } from 'types/datasources';

export function getSelectedDataSourceName(obj: SceneObject, name: DS_NAME): string | undefined {
  const val = sceneGraph.lookupVariable(name, obj)?.getValue();

  if (typeof val === 'string') {
    return val;
  }

  return undefined;
}

export function isProvisionedDataSource(dsUid: string, type: DS_TYPE): boolean {
  if (!dsUid) {
    return false;
  }

  return dsUid === PROVISIONED_DS_UIDS[type];
}

export function getDataSourceUidFromText(text: VariableValue | undefined, type: DS_TYPE): string | undefined {
  if (typeof text === 'object' || !text) {
    return undefined;
  }

  const datasources = getDataSourceSrv().getList({
    type,
    filter: (ds) => ds.name === text,
  });

  return datasources[0]?.uid;
}
