import { ExplorablePanel } from 'components/ExplorablePanel';
import { PanelType } from 'constants/panels';
import { FILTER_BY_NAME } from 'constants/variables';
import { hasEnvironmentAttribute } from 'utils/environmentFilter';
import { jobContainsNamespace } from 'utils/services';

import { makeServiceOverviewRelationshipPanel } from './makeServiceOverviewRelationshipPanel';

export function makeServiceOverviewUpstreamPanel(job: string, operation?: string): ExplorablePanel {
  const namespaceFilter = jobContainsNamespace(job) ? ', server_service_namespace="${serviceNamespace}"' : '';

  const environmentFilter = hasEnvironmentAttribute()
    ? ', server_${environmentAttribute}=~"${environmentValue:regex}"'
    : '';
  const filterByFilter = `$\{${FILTER_BY_NAME}:upstreamAppend}`;

  const rateQuery = `sum(rate($\{metricName:serviceGraphTotalRequests}{server="$\{serviceName}"${environmentFilter}${namespaceFilter}${filterByFilter}, client!="user"} [$__rate_interval])) by (client, client_service_namespace)`;
  const errorQuery = `sum(rate($\{metricName:serviceGraphFailedTotalRequests}{server="$\{serviceName}"${environmentFilter}${namespaceFilter}${filterByFilter}, client!="user"} [$__rate_interval])) by (client, client_service_namespace)`;
  const totalQuery = `sum(rate($\{metricName:serviceGraphTotalRequests}{server="$\{serviceName}"${environmentFilter}${namespaceFilter}${filterByFilter}, client!="user"} [$__rate_interval])) by (client, client_service_namespace)`;
  const durationQuery = `histogram_quantile(0.95, sum(rate($\{metricName:serviceGraphRequestServerSecondsBucket}{server="$\{serviceName}"${environmentFilter}${namespaceFilter}${filterByFilter}, client!="user"} [$__rate_interval])) by (le, client, client_service_namespace))`;

  return makeServiceOverviewRelationshipPanel({
    job,
    operation,
    refIdSuffix: 'upstream',
    serviceNameField: 'client',
    serviceNamespaceField: 'client_service_namespace',
    title: 'Upstream',
    trackingSuffix: 'upstream',
    type: PanelType.UPSTREAM,
    queries: {
      rate: rateQuery,
      errors: `(${errorQuery} OR ${rateQuery} * 0) / ${totalQuery}`,
      duration: durationQuery,
    },
  });
}
