import React from 'react';

import { locationService } from '@grafana/runtime';
import { SceneAppPage, SceneAppPageLike, SceneAppPageState, SceneObjectRef, SceneRouteMatch } from '@grafana/scenes';

import { DeclareIncidentControl } from 'components/DeclareIncidentControl';
import { HostModalScene } from 'components/HostModal';
import { MenuControlScene } from 'components/MenuControl';
import { NotificationScene } from 'components/Notification';
import { ServicePageSubtitle } from 'components/ServicePageSubtitle';
import { PRESERVE_URL_KEYS, ROUTE_DEFINITIONS, ROUTES } from 'constants/routing';
import { TECHNOLOGY } from 'constants/technology';
import { TrackedSceneAppPage } from 'faro/TrackedSceneAppPage';
import { makeOperationPage } from 'modules/operations/makeOperationPage';
import { getMetadataService } from 'services/MetadataService';
import { makeCommonControls } from 'utils/controls';
import { extractJobFromRoute } from 'utils/services';
import { makeTimeRange } from 'utils/timeRange';
import { getTitleFactory, onRefreshMetadataBehavior } from 'utils/title';
import { makeVariables } from 'utils/variables';

import { makeServiceMetadataLoaderScene } from './components/ServiceMetadataLoaderScene';
import { makeServiceDotnetPage } from './dotnet/makeServiceDotnetPage';
import { makeServiceGoPage } from './go/makeServiceGoPage';
import { makeServiceJvmPage } from './jvm/makeServiceJvmPage';
import { makeServiceLogsPage } from './logs/makeServiceLogsPage';
import { makeServiceOverviewPage } from './overview/makeServiceOverviewPage';
import { makeServiceProfilesPage } from './profiles/makeServiceProfilesPage';
import { makeServiceServiceMapPage } from './serviceMap/makeServiceServiceMapPage';
import { makeServiceTracesPage } from './traces/makeServiceTracesPage';
import { makeMaxPanelPage } from '../maxPanel/makeMaxPanelPage';

export function makeServicePage(
  routeMatch: SceneRouteMatch<any>,
  parent: SceneAppPageLike,
  notificationRef: SceneObjectRef<NotificationScene>,
  hostModalRef: SceneObjectRef<HostModalScene>
): SceneAppPage {
  const { encodedJob, job } = extractJobFromRoute(routeMatch);

  const metadataService = getMetadataService();

  // operations and panels are not valid tabs
  const tab = ['operations', 'panels'].includes(routeMatch.params.tab) ? undefined : routeMatch.params.tab;

  function getTabs(): TrackedSceneAppPage[] {
    const isInstrumented = metadataService.getIsInstrumented(job);

    return [
      makeServiceOverviewPage(job, encodedJob, isInstrumented!),
      ...(isInstrumented
        ? [
            makeServiceTracesPage(job, encodedJob),
            makeServiceLogsPage(job, encodedJob),
            makeServiceProfilesPage(job, encodedJob),
            makeServiceServiceMapPage(job, encodedJob),
            getTechnologyTab(job, encodedJob, metadataService.getTechnology(job)),
          ]
        : [makeServiceServiceMapPage(job, encodedJob)]),
    ].filter(Boolean) as TrackedSceneAppPage[];
  }

  const tabs = metadataService.isServiceStored(job) && metadataService.hasIsInstrumented(job) ? getTabs() : undefined;

  const shouldRenderTimeRangeComparison = routeMatch.url.endsWith(encodedJob);

  const page: TrackedSceneAppPage = new TrackedSceneAppPage({
    title: job,
    viewName: 'servicePage',
    url: tab && !tabs ? ROUTES.overviewWithTab(encodedJob, tab) : ROUTES.overview(encodedJob),
    renderTitle: () => getTitleFactory({ job }),
    subTitle: (
      <ServicePageSubtitle notificationRef={notificationRef} hostModalRef={hostModalRef} job={job} model={parent} />
    ),
    preserveUrlKeys: PRESERVE_URL_KEYS,
    getParentPage: () => parent,
    getScene: makeServiceMetadataLoaderScene(job),

    $variables: makeVariables({
      usePrometheus: true,
      useEnvironmentFilter: true,
      useGroupByFilter: true,
      job,
      isInstrumented: metadataService.getIsInstrumented(job),
    }),

    $timeRange: makeTimeRange(),

    controls: [
      ...makeCommonControls(shouldRenderTimeRangeComparison),
      new DeclareIncidentControl(job),
      new MenuControlScene({ job }),
    ],

    drilldowns: [
      {
        routePath: ROUTE_DEFINITIONS.maxPanel,
        getPage: makeMaxPanelPage(job, encodedJob),
      },
      {
        routePath: ROUTE_DEFINITIONS.operationWithTab,
        getPage: makeOperationPage(job, encodedJob, notificationRef, hostModalRef),
      },
      {
        routePath: ROUTE_DEFINITIONS.operation,
        getPage: makeOperationPage(job, encodedJob, notificationRef, hostModalRef),
      },
    ],
    tabs,
    $behaviors: [
      onRefreshMetadataBehavior<SceneAppPageState>(() => ({
        renderTitle: () => getTitleFactory({ job }),
        tabs: page.state.tabs ?? getTabs(),
        url: ROUTES.overview(encodedJob),
      })),
    ],
  });

  return page;
}

function getTechnologyTab(job: string, encodedJob: string, technology: TECHNOLOGY): TrackedSceneAppPage | undefined {
  if (technology === TECHNOLOGY.UNKNOWN) {
    /**
     * When technology is unknown, try to match with the url, it might be because
     * we directly landed on the technology page (no metadata available yet).
     * At this point, routeMatch still doesn't know about the tab route,
     * so we have to manually check and render it.
     */
    const { pathname } = locationService.getLocation();
    const urlTechnology = pathname.split('/').at(-1) || '';
    const validTechnologies = Object.values<string>(TECHNOLOGY);

    if (validTechnologies.includes(urlTechnology)) {
      technology = urlTechnology as TECHNOLOGY;
    }
  }

  switch (technology) {
    case TECHNOLOGY.DOTNET:
      return makeServiceDotnetPage(job, encodedJob);

    case TECHNOLOGY.GOLANG:
      return makeServiceGoPage(job, encodedJob);

    case TECHNOLOGY.JVM:
      return makeServiceJvmPage(job, encodedJob);

    default:
      return undefined;
  }
}
