import { TECHNOLOGY } from 'constants/technology';

export function determineTechnology(telemetrySdkLanguage: string | undefined | null): TECHNOLOGY {
  switch (telemetrySdkLanguage) {
    case 'cpp':
      return TECHNOLOGY.CPP;
    case 'dotnet':
      return TECHNOLOGY.DOTNET;
    case 'erlang':
      return TECHNOLOGY.ERLANG;
    case 'go':
      return TECHNOLOGY.GOLANG;
    case 'java':
      return TECHNOLOGY.JVM;
    case 'nodejs':
      return TECHNOLOGY.NODEJS;
    case 'php':
      return TECHNOLOGY.PHP;
    case 'python':
      return TECHNOLOGY.PYTHON;
    case 'ruby':
      return TECHNOLOGY.RUBY;
    case 'rust':
      return TECHNOLOGY.RUST;
    case 'web':
      return TECHNOLOGY.WEB;
    case 'webjs':
      return TECHNOLOGY.WEBJS;
    default:
      return TECHNOLOGY.UNKNOWN;
  }
}
