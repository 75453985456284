import { isEqual } from 'lodash';
import React from 'react';
import { concatMap, map } from 'rxjs';

import {
  SceneComponentProps,
  SceneDataTransformer,
  sceneGraph,
  SceneObjectBase,
  SceneObjectRef,
  SceneObjectState,
  SceneQueryRunner,
  VariableDependencyConfig,
} from '@grafana/scenes';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { FilterByVariable } from 'components/FilterByVariable';
import { TEMPO_DS } from 'constants/datasources';
import { ENVIRONMENT_ATTRIBUTE_NAME, ENVIRONMENT_VALUE_NAME, FILTER_BY_NAME, TEMPO_DS_NAME } from 'constants/variables';
import { addLinkToNodes, filterOutUser, getServiceMapQuery } from 'utils/serviceMap';
import { parseJob } from 'utils/services';

export interface ServiceOverviewServiceMapState {
  job: string;
}

export interface ServiceOverviewServiceMapInternalState extends SceneObjectState {
  job: string;
  serviceNamespace: string;
  serviceName: string;
  encodedServiceNamespace?: string;

  queryRunnerRef?: SceneObjectRef<SceneQueryRunner>;
  serviceMapPanel?: ExplorablePanel;
}

export class ServiceOverviewServiceMapScene extends SceneObjectBase<ServiceOverviewServiceMapInternalState> {
  static Component = ServiceOverviewServiceMapRenderer;

  protected _variableDependency = new VariableDependencyConfig(this, {
    variableNames: [TEMPO_DS_NAME, ENVIRONMENT_ATTRIBUTE_NAME, ENVIRONMENT_VALUE_NAME, FILTER_BY_NAME],
  });

  constructor(state: ServiceOverviewServiceMapState) {
    const { serviceName, encodedServiceNamespace, serviceNamespace } = parseJob(state.job);

    super({
      job: state.job,
      serviceName,
      encodedServiceNamespace,
      serviceNamespace,
    });

    this.addActivationHandler(() => {
      const queryRunner = new SceneQueryRunner({
        datasource: TEMPO_DS,
        queries: [this.getQuery()],
      });

      const serviceMapPanel = new ExplorablePanel({
        title: 'Service map',

        trackingSection: 'service',
        trackingPage: 'service-map',
        trackingPanel: 'panel',

        pluginId: 'nodeGraph',

        $data: new SceneDataTransformer({
          transformations: [
            () => (source) => source.pipe(map(filterOutUser)),
            () => (source) => source.pipe(concatMap(addLinkToNodes)),
          ],

          $data: queryRunner,
        }),
      });

      this.setState({
        queryRunnerRef: queryRunner.getRef(),
        serviceMapPanel,
      });

      const unsubscribable = (sceneGraph.lookupVariable(FILTER_BY_NAME, this) as FilterByVariable).subscribeToState(
        (newState, oldState) => {
          if (!isEqual(newState.filters, oldState.filters)) {
            const queryRunner = this.state.queryRunnerRef?.resolve();
            queryRunner?.setState({ queries: [this.getQuery()] });
            queryRunner?.cancelQuery();
            queryRunner?.runQueries();
          }
        }
      );

      return () => unsubscribable.unsubscribe();
    });
  }

  private getQuery() {
    const clientFilters: string[] = [
      `client="${this.state.serviceName}"`,
      `client_service_namespace="${this.state.serviceNamespace}"`,
    ];
    const serverFilters: string[] = [
      `server="${this.state.serviceName}"`,
      `server_service_namespace="${this.state.serviceNamespace}"`,
    ];

    return getServiceMapQuery(this, clientFilters, serverFilters);
  }
}

function ServiceOverviewServiceMapRenderer({ model }: SceneComponentProps<ServiceOverviewServiceMapScene>) {
  const { serviceMapPanel } = model.useState();

  return <>{serviceMapPanel && <serviceMapPanel.Component model={serviceMapPanel} />}</>;
}
