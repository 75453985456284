export enum TRACKING_EVENTS {
  OPTIN_MODAL_OPEN = 'optin_modal_open',
  OPTIN_ACKNOWLEDGE = 'activation_acknowledge_cost',
  OPTIN_ACTIVATE = 'activate',
  OPTIN_MODAL_CANCEL = 'optin_modal_cancel',

  OPTOUT_MODAL_OPEN = 'optout_modal_open',
  OPTOUT_ACKNOWLEDGE = 'deactivation_acknowledge',
  OPTOUT_DEACTIVATE = 'deactivate',
  OPTOUT_MODAL_CANCEL = 'optout_modal_cancel',

  ADD_SERVICE_CLICK = 'add_service_click',
  EMPTY_SCREEN_ADD_SERVICE_CLICK = 'add_empty_services_click',

  DATASOURCES = 'datasources',
  DATASOURCE_SELECT = 'ds_select',

  TIME_WINDOW_COMPARISON_ENABLE = 'twc_enable',
  TIME_WINDOW_COMPARISON_CHANGE = 'twc_change',

  SERVICE_MAP_LAYOUT = 'servicemap_layout_toggle', // can't be done due to not being able to hook into layout controls
  SERVICE_MAP_EXPLORE = 'servicemap_breakout_explore', // can't be done due to links being internal
  SERVICE_MAP_DETAILS = 'servicemap_navigate_servicedetail',

  SERVICES_LIST = 'serviceinventory_list',
  SERVICES_FILTER = 'serviceinventory_filter_apply',
  SERVICES_SEARCH = 'serviceinventory_search',
  SERVICES_SERVICE_SELECT = 'serviceinventory_service_select',

  SERVICES_ERRORS = 'serviceinventory_errors',

  SERVICE_DETAIL = 'service_detail',
  SERVICE_OPERATION_SELECT = 'service_select_operation', // can't be done due to a bug that will prevent onBuildUrl and interpolation
  SERVICE_UPSTREAM_SELECT = 'service_select_upstream', // can't be done due to a bug that will prevent onBuildUrl and interpolation
  SERVICE_DOWNSTREAM_SELECT = 'service_select_downstream', // can't be done due to a bug that will prevent onBuildUrl and interpolation

  SERVICE_RUNTIME_DETAIL = 'service_runtimemetrics_detail',

  PANEL_EXPLORE = 'panel_explore',
  PANEL_MAXIMIZE = 'panel_maximize',

  DEPENDENCIES_SERVICE_MAP_CLICK = 'dependencies_servicemap_click',

  GO_TO_ERRORS_CLICK = 'go_to_errors_click',

  FILTER_ATTRIBUTES = 'filter_attributes',
  ENV_ATTRIBUTE = 'env_attribute',

  BASELINE_MODAL_OPEN = 'baseline_modal_open',
  BASELINE_ACKNOWLEDGE = 'baseline_acknowledge_cost',
  BASELINE_ACTIVATE = 'baseline_activate',
  BASELINE_MODAL_CANCEL = 'baseline_modal_cancel',
  BASELINE_DEACTIVATE = 'baseline_deactivate',

  LOG_MODE_SWITCH = 'log_mode_switch',
  LOG_MODE_DEFAULT = 'log_mode_default',

  // Host Info
  HOST_INFO_MODAL_DEFAULT_STATE = 'host_info_modal_default_state',
  HOST_INFO_MODAL_CLOSED = 'host_info_modal_closed',

  HOST_USAGE_MODAL_OPENED = 'host_usage_modal_opened',
  HOST_USAGE = 'host_usage',

  HOST_INFO_REQUIRED_DOCS = 'host_info_required_docs',
}
