import { SceneFlexItem, SceneFlexLayout } from '@grafana/scenes';

import { EnvironmentFilterScene } from 'components/EnvironmentFilter/EnvironmentFilterScene';
import { FilterByScene } from 'components/FilterBy/FilterByScene';
import { GroupByScene } from 'components/GroupBy/GroupByScene';
import { GRID_GAP, MIN_PANEL_HEIGHT, MIN_TABLE_PANEL_HEIGHT } from 'constants/styles';
import { getServiceOverviewPanels } from 'panels/getServiceOverviewPanels';

export const makeOverviewExtensionBody = (job: string) => {
  const panels = getServiceOverviewPanels(job);
  const operationsPanel = panels.operations();
  const downstreamPanel = panels.downstream();
  const upstreamPanel = panels.upstream();

  return new SceneFlexLayout({
    direction: 'column',
    children: [
      new SceneFlexLayout({
        direction: 'row',
        wrap: 'wrap',
        children: [new GroupByScene(), new EnvironmentFilterScene(), new FilterByScene()],
      }),

      new SceneFlexLayout({
        direction: 'row',
        minHeight: MIN_PANEL_HEIGHT,
        children: [
          new SceneFlexItem({ body: panels.duration() }),
          new SceneFlexItem({ body: panels.errors() }),
          new SceneFlexItem({ body: panels.rate() }),
        ],
      }),

      new SceneFlexLayout({
        direction: 'row',
        children: [
          new SceneFlexLayout({
            direction: 'column',
            width: '100%',
            children: [
              new SceneFlexItem({
                minHeight: MIN_TABLE_PANEL_HEIGHT + GRID_GAP,
                md: {
                  minHeight: MIN_PANEL_HEIGHT,
                },
                body: panels.durationDistribution(),
              }),

              new SceneFlexItem({ minHeight: MIN_TABLE_PANEL_HEIGHT, body: operationsPanel }),
            ],
          }),

          new SceneFlexLayout({
            direction: 'column',
            width: '100%',
            children: [
              new SceneFlexItem({
                minHeight: MIN_TABLE_PANEL_HEIGHT + GRID_GAP,
                body: downstreamPanel,
              }),

              new SceneFlexItem({ minHeight: MIN_TABLE_PANEL_HEIGHT, body: upstreamPanel }),
            ],
          }),
        ],
      }),
    ],
  });
};
