import { DataFrame, DataLink, DataLinkClickEvent } from '@grafana/data';
import { getDataSourceSrv, locationService } from '@grafana/runtime';
import { sceneGraph, SceneObject } from '@grafana/scenes';

import { EDGES_NAME, NODES_NAME, USER_ROW_ID } from 'constants/serviceMap';
import {
  ENVIRONMENT_ATTRIBUTE_NAME,
  ENVIRONMENT_VALUE_NAME,
  FILTER_BY_NAME,
  PROMETHEUS_DS_TYPE,
} from 'constants/variables';
import { getDataSourceService } from 'services/DataSourceService';

import { duplicateDataFrame } from './data';
import { hasEnvironmentAttribute } from './environmentFilter';
import { encodeParameter, prefixRoute } from './routing';
import { targetInfoMetricName } from './semantics';
import { trackServiceMapDetails } from './tracking';

export const getNodesAndEdgesFromFrames = (frames: DataFrame[]) => {
  const nodesFrame = frames.find((frame) => frame.name === NODES_NAME);
  const edgesFrame = frames.find((frame) => frame.name === EDGES_NAME);

  return { nodesFrame, edgesFrame };
};

export const filterOutUser = (frames: DataFrame[]) => {
  const { nodesFrame, edgesFrame } = getNodesAndEdgesFromFrames(frames);
  if (!nodesFrame || !edgesFrame) {
    return frames;
  }

  const nodesWithoutUser = removeUserFromNodes(nodesFrame);
  if (!nodesWithoutUser) {
    return frames;
  }

  const edgesWithoutUser = removeUserFromEdges(edgesFrame);
  if (!edgesWithoutUser) {
    return frames;
  }

  return [nodesWithoutUser, edgesWithoutUser];
};

function removeUserFromNodes(nodes: DataFrame): DataFrame | undefined {
  const ids = nodes.fields.find((field) => field.name === 'id');
  if (!ids) {
    return;
  }

  const nodeIdToRemove = ids.values.findIndex((value) => value === USER_ROW_ID);

  if (nodeIdToRemove === -1) {
    return nodes;
  }

  const nodesWithoutUser = duplicateDataFrame(nodes);
  nodesWithoutUser.fields = nodes.fields.map((field) => ({
    ...field,
    values: field.values.filter((_, idx) => idx !== nodeIdToRemove),
  }));

  return nodesWithoutUser;
}

function removeUserFromEdges(nodes: DataFrame): DataFrame | undefined {
  const sources = nodes.fields.find((field) => field.name === 'source');
  if (!sources) {
    return;
  }

  const edgeIdsToRemove: number[] = sources.values.reduce((acc, value, idx) => {
    if (value === USER_ROW_ID) {
      acc.push(idx);
    }

    return acc;
  }, []);

  if (edgeIdsToRemove.length === 0) {
    return nodes;
  }

  const edgesWithoutUser = duplicateDataFrame(nodes);
  edgesWithoutUser.fields = nodes.fields.map((field) => ({
    ...field,
    values: field.values.filter((_, idx) => !edgeIdsToRemove.includes(idx)),
  }));

  return edgesWithoutUser;
}

export async function addLinkToNodes(frames: DataFrame[]): Promise<DataFrame[]> {
  const availableJobs = await fetchAvailableJobs();

  return frames.map((frame) => {
    if (frame.name !== 'Nodes') {
      return frame;
    }

    const field = frame.fields.find((field) => field.name === 'id');

    if (!field) {
      return frame;
    }

    const newLink: DataLink = {
      title: 'Application Observability/Service Overview',
      url: '',
      onClick: (evt) => {
        trackServiceMapDetails();

        locationService.push(evt.replaceVariables!(buildServiceOverviewUrl(evt, availableJobs)));
      },
      onBuildUrl: (evt) => buildServiceOverviewUrl(evt, availableJobs),
    };

    field.config.links = [newLink, ...(field.config.links?.filter((link) => link.title !== newLink.title) ?? [])];

    return frame;
  });
}

export function getServiceMapQuery(scene: SceneObject, clientFilters: string[] = [], serverFilters: string[] = []) {
  const baseQuery = {
    queryType: 'serviceMap',
    refId: 'serviceMap',
    serviceMapIncludeNamespace: true,
  };

  if (hasEnvironmentAttribute()) {
    clientFilters.push(`client_$\{${ENVIRONMENT_ATTRIBUTE_NAME}}=~"$\{${ENVIRONMENT_VALUE_NAME}:regex}"`);
    serverFilters.push(`server_$\{${ENVIRONMENT_ATTRIBUTE_NAME}}=~"$\{${ENVIRONMENT_VALUE_NAME}:regex}"`);
  }

  const filterByUpstream = sceneGraph.interpolate(scene, `$\{${FILTER_BY_NAME}:serviceMapUpstream}`);
  if (filterByUpstream.length > 0) {
    serverFilters.push(filterByUpstream);
  }

  const filterByDownstream = sceneGraph.interpolate(scene, `$\{${FILTER_BY_NAME}:serviceMapDownstream}`);
  if (filterByDownstream.length > 0) {
    clientFilters.push(filterByDownstream);
  }

  const serviceMapQueries = [clientFilters.join(', '), serverFilters.join(', ')];
  return {
    ...baseQuery,
    serviceMapQuery: serviceMapQueries.map((q) => `{${q}}`),
  };
}

export async function fetchAvailableJobs(): Promise<string[]> {
  const ds = await getDataSourceSrv().get(getDataSourceService().getSelectedDataSourceUID(PROMETHEUS_DS_TYPE));

  try {
    const req = await (ds as any).metadataRequest('/api/v1/label/job/values', {
      'match[]': targetInfoMetricName,
      from: 'now-90d',
      to: 'now',
    });

    return req.data.data ?? [];
  } catch (err) {
    return [];
  }
}

function buildServiceOverviewUrl(evt: DataLinkClickEvent, availableJobs: string[]): string {
  const serviceName = evt.replaceVariables?.('${__data.fields.title}') ?? '';
  const serviceNamespace = evt.replaceVariables?.('${__data.fields.subtitle}') ?? '';

  const service = serviceNamespace ? `${serviceNamespace}/${serviceName}` : serviceName;

  const matchingService = availableJobs.find((job) => job.endsWith(service)) ?? service;
  const value = matchingService ? encodeParameter(matchingService) : '${__data.fields.title}';

  return prefixRoute(`services/${value}?\${__url_time_range}&\${__all_variables}`);
}
