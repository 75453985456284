import { PRESERVE_URL_KEYS, ROUTE_DEFINITIONS, ROUTES } from 'constants/routing';
import { TrackedSceneAppPage } from 'faro/TrackedSceneAppPage';

import { makeOperationTracesScene } from './makeOperationTracesScene';

export function makeOperationTracesPage(
  job: string,
  encodedJob: string,
  operation: string,
  encodedOperation: string
): TrackedSceneAppPage {
  return new TrackedSceneAppPage({
    viewName: 'operation-traces',
    title: 'Traces',
    routePath: ROUTE_DEFINITIONS.operationTraces,
    url: ROUTES.operationTraces(encodedJob, encodedOperation),
    preserveUrlKeys: PRESERVE_URL_KEYS,
    getScene: makeOperationTracesScene(job, encodedJob, operation, encodedOperation),
  });
}
