import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useReducer } from 'react';

import { dateTime, TimeRange, usePluginContext } from '@grafana/data';
import { behaviors, EmbeddedScene, SceneControlsSpacer, SceneTimeRange } from '@grafana/scenes';
import { DashboardCursorSync } from '@grafana/schema';

import { initializeAccessControl } from 'constants/accessControl';
import { makeServiceMetadataLoaderScene } from 'modules/service/components/ServiceMetadataLoaderScene';
import { NewMetadataFetchedEvent, TabChange } from 'modules/service/events';
import { initializeDataSourceService } from 'services/DataSourceService';
import { initializeDefaultEnvironmentValueService } from 'services/DefaultEnvironmentValueService';
import { initializeHostInfoService } from 'services/HostInfoService';
import { getMetadataService, initializeMetadataService } from 'services/MetadataService';
import { initializeOverridesService } from 'services/OverridesService';
import { initializePluginConfigService } from 'services/PluginConfigService';
import { initializeRenderService } from 'services/RenderService';
import { makeCommonControls } from 'utils/controls';
import { makeVariables } from 'utils/variables';

import { makeOverviewExtensionBody } from './makeOverviewExtensionBody';
import { OpenInAppO11yControl } from './OpenInAppO11yControl';

export interface OverviewExtensionProps {
  serviceName: string;
  namespace?: string;
  initialTimeRange: TimeRange;
  onTimeRangeChange?: (timeRange: TimeRange) => void;
  onTabChange?: (props: { query: string | undefined; tab: 'traces' | 'logs' }) => void;
}

export const OverviewExtensionScene = (props: OverviewExtensionProps) => {
  const { meta } = usePluginContext();
  const [ready, setReady] = useReducer(() => true, false);

  useEffect(() => {
    async function initialize() {
      initializeRenderService();
      await initializeOverridesService();
      initializePluginConfigService(meta);
      initializeAccessControl();
      initializeDataSourceService();
      initializeMetadataService();
      initializeDefaultEnvironmentValueService();
      initializeHostInfoService();
    }

    if (!ready) {
      initialize().then(() => setReady());
    }
  }, [meta, ready]);

  if (!ready) {
    return null;
  }

  return <OverviewExtensionSceneRenderer {...props} />;
};

const OverviewExtensionSceneRenderer = ({
  serviceName,
  namespace,
  initialTimeRange,
  onTimeRangeChange,
  onTabChange,
}: OverviewExtensionProps) => {
  const embeddedScene = useMemo(() => {
    const job = namespace ? `${namespace}/${serviceName}` : serviceName;
    const timeRange = new SceneTimeRange({
      from:
        typeof initialTimeRange.raw.from === 'string'
          ? initialTimeRange.raw.from
          : initialTimeRange.raw.from.toISOString(),
      to: typeof initialTimeRange.raw.to === 'string' ? initialTimeRange.raw.to : initialTimeRange.raw.to.toISOString(),
    });

    return new EmbeddedScene({
      $behaviors: [
        new behaviors.CursorSync({ key: 'sync1', sync: DashboardCursorSync.Tooltip }),
        (scene: EmbeddedScene) => {
          const unsubscribable = scene.subscribeToEvent(
            NewMetadataFetchedEvent,
            ({ payload: { isInstrumented, frames, job } }) => {
              getMetadataService().refreshService(job, isInstrumented, frames);
              scene.setState({ body: makeOverviewExtensionBody(job) });
            }
          );

          return () => {
            unsubscribable.unsubscribe();
          };
        },
        (scene: EmbeddedScene) => {
          const unsubscribable = scene.state.$timeRange?.subscribeToState((newState, oldState) => {
            if (!isEqual(newState, oldState)) {
              const { from, to, raw } = newState.value;

              onTimeRangeChange?.({ from, to, raw });
            }
          });

          return () => {
            unsubscribable?.unsubscribe();
          };
        },
        (scene: EmbeddedScene) => {
          const unsubscribable = scene.subscribeToEvent(TabChange, ({ payload: { tab, query, from, to } }) => {
            if (from && to && onTimeRangeChange) {
              const dateTimeFrom = dateTime(from);
              const dateTimeTo = dateTime(to);

              onTimeRangeChange({
                from: dateTimeFrom,
                to: dateTimeTo,
                raw: {
                  from: dateTimeFrom.toISOString(),
                  to: dateTimeTo.toISOString(),
                },
              });
            }

            onTabChange?.({ tab, query });
          });

          return () => {
            unsubscribable?.unsubscribe();
          };
        },
      ],
      $variables: makeVariables({
        usePrometheus: true,
        useEnvironmentFilter: true,
        useGroupByFilter: true,
        useFilterBy: true,
        job,
        isInstrumented: getMetadataService().getIsInstrumented(job),
      }),
      $timeRange: timeRange,

      controls: [
        new OpenInAppO11yControl({ job, timeRange: timeRange.getRef() }),
        new SceneControlsSpacer(),
        ...makeCommonControls(true),
      ],
      body: makeServiceMetadataLoaderScene(job)(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we don't want to recreate the scene on time range change, just the panels themselves
  }, [serviceName, namespace, onTimeRangeChange]);

  if (!embeddedScene) {
    return null;
  }

  return <embeddedScene.Component model={embeddedScene} />;
};
