import React, { useCallback, useState } from 'react';

import { DataSourcePicker } from '@grafana/runtime';
import { EmbeddedScene } from '@grafana/scenes';
import { Field, useStyles2 } from '@grafana/ui';

import { LOKI_DS_TYPE, PROMETHEUS_DS_TYPE, PYROSCOPE_DS_TYPE, TEMPO_DS_TYPE } from 'constants/variables';
import { getDataSourceService } from 'services/DataSourceService';
import { DS_TYPE } from 'types/datasources';

import { getCommonStyles } from './styles';

export interface DataSourceTabProps {
  getScene: () => EmbeddedScene;
}

export const DataSourceTab = (_props: DataSourceTabProps) => {
  const styles = useStyles2(getCommonStyles);

  const [defaultDataSources, setDefaultDataSources] = useState(getDataSourceService().getDefaultDataSources());

  const onDataSourceChange = useCallback((pluginId: DS_TYPE, uid: string) => {
    getDataSourceService().saveDataSourceSelection(pluginId, uid);
    setDefaultDataSources((values) => ({
      ...values,
      [pluginId]: uid,
    }));
  }, []);

  return (
    <>
      <h5 className={styles.title}>Data&nbsp;sources</h5>
      <p className={styles.description}>Configure data source for each signal type. Changes are saved on selection.</p>

      <div>
        <Field label="Metrics data source" data-cy="metricsDataSourcePicker">
          <DataSourcePicker
            current={defaultDataSources.prometheus}
            noDefault
            pluginId={PROMETHEUS_DS_TYPE}
            onChange={(ds) => onDataSourceChange(PROMETHEUS_DS_TYPE, ds.uid)}
          />
        </Field>
        <Field label="Logs data source" data-cy="logsDataSourcePicker">
          <DataSourcePicker
            current={defaultDataSources.loki}
            noDefault
            pluginId={LOKI_DS_TYPE}
            onChange={(ds) => onDataSourceChange(LOKI_DS_TYPE, ds.uid)}
          />
        </Field>
        <Field label="Traces data source" data-cy="tracesDataSourcePicker">
          <DataSourcePicker
            current={defaultDataSources.tempo}
            noDefault
            pluginId={TEMPO_DS_TYPE}
            onChange={(ds) => onDataSourceChange(TEMPO_DS_TYPE, ds.uid)}
          />
        </Field>
        <Field label="Profiles data source" data-cy="profilesDataSourcePicker">
          <DataSourcePicker
            current={defaultDataSources['grafana-pyroscope-datasource']}
            noDefault
            pluginId={PYROSCOPE_DS_TYPE}
            onChange={(ds) => onDataSourceChange(PYROSCOPE_DS_TYPE, ds.uid)}
          />
        </Field>
      </div>
    </>
  );
};
