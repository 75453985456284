import alibabaIcon from 'img/icons/cloud/alibaba.svg';
import awsIcon from 'img/icons/cloud/aws.svg';
import azureIcon from 'img/icons/cloud/azure.svg';
import gcpIcon from 'img/icons/cloud/gcp.svg';
import genericIcon from 'img/icons/cloud/genericProvider.svg';
import herokuIcon from 'img/icons/cloud/heroku.svg';
import ibmIcon from 'img/icons/cloud/ibm.svg';
import tencentIcon from 'img/icons/cloud/tencent.svg';

export enum CLOUD {
  UNKNOWN = 'unknown',
  ALIBABA = 'alibaba_cloud',
  AWS = 'aws',
  AZURE = 'azure',
  GCP = 'gcp',
  HEROKU = 'heroku',
  IBM = 'ibm_cloud',
  TENCENT = 'tencent_cloud',
}

export const CLOUD_NAME: Record<CLOUD, string> = {
  [CLOUD.ALIBABA]: 'Alibaba',
  [CLOUD.AWS]: 'AWS',
  [CLOUD.AZURE]: 'Azure',
  [CLOUD.GCP]: 'GCP',
  [CLOUD.HEROKU]: 'Heroku',
  [CLOUD.IBM]: 'IBM',
  [CLOUD.TENCENT]: 'Tencent',
  [CLOUD.UNKNOWN]: 'unknown',
} as const;

export const CLOUD_ICON: Record<CLOUD, any> = {
  [CLOUD.ALIBABA]: alibabaIcon,
  [CLOUD.AWS]: awsIcon,
  [CLOUD.AZURE]: azureIcon,
  [CLOUD.GCP]: gcpIcon,
  [CLOUD.HEROKU]: herokuIcon,
  [CLOUD.IBM]: ibmIcon,
  [CLOUD.TENCENT]: tencentIcon,
  [CLOUD.UNKNOWN]: genericIcon,
};
