import { SceneQueryRunner } from '@grafana/scenes';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { PROMETHEUS_DS } from 'constants/datasources';
import { DEFAULT_FIELD_CONFIG } from 'constants/panels';

export function makeServiceJvmClassesPanel(): ExplorablePanel {
  return new ExplorablePanel({
    description: 'Number of currently loaded classes',

    title: 'Classes',

    trackingSection: 'service',
    trackingPage: 'jvm',
    trackingPanel: 'classes',

    fieldConfig: {
      defaults: {
        ...DEFAULT_FIELD_CONFIG,
        unit: 'none',
      },
      overrides: [],
    },

    options: {
      tooltip: {
        mode: 'multi',
      },
    },

    $data: new SceneQueryRunner({
      datasource: PROMETHEUS_DS,
      maxDataPointsFromWidth: true,
      queries: [
        {
          expr: '{__name__=~"jvm_class_count|process_runtime_jvm_classes_current_loaded", job="${job}", instance=~"${instance:regex}"}\nor\njvm_classes_loaded{job="${job}", instance=~"${instance:regex}"}',
          legendFormat: '{{instance}}',
          refId: 'serviceJvmClasses',
        },
      ],
    }),
  });
}
