import React, { useMemo } from 'react';

import { PluginExtensionLink, UrlQueryMap } from '@grafana/data';
import {
  GetPluginExtensionsOptions,
  getPluginLinkExtensions,
  usePluginLinks as usePluginLinksOriginal,
} from '@grafana/runtime';

import { LOKI_DS_NAME, PROMETHEUS_DS_NAME, TEMPO_DS_NAME } from 'constants/variables';
import { useQueryParams } from 'hooks/useQueryParams';
import { getMetadataService } from 'services/MetadataService';
import { PluginExtensionLinkContext, PluginExtensionLinkServiceContext, PluginExtensionPoints } from 'types/extensions';
import { normalizeLabel } from 'utils/format';
import { parseJob } from 'utils/services';
import { paramsToTimeRange } from 'utils/timeRange';

import { ExtensionLinkMenu } from './ExtensionLinkMenu';

interface Props {
  context: PluginExtensionLinkContext;
  extensionPointId: PluginExtensionPoints;
}

// `usePluginLinks()` is only available in Grafana>=11.1.0, so we have a fallback for older versions
const usePluginLinks = usePluginLinksOriginal !== undefined ? usePluginLinksOriginal : useExtensionLinks;

export const ExtensionLinks = ({ job, operation }: { job: string; operation?: string }) => {
  // build a URL back to service overview.
  // make sure to always include absolute time range in the URL
  const [params] = useQueryParams();
  const metadataService = getMetadataService();
  const { serviceName, serviceNamespace } = parseJob(job);

  const rawTimeRange = paramsToTimeRange(params);

  const k8s = metadataService.getK8s(job);
  const cloud = metadataService.getCloud(job);

  const extensionContext: PluginExtensionLinkContext = {
    timeRange: rawTimeRange,
    datasources: getDatasources(params),
    service: {
      job,
      name: serviceName,
      namespace: serviceNamespace,
    },
    k8s: k8s.length === 0 ? undefined : normalizeEntries(k8s),
    cloud: cloud.length === 0 ? undefined : normalizeEntries(cloud),
    ...(operation !== undefined ? { operation } : {}),
  };

  const extensionPointId =
    operation !== undefined ? PluginExtensionPoints.OperationAction : PluginExtensionPoints.ServiceAction;

  return <ExtensionLinksMenuItems context={extensionContext} extensionPointId={extensionPointId} />;
};

const ExtensionLinksMenuItems = ({ context, extensionPointId }: Props) => {
  const { links, isLoading } = usePluginLinks({ context, extensionPointId, limitPerPlugin: 3 });

  if (isLoading || links.length === 0) {
    return null;
  }

  return <ExtensionLinkMenu extensions={links} />;
};

function useExtensionLinks({ context, extensionPointId, limitPerPlugin }: GetPluginExtensionsOptions): {
  links: PluginExtensionLink[];
  isLoading: boolean;
} {
  return useMemo(() => {
    // getPluginLinkExtensions is available in Grafana>=10.0,
    // so will be undefined in earlier versions. Just return an
    // empty list of extensions in this case.
    if (getPluginLinkExtensions === undefined) {
      return {
        links: [],
        isLoading: false,
      };
    }
    const { extensions } = getPluginLinkExtensions({
      extensionPointId,
      context,
      limitPerPlugin,
    });

    return {
      links: extensions,
      isLoading: false,
    };
  }, [context, extensionPointId, limitPerPlugin]);
}

function normalizeEntries<T>(entries: Array<Record<string, T>>): Array<Record<string, T>> {
  return entries.map((entry) =>
    Object.fromEntries(Object.entries(entry).map(([key, value]) => [normalizeLabel(key), value]))
  );
}

function getDatasources(params: UrlQueryMap): PluginExtensionLinkServiceContext['datasources'] {
  const prometheus = params[`var-${PROMETHEUS_DS_NAME}`];
  const loki = params[`var-${LOKI_DS_NAME}`];
  const tempo = params[`var-${TEMPO_DS_NAME}`];
  return {
    prometheus: typeof prometheus === 'string' ? { name: prometheus } : undefined,
    loki: typeof loki === 'string' ? { name: loki } : undefined,
    tempo: typeof tempo === 'string' ? { name: tempo } : undefined,
  };
}
