import { debounce, isEqual } from 'lodash';
import { Unsubscribable } from 'rxjs';

import { EmbeddedScene, sceneGraph, SceneRouteMatch } from '@grafana/scenes';

import { PromSceneQueryRunner } from 'components/PromSceneQueryRunner';
import { MIN_INTERVAL, PROMETHEUS_DS } from 'constants/datasources';
import { BASELINE_CONSTANT_REF_ID } from 'constants/query';
import { SERVICES_SPARKLINE_MAX_DATA_POINTS } from 'constants/sparkline';
import { ENVIRONMENT_ATTRIBUTE_NAME, ENVIRONMENT_VALUE_NAME, FILTER_BY_NAME } from 'constants/variables';
import { makeRedQueriesInstrumented } from 'queries/makeRedQueriesInstrumented';
import { makeRedQueriesUninstrumented } from 'queries/makeRedQueriesUninstrumented';
import { getFullTargetInfoQuery } from 'queries/targetInfo';
import { getMetadataService } from 'services/MetadataService';
import { getPluginConfigService } from 'services/PluginConfigService';
import { DataQueryExtended } from 'types/queries';
import { hasEnvironmentAttribute } from 'utils/environmentFilter';
import { makeVariables } from 'utils/variables';

import { ServicesScene } from './components/ServicesScene';
import { SERVICES_VIEW_NAME } from './makeServicesPage';

export function makeServicesScene(): (routeMatch: SceneRouteMatch<any>) => EmbeddedScene {
  const filteredLabels = [
    hasEnvironmentAttribute() ? `$\{${ENVIRONMENT_ATTRIBUTE_NAME}}=~"$\{${ENVIRONMENT_VALUE_NAME}}"` : '',
  ]
    .concat(`$\{${FILTER_BY_NAME}:targetInfo}`)
    .filter(Boolean)
    .join(',');

  const servicesItemsFilteredQuery = `group by (telemetry_sdk_language, job) ($\{metricName:targetInfo}{${filteredLabels}})`;
  const servicesSpanKind = `group by (span_kind, job) ($\{metricName:spanMetricsCount}{span_kind=~"SPAN_KIND_(CLIENT|PRODUCER|SERVER|CONSUMER)",${filteredLabels}})`;

  const pluginConfig = getPluginConfigService().getPluginConfig();

  const { labels: uninstrumentedLabelsQuery } = makeRedQueriesUninstrumented({ groupBy: 'server, connection_type' });

  return () =>
    new EmbeddedScene({
      $variables: makeVariables({
        usePrometheus: true,
        useEnvironmentFilter: true,
        useFilterBy: true,
        viewName: SERVICES_VIEW_NAME,
      }),

      body: new ServicesScene({
        // this data source loads the list of services
        $data: new PromSceneQueryRunner({
          datasource: PROMETHEUS_DS,
          maxDataPoints: SERVICES_SPARKLINE_MAX_DATA_POINTS,
          minInterval: MIN_INTERVAL,
          queries: [
            {
              expr: servicesItemsFilteredQuery,
              refId: 'servicesItems',
            },
            {
              refId: BASELINE_CONSTANT_REF_ID,
              expr: `${pluginConfig.baselineRulePrefix ?? 'appo11y'}:duration:threshold`,
              instant: true,
            },
            {
              refId: 'uninstrumentedServicesLabels',
              expr: uninstrumentedLabelsQuery,
            },
            ...(pluginConfig.isClientOnlyServicesEnabled
              ? [
                  {
                    expr: servicesSpanKind,
                    refId: 'servicesSpanKind',
                  },
                ]
              : []),
          ],
        }),
        // and this data source loads RED metric data for sparklines for
        // currently visible services
        redQueryRunner: new PromSceneQueryRunner({
          $behaviors: [
            (runner: PromSceneQueryRunner) => {
              const servicesScene = sceneGraph.getAncestor(runner, ServicesScene);
              const unsubs: Unsubscribable[] = [];

              // hold hash of currently selected page services
              const memo = {
                hash: '',
              };

              const requery = debounce(() => {
                const services = servicesScene.state.services;
                if (!services?.length) {
                  runner.cancelQuery();
                  runner.setState({ queries: [] });
                  memo.hash = '';
                  return;
                }
                const clientJobs: string[] = [];
                const serverJobs: string[] = [];
                const uninstrumentedServiceNames: string[] = [];

                const metadataService = getMetadataService();

                services.forEach((service) => {
                  if (metadataService.getIsInstrumented(service.job)) {
                    if (metadataService.getIsClientOnly(service.job)) {
                      clientJobs.push(service.job);
                    } else {
                      serverJobs.push(service.job);
                    }
                  } else {
                    uninstrumentedServiceNames.push(service.job);
                  }
                });

                const hash = JSON.stringify({
                  clientJobs: clientJobs.sort(),
                  serverJobs: serverJobs.sort(),
                  uninstrumentedServiceNames: uninstrumentedServiceNames.sort(),
                });
                if (memo.hash === hash) {
                  return;
                }
                memo.hash = hash;

                const {
                  rate: rateQueryOnSpanMetrics,
                  errors: errorQueryOnSpanMetrics,
                  p95: p95QueryOnSpanMetrics,
                } = makeRedQueriesInstrumented({ groupBy: 'job', jobs: serverJobs });

                const {
                  rate: rateQueryOnClientSpanMetrics,
                  errors: errorQueryOnClientSpanMetrics,
                  p95: p95QueryOnClientSpanMetrics,
                } = makeRedQueriesInstrumented({ groupBy: 'job', serviceType: 'client', jobs: clientJobs });

                const {
                  rate: rateQueryOnServiceGraphMetrics,
                  errors: errorQueryOnServiceGraphMetrics,
                  p95: p95QueryOnServiceGraphMetrics,
                } = makeRedQueriesUninstrumented({
                  groupBy: 'server, connection_type',
                  serverNames: uninstrumentedServiceNames,
                });

                const metadataQuery = getFullTargetInfoQuery(
                  Array.from(new Set([...serverJobs, ...clientJobs])),
                  hasEnvironmentAttribute()
                );

                const queries: DataQueryExtended[] = [
                  {
                    expr: metadataQuery,
                    refId: 'servicesMetadata',
                  },
                  ...(pluginConfig.isClientOnlyServicesEnabled && clientJobs.length
                    ? [
                        {
                          expr: rateQueryOnClientSpanMetrics.query,
                          refId: 'servicesClientRate',
                        },
                        {
                          expr: errorQueryOnClientSpanMetrics.query,
                          refId: 'servicesClientErrors',
                        },
                        {
                          expr: p95QueryOnClientSpanMetrics.query,
                          refId: 'servicesClientDuration',
                        },
                      ]
                    : []),
                  ...(serverJobs.length
                    ? [
                        {
                          expr: rateQueryOnSpanMetrics.query,
                          refId: 'servicesRate',
                        },
                        {
                          expr: errorQueryOnSpanMetrics.query,
                          refId: 'servicesErrors',
                        },
                        {
                          expr: p95QueryOnSpanMetrics.query,
                          refId: 'servicesDuration',
                        },
                      ]
                    : []),
                  ...(uninstrumentedServiceNames.length
                    ? [
                        {
                          expr: rateQueryOnServiceGraphMetrics.query,
                          refId: 'traceGraphServicesRate',
                        },
                        {
                          expr: errorQueryOnServiceGraphMetrics.query,
                          refId: 'traceGraphServicesErrors',
                        },
                        {
                          expr: p95QueryOnServiceGraphMetrics.query,
                          refId: 'traceGraphServicesDuration',
                        },
                      ]
                    : []),
                ];
                runner.setState({ queries });
                runner.runQueries();
              });

              unsubs.push(
                servicesScene.subscribeToState((newState, prevState) => {
                  if (!isEqual(newState.services, prevState.services)) {
                    requery();
                  }
                })
              );

              return () => {
                unsubs.forEach((unsub) => unsub.unsubscribe());
              };
            },
          ],
          datasource: PROMETHEUS_DS,
          maxDataPoints: SERVICES_SPARKLINE_MAX_DATA_POINTS,
          minInterval: MIN_INTERVAL,
          queries: [],
        }),
      }),
    });
}
