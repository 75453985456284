import { MetricsMode } from 'types/settings';

import { DEFAULT_TARGET_INFO_METRIC_NAME, OTEL_TARGET_INFO_METRIC_NAME } from './semantics';

export type MetricNamesDefinition = Record<
  MetricsMode,
  {
    targetInfo: string;

    serviceGraphTotalRequests: string;
    serviceGraphFailedTotalRequests: string;

    serviceGraphRequestClientSecondsBucket: string;
    serviceGraphRequestClientSecondsCount: string;
    serviceGraphRequestClientSecondsSum: string;

    serviceGraphRequestServerSecondsBucket: string;
    serviceGraphRequestServerSecondsCount: string;
    serviceGraphRequestServerSecondsSum: string;

    spanMetricsTotalCalls: string;
    spanMetricsBucket: string;
    spanMetricsCount: string;
    spanMetricsSum: string;
  }
>;

export function isValidMetricName(value: unknown): value is keyof MetricNamesDefinition[MetricsMode] {
  return typeof value === 'string' && Object.values(METRIC_NAMES[MetricsMode.tempoMetricsGen]).includes(value as any);
}

export const METRIC_NAMES: MetricNamesDefinition = {
  [MetricsMode.tempoMetricsGen]: {
    targetInfo: DEFAULT_TARGET_INFO_METRIC_NAME,

    serviceGraphTotalRequests: 'traces_service_graph_request_total',
    serviceGraphFailedTotalRequests: 'traces_service_graph_request_failed_total',
    serviceGraphRequestClientSecondsBucket: 'traces_service_graph_request_client_seconds_bucket',
    serviceGraphRequestClientSecondsCount: 'traces_service_graph_request_client_seconds_count',
    serviceGraphRequestClientSecondsSum: 'traces_service_graph_request_client_seconds_sum',
    serviceGraphRequestServerSecondsBucket: 'traces_service_graph_request_server_seconds_bucket',
    serviceGraphRequestServerSecondsCount: 'traces_service_graph_request_server_seconds_count',
    serviceGraphRequestServerSecondsSum: 'traces_service_graph_request_server_seconds_sum',

    spanMetricsTotalCalls: 'traces_spanmetrics_calls_total',
    spanMetricsBucket: 'traces_spanmetrics_latency_bucket',
    spanMetricsCount: 'traces_spanmetrics_latency_count',
    spanMetricsSum: 'traces_spanmetrics_latency_sum',
  },
  [MetricsMode.otelCollector]: {
    targetInfo: OTEL_TARGET_INFO_METRIC_NAME,

    serviceGraphTotalRequests: 'traces_service_graph_request_total',
    serviceGraphFailedTotalRequests: 'traces_service_graph_request_failed_total',
    serviceGraphRequestClientSecondsBucket: 'traces_service_graph_request_client_seconds_bucket',
    serviceGraphRequestClientSecondsCount: 'traces_service_graph_request_client_seconds_count',
    serviceGraphRequestClientSecondsSum: 'traces_service_graph_request_client_seconds_sum',
    serviceGraphRequestServerSecondsBucket: 'traces_service_graph_request_server_seconds_bucket',
    serviceGraphRequestServerSecondsCount: 'traces_service_graph_request_server_seconds_count',
    serviceGraphRequestServerSecondsSum: 'traces_service_graph_request_server_seconds_sum',

    spanMetricsTotalCalls: 'calls_total',
    spanMetricsBucket: 'duration_seconds_bucket',
    spanMetricsCount: 'duration_seconds_count',
    spanMetricsSum: 'duration_seconds_sum',
  },
  [MetricsMode.legacy]: {
    targetInfo: OTEL_TARGET_INFO_METRIC_NAME,

    serviceGraphTotalRequests: 'traces_service_graph_request_total',
    serviceGraphFailedTotalRequests: 'traces_service_graph_request_failed_total',
    serviceGraphRequestClientSecondsBucket: 'traces_service_graph_request_client_seconds_bucket',
    serviceGraphRequestClientSecondsCount: 'traces_service_graph_request_client_seconds_count',
    serviceGraphRequestClientSecondsSum: 'traces_service_graph_request_client_seconds_sum',
    serviceGraphRequestServerSecondsBucket: 'traces_service_graph_request_server_seconds_bucket',
    serviceGraphRequestServerSecondsCount: 'traces_service_graph_request_server_seconds_count',
    serviceGraphRequestServerSecondsSum: 'traces_service_graph_request_server_seconds_sum',

    spanMetricsTotalCalls: 'traces_spanmetrics_calls_total',
    spanMetricsBucket: 'traces_spanmetrics_latency_bucket',
    spanMetricsCount: 'traces_spanmetrics_latency_count',
    spanMetricsSum: 'traces_spanmetrics_latency_sum',
  },
} as const;
