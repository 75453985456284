import { catchError, lastValueFrom, of } from 'rxjs';

import { getBackendSrv } from '@grafana/runtime';

import { PLUGIN_ID } from 'constants/plugin';
import { Instance } from 'types/instance';

const instanceUrl = `/api/plugin-proxy/${PLUGIN_ID}/instance`;

export interface InstanceResponse {
  ok: boolean;
  data: Instance | undefined;
  error?: Error;
}

export async function getInstanceInfo(): Promise<InstanceResponse> {
  return await lastValueFrom(
    getBackendSrv()
      .fetch<Instance>({
        url: instanceUrl,
        showErrorAlert: false,
        showSuccessAlert: false,
      })
      .pipe(catchError((err) => of({ ok: false, data: undefined, error: err })))
  );
}
