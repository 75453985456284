import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Field, Form, InputControl, useStyles2 } from '@grafana/ui';

import { DebouncedTextArea } from 'components/DebouncedTextArea';
import { getFaro } from 'faro/instance';

export interface Props {
  current: string;
  defaultValue: string;
  description: string;
  label: string;
  placeholder: string;
  required: boolean;
  isSaving: boolean;
  onSubmit: (query: string | undefined) => Promise<void>;
}

export const LogsQueryForm = ({
  current,
  defaultValue,
  description,
  label,
  placeholder,
  required,
  isSaving,
  onSubmit,
}: Props) => {
  const styles = useStyles2(getStyles);

  const defaultValues = { query: current };

  const handleSubmit = async (query: string | undefined) => {
    try {
      await onSubmit(query);
    } catch (err) {
      const error = err instanceof Error ? err : new Error('Could not save logs query change');
      getFaro()?.api.pushError(error);
    }
  };

  return (
    <Form<{ query: string }>
      onSubmit={(form) => {
        handleSubmit(form.query);
      }}
      validateOn="all"
      defaultValues={defaultValues}
    >
      {({ control, errors, formState, setValue, watch }) => {
        const queryField = watch('query');
        const isResetDisabled = isSaving || queryField === defaultValue;
        const isSaveDisabled =
          isSaving || !formState.isDirty || queryField === defaultValues.query || errors.query !== undefined;

        return (
          <>
            <Field label={label} description={description} invalid={!!errors.query} error={<>Field cannot be empty</>}>
              <InputControl
                rules={{ required }}
                render={({ field: { ref, ...field } }) => (
                  <DebouncedTextArea {...field} placeholder={placeholder} rows={4} />
                )}
                control={control}
                name="query"
              />
            </Field>

            <div className={styles.actions}>
              <Button
                data-cy="logsQueryFormReset"
                variant="secondary"
                disabled={isResetDisabled}
                onClick={() => {
                  setValue('query', defaultValue, { shouldValidate: true });
                  handleSubmit(undefined);
                }}
              >
                Reset
              </Button>
              <Button data-cy="logsQueryFormSave" type="submit" disabled={isSaveDisabled}>
                Save
              </Button>
            </div>
          </>
        );
      }}
    </Form>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    actions: css`
      display: flex;
      gap: ${theme.spacing(1)};
    `,
  };
}
