import { css } from '@emotion/css';
import React, { useEffect } from 'react';
import { lastValueFrom, Unsubscribable } from 'rxjs';

import { GrafanaTheme2, LoadingState, PanelData } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import {
  SceneComponentProps,
  SceneObjectBase,
  SceneObjectState,
  SceneQueryRunner,
  SceneTimeRange,
} from '@grafana/scenes';
import { Button, Divider, LoadingPlaceholder, Modal, Stack, Text, TextLink, useStyles2 } from '@grafana/ui';

import { USAGE_DS } from 'constants/datasources';
import { PROMETHEUS_DS_TYPE } from 'constants/variables';
import { getFaro } from 'faro/instance';
import { getInstanceService } from 'services/InstanceService';
import { Dashboard } from 'types/dashboards';
import { trackHostUsage } from 'utils/tracking';

export interface HostUsageInfoState extends SceneObjectState {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;

  billingDashboardUrl?: string;
  billableHostHours?: number;
  hostHours?: number;
  instances?: number;
  isPromLoading?: boolean;
  isUsageLoading?: boolean;
  services?: number;
}

const totalNumberOfInstancesRefId = 'totalNumberOfInstances';
const totalNumberOfServicesRefId = 'totalNumberOfServices';
const totalNumberOfBillableHostHoursRefId = 'totalNumberOfBillableHostHours';
const totalNumberOfHostHoursRefId = 'totalNumberOfHostHours';

const MONTH_TO_DATE = { from: 'now/M', to: 'now' };

export class HostUsageInfo extends SceneObjectBase<HostUsageInfoState> {
  static Component = HostUsageInfoRenderer;

  constructor(state: HostUsageInfoState) {
    const promQueryRunner = new SceneQueryRunner({
      $timeRange: new SceneTimeRange(MONTH_TO_DATE),
      datasource: { uid: 'grafanacloud-prom', type: PROMETHEUS_DS_TYPE },
      queries: [],
    });

    const usageQueryRunner = new SceneQueryRunner({
      $timeRange: new SceneTimeRange(MONTH_TO_DATE),
      datasource: USAGE_DS,
      queries: [],
    });

    super({ ...state, isPromLoading: true, isUsageLoading: true });

    this.addActivationHandler(() => {
      const unsubscribables: Unsubscribable[] = [
        // React to results from prom queries
        promQueryRunner.subscribeToState(({ data }) => {
          if (!data || data.state !== LoadingState.Done || data.series.length === 0) {
            return;
          }

          const totalNumberOfServices = this.getTotalNumber(data, totalNumberOfServicesRefId, 'Value');
          const totalNumberOfInstances = this.getTotalNumber(data, totalNumberOfInstancesRefId, 'Value');

          this.setState({
            services: totalNumberOfServices || 0,
            instances: totalNumberOfInstances || 0,
            isPromLoading: false,
          });
        }),

        // React to results from usage queries
        usageQueryRunner.subscribeToState(({ data }) => {
          if (!data || data.state !== LoadingState.Done || data.series.length === 0) {
            return;
          }

          const totalHostHours = this.getTotalNumber(data, totalNumberOfHostHoursRefId, 'Value');
          const totalBillableHostHours = this.getTotalNumber(
            data,
            totalNumberOfBillableHostHoursRefId,
            'grafanacloud_org_app_o11y_billable_host_hours'
          );

          this.setState({
            billableHostHours: totalBillableHostHours || 0,
            hostHours: Math.round(totalHostHours || 0),
            isUsageLoading: false,
          });
        }),

        this.subscribeToState((newState, oldState) => {
          // Run queries every time the modal is opened
          if (newState.isOpen && newState.isOpen !== oldState.isOpen) {
            promQueryRunner.setState({
              queries: [
                {
                  expr: 'count(group by (job) (traces_target_info{job!=""}))',
                  refId: totalNumberOfServicesRefId,
                  instant: true,
                },
                {
                  expr: 'count(group by (instance) (traces_target_info{job!=""}))',
                  refId: totalNumberOfInstancesRefId,
                  instant: true,
                },
              ],
            });

            const { orgId, hmInstancePromName } = getInstanceService().getInstance() ?? {};
            usageQueryRunner.setState({
              queries: [
                {
                  expr: `grafanacloud_org_app_o11y_billable_host_hours{org_id="${orgId}"}`,
                  refId: totalNumberOfBillableHostHoursRefId,
                  instant: true,
                },
                {
                  expr: `label_replace(
    (
        avg_over_time(
            max by(id) ( (grafanacloud_instance_active_traces_host_info_series{org_id="${orgId}"} < Inf))[$__range:60s]
        ) * $__range_s/3600
    )  * on (id) group_left(name, org_name) topk(1, grafanacloud_instance_info{type="prometheus", org_id="${orgId}", name="${hmInstancePromName}"}) by (id, name), "stack_name", "$1", "name", "(.*)") > 0`,
                  refId: totalNumberOfHostHoursRefId,
                  instant: true,
                },
              ],
            });

            promQueryRunner.runQueries();
            usageQueryRunner.runQueries();

            if (!this.state.billingDashboardUrl) {
              this.getBillingDashboardUrl().then((url) => {
                this.setState({ billingDashboardUrl: url });
              });
            }
          }
        }),
      ];

      return () => {
        unsubscribables.forEach((u) => u.unsubscribe());
      };
    });
  }

  private getTotalNumber(data: PanelData, targetRefId: string, field: string): number | undefined {
    const serie = data.series.find(({ refId }) => refId === targetRefId);
    if (!serie) {
      return;
    }

    const valueField = serie.fields.find(({ name }) => name === field);
    if (!valueField) {
      return;
    }

    return valueField.values.at(0);
  }

  private async getBillingDashboardUrl() {
    const defaultUrl = '/dashboards?layout=folders';

    try {
      const dashboards = await lastValueFrom(
        getBackendSrv().fetch<Dashboard[]>({ headers: {}, method: 'GET', url: `api/search?type=dash-db&tag=billing` })
      );

      const billingDashboard = dashboards.data.find(({ uri }) => uri === 'db/billing-usage');
      return billingDashboard?.url ?? defaultUrl;
    } catch (err) {
      const error = err instanceof Error ? err : new Error('Failed to fetch billing dashboard url');
      getFaro()?.api.pushError(error);

      return defaultUrl;
    }
  }
}

function HostUsageInfoRenderer({ model }: SceneComponentProps<HostUsageInfo>) {
  const styles = useStyles2(getStyles);
  const {
    billingDashboardUrl,
    billableHostHours,
    hostHours,
    instances,
    isOpen,
    isPromLoading,
    isUsageLoading,
    services,
    setIsOpen,
  } = model.useState();

  useEffect(() => {
    if (
      billableHostHours !== undefined &&
      instances !== undefined &&
      services !== undefined &&
      hostHours !== undefined
    ) {
      trackHostUsage({ hostHours, billableHostHours, instances, services });
    }
  }, [billableHostHours, instances, services, hostHours]);

  return (
    <Modal
      className={styles.modal}
      title="Usage info"
      isOpen={isOpen}
      onDismiss={() => {
        setIsOpen(false);
      }}
    >
      <Text element="p" color="secondary">
        This is an overview of your Application Observability usage in the current month. For more information check
        your billing{' '}
        <TextLink href={billingDashboardUrl || ''} inline={false}>
          dashboard
        </TextLink>
        .
      </Text>

      <br />

      <>
        <Stack justifyContent="space-between">
          <Text>Billable host hours</Text>
          <Text>
            {isUsageLoading ? (
              <LoadingPlaceholder className={styles.loadingPlaceholder} text="Loading billable host hours" />
            ) : (
              String(billableHostHours)
            )}
          </Text>
        </Stack>

        <Divider spacing={1} />

        <Stack justifyContent="space-between">
          <Text>Total host hours</Text>
          <Text>
            {isUsageLoading ? (
              <LoadingPlaceholder className={styles.loadingPlaceholder} text="Loading host hours" />
            ) : (
              String(hostHours)
            )}
          </Text>
        </Stack>

        <Divider spacing={1} />

        <Stack justifyContent="space-between">
          <Text>Services</Text>
          <Text>
            {isPromLoading ? (
              <LoadingPlaceholder className={styles.loadingPlaceholder} text="Loading services" />
            ) : (
              String(services)
            )}
          </Text>
        </Stack>

        <Divider spacing={1} />

        <Stack justifyContent="space-between">
          <Text>Service instances</Text>
          <Text>
            {isPromLoading ? (
              <LoadingPlaceholder className={styles.loadingPlaceholder} text="Loading instances" />
            ) : (
              String(instances)
            )}
          </Text>
        </Stack>
      </>
      <Modal.ButtonRow>
        <Button
          variant="primary"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          Close
        </Button>
      </Modal.ButtonRow>
    </Modal>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    modal: css`
      width: 480px;
    `,
    loadingPlaceholder: css`
      margin-bottom: 0;
    `,
  };
}
