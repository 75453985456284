import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import {
  SceneComponentProps,
  SceneObjectBase,
  SceneObjectState,
  SceneObjectUrlSyncConfig,
  SceneObjectUrlValues,
} from '@grafana/scenes';
import { Select, useStyles2 } from '@grafana/ui';

export enum DurationAggregationFunction {
  P99 = 'p99',
  P95 = 'p95',
  AVG = 'avg',
}

interface AggregationDropdownSceneState extends SceneObjectState {
  aggregationFunction: DurationAggregationFunction;
}

export class AggregationDropdownScene extends SceneObjectBase<AggregationDropdownSceneState> {
  static Component = AggregationDropdownComponent;

  protected _urlSync = new SceneObjectUrlSyncConfig(this, { keys: ['durationAggregation'] });

  constructor() {
    super({
      aggregationFunction: DurationAggregationFunction.P95,
    });
  }

  getUrlState() {
    return { durationAggregation: this.state.aggregationFunction };
  }

  updateFromUrl(values: SceneObjectUrlValues) {
    if (values.durationAggregation) {
      this.setState({ aggregationFunction: values.durationAggregation as DurationAggregationFunction });
    }
  }
}

const DurationDropdownOptions: Array<SelectableValue<DurationAggregationFunction>> = [
  {
    label: 'P99',
    value: DurationAggregationFunction.P99,
  },
  {
    label: 'P95',
    value: DurationAggregationFunction.P95,
  },
  {
    label: 'AVG',
    value: DurationAggregationFunction.AVG,
  },
];

export function AggregationDropdownComponent({ model }: SceneComponentProps<AggregationDropdownScene>) {
  const { aggregationFunction } = model.useState();

  const styles = useStyles2(getStyles);

  return (
    <Select
      id="duration-aggregation-function"
      inputId="duration-aggregation-function-input"
      className={styles.dropdown}
      options={DurationDropdownOptions}
      value={aggregationFunction}
      onChange={(val) => {
        if (val.value) {
          model.setState({ aggregationFunction: val.value });
        }
      }}
    />
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  dropdown: css`
    min-height: ${theme.spacing(3)};
    background: ${theme.colors.secondary.main};
    border-color: ${theme.colors.secondary.main};
  `,
});
