import { map } from 'rxjs';

import { CustomTransformOperator, DataLink, DataLinkClickEvent, urlUtil } from '@grafana/data';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { TRACEQL_TYPE } from 'constants/query';
import { ROUTES } from 'constants/routing';
import { TEMPO_DS_TYPE } from 'constants/variables';
import { getDataSourceService } from 'services/DataSourceService';
import { getRenderService } from 'services/RenderService';
import { encodeURIParameter } from 'utils/routing';
import { getFullInterpolatedTracesQuery, prepareTracesQueryForUninstrumentedExplore } from 'utils/tempo';

export const updateTraceIdLinkTransformation: (
  isInstrumented: boolean,
  getScene: () => ExplorablePanel
) => CustomTransformOperator = (isInstrumented, getScene) => () => {
  const isRunningAsExtension = getRenderService().isRunningAsExtension();
  if (isRunningAsExtension) {
    return (source) => source;
  }

  return (source) =>
    source.pipe(
      map((frames) =>
        frames.map((frame) => {
          const field = frame.fields.find(({ name }) => name.toLowerCase() === 'traceid');

          if (!field) {
            return frame;
          }

          const newLink: DataLink = {
            title: 'See trace',
            url: '',
            onBuildUrl: (evt) => buildTracesUrl(evt, isInstrumented, getScene),
          };

          field.config.links = [newLink];
          return frame;
        })
      )
    );
};

function buildTracesUrl(evt: DataLinkClickEvent, isInstrumented: boolean, getScene: () => ExplorablePanel): string {
  const traceId = evt.replaceVariables?.('${__value.raw}');

  if (!isInstrumented) {
    const job = evt.replaceVariables?.('${job}') || '';
    const tempoQuery = getFullInterpolatedTracesQuery({
      interpolate: evt.replaceVariables,
      job,
      operation: undefined,
      withError: false,
      getScene,
    });
    prepareTracesQueryForUninstrumentedExplore(tempoQuery, evt.replaceVariables!);

    const datasource = {
      type: TEMPO_DS_TYPE,
      uid: getDataSourceService().getSelectedDataSourceUID(TEMPO_DS_TYPE),
    };

    return urlUtil.renderUrl('/explore', {
      left: JSON.stringify({
        datasource,
        queries: [tempoQuery],
      }),
      right: JSON.stringify({
        datasource,
        queries: [
          {
            queryType: TRACEQL_TYPE,
            query: traceId,
            refId: 'A',
            limit: 20,
            datasource,
            tableType: 'traces',
          },
        ],
      }),
    });
  }

  const job = encodeURIParameter(evt.replaceVariables?.('${job}') || '');
  const link = `${ROUTES.traces(job)}?\${__url_time_range}&\${__all_variables}&traceId=${traceId}`;

  return evt.replaceVariables?.(link) || '';
}
