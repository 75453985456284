import React from 'react';

import { LoadingState } from '@grafana/data';
import { SceneComponentProps, sceneGraph, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import { Alert, Field, LoadingPlaceholder, MultiSelect, useStyles2, VerticalGroup } from '@grafana/ui';

import { getResourceAttributesFromPanelData, safelySaveAttributes } from 'utils/config';
import { getEnvironmentAttribute } from 'utils/environmentFilter';
import { getGroupByFilterByAttributes, RESOURCE_PREFIX, SPAN_PREFIX } from 'utils/groupByFilterBy';

import { getCommonStyles } from './styles';

export interface GroupByFilterByConfigState extends SceneObjectState {
  isLoading: boolean;
  isValid: boolean;
  attributes: Array<{
    label: string;
    value: string;
  }>;
  isSubmitting: boolean;
  selectedAttributes: string[];
}

export class GroupByFilterByConfigScene extends SceneObjectBase<GroupByFilterByConfigState> {
  static Component = GroupByFilterByConfigRenderer;

  constructor() {
    // We do not want to handle environment attribute from this section of the config
    const environmentAttribute = getEnvironmentAttribute();
    const selectedAttributes = getGroupByFilterByAttributes({ withEnvironmentAttribute: false }).filter(
      (attribute) => attribute !== environmentAttribute
    );

    super({
      isLoading: true,
      isValid: false,
      attributes: [],
      isSubmitting: false,
      selectedAttributes,
    });

    this.addActivationHandler(() => {
      const unsubscribable = sceneGraph.getData(this).subscribeToState(({ data }) => {
        const isLoading = !data || data.state !== LoadingState.Done;
        const isValid = !isLoading && !data.errors;

        if (!isValid) {
          this.setState({ isValid, isLoading });
          return;
        }

        const attributes = getResourceAttributesFromPanelData(data)
          .filter(({ value }) => value !== environmentAttribute)
          .map(({ label, value }) => ({
            label: label,
            value: `${RESOURCE_PREFIX}${value}`,
            description: 'Resource attribute',
          }));

        if (selectedAttributes && selectedAttributes.length > 0) {
          // Manually add the currently selected attributes
          // This can happen because we allow users filter on attributes not yet
          // Available in their data
          selectedAttributes.forEach((attribute) => {
            const attributeExists = attributes.some(
              ({ label, value }) =>
                attribute === label ||
                attribute === value ||
                (environmentAttribute &&
                  (attribute === environmentAttribute || attribute.includes(environmentAttribute)))
            );

            if (!attributeExists) {
              attributes.push({ label: attribute, value: attribute, description: '' });
            }
          });
        }

        this.setState({ isLoading, isValid, attributes, selectedAttributes });
      });

      return () => unsubscribable.unsubscribe();
    });
  }

  async onSave(attributes: string[]) {
    this.setState({ isSubmitting: true });

    const success = await safelySaveAttributes(
      {
        newConfig: { groupByFilterByAttributes: attributes },
        oldConfig: { groupByFilterByAttributes: this.state.selectedAttributes },
      },
      {
        newAttributes: this.removePrefix(attributes),
        oldAttributes: this.removePrefix(this.state.selectedAttributes),
      }
    );
    const newState: Partial<GroupByFilterByConfigState> = { isSubmitting: false, isValid: success };
    if (success) {
      newState.selectedAttributes = attributes;
    }

    this.setState(newState);
  }

  private removePrefix(attributes: string[]): string[] {
    return attributes.map((attribute) => {
      if (attribute.startsWith(RESOURCE_PREFIX)) {
        return attribute.replace(RESOURCE_PREFIX, '');
      }

      if (attribute.startsWith(SPAN_PREFIX)) {
        return attribute.replace(SPAN_PREFIX, '');
      }

      return attribute;
    });
  }
}

function GroupByFilterByConfigRenderer({ model }: SceneComponentProps<GroupByFilterByConfigScene>) {
  const commonStyles = useStyles2(getCommonStyles);
  const { isLoading, isValid, attributes, isSubmitting, selectedAttributes } = model.useState();

  if (isLoading) {
    return <LoadingPlaceholder text="Loading configuration" />;
  }

  return (
    <>
      <hr className={commonStyles.separator} />

      <h4 className={commonStyles.sectionTitle}>Filter and Group By</h4>

      <div className={commonStyles.container}>
        {!isValid && (
          <Alert title="Something went wrong">
            <VerticalGroup>
              <div>Please try reloading the page</div>
            </VerticalGroup>
          </Alert>
        )}

        <h6>Filter by and group by semantic attribute</h6>

        <Field label="Select the resource attribute for your filter by and group by selectors">
          <MultiSelect
            disabled={!isValid || isSubmitting}
            options={attributes}
            value={selectedAttributes}
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            onChange={(values) => {
              const selected = values.filter(({ value }) => value !== undefined).map(({ value }) => value!);

              model.onSave(selected);
            }}
            allowCustomValue
            onCreateOption={(value) => {
              attributes.push({ label: value, value });

              model.onSave([...selectedAttributes, value]);
            }}
          />
        </Field>
      </div>
    </>
  );
}
