import React, { useCallback, useState } from 'react';

import { EmbeddedScene } from '@grafana/scenes';
import { Button, Field, useStyles2 } from '@grafana/ui';

import { DeactivateModal } from 'components/DeactivateModal';
import { InitializeModal } from 'components/InitializeModal';
import { ACCESS_CONTROL } from 'constants/accessControl';
import { InitializationCompleteBusEvent, ShowLandingPageBusEvent } from 'modules/initialize/initializeEvents';
import { getOverridesService } from 'services/OverridesService';
import {
  trackOptinModalCancel,
  trackOptinModalOpen,
  trackOptoutModalCancel,
  trackOptoutModalOpen,
} from 'utils/tracking';

import { SpanMetricsSource } from './spanMetricsSource/SpanMetricsSource';
import { getCommonStyles } from './styles';

export interface SystemTabProps {
  getScene: () => EmbeddedScene;
}

export const SystemTab = ({ getScene }: SystemTabProps) => {
  const styles = useStyles2(getCommonStyles);

  const [hasOverrides, setHasOverrides] = useState(getOverridesService().hasInitializationOverrides());
  const [isDeActivationModalOpened, setIsDeActivationModalOpened] = useState(false);

  const handleDeactivationModalToggle = (value: boolean) => {
    setIsDeActivationModalOpened(value);

    if (value) {
      trackOptoutModalOpen();
    } else {
      trackOptoutModalCancel();
    }
  };

  const handleInitializationModalToggle = (value: boolean) => {
    setIsDeActivationModalOpened(value);

    if (value) {
      trackOptinModalOpen('config');
    } else {
      trackOptinModalCancel('config');
    }
  };

  const deactivateOverrides = useCallback(() => {
    setHasOverrides(false);
    getScene().publishEvent(new ShowLandingPageBusEvent(), true);
  }, [getScene]);

  const initializeOverrides = useCallback(() => {
    setHasOverrides(true);
    getScene().publishEvent(new InitializationCompleteBusEvent({ redirect: true }), true);
  }, [getScene]);

  if (!ACCESS_CONTROL.PLUGIN.INITIALIZE) {
    return null;
  }

  return (
    <>
      <SpanMetricsSource getScene={getScene} updateHasOverrides={setHasOverrides} />

      {hasOverrides ? (
        <>
          <Field
            label="Grafana Cloud traces metric generation"
            description={
              <>
                Stop generating metrics from traces in Grafana Cloud. To learn more about metric generation, consult the{' '}
                <a
                  href="https://grafana.com/docs/grafana-cloud/monitor-applications/application-observability"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.link}
                >
                  Application Observability documentation
                </a>
                .
              </>
            }
          >
            <Button
              variant="destructive"
              data-cy="config-show-deactivate-button"
              onClick={() => handleDeactivationModalToggle(true)}
            >
              Deactivate
            </Button>
          </Field>

          <DeactivateModal
            isOpened={isDeActivationModalOpened}
            onDeactivate={deactivateOverrides}
            onClose={() => handleDeactivationModalToggle(false)}
          />
        </>
      ) : (
        <>
          <Field
            label="Grafana Cloud traces metric generation"
            description={
              <>
                Start generating metrics from traces in Grafana Cloud. To learn more about metric generation, consult
                the{' '}
                <a
                  href="https://grafana.com/docs/grafana-cloud/monitor-applications/application-observability"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.link}
                >
                  Application Observability documentation
                </a>
                .
              </>
            }
          >
            <Button data-cy="config-show-activate-button" onClick={() => handleInitializationModalToggle(true)}>
              Activate
            </Button>
          </Field>

          <InitializeModal
            isOpened={isDeActivationModalOpened}
            page="config"
            onInitialize={initializeOverrides}
            onClose={() => handleInitializationModalToggle(false)}
          />
        </>
      )}
    </>
  );
};
