import { SceneQueryRunner } from '@grafana/scenes';
import { ThresholdsMode } from '@grafana/schema';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { PROMETHEUS_DS } from 'constants/datasources';
import { DEFAULT_FIELD_CONFIG } from 'constants/panels';

export function makeServiceJvmGarbageCollectionPanel(): ExplorablePanel {
  return new ExplorablePanel({
    description: 'Percentage of time spend for garbage collection pauses',

    title: 'Garbage Collection',

    trackingSection: 'service',
    trackingPage: 'jvm',
    trackingPanel: 'garbage-collection',

    fieldConfig: {
      defaults: {
        ...DEFAULT_FIELD_CONFIG,
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
          ],
        },
        unit: 'percentunit',
      },
      overrides: [],
    },

    $data: new SceneQueryRunner({
      datasource: PROMETHEUS_DS,
      maxDataPointsFromWidth: true,
      queries: [
        {
          expr: '(sum by(instance) (rate({__name__=~"jvm_gc_duration_sum|jvm_gc_duration_seconds_sum|process_runtime_jvm_gc_duration_sum|process_runtime_jvm_gc_duration_seconds_sum", job="${job}", instance=~"${instance:regex}"}[$__rate_interval])))\nor\n(sum by(instance) (rate({__name__=~"jvm_gc_pause_sum|jvm_gc_pause_seconds_sum", job="${job}", instance=~"${instance:regex}"}[$__rate_interval])))\nor\n(sum by(instance) (rate(jvm_gc_pause_milliseconds_sum{job="${job}", instance=~"${instance:regex}"}[$__rate_interval])) / 1000)',
          refId: 'serviceJvmGarbageCollection',
        },
      ],
    }),
  });
}
