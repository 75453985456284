import { PRESERVE_URL_KEYS, ROUTE_DEFINITIONS, ROUTES } from 'constants/routing';
import { TrackedSceneAppPage } from 'faro/TrackedSceneAppPage';

import { makeServiceServiceMapScene } from './makeServiceServiceMapScene';

export function makeServiceServiceMapPage(job: string, encodedJob: string): TrackedSceneAppPage {
  return new TrackedSceneAppPage({
    viewName: 'service-service-map',
    title: 'Service Map',
    routePath: ROUTE_DEFINITIONS.serviceMap,
    url: ROUTES.serviceMap(encodedJob),
    preserveUrlKeys: PRESERVE_URL_KEYS,
    getScene: makeServiceServiceMapScene(job),
  });
}
