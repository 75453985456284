import { PyroscopeQuery } from 'types/queries';
import { parseJob } from 'utils/services';

type PyroscopeQueryValidation = {
  isValid: boolean;
  errors: string[];
};

export function isValidQuery(query: PyroscopeQuery | undefined | null, job: string): PyroscopeQueryValidation {
  const result: PyroscopeQueryValidation = {
    isValid: true,
    errors: [],
  };

  if (!query || !query.query) {
    result.isValid = false;
    result.errors.push('missing query string');
    return result;
  }

  const labels = getLabelsFromQuery(query);
  const { serviceName, serviceNamespace } = parseJob(job);

  if (serviceNamespace) {
    const label = labels.find(([name, _]) => name === 'namespace');
    if (!label) {
      result.errors.push('missing namespace');
    } else if (label[1] !== serviceNamespace) {
      result.errors.push(`must use ${serviceNamespace} as a namespace`);
    }
  }

  const label = labels.find(([name, _]) => name === 'service_name');
  if (!label) {
    result.errors.push('missing service name');
  } else if (label[1] !== serviceName) {
    result.errors.push(`must use ${serviceName} as a service name`);
  }

  result.isValid = result.errors.length === 0;
  return result;
}

function getLabelsFromQuery(query: PyroscopeQuery): string[][] {
  if (!query.labelSelector) {
    return [];
  }

  // Capture all the label selectors within the braces.
  const labelSelector = query.labelSelector
    .trim()
    .replace(/^\{+|\{+$/g, '') // Remove '{'.
    .replace(/^\}+|\}+$/g, ''); // Remove '}'.

  // Trim surrounding whitespace, quote characters, then return an array of
  // pairs.
  return labelSelector
    .trim()
    .split(',')
    .map((s) =>
      s
        .trim()
        .split('=')
        .map((v) => v.trim().replace(/^"+|"+$/g, ''))
    );
}
