import { PRESERVE_URL_KEYS, ROUTE_DEFINITIONS, ROUTES } from 'constants/routing';
import { TrackedSceneAppPage } from 'faro/TrackedSceneAppPage';

import { makeServiceProfilesScene } from './makeServiceProfilesScene';

export function makeServiceProfilesPage(job: string, encodedJob: string): TrackedSceneAppPage {
  return new TrackedSceneAppPage({
    viewName: 'service-profiles',
    title: 'Profiles',
    routePath: ROUTE_DEFINITIONS.profiles,
    url: ROUTES.profiles(encodedJob),
    preserveUrlKeys: PRESERVE_URL_KEYS,
    getScene: makeServiceProfilesScene(job),
  });
}
