import { SceneObjectUrlSyncHandler, SceneObjectUrlValue, SceneObjectUrlValues } from '@grafana/scenes';

import { AttributeFilter, FilterByVariable, prepareFilter } from './FilterByVariable';

export class FilterByVariableUrlSyncHandler implements SceneObjectUrlSyncHandler {
  constructor(private _variable: FilterByVariable) {}

  private getKey(): string {
    return `var-${this._variable.state.name}`;
  }

  getKeys(): string[] {
    return [this.getKey()];
  }

  getUrlState(): SceneObjectUrlValues {
    const filters = this._variable.state.filters;

    if (filters.length === 0) {
      return { [this.getKey()]: [] };
    }

    const value = filters
      .reduce<AttributeFilter[]>((acc, filter) => {
        const separatedFilters =
          filter.value.length > 1 ? filter.value.map((value) => ({ ...filter, value: [value] })) : [filter];

        return [...acc, ...separatedFilters];
      }, [])
      .map((filter) => toArray(filter).map(escapeDelimiter).join('|'));

    return { [this.getKey()]: value };
  }

  updateFromUrl(values: SceneObjectUrlValues): void {
    const urlValue = values[this.getKey()];

    if (urlValue == null) {
      return;
    }

    const filters = deserializeUrlToFilters(urlValue);
    this._variable.setState({ filters });
  }
}

function deserializeUrlToFilters(value: SceneObjectUrlValue): AttributeFilter[] {
  if (Array.isArray(value)) {
    const values = value;
    const uniqueValues = values
      .map(toFilter)
      .filter(isFilter)
      .reduce<Record<string, AttributeFilter>>((acc, filter) => {
        const currentFilter = acc[filter.key];
        if (currentFilter == null) {
          return { ...acc, [filter.key]: filter };
        }

        return {
          ...acc,
          [filter.key]: {
            ...currentFilter,
            value: [...currentFilter.value, ...filter.value],
          },
        };
      }, {});

    return Object.values(uniqueValues);
  }

  const filter = toFilter(value);
  return filter === null ? [] : [filter];
}

function escapeDelimiter(value: string | undefined): string {
  if (value === null || value === undefined) {
    return '';
  }

  return /\|/g[Symbol.replace](value, '__gfp__');
}

function unescapeDelimiter(value: string | undefined): string {
  if (value === null || value === undefined) {
    return '';
  }

  return /__gfp__/g[Symbol.replace](value, '|');
}

function toArray(filter: AttributeFilter): string[] {
  return [filter.key, filter.operator, prepareFilter(filter.value)];
}

function toFilter(value: string | number | boolean | undefined | null): AttributeFilter | null {
  if (typeof value !== 'string' || value.length === 0) {
    return null;
  }

  const parts = value.split('|').map(unescapeDelimiter);

  return {
    key: parts[0],
    operator: parts[1],
    value: parts[2] ? [parts[2]] : [],
    condition: '',
  };
}

function isFilter(filter: AttributeFilter | null): filter is AttributeFilter {
  return (
    filter !== null &&
    typeof filter.key === 'string' &&
    (Array.isArray(filter.value) || typeof filter.value === 'string')
  );
}
