import { css } from '@emotion/css';
import React from 'react';

import { DataSourceApi, GrafanaTheme2 } from '@grafana/data';
import { getDataSourceSrv } from '@grafana/runtime';
import {
  SceneComponentProps,
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { Alert, Button, useStyles2, VerticalGroup } from '@grafana/ui';

import { PYROSCOPE_DS } from 'constants/datasources';
import { INPUT_DEBOUNCE_MS } from 'constants/misc';
import { PYROSCOPE_DS_NAME } from 'constants/variables';
import { PyroscopeQuery } from 'types/queries';
import { getSelectedDataSourceName } from 'utils/datasources';

import { isValidQuery } from './utils';

export interface ProfilesSearchEditorState extends SceneObjectState {
  job: string;
  query: PyroscopeQuery;
  updateQuery: (query: PyroscopeQuery) => void;
}

export type InternalProfilesSearchEditorState = SceneObjectState &
  ProfilesSearchEditorState & {
    dataSourceId: string;
    isValid: boolean;
    errors: string[];
    pendingChanges: NodeJS.Timeout | undefined;
    editor: HTMLDivElement | null;
  };

export class ProfilesSearchEditor extends SceneObjectBase<InternalProfilesSearchEditorState> {
  dataSource: DataSourceApi | undefined;
  loadingDataSource = false;

  static Component = ProfilesSearchEditorRenderer;

  protected _variableDependency = new VariableDependencyConfig(this, {
    statePaths: ['dataSourceId'],
    onVariableUpdateCompleted: this.loadDataSource.bind(this),
  });

  constructor(state: ProfilesSearchEditorState) {
    super({
      ...state,
      dataSourceId: PYROSCOPE_DS.uid,
      isValid: true,
      errors: [],
      pendingChanges: undefined,
      editor: null,
    });

    this.addActivationHandler(this.loadDataSource.bind(this));

    this.onChange = this.onChange.bind(this);
    this.propagateQuery = this.propagateQuery.bind(this);
  }

  onChange(query: PyroscopeQuery): void {
    clearTimeout(this.state.pendingChanges);

    this.setState({
      query,
      pendingChanges: setTimeout(() => this.propagateQuery(), INPUT_DEBOUNCE_MS),
    });
  }

  propagateQuery(): void {
    this.state.editor?.focus();

    clearTimeout(this.state.pendingChanges);

    const { isValid, errors } = isValidQuery(this.state.query, this.state.job);
    this.setState({ isValid, errors });

    if (isValid) {
      this.state.updateQuery(this.state.query);
    }
  }

  private loadDataSource() {
    if (!sceneGraph.hasVariableDependencyInLoadingState(this) && !this.loadingDataSource) {
      this.loadingDataSource = true;
      getDataSourceSrv()
        .get(getSelectedDataSourceName(this, PYROSCOPE_DS_NAME))
        .then((ds) => {
          this.dataSource = ds;
          this.forceRender();
        });
    }
  }
}

export function ProfilesSearchEditorRenderer({ model }: SceneComponentProps<ProfilesSearchEditor>) {
  const styles = useStyles2(getStyles);
  const { editor, isValid, errors, query } = model.useState();

  if (model.dataSource && query) {
    const Editor = model.dataSource.components?.QueryEditor;

    const buildErrorList = (errors: string[]) => {
      return errors.length === 0 ? (
        <span>malformed query</span>
      ) : (
        <ul>
          {errors.map((err, idx) => (
            <li key={idx}>{err}</li>
          ))}
        </ul>
      );
    };

    if (Editor) {
      return (
        <div
          className={styles.editorContainer}
          ref={(elem) => {
            if (elem !== editor) {
              model.setState({ editor: elem });
            }
          }}
        >
          <Button
            className={styles.runButton}
            variant="primary"
            onClick={model.propagateQuery}
            data-fs-element="App o11y - Run query"
          >
            Run query
          </Button>

          <div className={styles.editor}>
            <Editor
              datasource={model.dataSource}
              query={query}
              onChange={model.onChange}
              onRunQuery={model.propagateQuery}
            />
          </div>

          {!isValid && (
            <Alert className={styles.alert} title="Invalid query" severity="warning">
              <VerticalGroup>{buildErrorList(errors)}</VerticalGroup>
            </Alert>
          )}
        </div>
      );
    }
  }

  return <p>Loading profiles search...</p>;
}

function getStyles(theme: GrafanaTheme2) {
  return {
    editorContainer: css`
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    `,
    runButton: css`
      height: 24px;
      line-height: 22px;
      vertical-align: middle;
      padding: 0 7px;
      font-size: 12px;
      font-weight: 500;
      margin-top: 4px;

      margin-bottom: ${theme.spacing(1)};
    `,
    editor: css`
      width: 100%;
    `,
    alert: css`
      margin-top: ${theme.spacing(1)};
      margin-bottom: ${theme.spacing(1)};
      width: 100%;
    `,
  };
}
