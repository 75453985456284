import { Route } from 'react-router-dom';

import {
  BrowserConfig,
  initializeFaro as coreInit,
  Faro,
  getWebInstrumentations,
  ReactIntegration,
  ReactRouterHistory,
  ReactRouterVersion,
} from '@grafana/faro-react';
import { locationService } from '@grafana/runtime';

import { ENV } from 'constants/environment';
import { FARO_ENDPOINT, FARO_GLOBAL_OBJECT_KEY } from 'constants/faro';
import { PLUGIN_ID, PLUGIN_VERSION } from 'constants/plugin';
import { USER } from 'constants/user';

import { setFaro } from './instance';

export function getLocalhostConfig(): Partial<BrowserConfig> {
  return {
    transports: [],
    app: {
      environment: 'localhost',
    },
  };
}

export function getEnvConfig(environment: string): Partial<BrowserConfig> {
  return {
    url: FARO_ENDPOINT,
    app: {
      environment,
    },
  };
}

export function getConfig(): Partial<BrowserConfig> {
  switch (true) {
    case ENV.LOCAL:
      return getLocalhostConfig();

    case ENV.DEV:
      return getEnvConfig('dev');

    case ENV.OPS:
      return getEnvConfig('ops');

    default:
      return getEnvConfig('prod');
  }
}

export function initializeFaro(): Faro {
  const baseConfig = getConfig();

  let faro: Faro = Object.prototype.hasOwnProperty.call(window, FARO_GLOBAL_OBJECT_KEY)
    ? (window as any)[FARO_GLOBAL_OBJECT_KEY]
    : undefined;

  const isFromCache = !!faro;

  faro =
    faro ??
    coreInit({
      ...baseConfig,
      isolate: true,
      globalObjectKey: FARO_GLOBAL_OBJECT_KEY,
      beforeSend: (event) => {
        if (!(event.meta.page?.url ?? '').includes(PLUGIN_ID)) {
          return null;
        }

        return event;
      },
      instrumentations: [
        ...getWebInstrumentations({
          captureConsole: true,
        }),
        new ReactIntegration({
          router: {
            version: ReactRouterVersion.V5,
            dependencies: {
              history: locationService.getHistory() as ReactRouterHistory,
              Route,
            },
          },
        }),
      ],
      app: {
        ...baseConfig.app,
        name: PLUGIN_ID,
        version: PLUGIN_VERSION,
      },
      user: {
        id: String(USER.ID),
        username: String(USER.NAME),
        attributes: {
          orgId: String(USER.ORG_ID),
          orgName: String(USER.ORG_NAME),
          isSignedIn: String(USER.IS_SIGNED_IN),
        },
      },
      sessionTracking: {
        enabled: true,
        persistent: true,
      },
      ignoreErrors: [
        // Console
        'Possible side-effect in debug-evaluate',

        // Browser extensions
        'is not a child of this node',

        // Network errors
        'Unexpected end of JSON input',
        'JSON.parse: unexpected end of data at',
        'JSON Parse error: Unexpected EOF',

        // Grafana
        'ResizeObserver loop completed',
        'ResizeObserver loop limit exceeded',

        // Random
        'Script error.',
        'Non-Error exception captured with keys',
      ],
    });

  faro.unpause();

  faro.api.pushLog([isFromCache ? 'Faro was retrieved from cache' : 'Faro was initialized']);

  setFaro(faro);

  return faro;
}
