import { css } from '@emotion/css';
import React, { ComponentType } from 'react';

import { DataSourceApi, GrafanaTheme2, QueryEditorProps } from '@grafana/data';
import { getDataSourceSrv } from '@grafana/runtime';
import {
  SceneComponentProps,
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { DataQuery } from '@grafana/schema';
import { Alert, Button, useStyles2, VerticalGroup } from '@grafana/ui';

import { TEMPO_DS } from 'constants/datasources';
import { INPUT_DEBOUNCE_MS } from 'constants/misc';
import { TRACEQL_SEARCH_TYPE } from 'constants/query';
import { ALL_VARIABLE_REGEX, TEMPO_DS_NAME } from 'constants/variables';
import {
  isValidQuery,
  removeAllValueFromQuery,
  removeAllVariableFromFilters,
} from 'modules/service/components/TracesScene/utils';
import { TempoQuery } from 'types/queries';
import { getSelectedDataSourceName } from 'utils/datasources';
import { hasEnvironmentAttribute } from 'utils/environmentFilter';
import { deepCopy } from 'utils/object';

export interface TracesSearchState {
  operation: string | undefined;
  query: TempoQuery;
  queryScope: TempoQuery;
  serviceName: string;
  serviceNamespace: string | undefined;
  updateQuery: (query: TempoQuery, forceRun: boolean) => void;
}

export type InternalTracesSearchState = SceneObjectState &
  TracesSearchState & {
    dataSourceId: string;
    isValid: boolean;
    pendingChanges: NodeJS.Timeout | undefined;
  };

export class TracesSearchEditor extends SceneObjectBase<InternalTracesSearchState> {
  static Component = TracesSearchEditorRenderer;

  protected _variableDependency = new VariableDependencyConfig(this, {
    statePaths: ['dataSourceId'],
    onVariableUpdateCompleted: this.loadDataSource.bind(this),
  });

  dataSource: DataSourceApi | undefined;

  loadingDataSource = false;

  constructor(state: TracesSearchState) {
    super({ ...state, dataSourceId: TEMPO_DS.uid, isValid: true, pendingChanges: undefined });

    this.addActivationHandler(this.loadDataSource.bind(this));

    this.onChange = this.onChange.bind(this);
    this.propagateQuery = this.propagateQuery.bind(this);
  }

  onChange(query: TempoQuery): void {
    clearTimeout(this.state.pendingChanges);

    if (query.queryType !== TRACEQL_SEARCH_TYPE) {
      // We only want the filters to change when we're on TRACEQL_SEARCH_TYPE,
      // So we must always override them with our own
      query.filters = this.state.query.filters;
    }
    this.setState({
      query,
      pendingChanges: setTimeout(() => this.propagateQuery(), INPUT_DEBOUNCE_MS),
    });
  }

  propagateQuery(forceRun = false): void {
    clearTimeout(this.state.pendingChanges);

    const isValid = isValidQuery(
      this.filterOutAllValueEnvironment(this.state.query),
      this.filterOutAllValueEnvironment(this.state.queryScope)
    );

    if (isValid && this.state.query.queryType !== 'clear') {
      // query editor tends to mutate query in-place -.-
      // this messes with our change detection, so deep copy
      this.state.updateQuery(deepCopy(this.state.query), forceRun);
    }
    if (isValid !== this.state.isValid) {
      this.setState({ isValid });
    }
  }

  private filterOutAllValueEnvironment(tempoQuery: TempoQuery): TempoQuery {
    const environmentAttribute = sceneGraph.interpolate(this, '${environmentAttribute:dotted}');

    return {
      ...tempoQuery,
      filters: removeAllVariableFromFilters(tempoQuery.filters),
      query: removeAllValueFromQuery(tempoQuery.query, environmentAttribute),
    };
  }

  private loadDataSource(): void {
    if (!sceneGraph.hasVariableDependencyInLoadingState(this) && !this.loadingDataSource) {
      this.loadingDataSource = true;

      getDataSourceSrv()
        .get(getSelectedDataSourceName(this, TEMPO_DS_NAME))
        .then((ds) => {
          this.dataSource = ds;
          this.forceRender();
        });
    }
  }
}

type TempoQueryEditorProps = QueryEditorProps<DataSourceApi, DataQuery> & {
  addVariablesToOptions: boolean;
};

export function TracesSearchEditorRenderer({ model }: SceneComponentProps<TracesSearchEditor>) {
  const styles = useStyles2(getStyles);

  const { isValid, operation, query, queryScope, serviceName, serviceNamespace } = model.useState();

  const withOperation = !!operation;
  const withEnvironmentAttribute = hasEnvironmentAttribute();

  const environmentValue = sceneGraph.interpolate(model, '${environmentValue:regex}');

  if (model.dataSource) {
    const Editor = model.dataSource.components?.QueryEditor as ComponentType<TempoQueryEditorProps> | undefined;

    if (Editor) {
      return (
        <div className={styles.editor}>
          <Button className={styles.runQueryButton} variant="primary" onClick={() => model.propagateQuery(true)}>
            Run query
          </Button>

          <Editor
            datasource={model.dataSource}
            query={query}
            onChange={model.onChange}
            onRunQuery={model.propagateQuery}
            addVariablesToOptions={false}
          />

          {!isValid && (
            <Alert className={styles.alert} title="Invalid query" severity="warning">
              <VerticalGroup>
                <div className={styles.alertMessage}>
                  {query?.queryType === TRACEQL_SEARCH_TYPE ? (
                    <>
                      Queries need to have <code>Service Name = {serviceName}</code>
                      {serviceNamespace ? (
                        <>
                          {withEnvironmentAttribute || withOperation ? ', ' : ' and '}
                          <code>Resource service.namespace = {serviceNamespace}</code>
                        </>
                      ) : (
                        ''
                      )}
                      {withEnvironmentAttribute && environmentValue !== ALL_VARIABLE_REGEX ? (
                        <>
                          {withOperation ? ', ' : ' and '}
                          <code>
                            Resource {sceneGraph.interpolate(model, '${environmentAttribute:dotted}')} ={' '}
                            {sceneGraph.interpolate(model, '${environmentValue:regex}')}
                          </code>
                        </>
                      ) : (
                        ''
                      )}
                      {withOperation ? (
                        <>
                          {' '}
                          and <code>Span Name = {operation}</code>
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    <>
                      Queries must be scoped to: <code className={styles.code}>{queryScope.query}</code>
                    </>
                  )}
                </div>
              </VerticalGroup>
            </Alert>
          )}
        </div>
      );
    }
  }

  return <p>Loading traces search...</p>;
}

function getStyles(theme: GrafanaTheme2) {
  return {
    runQueryButton: css`
      float: right;
      background: ${theme.colors.primary.main};
      color: white;
      height: 24px;
      line-height: 22px;
      vertical-align: middle;
      padding: 0 7px;
      font-size: 12px;
      font-weight: 500;
      margin-top: 4px;

      &::after {
        content: 'Run query';
      }

      & > span {
        display: none;
      }

      &:hover {
        background: ${theme.colors.primary.main};
      }
    `,
    editor: css`
      // hide serviceMap && upload && search options
      & label[for^='option-upload'],
      & input[id^='option-upload'],
      & label[for^='option-serviceMap'],
      & input[id^='option-serviceMap'],
      & label[for^='option-search'],
      & input[id^='option-search'] {
        display: none;
      }

      // Remove import trace button
      & > div[class$='InlineFieldRow'] {
        & div[class$='horizontal-group'] button[class$='button'] {
          display: none;
        }
      }
    `,
    alert: css`
      margin-top: 10px;
    `,
    alertMessage: css`
      margin-bottom: ${theme.spacing(1)};
    `,
    code: css`
      white-space: normal;
    `,
  };
}
