import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { CellProps, Icon, Stack, Tooltip, useStyles2, useTheme2 } from '@grafana/ui';

import { Badge } from 'components/Badge';
import { ShimmerPlaceholder } from 'components/ShimmerPlaceholder';
import { CLOUD_ICON, CLOUD_NAME } from 'constants/cloud';
import { TECHNOLOGY, TECHNOLOGY_ICON, TECHNOLOGY_NAME } from 'constants/technology';
import k8sIcon from 'img/icons/k8s.svg';
import { getMetadataService } from 'services/MetadataService';
import { Service } from 'types/services';
import { determineCloud } from 'utils/cloud';

export const TechnologyCell = ({
  row: {
    original: {
      job,
      loading,
      serviceName,
      serviceNamespace,
      data: { rate, errors, duration },
    },
  },
}: CellProps<Service, void>) => {
  const styles = useStyles2(getStyles);
  const theme = useTheme2();

  const metadataService = getMetadataService();

  const technology = metadataService.getTechnology(job);
  const k8s = metadataService.getK8s(job);
  const cloud = metadataService.getCloud(job);
  const cloudProvider = determineCloud(cloud[cloud.length - 1]?.cloud_provider);
  const clientKind = metadataService.getClientKind(job);
  const isInstrumented = metadataService.getIsInstrumented(job);

  const redDataLoading = rate.loading || errors.loading || duration.loading;

  if (loading || (redDataLoading && isInstrumented !== false)) {
    return <ShimmerPlaceholder className={styles.placeholder} />;
  }

  const technologyName = TECHNOLOGY_NAME[technology];
  const cloudName = CLOUD_NAME[cloudProvider];

  return (
    <Stack gap={1.5} data-cy={`servicetechnology-${serviceNamespace}-${serviceName}`}>
      {!isInstrumented && (
        <Tooltip content="Uninstrumented service is inferred from client spans of other services communicating with it">
          <Icon
            data-cy="uninstrumented-service-icon"
            color={theme.colors.text.secondary}
            className={styles.uninstrumentedIcon}
            name="channel-add"
          />
        </Tooltip>
      )}
      {technology !== TECHNOLOGY.UNKNOWN || isInstrumented ? (
        <Tooltip content={technologyName}>
          <img src={TECHNOLOGY_ICON[technology]} alt={technologyName} />
        </Tooltip>
      ) : null}

      {cloud.length > 0 ? (
        <Tooltip content={cloudName}>
          <img src={CLOUD_ICON[cloudProvider]} alt={cloudName} />
        </Tooltip>
      ) : null}

      {k8s.length > 0 ? (
        <Tooltip content="Kubernetes">
          <img src={k8sIcon} alt="k8s" />
        </Tooltip>
      ) : null}

      {clientKind && <Badge text={clientKind} />}
    </Stack>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    placeholder: css`
      width: ${theme.spacing(2.5)};
      height: ${theme.spacing(2.5)};
    `,
    uninstrumentedIcon: css`
      width: 20px;
      height: 20px;
    `,
  };
}
