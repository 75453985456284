import { escapeRegExp } from 'utils/regexp';

export const getFullTargetInfoQuery = (jobs: string | string[], withEnvironment: boolean) => {
  const operator = Array.isArray(jobs) ? '=~' : '=';

  jobs = Array.isArray(jobs) ? (jobs?.map((x) => escapeRegExp(x, true)).join('|') ?? '') : jobs;

  return `group without (foo) ($\{metricName:targetInfo}{job${operator}"${
    jobs
  }"${withEnvironment ? `, $\{environmentAttribute}=~"$\{environmentValue:regex}"` : ''}})`;
};
