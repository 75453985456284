export const DOES_NOT_MATCH_REGEX_OPERATOR = '!~';
export const ENDS_WITH_OPERATOR = '__ends_with';
export const EQUALS_OPERATOR = '=';
export const NOT_EQUALS_OPERATOR = '!=';
export const REGEX_OPERATOR = '=~';
export const STARTS_WITH_OPERATOR = '__starts_with';

export const TECHNOLOGY_FILTER = 'technology';
export const SERVICE_NAMESPACE_FILTER = 'serviceNamespace';

export const SERVICES_FILTERS = [
  { text: 'service.namespace', value: SERVICE_NAMESPACE_FILTER },
  { text: 'technology', value: TECHNOLOGY_FILTER },
];
export const SERVICES_FILTERS_KEYS = SERVICES_FILTERS.map(({ value }) => value);

export const EMPTY_FILTER_LABEL = '(empty)';
