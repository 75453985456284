import { isEqual } from 'lodash';
import React from 'react';

import {
  SceneComponentProps,
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { LinkButton } from '@grafana/ui';

import { EnvironmentValueVariable } from 'components/EnvironmentValueVariable';
import { ROUTES } from 'constants/routing';
import { TRACKING_EVENTS } from 'constants/tracking';
import { ENVIRONMENT_VALUE_NAME, JOB_NAME, OPERATION_NAME } from 'constants/variables';
import { useLinkBuilder } from 'hooks/useLinkBuilder';
import { removeAllValueFromQuery, removeAllVariableFromFilters } from 'modules/service/components/TracesScene/utils';
import { TabChange } from 'modules/service/events';
import { getTempoQuery } from 'queries/tempo';
import { getRenderService } from 'services/RenderService';
import { TempoQuery } from 'types/queries';
import { getEnvironmentAttribute } from 'utils/environmentFilter';
import { encodeParameter } from 'utils/routing';
import { trackEvent } from 'utils/tracking';

interface ErrorTracesButtonState extends SceneObjectState {
  tracesQuery?: TempoQuery;
  job?: string;
  operation?: string;
  isRunningAsExtension: boolean;
}

export class ErrorTracesButton extends SceneObjectBase<ErrorTracesButtonState> {
  static Component = ErrorTracesButtonComponent;

  protected _variableDependency = new VariableDependencyConfig(this, {
    variableNames: [JOB_NAME, OPERATION_NAME, ENVIRONMENT_VALUE_NAME],
  });

  constructor() {
    super({ isRunningAsExtension: getRenderService().isRunningAsExtension() });

    this.addActivationHandler(() => {
      const unsubscribable = (
        sceneGraph.lookupVariable(ENVIRONMENT_VALUE_NAME, this) as EnvironmentValueVariable | undefined
      )?.subscribeToState((newState, oldState) => {
        if (!isEqual(newState.value, oldState.value)) {
          this.setState({
            tracesQuery: this.buildTracesQueryString(this.state.job!),
          });
        }
      });

      const job = sceneGraph.lookupVariable(JOB_NAME, this)?.getValue() as string;

      const operationVariable = `$\{${OPERATION_NAME}}`;
      const operationValue = sceneGraph.interpolate(this, operationVariable);
      const operation = operationValue === operationVariable ? undefined : operationValue;
      this.setState({
        job,
        operation,
        tracesQuery: this.buildTracesQueryString(job, operation),
      });

      return () => unsubscribable?.unsubscribe();
    });
  }

  onClick(tracesQuery?: string) {
    trackEvent(TRACKING_EVENTS.GO_TO_ERRORS_CLICK, { isRunningAsExtension: this.state.isRunningAsExtension });

    if (this.state.isRunningAsExtension && tracesQuery) {
      this.publishEvent(new TabChange({ tab: 'traces', query: tracesQuery }), true);
    }
  }

  private buildTracesQueryString(job: string, operation?: string): TempoQuery {
    const environmentName = getEnvironmentAttribute() || '';

    const tempoQuery = getTempoQuery(job, operation, undefined, true);
    tempoQuery.query = removeAllValueFromQuery(sceneGraph.interpolate(this, tempoQuery.query), environmentName);
    tempoQuery.filters = removeAllVariableFromFilters(
      (tempoQuery.filters || []).map((filter) => ({
        ...filter,
        value: Array.isArray(filter.value)
          ? filter.value.map((v) => sceneGraph.interpolate(this, v))
          : sceneGraph.interpolate(this, filter.value),
        tag: sceneGraph.interpolate(this, filter.tag),
      }))
    );

    return tempoQuery;
  }
}

function ErrorTracesButtonComponent({ model }: SceneComponentProps<ErrorTracesButton>) {
  const buildLink = useLinkBuilder();
  const { tracesQuery, job, operation, isRunningAsExtension } = model.useState();

  if (!job) {
    return null;
  }

  const link = buildLink(
    operation
      ? ROUTES.operationTraces(encodeParameter(job), encodeParameter(operation))
      : ROUTES.traces(encodeParameter(job)),
    { tracesQuery: encodeURIComponent(JSON.stringify(tracesQuery)) }
  );

  return (
    <LinkButton
      size="sm"
      variant="secondary"
      href={isRunningAsExtension ? undefined : link}
      onClick={() => {
        model.onClick(tracesQuery?.query);
      }}
    >
      Traces
    </LinkButton>
  );
}
