import { EmbeddedScene, QueryVariable, SceneFlexItem, SceneFlexLayout, SceneRouteMatch } from '@grafana/scenes';

import { WarnBannerScene } from 'components/WarnBanner';
import { PROMETHEUS_DS } from 'constants/datasources';
import { MIN_PANEL_HEIGHT } from 'constants/styles';
import { TECHNOLOGY } from 'constants/technology';
import { ServiceRuntimeEmbeddedScene } from 'modules/service/components/ServiceRuntimeEmbeddedScene';
import { makeVariables } from 'utils/variables';

import { makeServiceDotnetCpuTimePanel } from './panels/makeServiceDotnetCpuTimePanel';
import { makeServiceDotnetGCHeapSizePanel } from './panels/makeServiceDotnetGCHeapSizePanel';
import { makeServiceDotnetProcessMemoryUsagePanel } from './panels/makeServiceDotnetProcessMemoryUsagePanel';
import { makeServiceDotnetProcessThreadsPanel } from './panels/makeServiceDotnetProcessThreadsPanel';

export function makeServiceDotnetScene(job: string): (routeMatch: SceneRouteMatch<any>) => EmbeddedScene {
  return () => {
    const gcHeapSizePanel = makeServiceDotnetGCHeapSizePanel();
    const processThreadsPanel = makeServiceDotnetProcessThreadsPanel();
    const cpuTime = makeServiceDotnetCpuTimePanel();
    const processMemoryUsage = makeServiceDotnetProcessMemoryUsagePanel();

    const panels = [gcHeapSizePanel, processThreadsPanel, cpuTime, processMemoryUsage];

    return new ServiceRuntimeEmbeddedScene({
      technology: TECHNOLOGY.DOTNET,

      panelsRefs: panels.map((panel) => panel.getRef()),

      $variables: makeVariables({ job }, [
        new QueryVariable({
          defaultToAll: true,
          description: 'The instance of the application, e.g. pod1',
          label: 'Instance',
          includeAll: true,
          isMulti: true,
          name: 'instance',
          datasource: PROMETHEUS_DS,
          allValue: '.*',
          query: {
            query: 'label_values(process_threads{job="${job}"}, instance)',
            refId: 'serviceDotnetInstances',
          },
        }),
      ]),

      body: new SceneFlexLayout({
        direction: 'column',
        children: [
          new SceneFlexLayout({
            direction: 'row',
            ySizing: 'content',
            children: [
              new SceneFlexItem({
                body: new WarnBannerScene({
                  panelsRefs: panels.map((panel) => panel.getRef()),
                  link: 'https://opentelemetry.io/docs/instrumentation/net/automatic/',
                }),
              }),
            ],
          }),

          new SceneFlexLayout({
            direction: 'row',
            ySizing: 'content',
            children: [
              new SceneFlexItem({
                minHeight: MIN_PANEL_HEIGHT,
                body: cpuTime,
              }),
              new SceneFlexItem({
                minHeight: MIN_PANEL_HEIGHT,
                body: processMemoryUsage,
              }),
            ],
          }),

          new SceneFlexLayout({
            direction: 'row',
            ySizing: 'content',
            children: [
              new SceneFlexItem({
                minHeight: MIN_PANEL_HEIGHT,
                body: processThreadsPanel,
              }),
              new SceneFlexItem({
                minHeight: MIN_PANEL_HEIGHT,
                body: gcHeapSizePanel,
              }),
            ],
          }),
        ],
      }),
    }) as unknown as EmbeddedScene;
  };
}
