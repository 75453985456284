import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { SceneComponentProps, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import { LinkButton } from '@grafana/ui';

import { PanelType } from 'constants/panels';
import { ROUTES } from 'constants/routing';
import { useLinkBuilder } from 'hooks/useLinkBuilder';
import { getRenderService } from 'services/RenderService';
import { isMaxPanelRoute } from 'utils/maxPanel';
import { encodeParameter } from 'utils/routing';
import { trackMaximizePanel } from 'utils/tracking';

export interface MaximizePanelButtonProps extends SceneObjectState {
  panel: PanelType;
  job: string;
  operation?: string;
  isRunningAsExtension: boolean;
}

export class MaximizePanelButton extends SceneObjectBase<MaximizePanelButtonProps> {
  static Component = MaximizePanelButtonRenderer;

  constructor(panel: PanelType, job: string, operation?: string) {
    super({ panel, job, operation, isRunningAsExtension: getRenderService().isRunningAsExtension() });
  }
}

function MaximizePanelButtonRenderer({ model }: SceneComponentProps<MaximizePanelButton>) {
  const { panel, job, operation, isRunningAsExtension } = model.useState();
  const buildLink = useLinkBuilder();
  const url = operation
    ? ROUTES.maxOperationPanel(encodeParameter(job), encodeParameter(operation), panel)
    : ROUTES.maxPanel(encodeParameter(job), panel);

  const { pathname } = useLocation();
  const showButton = useMemo(() => !isMaxPanelRoute(pathname), [pathname]);

  if (isRunningAsExtension || !showButton) {
    return null;
  }

  return (
    <LinkButton
      data-cy="maximizePanelButton"
      size="sm"
      variant="secondary"
      icon="expand-arrows"
      href={buildLink(url)}
      onClick={() => {
        const section = operation ? 'operation' : 'service';
        trackMaximizePanel(section, panel);
      }}
    />
  );
}
