import { isEqual, uniqWith } from 'lodash';

import { DataFrame, FieldType } from '@grafana/data';

import {
  CLOUD_PREFIX,
  EMBRACE_PREFIX,
  FARO_PREFIX,
  K8S_PREFIX,
  PROCESS_RUNTIME_PREFIX,
  TELEMETRY_SDK_LANGUAGE_KEY,
  TELEMETRY_SDK_PREFIX,
} from 'constants/services';
import { SpanKind } from 'constants/spanKind';
import { TECHNOLOGY } from 'constants/technology';
import { getFaro } from 'faro/instance';
import { ServiceMetadata } from 'types/services';
import { determineTechnology } from 'utils/technology';

import { getDataSourceService } from './DataSourceService';

let metadataService: MetadataService;

export class MetadataService {
  private store: Record<
    string,
    {
      raw: Array<Record<string, string>>;
      cache: Partial<ServiceMetadata>;
    }
  > = {};

  private baselineEnabled?: boolean;

  private labels: string[] = [];

  constructor() {
    const fetchLabels = async () => {
      try {
        const labels = await getDataSourceService().getAllAttributes();
        this.labels = labels.map(({ text }) => text);
      } catch (err) {
        getFaro()?.api.pushError(err instanceof Error ? err : new Error('Could not fetch tempo attributes'));
      }
    };

    fetchLabels();
  }

  isBaselineEnabled(): boolean {
    return this.baselineEnabled ?? false;
  }

  setIsBaselineEnabled(baselineEnabled: boolean): void {
    this.baselineEnabled = baselineEnabled;
  }

  // Resets the store completely
  // Used to clear out the store when changing the datasource
  resetStore(): void {
    this.store = {};
  }

  // Adds or overrides a service and invalidates its cache
  // This is usually used when we are 100% that the service should be refreshed
  addService(service: string, isInstrumented: boolean): void {
    this.store[service] = {
      raw: [],
      cache: {
        isInstrumented,
      },
    };
  }

  // Checks and refreshes the data that we got stored for a service
  // If the service does not exist, this will create it
  // Returns true if the service was refreshed
  refreshService(service: string, isInstrumented: boolean, dataFrames: DataFrame[]): boolean {
    let labels = dataFrames
      .map((dataFrame) => this.extractLabelsFromDataFrame(dataFrame))
      .filter((labels) => Object.keys(labels).length > 0);

    labels = uniqWith(labels, isEqual);

    if (isInstrumented === this.store[service]?.cache.isInstrumented && isEqual(this.store[service]?.raw, labels)) {
      return false;
    }

    this.store[service] = {
      raw: labels,
      cache: {
        isInstrumented,
        spanKind: this.store[service]?.cache?.spanKind,
        clientKind: this.store[service]?.cache?.clientKind,
      },
    };

    return true;
  }

  // Checks if we have a service stored
  isServiceStored(service: PropertyKey): boolean {
    return Object.prototype.hasOwnProperty.call(this.store, service);
  }

  // Adds an additional set of data and invalidates the cache for the given service as it's not up to date anymore
  addLabels(service: string, isInstrumented: boolean, dataFrame: DataFrame): void {
    const labels = this.extractLabelsFromDataFrame(dataFrame);

    if (!this.isServiceStored(service)) {
      this.addService(service, isInstrumented);
    }

    if (Object.keys(labels).length > 0 && !this.storeContainsLabels(service, labels)) {
      this.store[service].raw.push(labels);
      this.store[service].cache = {
        isInstrumented,
      };
    }
  }

  // Checks if we have the isInstrumented flag in the cache
  hasIsInstrumented(service: string): boolean {
    return this.store[service]?.cache.isInstrumented !== undefined;
  }

  // Returns the inInstrumented flag
  getIsInstrumented(service: string): boolean | undefined {
    return !!this.store[service]?.cache.isInstrumented;
  }

  // Adds spanKind available for service
  addSpanKind(service: string, spanKind: SpanKind) {
    if (!this.store[service]) {
      this.addService(service, false);
    }

    this.store[service].cache.spanKind = [...(this.store[service].cache.spanKind || []), spanKind];
  }

  // Returns spanKind available for a particular service
  getSpanKind(service: string): SpanKind[] | undefined {
    return this.store[service]?.cache?.spanKind;
  }

  getIsClientOnly(service: string): boolean {
    const spanKinds = this.getSpanKind(service);

    return (
      spanKinds !== undefined &&
      spanKinds.length > 0 &&
      !(spanKinds.includes(SpanKind.CONSUMER) || spanKinds.includes(SpanKind.SERVER))
    );
  }

  getIsBatchService(service: string): boolean {
    const spanKinds = this.getSpanKind(service);

    return (
      spanKinds !== undefined &&
      spanKinds.length > 0 &&
      (spanKinds.includes(SpanKind.CONSUMER) || spanKinds.includes(SpanKind.PRODUCER)) &&
      !(spanKinds.includes(SpanKind.CLIENT) || spanKinds.includes(SpanKind.SERVER))
    );
  }

  // Checks if we have the technology in the cache or if we can determine it based on the data that the service has
  hasTechnology(service: string): boolean {
    if (!this.isServiceStored(service)) {
      return false;
    }

    if (this.store[service].cache.technology !== undefined) {
      return true;
    }

    this.setTechnology(service);

    return this.store[service].cache.technology !== undefined;
  }

  // Returns the technology
  getTechnology(service: string): ServiceMetadata['technology'] {
    if (!this.isServiceStored(service)) {
      return TECHNOLOGY.UNKNOWN;
    }

    this.hasTechnology(service);

    return this.store[service].cache.technology ?? TECHNOLOGY.UNKNOWN;
  }

  hasTelemetrySdk(service: string): boolean {
    if (!this.isServiceStored(service)) {
      return false;
    }

    if (this.store[service].cache.technologySdk !== undefined) {
      return true;
    }

    this.setTelemetrySdk(service);

    return this.store[service].cache.technologySdk !== undefined;
  }

  getTelemetrySdk(service: string): ServiceMetadata['technologySdk'] {
    if (!this.isServiceStored(service)) {
      return [];
    }

    this.hasTelemetrySdk(service);

    return this.store[service].cache.technologySdk ?? [];
  }

  // Checks if we have the processRuntime in the cache or if we can determine it based on the data that the service has
  hasProcessRuntime(service: string): boolean {
    if (!this.isServiceStored(service)) {
      return false;
    }

    if (this.store[service].cache.processRuntime !== undefined) {
      return true;
    }

    this.setProcessRuntime(service);

    return this.store[service].cache.processRuntime !== undefined;
  }

  // Returns the processRuntime
  getProcessRuntime(service: string): ServiceMetadata['processRuntime'] {
    if (!this.isServiceStored(service)) {
      return [];
    }

    this.hasProcessRuntime(service);

    return this.store[service].cache.processRuntime ?? [];
  }

  // Checks if we have the cloud in the cache or if we can determine it based on the data that the service has
  hasCloud(service: string): boolean {
    if (!this.isServiceStored(service)) {
      return false;
    }

    if (this.store[service].cache.cloud !== undefined) {
      return true;
    }

    this.setCloud(service);

    return this.store[service].cache.cloud !== undefined;
  }

  // Returns the cloud
  getCloud(service: string): ServiceMetadata['cloud'] {
    if (!this.isServiceStored(service)) {
      return [];
    }

    this.hasCloud(service);

    return this.store[service].cache.cloud ?? [];
  }

  hasClientInfo(service: string): boolean {
    if (!this.isServiceStored(service)) {
      return false;
    }

    if (this.store[service].cache.client !== undefined) {
      return true;
    }

    this.setClientInfo(service);

    return this.store[service].cache.client !== undefined;
  }

  getClientInfo(service: string): ServiceMetadata['client'] {
    if (!this.isServiceStored(service)) {
      return [];
    }

    this.hasClientInfo(service);

    return this.store[service].cache.client ?? [];
  }

  getClientKind(service: string): ServiceMetadata['clientKind'] | undefined {
    if (!this.isServiceStored(service)) {
      return undefined;
    }

    return this.store[service]?.cache?.clientKind;
  }

  setClientInfo(service: string) {
    this.store[service].cache.client = undefined;
    this.store[service].cache.clientKind = undefined;
    const webData = this.genericProcessor(service, FARO_PREFIX);
    if (webData.length > 0) {
      this.store[service].cache.client = webData;
      this.store[service].cache.clientKind = 'web';
      return;
    }

    const mobileData = this.genericProcessor(service, EMBRACE_PREFIX);
    if (mobileData.length > 0) {
      this.store[service].cache.client = webData;
      this.store[service].cache.clientKind = 'mobile';
      return;
    }

    if (this.getIsBatchService(service)) {
      this.store[service].cache.clientKind = 'batch';
      return;
    } else if (this.getIsClientOnly(service)) {
      this.store[service].cache.clientKind = 'client';
      return;
    }
  }

  // Checks if we have the k8s in the cache or if we can determine it based on the data that the service has
  hasK8s(service: string): boolean {
    if (!this.isServiceStored(service)) {
      return false;
    }

    if (this.store[service].cache.k8s !== undefined) {
      return true;
    }

    this.setK8s(service);

    return this.store[service].cache.k8s !== undefined;
  }

  // Returns the k8s
  getK8s(service: string): ServiceMetadata['k8s'] {
    if (!this.isServiceStored(service)) {
      return [];
    }

    this.hasK8s(service);

    return this.store[service].cache.k8s ?? [];
  }

  getOriginalLabelName(target: string): string | undefined {
    const cleanLabel = (label: string) => label.replaceAll('.', '').replaceAll('_', '');
    return this.labels.find((label) => cleanLabel(label) === cleanLabel(target));
  }

  // Returns the labels object from a data frame
  // It also filters out internal properties
  private extractLabelsFromDataFrame(dataFrame: DataFrame): Record<string, string> {
    const labels: Record<string, string> =
      dataFrame.fields.find((field) => field.type === FieldType.number)?.labels ?? {};

    return Object.entries(labels).reduce<Record<string, string>>((acc, [key, value]) => {
      if (!key.startsWith('__')) {
        acc[key] = value;
      }

      return acc;
    }, {});
  }

  // Checks if a set of labels is already present in the store
  private storeContainsLabels(service: string, labels: Record<string, string>): boolean {
    return this.store[service].raw.some((storedLabelsEntry) => isEqual(storedLabelsEntry, labels));
  }

  // Tries to determines the technology and sets it in the cache
  private setTechnology(service: string): void {
    const telemetrySdkLanguage = this.store[service].raw.reduceRight<string | undefined>((acc, labelsSet) => {
      if (acc !== undefined) {
        return acc;
      }

      return labelsSet[TELEMETRY_SDK_LANGUAGE_KEY];
    }, undefined);

    if (telemetrySdkLanguage) {
      this.store[service].cache.technology = determineTechnology(telemetrySdkLanguage);
    }
  }

  // A generic process to parse labels based on a prefix
  private genericProcessor(service: string, prefix: string): Array<Record<string, string>> {
    return uniqWith(
      this.store[service].raw.reduce<Array<Record<string, string>>>((acc, labels) => {
        const entry = Object.entries(labels).reduce<Record<string, string>>((acc, [key, value]) => {
          if (key.startsWith(prefix)) {
            acc[key] = value;
          }

          return acc;
        }, {});

        if (Object.keys(entry).length > 0) {
          acc.push(entry);
        }

        return acc;
      }, []),
      isEqual
    );
  }

  // Parses the raw labels and tries to build the processRuntime cache
  private setProcessRuntime(service: string): void {
    const data = this.genericProcessor(service, PROCESS_RUNTIME_PREFIX);

    if (data.length > 0) {
      this.store[service].cache.processRuntime = data;
    }
  }

  // Parses the raw labels and tries to build the processRuntime cache
  private setTelemetrySdk(service: string): void {
    const data = this.genericProcessor(service, TELEMETRY_SDK_PREFIX);

    if (data.length > 0) {
      this.store[service].cache.technologySdk = data;
    }
  }

  // Parses the raw labels and tries to build the cloud cache
  private setCloud(service: string): void {
    const data = this.genericProcessor(service, CLOUD_PREFIX);

    if (data.length > 0) {
      this.store[service].cache.cloud = data;
    }
  }

  // Parses the raw labels and tries to build the k8s cache
  private setK8s(service: string): void {
    const data = this.genericProcessor(service, K8S_PREFIX);

    if (data.length > 0) {
      this.store[service].cache.k8s = data;
    }
  }
}

export function getMetadataService(): MetadataService {
  return metadataService;
}

export function initializeMetadataService(): void {
  if (!metadataService) {
    metadataService = new MetadataService();
  }
}
