import { EmbeddedScene, QueryVariable, SceneFlexItem, SceneFlexLayout, SceneRouteMatch } from '@grafana/scenes';

import { WarnBannerScene } from 'components/WarnBanner';
import { PROMETHEUS_DS } from 'constants/datasources';
import { MIN_PANEL_HEIGHT } from 'constants/styles';
import { TECHNOLOGY } from 'constants/technology';
import { ServiceRuntimeEmbeddedScene } from 'modules/service/components/ServiceRuntimeEmbeddedScene';
import { makeVariables } from 'utils/variables';

import { makeServiceJvmClassesPanel } from './panels/makeServiceJvmClassesPanel';
import { makeServiceJvmCPUUtilizationPanel } from './panels/makeServiceJvmCPUUtilizationPanel';
import { makeServiceJvmGarbageCollectionPanel } from './panels/makeServiceJvmGarbageCollectionPanel';
import { makeServiceJvmHeapMemoryPanel } from './panels/makeServiceJvmHeapMemoryPanel';
import { makeServiceJvmThreadsPanel } from './panels/makeServiceJvmThreadsPanel';

export function makeServiceJvmScene(job: string): (routeMatch: SceneRouteMatch<any>) => EmbeddedScene {
  return () => {
    const cpuUtilizationPanel = makeServiceJvmCPUUtilizationPanel();
    const heapMemoryPanel = makeServiceJvmHeapMemoryPanel();
    const garbageCollectionPanel = makeServiceJvmGarbageCollectionPanel();
    const classesPanel = makeServiceJvmClassesPanel();
    const threadsPanel = makeServiceJvmThreadsPanel();

    const panels = [cpuUtilizationPanel, heapMemoryPanel, garbageCollectionPanel, classesPanel, threadsPanel];

    return new ServiceRuntimeEmbeddedScene({
      technology: TECHNOLOGY.JVM,

      panelsRefs: panels.map((panel) => panel.getRef()),

      $variables: makeVariables({}, [
        new QueryVariable({
          defaultToAll: true,
          description: 'The instance of the application, e.g. pod1',
          label: 'Instance',
          includeAll: true,
          isMulti: true,
          name: 'instance',
          datasource: PROMETHEUS_DS,
          allValue: '.*',
          query: {
            query: 'label_values(process_runtime_jvm_memory_usage{job="${job}"}, instance)',
            refId: 'serviceJvmInstances',
          },
        }),
      ]),

      body: new SceneFlexLayout({
        direction: 'column',
        children: [
          new SceneFlexLayout({
            direction: 'row',
            ySizing: 'content',
            children: [
              new SceneFlexItem({
                body: new WarnBannerScene({
                  panelsRefs: panels.map((panel) => panel.getRef()),
                  link: 'https://opentelemetry.io/docs/instrumentation/java/automatic/',
                }),
              }),
            ],
          }),

          new SceneFlexLayout({
            direction: 'row',
            ySizing: 'content',
            children: [
              new SceneFlexItem({
                minHeight: MIN_PANEL_HEIGHT,
                body: cpuUtilizationPanel,
              }),

              new SceneFlexItem({
                minHeight: MIN_PANEL_HEIGHT,
                body: heapMemoryPanel,
              }),
            ],
          }),

          new SceneFlexLayout({
            direction: 'row',
            ySizing: 'content',
            children: [
              new SceneFlexItem({
                minHeight: MIN_PANEL_HEIGHT,
                body: garbageCollectionPanel,
              }),

              new SceneFlexItem({
                minHeight: MIN_PANEL_HEIGHT,
                body: classesPanel,
              }),

              new SceneFlexItem({
                minHeight: MIN_PANEL_HEIGHT,
                body: threadsPanel,
              }),
            ],
          }),
        ],
      }),
    }) as unknown as EmbeddedScene;
  };
}
