import { css } from '@emotion/css';
import React, { useMemo } from 'react';

import { GrafanaTheme2, rangeUtil } from '@grafana/data';
import { SceneAppPageLike, sceneGraph, SceneObjectRef } from '@grafana/scenes';
import { Spinner, Stack, useStyles2 } from '@grafana/ui';

import { DEFAULT_TIME_RANGE } from 'constants/timeRange';
import { Metadata } from 'modules/service/components/ServicePageTitle/Metadata';
import { getMetadataService } from 'services/MetadataService';
import { parseJob } from 'utils/services';

import { EntityAssertionsWidget } from './EntityAssertionsWidget';
import { HostModalScene } from './HostModal';
import { NotificationScene } from './Notification';
import { Subtitle } from './Subtitle';

export interface ServicePageSubtitleProps {
  job: string;

  notificationRef: SceneObjectRef<NotificationScene>;
  hostModalRef: SceneObjectRef<HostModalScene>;

  model: SceneAppPageLike;
}

export const ServicePageSubtitle = ({ job, ...rest }: ServicePageSubtitleProps) => {
  const metadataService = getMetadataService();
  const isLoading = !(metadataService.isServiceStored(job) && metadataService.hasIsInstrumented(job));

  return isLoading ? <Spinner /> : <ServicePageSubtitleRenderer job={job} {...rest} />;
};

function ServicePageSubtitleRenderer({ job, notificationRef, hostModalRef, model }: ServicePageSubtitleProps) {
  const styles = useStyles2(getStyles);
  const { serviceName, serviceNamespace } = parseJob(job);

  const timeRange = useMemo(() => {
    const timeRangeState = sceneGraph.getTimeRange(model)?.state;
    return rangeUtil.convertRawToRange({
      from: timeRangeState?.from ?? DEFAULT_TIME_RANGE.from,
      to: timeRangeState?.to ?? DEFAULT_TIME_RANGE.to,
    });
  }, [model]);

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.metadata}>
          <Stack gap={1} alignItems="center">
            <Metadata job={job} />
          </Stack>
        </div>

        <Subtitle notificationRef={notificationRef} hostModalRef={hostModalRef} />

        <div className={styles.assertions}>
          <EntityAssertionsWidget name={serviceName} namespace={serviceNamespace} range={timeRange} />
        </div>
      </div>
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    container: css`
      margin-top: ${theme.spacing(1)};
      margin-bottom: -${theme.spacing(2)};
    `,
    metadata: css`
      grid-area: metadata;
    `,
    assertions: css`
      grid-area: assertions;
    `,
    grid: css`
      display: grid;
      grid-template-columns: max-content 1fr;
      gap: ${theme.spacing(1)};
      grid-template-areas:
        'assertions notifications metadata'
        'expandedNotifications expandedNotifications expandedNotifications';

      ${theme.breakpoints.down('md')} {
        grid-template-columns: min-content 1fr;
        grid-template-areas:
          'assertions notifications'
          'metadata metadata'
          'expandedNotifications expandedNotifications';
      }
    `,
  };
}
