import { AppPluginMeta } from '@grafana/data';

import { LogQueryMode } from 'constants/logs';
import { LOKI_DS_TYPE, PROMETHEUS_DS_TYPE, PYROSCOPE_DS_TYPE, TEMPO_DS_TYPE } from 'constants/variables';

export interface DefaultDataSources {
  [PROMETHEUS_DS_TYPE]?: string;
  [TEMPO_DS_TYPE]?: string;
  [LOKI_DS_TYPE]?: string;
  [PYROSCOPE_DS_TYPE]?: string;
}

export enum MetricsMode {
  tempoMetricsGen = 'tempoMetricsGen',
  otelCollector = 'otelCollector',
  legacy = 'legacy',
}

export function isMetricsMode(value: unknown): value is MetricsMode {
  return typeof value === 'string' && Object.values(MetricsMode).includes(value as any);
}

export interface PluginJsonData {
  orgId?: string;
  stackId?: string;
  defaultDataSources?: DefaultDataSources;
  disableOptIn?: boolean;
  isAlertingEnabled?: boolean;

  // will calculate based on last minute if true. default false
  baselineDev?: boolean;

  // baseline rec rule prefix. default "appo11y"
  baselineRulePrefix?: string;

  // baseline rec group prefix. default "baseline"
  baselineGroupPrefix?: string;

  // should baseline by calculated aggregated by all environments? default is false, only per specific ennvironment
  baselineAggregateAllEnvs?: boolean;

  // which duration aggregations to calculate baseline for. "all", "p95", "p99", "average". default "p95"
  baselineDurationAggregation?: string;
  logsQueryFormatting?: string;
  logsQueryWithNamespace?: string;
  logsQueryWithoutNamespace?: string;
  logsFilterByTraceId?: boolean;
  logsFilterBySpanId?: boolean;
  targetInfoMetricName?: string;
  grafanaComEndpoint?: string;
  environmentAttribute?: string;
  defaultEnvironmentValue?: string;
  groupByFilterByAttributes?: string[];
  stackHasData?: boolean;
  defaultLogQueryMode?: LogQueryMode;
  metricsMode?: MetricsMode;

  // Enable client only services. True by default
  isClientOnlyServicesEnabled?: boolean;

  // remove alerts for pipeline issues if set to true
  disablePipelineNotifications?: boolean;

  // Allow a particular stack to not see the host info alert
  disableHostInfoAlert?: boolean;
}

export type AppO11yPluginMeta = AppPluginMeta<PluginJsonData>;
