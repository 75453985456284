import { css, cx } from '@emotion/css';
import React, { forwardRef, HTMLAttributes } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

export const ShimmerPlaceholder = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...rest }, ref) => {
    const styles = useStyles2(getStyles);
    return <div className={cx(styles.shimmerPlaceholder, className)} {...rest} ref={ref}></div>;
  }
);

ShimmerPlaceholder.displayName = 'ShimmerPlaceholder';

const getStyles = (theme: GrafanaTheme2) => ({
  shimmerPlaceholder: css`
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #ccccdc;
    opacity: 0.35;
    border-radius: ${theme.shape.radius.default};

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0)
      );
      animation: shimmer 2s infinite;
      content: '';
    }

    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  `,
});
