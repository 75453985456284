import React, { useMemo } from 'react';

import { useReturnToPrevious } from '@grafana/runtime';
import { SceneComponentProps, sceneGraph, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import { LinkButton } from '@grafana/ui';

import { DataQueryExtended } from 'types/queries';
import { trackPanelExplore } from 'utils/tracking';

export interface ExploreButtonOptions {
  trackingSection: string;
  trackingPage: string;
  trackingPanel: string;
}

interface ExploreButtonState extends SceneObjectState {
  options: ExploreButtonOptions;
}

export class ExploreButton extends SceneObjectBase<ExploreButtonState> {
  static Component = ExploreButtonComponent;

  constructor(options: ExploreButtonOptions) {
    super({ options });
  }
}

export function ExploreButtonComponent({ model }: SceneComponentProps<ExploreButton>) {
  const setReturnToPrevious = useReturnToPrevious();

  const { options } = model.useState();

  const { data } = sceneGraph.getData(model).useState();

  const { from, to } = sceneGraph.getTimeRange(model).useState();

  const left = useMemo(() => {
    const queries: DataQueryExtended[] = (data?.request?.targets ?? []).map((query: DataQueryExtended) => {
      let interpolatedSeviceMapQuery: string | string[] | undefined = undefined;

      if (Array.isArray(query.serviceMapQuery) && query.serviceMapQuery.length) {
        interpolatedSeviceMapQuery = query.serviceMapQuery.map((q) => sceneGraph.interpolate(model, q));
      } else if (query.serviceMapQuery && typeof query.serviceMapQuery === 'string') {
        interpolatedSeviceMapQuery = sceneGraph.interpolate(model, query.serviceMapQuery);
      }

      return {
        ...query,
        legendFormat: undefined,
        expr: sceneGraph.interpolate(model, query.expr),
        serviceMapQuery: interpolatedSeviceMapQuery,
      };
    });

    const datasource = queries.find((query) => !!query.datasource?.uid)?.datasource?.uid;

    if (queries?.length && datasource && from && to) {
      return encodeURIComponent(
        JSON.stringify({
          datasource,
          queries,
          range: {
            from,
            to,
          },
        })
      );
    }
    return '';
  }, [data, from, to, model]);

  if (left) {
    return (
      <LinkButton
        key="explore"
        icon="compass"
        size="sm"
        variant="secondary"
        href={`/explore?left=${left}`}
        onClick={() => {
          trackPanelExplore(options.trackingSection, options.trackingPage, options.trackingPanel);
          setReturnToPrevious('Application Observability');
        }}
      >
        Explore
      </LinkButton>
    );
  }
  return null;
}
