import { AppEvents, FieldType, PanelData } from '@grafana/data';
import { BackendSrvRequest, getAppEvents } from '@grafana/runtime';

import { TARGET_INFO_REF_ID } from 'constants/query';
import { getFaro } from 'faro/instance';
import { getOverridesService } from 'services/OverridesService';
import { getPluginConfigService } from 'services/PluginConfigService';
import { PluginJsonData } from 'types/settings';

import { normalizeLabel } from './format';
import { isDefaultTargetInfoMetricName } from './semantics';

export function getResourceAttributesFromPanelData(data: PanelData): Array<{ label: string; value: string }> {
  const attributeSet = data.series
    .filter(({ refId }) => refId === TARGET_INFO_REF_ID)
    .reduce((acc, { fields }) => {
      const labels = fields.find((f) => f.type === FieldType.number)?.labels ?? {};

      for (const key of Object.keys(labels)) {
        if (isValidAttribute(key)) {
          acc.add(key);
        }
      }

      return acc;
    }, new Set<string>());

  return Array.from(attributeSet).map((raw) => {
    const attribute = normalizeLabel(raw);

    return { label: attribute, value: attribute };
  });
}

function isValidAttribute(attribute: string) {
  const reservedAttributes = ['job', 'service_name', 'service_namespace'];

  return !(attribute.startsWith('__') || attribute.endsWith('__')) && !reservedAttributes.includes(attribute);
}

export async function safelySaveAttributes(
  config: { newConfig: Partial<PluginJsonData>; oldConfig: Partial<PluginJsonData> },
  attributes: { newAttributes: string[]; oldAttributes: string[] }
): Promise<boolean> {
  const overridesService = getOverridesService();

  const saved = await savePluginConfig(config.newConfig);
  if (!saved) {
    return false;
  }

  if (overridesService.hasInitializationOverrides() && isDefaultTargetInfoMetricName) {
    try {
      await overridesService.updateExtraDimensions(attributes.newAttributes, attributes.oldAttributes);
    } catch (err) {
      const error = err instanceof Error ? err : new Error('Could not save overrides value, try again later');

      getFaro()?.api.pushError(error);

      const appEvents = getAppEvents();
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [error?.message && 'Could not save overrides value, try again later'],
      });

      await savePluginConfig(config.oldConfig);
      return false;
    }
  }

  return true;
}

export async function savePluginConfig(
  config: Partial<PluginJsonData>,
  options: Partial<BackendSrvRequest> = {}
): Promise<boolean> {
  try {
    await getPluginConfigService().savePluginConfig(config, options);
  } catch (err) {
    const error = err instanceof Error ? err : new Error('Could not save change, try again later');
    getFaro()?.api.pushError(error);

    const appEvents = getAppEvents();
    appEvents.publish({
      type: AppEvents.alertError.name,
      payload: [error?.message && 'Could not save change, try again later'],
    });
    return false;
  }

  return true;
}
