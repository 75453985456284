import { isEqual } from 'lodash';

import { DeepPartial, sceneGraph } from '@grafana/scenes';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { GroupByVariable } from 'components/GroupByVariable';
import { BASELINE_COMPARER_VALUE } from 'components/TimeRangeCompareWithBaseline';
import { GROUP_BY_NAME } from 'constants/variables';
import { getTimeCompare } from 'utils/scenes';

export const showLegendBehavior =
  ({ checkGroupBy = true }: { checkGroupBy?: boolean } = {}) =>
  (panel: ExplorablePanel) => {
    const variable = sceneGraph.lookupVariable(GROUP_BY_NAME, panel) as GroupByVariable | undefined;
    const timeWindowComparison = getTimeCompare(panel);

    const getNewState = () => {
      const compareWith = timeWindowComparison?.state.compareWith;
      return getNewOptionsState(
        panel.state.options,
        (variable && !variable.isEmpty()) || (compareWith !== undefined && compareWith !== BASELINE_COMPARER_VALUE)
      );
    };

    panel.setState(getNewState());

    const unsubscribable = variable?.subscribeToState((newState, oldState) => {
      if (!checkGroupBy) {
        return;
      }

      if (!isEqual(newState.value, oldState.value)) {
        panel.setState(getNewState());
      }
    });

    const unsubscribable2 = timeWindowComparison?.subscribeToState((newState, oldState) => {
      if (
        (newState.compareWith === undefined ||
          oldState.compareWith === undefined ||
          newState.compareWith === BASELINE_COMPARER_VALUE ||
          oldState.compareWith === BASELINE_COMPARER_VALUE) &&
        newState.compareWith !== oldState.compareWith
      ) {
        panel.setState(getNewState());
      }
    });

    return () => {
      unsubscribable?.unsubscribe();
      unsubscribable2?.unsubscribe();
    };
  };

function getNewOptionsState(options: DeepPartial<{ legend: unknown }> = {}, isEmpty: boolean) {
  return {
    options: {
      ...options,
      legend: {
        ...(options.legend || {}),
        showLegend: isEmpty,
      },
    },
  };
}
