import { css } from '@emotion/css';
import React, { useState } from 'react';

import { GrafanaTheme2, isIconName } from '@grafana/data';
import { Button, Icon, Stack, Text, TextLink, Toggletip, useStyles2 } from '@grafana/ui';

import { MetadataTooltipValue } from './MetadataTooltipValue';

export interface MetadataValueProps {
  entries: Record<string, Array<{ label: string; link?: string | undefined }>>;
  icon: string;
  iconAlt: string;
  title: string;
  text: string;

  link?: string;
  renderWithoutEntries?: boolean;
}

export function MetadataValue({ entries, icon, iconAlt, link, renderWithoutEntries, text, title }: MetadataValueProps) {
  const styles = useStyles2(getStyles);
  const [isOpen, setIsOpen] = useState(false);

  const img = isIconName(icon) ? <Icon name={icon} /> : <img src={icon} alt={iconAlt} />;

  if (Object.keys(entries).length === 0) {
    if (!renderWithoutEntries) {
      return null;
    }

    return <>{img}</>;
  }

  return (
    <Toggletip
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      content={
        <div className={styles.wrapper}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>
              {img}

              {link ? (
                <div className={styles.link}>
                  <TextLink href={link}>{title}</TextLink>
                </div>
              ) : (
                <h5>{title}</h5>
              )}
            </div>

            <hr />
          </div>

          <div className={styles.container}>
            {Object.entries(entries).map(([label, labelEntries], idx, array) => (
              <React.Fragment key={label}>
                <MetadataTooltipValue entries={labelEntries} label={label} />

                {idx !== array.length - 1 && <hr />}
              </React.Fragment>
            ))}
          </div>
        </div>
      }
      placement="bottom"
      closeButton={false}
    >
      <Button
        key={title}
        variant="secondary"
        fill="text"
        data-cy="metadata-button"
        data-fs-element={`App o11y - ${text} metadata`}
      >
        <Stack gap={1} alignItems="center">
          {img}

          <Text element="span">{text}</Text>

          {isOpen === false && <Icon name="angle-down" />}
          {isOpen === true && <Icon name="angle-up" />}
        </Stack>
      </Button>
    </Toggletip>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    titleContainer: css`
      display: flex;
      flex-direction: column;

      hr {
        width: 100%;
        margin-bottom: 0;
      }
    `,
    title: css`
      padding: ${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(2)};

      display: flex;
      align-items: center;
      gap: ${theme.spacing(1)};

      h5 {
        margin-bottom: 0;
      }
    `,

    wrapper: css`
      margin: -${theme.spacing(3)} -${theme.spacing(2)};
      background-color: ${theme.colors.background.canvas};
    `,

    container: css`
      padding: ${theme.spacing(2)};

      min-width: 250px;
      max-width: 500px;
      width: auto;
      max-height: 500px;
      overflow-y: auto;
    `,
    link: css`
      a {
        text-decoration: none !important;

        &:hover {
          color: ${theme.colors.secondary.text};
        }
      }
    `,
  };
}
