import React from 'react';

import { LinkButton, Stack } from '@grafana/ui';

import { ROUTES } from 'constants/routing';
import { useLinkBuilder } from 'hooks/useLinkBuilder';

export interface MaxPanelTitleProps {
  title: string;
  encodedJob: string;
  job: string;
  encodedOperation?: string;
  operation?: string;
}

export const MaxPanelTitle = ({ title, encodedJob, job, encodedOperation, operation }: MaxPanelTitleProps) => {
  const buildLink = useLinkBuilder();
  // const backLink = buildLink(`/services/${encodedJob}${encodedOperation ? `/operations/${encodedOperation}` : ''}`);
  const backLink = buildLink(
    encodedOperation ? ROUTES.operation(encodedJob, encodedOperation) : ROUTES.overview(encodedJob)
  );

  return (
    <Stack direction="row" gap={2} alignItems="center">
      <LinkButton variant="secondary" icon="arrow-left" href={backLink} tooltip="Go back" tooltipPlacement="top" />
      <h1>
        {operation ?? job}: {title}
      </h1>
    </Stack>
  );
};
