import cPlusPlusIcon from 'img/icons/technologies/cplusplus.svg';
import dotnetIcon from 'img/icons/technologies/dotnet.svg';
import erlangIcon from 'img/icons/technologies/erlang.svg';
import genericIcon from 'img/icons/technologies/genericTechnology.svg';
import goIcon from 'img/icons/technologies/go.svg';
import javaIcon from 'img/icons/technologies/java.svg';
import jsIcon from 'img/icons/technologies/js.svg';
import nodejsIcon from 'img/icons/technologies/node.svg';
import phpIcon from 'img/icons/technologies/php.svg';
import pythonIcon from 'img/icons/technologies/python.svg';
import rubyIcon from 'img/icons/technologies/ruby.svg';
import rustIcon from 'img/icons/technologies/rust.svg';

export enum TECHNOLOGY {
  CPP = 'cpp',
  DOTNET = 'dotnet',
  ERLANG = 'erlang',
  GOLANG = 'golang',
  JVM = 'jvm',
  NODEJS = 'nodejs',
  PHP = 'php',
  PYTHON = 'python',
  RUBY = 'ruby',
  RUST = 'rust',
  UNKNOWN = 'unknown',
  WEB = 'web',
  WEBJS = 'webjs',
}

export const TECHNOLOGY_NAME: Record<TECHNOLOGY, string> = {
  [TECHNOLOGY.CPP]: 'C++',
  [TECHNOLOGY.DOTNET]: '.NET',
  [TECHNOLOGY.ERLANG]: 'erlang',
  [TECHNOLOGY.GOLANG]: 'Go',
  [TECHNOLOGY.JVM]: 'JVM',
  [TECHNOLOGY.NODEJS]: 'NodeJS',
  [TECHNOLOGY.PHP]: 'PHP',
  [TECHNOLOGY.PYTHON]: 'Python',
  [TECHNOLOGY.RUBY]: 'Ruby',
  [TECHNOLOGY.RUST]: 'Rust',
  [TECHNOLOGY.WEB]: 'JavaScript',
  [TECHNOLOGY.WEBJS]: 'JavaScript',
  [TECHNOLOGY.UNKNOWN]: 'Unknown technology',
} as const;

export const TECHNOLOGY_ICON: Record<TECHNOLOGY, any> = {
  [TECHNOLOGY.CPP]: cPlusPlusIcon,
  [TECHNOLOGY.DOTNET]: dotnetIcon,
  [TECHNOLOGY.ERLANG]: erlangIcon,
  [TECHNOLOGY.GOLANG]: goIcon,
  [TECHNOLOGY.JVM]: javaIcon,
  [TECHNOLOGY.NODEJS]: nodejsIcon,
  [TECHNOLOGY.PHP]: phpIcon,
  [TECHNOLOGY.PYTHON]: pythonIcon,
  [TECHNOLOGY.RUBY]: rubyIcon,
  [TECHNOLOGY.RUST]: rustIcon,
  [TECHNOLOGY.WEB]: jsIcon,
  [TECHNOLOGY.WEBJS]: jsIcon,
  [TECHNOLOGY.UNKNOWN]: genericIcon,
} as const;
