import { isEqual } from 'lodash';

import {
  ConstantVariable,
  QueryVariable,
  sceneGraph,
  SceneObject,
  SceneStatelessBehavior,
  SceneVariable,
  SceneVariableSet,
  SceneVariableState,
} from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';

import { EnvironmentAttributeVariable } from 'components/EnvironmentAttributeVariable';
import { EnvironmentValueVariable } from 'components/EnvironmentValueVariable';
import { EscapedConstantVariable } from 'components/EscapedConstantVariable';
import { FilterByVariable } from 'components/FilterByVariable';
import { GroupByVariable } from 'components/GroupByVariable';
import { MetricsModeVariable } from 'components/MetricsModeVariable';
import { ServiceInventoryFilterByVariable } from 'components/ServiceInventoryFilterByVariable';
import { ServiceMapFilterByVariable } from 'components/ServiceMapFilterByVariable';
import { SmartDataSourceVariable } from 'components/SmartDataSourceVariable';
import {
  GROUP_BY_NAME,
  JOB_NAME,
  LOKI_DS_NAME,
  LOKI_DS_TYPE,
  METRICS_MODE_NAME,
  OPERATION_NAME,
  PROMETHEUS_DS_NAME,
  PROMETHEUS_DS_TYPE,
  PYROSCOPE_DS_NAME,
  PYROSCOPE_DS_TYPE,
  SERVICE_NAME_NAME,
  SERVICE_NAMESPACE_NAME,
  TEMPO_DS_NAME,
  TEMPO_DS_TYPE,
} from 'constants/variables';
import { SERVICE_OVERVIEW_SERVICE_MAP } from 'modules/service/serviceMap/makeServiceServiceMapScene';
import { SERVICE_MAP_VIEW_NAME } from 'modules/serviceMap/makeServiceMapPage';
import { SERVICES_VIEW_NAME } from 'modules/services/makeServicesPage';
import { getDataSourceService } from 'services/DataSourceService';
import { getDefaultEnvironmentValueService } from 'services/DefaultEnvironmentValueService';
import { getMetadataService } from 'services/MetadataService';

import { getEnvironmentAttribute } from './environmentFilter';
import { getGroupByVariableOptions } from './groupByFilterBy';
import { getMetricsMode } from './metricsMode';
import { parseJob } from './services';

export interface VariableSet {
  usePrometheus?: boolean;
  useLoki?: boolean;
  useTempo?: boolean;
  usePyroscope?: boolean;
  useEnvironmentFilter?: boolean;
  useGroupByFilter?: boolean;
  useFilterBy?: boolean;

  job?: string;
  operation?: string;
  viewName?: string;
  isInstrumented?: boolean;
}

export function makeVariables(config: VariableSet = {}, additionalVariables: SceneVariable[] = []): SceneVariableSet {
  const variables: Array<SceneVariable<SceneVariableState>> = [
    ...(config.usePrometheus
      ? [
          new SmartDataSourceVariable({
            name: PROMETHEUS_DS_NAME,
            label: 'Metrics',
            pluginId: PROMETHEUS_DS_TYPE,
            hide: VariableHide.hideVariable,
          }),
        ]
      : []),

    ...(config.useLoki
      ? [
          new SmartDataSourceVariable({
            name: LOKI_DS_NAME,
            label: 'Logs',
            pluginId: LOKI_DS_TYPE,
            hide: VariableHide.hideVariable,
          }),
        ]
      : []),

    ...(config.useTempo
      ? [
          new SmartDataSourceVariable({
            name: TEMPO_DS_NAME,
            label: 'Traces',
            pluginId: TEMPO_DS_TYPE,
            hide: VariableHide.hideVariable,
          }),
        ]
      : []),

    ...(config.usePyroscope
      ? [
          new SmartDataSourceVariable({
            name: PYROSCOPE_DS_NAME,
            label: 'Profiles',
            pluginId: PYROSCOPE_DS_TYPE,
            hide: VariableHide.hideVariable,
          }),
        ]
      : []),

    new MetricsModeVariable(),
  ];

  const behaviors: Array<SceneObject | SceneStatelessBehavior> = [
    (scene) => {
      const variable = sceneGraph.lookupVariable(PROMETHEUS_DS_NAME, scene) as QueryVariable | undefined;

      const unsubscribable = variable?.subscribeToState(({ value: newValue }, { value: oldValue }) => {
        if (oldValue && newValue !== oldValue) {
          getMetadataService().resetStore();
        }
      });

      return () => unsubscribable?.unsubscribe();
    },
    (scene) => {
      const variable = sceneGraph.lookupVariable(METRICS_MODE_NAME, scene) as MetricsModeVariable | undefined;
      const metricsMode = getMetricsMode();

      if (variable && variable.state.value !== metricsMode) {
        variable?.update(metricsMode);
      }
    },
    (scene) => {
      const defaultDatasources = getDataSourceService().getDefaultDataSources();
      const datasourceVariables = [
        {
          variable: sceneGraph.lookupVariable(PROMETHEUS_DS_NAME, scene) as SmartDataSourceVariable | undefined,
          type: PROMETHEUS_DS_TYPE,
        },
        {
          variable: sceneGraph.lookupVariable(TEMPO_DS_NAME, scene) as SmartDataSourceVariable | undefined,
          type: TEMPO_DS_TYPE,
        },
        {
          variable: sceneGraph.lookupVariable(LOKI_DS_NAME, scene) as SmartDataSourceVariable | undefined,
          type: LOKI_DS_TYPE,
        },
        {
          variable: sceneGraph.lookupVariable(PYROSCOPE_DS_NAME, scene) as SmartDataSourceVariable | undefined,
          type: PYROSCOPE_DS_TYPE,
        },
      ] as const;

      // Keep datasource variable in sync with the latest value we have on config
      datasourceVariables.forEach(({ variable, type }) => {
        if (variable) {
          const defaultValue = defaultDatasources[type];
          if (defaultValue !== undefined && variable.state.value !== defaultValue) {
            variable.setState({ value: defaultValue });
          }
        }
      });
    },
  ];

  if (config.job) {
    variables.push(
      new ConstantVariable({
        name: JOB_NAME,
        label: 'Job',
        hide: VariableHide.hideVariable,
        skipUrlSync: true,
        value: config.job,
      })
    );

    const { serviceName, serviceNamespace } = parseJob(config.job);

    variables.push(
      new EscapedConstantVariable({
        name: SERVICE_NAME_NAME,
        label: 'Service Name',
        hide: VariableHide.hideVariable,
        skipUrlSync: true,
        value: serviceName,
      })
    );

    variables.push(
      new EscapedConstantVariable({
        name: SERVICE_NAMESPACE_NAME,
        label: 'Service Namespace',
        hide: VariableHide.hideVariable,
        skipUrlSync: true,
        value: serviceNamespace,
      })
    );
  }

  if (config.operation) {
    variables.push(
      new EscapedConstantVariable({
        name: OPERATION_NAME,
        label: 'Operation',
        hide: VariableHide.hideVariable,
        skipUrlSync: true,
        value: config.operation,
      })
    );
  }

  if (config.useEnvironmentFilter) {
    const defaultEnvironmentValueService = getDefaultEnvironmentValueService();
    const environmentAttributeVariable = new EnvironmentAttributeVariable();
    const environmentValueVariable = new EnvironmentValueVariable({
      defaultEnvironmentValue: defaultEnvironmentValueService.defaultValue,
      withOperation: !!config.operation,
      withJob: !!config.job,
      isInstrumented: config.isInstrumented,
    });

    variables.push(environmentAttributeVariable, environmentValueVariable);

    behaviors.push(() => {
      const environmentAttribute = getEnvironmentAttribute();
      const defaultEnvironmentValue = defaultEnvironmentValueService.defaultValue;
      const isNewEnvironmentAttribute = environmentAttributeVariable?.state.value !== environmentAttribute;

      if (isNewEnvironmentAttribute) {
        environmentAttributeVariable?.updateValue(environmentAttribute);
      }

      if (isNewEnvironmentAttribute || environmentValueVariable.defaultEnvironmentValue !== defaultEnvironmentValue) {
        environmentValueVariable.updateQuery(environmentAttribute, defaultEnvironmentValue);
      }

      return undefined;
    });
  }

  if (config.useGroupByFilter) {
    variables.push(new GroupByVariable());

    behaviors.push((scene) => {
      const groupByFilter = sceneGraph.lookupVariable(GROUP_BY_NAME, scene) as GroupByVariable | undefined;

      const latestOptions = getGroupByVariableOptions();
      if (!isEqual(groupByFilter?.state.query, latestOptions)) {
        groupByFilter?.updateQuery();
      }
    });
  }

  if (config.useFilterBy) {
    const state = {
      job: config.job,
      operation: config.operation,
      isInstrumented: config.isInstrumented,
    };

    switch (config.viewName) {
      case SERVICES_VIEW_NAME:
        variables.push(new ServiceInventoryFilterByVariable(state));
        break;
      case SERVICE_MAP_VIEW_NAME:
      case SERVICE_OVERVIEW_SERVICE_MAP:
        variables.push(new ServiceMapFilterByVariable(state));
        break;
      default:
        variables.push(new FilterByVariable(state));
    }
  }

  return new SceneVariableSet({
    variables: [...variables, ...additionalVariables],
    $behaviors: behaviors,
  });
}
