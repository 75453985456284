import { FieldColorModeId, FieldConfig } from '@grafana/data';
import { GraphGradientMode, TableCellDisplayMode } from '@grafana/ui';

export const APP_O11Y_PALETTE_ID = 'appO11yPalette';

export const DEFAULT_FIELD_CONFIG: FieldConfig = {
  color: {
    mode: APP_O11Y_PALETTE_ID as FieldColorModeId,
  },
};

export const FILL_OPACITY_INVENTORY = 10;
export const DEFAULT_FILL_OPACITY = 30;

export const DEFAULT_SPARKLINE_CELL_OPTIONS = {
  mode: TableCellDisplayMode.Sparkline,
  type: TableCellDisplayMode.Sparkline,
  gradientMode: GraphGradientMode.Opacity,
  fillOpacity: DEFAULT_FILL_OPACITY,
  spanNulls: true,
};

export enum PanelType {
  ERRORS = 'errors',
  DURATION = 'duration',
  RATE = 'rate',
  DURATION_DISTRIBUTION = 'durationDistribution',
  OPERATIONS = 'operations',
  DOWNSTREAM = 'downstream',
  UPSTREAM = 'upstream',
}
