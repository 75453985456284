import { uniq } from 'lodash';

import { DOES_NOT_MATCH_REGEX_OPERATOR, REGEX_OPERATOR } from 'constants/filterBy';
import { getOverridesService } from 'services/OverridesService';
import { getPluginConfigService } from 'services/PluginConfigService';

import { getEnvironmentAttribute } from './environmentFilter';
import { dotToDash } from './format';

export const RESOURCE_PREFIX = 'resource.';
export const SPAN_PREFIX = 'span.';
export const EMPTY_GROUP_BY_VALUE = '__empty';
const IGNORED_DIMENSIONS = ['service.namespace'];

export function getGroupByFilterByAttributes({ withEnvironmentAttribute = false }): string[] {
  const { groupByFilterByAttributes = [] } = getPluginConfigService().getPluginConfig();
  const overridesService = getOverridesService();
  const environmentAttribute = getEnvironmentAttribute();

  const attributes = [];

  if (groupByFilterByAttributes?.length > 0 || !overridesService.hasInitializationOverrides()) {
    attributes.push(
      ...uniq([
        ...groupByFilterByAttributes,
        ...(withEnvironmentAttribute && environmentAttribute ? [environmentAttribute] : []),
      ])
    );
  } else {
    attributes.push(
      ...overridesService.getAllDimensions().filter((dimension) => {
        if (!withEnvironmentAttribute && dimension === environmentAttribute) {
          return false;
        }

        return !IGNORED_DIMENSIONS.includes(dimension);
      })
    );
  }
  return attributes.sort();
}

export function getFilterByOptions(): Array<{ text: string; value: string }> {
  return getGroupByFilterByAttributes({ withEnvironmentAttribute: false }).map((raw) => {
    const attribute = raw.replace(RESOURCE_PREFIX, '').replace(SPAN_PREFIX, '');

    return {
      text: attribute,
      value: dotToDash(attribute),
    };
  });
}

export function getGroupByVariableOptions(): string {
  const attributes = getGroupByFilterByAttributes({ withEnvironmentAttribute: true }).map((attribute) => {
    const withoutPrefix = attribute.includes(RESOURCE_PREFIX) ? attribute.replace(RESOURCE_PREFIX, '') : attribute;

    return `${withoutPrefix} : ${dotToDash(withoutPrefix)}`;
  });

  // CustomVariable will automatically select the first item when none are available,
  // We add an empty value that will not be used, but will allow for an empty state
  return `${EMPTY_GROUP_BY_VALUE},${attributes.join(',')}`;
}

export const isRegexOperator = (operator: string): boolean =>
  operator === REGEX_OPERATOR || operator === DOES_NOT_MATCH_REGEX_OPERATOR;
