import React from 'react';

import { EmbeddedScene, SceneFlexItem, SceneFlexLayout, SceneQueryRunner } from '@grafana/scenes';

import { PROMETHEUS_DS } from 'constants/datasources';
import { TARGET_INFO_REF_ID } from 'constants/query';
import { targetInfoMetricName } from 'utils/semantics';
import { makeVariables } from 'utils/variables';

import { BaselineConfigScene } from './BaselineConfigScene';
import { ClientOnlyServicesConfigScene } from './ClientOnlyServicesConfigScene';
import { EnvironmentFilterConfigScene } from './EnvironmentFilterConfigScene';
import { GroupByFilterByConfigScene } from './GroupByFilterByConfigScene';

export interface SettingsTabProps {
  getScene: () => EmbeddedScene;
}

export const SettingsTab = (_props: SettingsTabProps) => {
  const configSections = [
    new SceneFlexItem({ body: new EnvironmentFilterConfigScene() }),
    new SceneFlexItem({ body: new GroupByFilterByConfigScene() }),
    new SceneFlexItem({ body: new BaselineConfigScene() }),
    new SceneFlexItem({ body: new ClientOnlyServicesConfigScene() }),
  ];

  const scene = new EmbeddedScene({
    $variables: makeVariables({ usePrometheus: true }),

    $data: new SceneQueryRunner({
      queries: [
        {
          datasource: PROMETHEUS_DS,
          refId: TARGET_INFO_REF_ID,
          // Need to use group for compatibility with metrics aggregation
          expr: `group without () (${targetInfoMetricName})`,
          instant: true,
        },
      ],
    }),
    body: new SceneFlexLayout({
      direction: 'column',
      children: configSections,
    }),
  });

  return <scene.Component model={scene} />;
};
