import { SceneDataTransformer } from '@grafana/scenes';
import {
  FieldColorModeId,
  GraphDrawStyle,
  GraphGradientMode,
  LineInterpolation,
  TooltipDisplayMode,
} from '@grafana/schema';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { PromSceneQueryRunner } from 'components/PromSceneQueryRunner';
import { MIN_INTERVAL, PROMETHEUS_DS } from 'constants/datasources';
import { DEFAULT_FILL_OPACITY, PanelType } from 'constants/panels';
import { GROUP_BY_NAME } from 'constants/variables';
import { MaximizePanelButton } from 'panels/maximizePanelButton';
import { makeAnomalyHighlightOverrides, makeDrilldownLinks, makeOverrides } from 'panels/red/panelsConfig';
import { QueryWithThresholds } from 'types/queries';
import { RATE_COLOR } from 'utils/colors';
import { queriesWithAnomalyThresholds } from 'utils/queries';

import { showAnomalyThresholdsBehavior } from './showAnomalyThresholdsBehavior';
import { showLegendBehavior } from './showLegendBehavior';
import { addDefaultSeriesNameTransformation } from './transformations/addDefaultSeriesNameTransformation';
import { addTWCFieldSeriesIndexTransformation } from './transformations/addTWCFieldSeriesIndexTransformation';
import { baselineAnomalyHighlightTransformation } from './transformations/baselineAnomalyHighlightTransformation';
import { sortFramesTransformation } from './transformations/sortFramesTransformation';
import { updateColorsBehaviour } from './updateColorsBehaviour';

export function makeRatePanel({
  job,
  operation,
  module,
  query,
  isInstrumented,
}: {
  job: string;
  operation?: string;
  module: 'operation' | 'service';
  query: QueryWithThresholds;
  isInstrumented: boolean;
}): ExplorablePanel {
  const refId = `${module}RateQuery`;

  const queries = queriesWithAnomalyThresholds(
    {
      refId,
      expr: query.query,
      legendFormat: `$\{${GROUP_BY_NAME}:legend${isInstrumented ? '' : 'downstream'}:Rate}`,
    },
    query
  );

  const queryRunner = new PromSceneQueryRunner({
    datasource: PROMETHEUS_DS,
    queries,
    queriesWithThresholds: queries,
    maxDataPointsFromWidth: true,
    minInterval: MIN_INTERVAL,
  });

  const panel: ExplorablePanel = new ExplorablePanel({
    title: 'Rate',

    trackingSection: module,
    trackingPage: 'overview',
    trackingPanel: 'rate',

    fieldConfig: {
      defaults: {
        color: {
          mode: FieldColorModeId.Fixed,
          fixedColor: RATE_COLOR,
        },
        custom: {
          fillOpacity: DEFAULT_FILL_OPACITY,
          spanNulls: true,
          drawStyle: GraphDrawStyle.Line,
          gradientMode: GraphGradientMode.Opacity,
          lineInterpolation: LineInterpolation.Smooth,
        },
        unit: 'reqps',
        links: makeDrilldownLinks({
          withOperation: module === 'operation',
          isInstrumented,
          getScene: () => panel,
        }),
      },
      overrides: [...makeOverrides(refId, RATE_COLOR), ...makeAnomalyHighlightOverrides()],
    },

    headerActions: [new MaximizePanelButton(PanelType.RATE, job, operation)],

    options: {
      tooltip: {
        mode: TooltipDisplayMode.Multi,
      },
    },

    $behaviors: [
      showAnomalyThresholdsBehavior(queryRunner),
      showLegendBehavior(),
      (panel: ExplorablePanel) => updateColorsBehaviour(panel, RATE_COLOR),
    ],

    $data: new SceneDataTransformer({
      transformations: [
        sortFramesTransformation,
        addTWCFieldSeriesIndexTransformation,
        addDefaultSeriesNameTransformation,
        baselineAnomalyHighlightTransformation,
      ],
      $data: queryRunner,
    }),
  });

  return panel;
}
