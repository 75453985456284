import { EmbeddedScene, SceneFlexItem, SceneFlexLayout, SceneReactObject, SceneRouteMatch } from '@grafana/scenes';

import { makeTimeRange } from 'utils/timeRange';
import { makeVariables } from 'utils/variables';

import { ExploreData } from './components/ExploreData';
import { GetStarted } from './components/GetStarted';
import { Title } from './components/Title';
import { InitializationCompleteBusEvent } from './initializeEvents';

export function makeLandingScene(): (routeMatch: SceneRouteMatch<any>) => EmbeddedScene {
  return () => {
    const es = new EmbeddedScene({
      $variables: makeVariables({ useTempo: true }),

      $timeRange: makeTimeRange(),

      body: new SceneFlexLayout({
        direction: 'column',
        minWidth: 0,
        children: [
          new SceneFlexItem({
            body: new SceneReactObject({
              component: Title,
            }),
          }),

          new SceneFlexItem({
            body: new SceneReactObject({
              component: GetStarted,
              props: {
                onInitialize: () => {
                  es.publishEvent(new InitializationCompleteBusEvent({ redirect: true }), true);
                },
              },
            }),
          }),

          new SceneFlexItem({
            body: new SceneReactObject({
              component: ExploreData,
            }),
          }),
        ],
      }),
    });
    return es;
  };
}
