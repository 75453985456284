import {
  SceneObject,
  SceneRefreshPicker,
  SceneTimePicker,
  SceneTimeRangeCompare,
  VariableValueSelectors,
} from '@grafana/scenes';

import { TimeRangeCompareWithBaseline } from 'components/TimeRangeCompareWithBaseline';

import { trackTimeWindowComparisonChange, trackTimeWindowComparisonEnable } from './tracking';

export function makeCommonControls(includeComparer = true): SceneObject[] {
  return [
    new VariableValueSelectors({}),

    new SceneTimePicker({
      isOnCanvas: true,
    }),
    includeComparer
      ? new TimeRangeCompareWithBaseline({
          $behaviors: [
            (scene: SceneTimeRangeCompare) => {
              const unsubscribable = scene.subscribeToState(
                ({ compareWith: newCompareWith }, { compareWith: oldCompareWith }) => {
                  if (newCompareWith) {
                    if (!oldCompareWith) {
                      trackTimeWindowComparisonEnable(newCompareWith);
                    } else {
                      trackTimeWindowComparisonChange(oldCompareWith, newCompareWith);
                    }
                  }
                }
              );

              return () => unsubscribable.unsubscribe();
            },
          ],
        })
      : undefined,
    new SceneRefreshPicker({
      isOnCanvas: true,
    }),
  ].filter((control) => control !== undefined) as SceneObject[];
}
