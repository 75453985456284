import { sortBy } from 'lodash';

import { SERVICE_NAMESPACE_FILTER, SERVICES_FILTERS } from 'constants/filterBy';
import { getFilterByOptions } from 'utils/groupByFilterBy';

import { FilterByVariable } from './FilterByVariable';
import { SERVICE_NAMESPACE_VALUE_KEY } from './ServiceMapFilterByVariable';

export type ServiceInventoryFilterByVariableState = ConstructorParameters<typeof FilterByVariable>[0];

export class ServiceInventoryFilterByVariable extends FilterByVariable {
  constructor(state: ServiceInventoryFilterByVariableState) {
    super({
      ...state,
      getTagKeysProvider: () => {
        // Remove service.namespace as we add our own for the frontend
        const attributes = getFilterByOptions().filter((item) => item.value !== 'service_namespace');
        attributes.push(...SERVICES_FILTERS);

        return Promise.resolve({
          replace: true,
          values: sortBy(attributes, (value) => value.text),
        });
      },
    });

    this.addActivationHandler(() => {
      const hasServiceNamespaceFilter = this.state.filters.find((filter) => filter.key === SERVICE_NAMESPACE_VALUE_KEY);
      if (hasServiceNamespaceFilter) {
        this.setState({
          filters: this.state.filters.map((filter) => {
            if (filter.key === SERVICE_NAMESPACE_VALUE_KEY) {
              filter.key = SERVICE_NAMESPACE_FILTER;
            }

            return filter;
          }),
        });
      }
    });
  }

  override cleanFilters() {
    return;
  }
}
