// Service Metadata
export const CLOUD_PREFIX = 'cloud_';
export const K8S_PREFIX = 'k8s_';
export const PROCESS_RUNTIME_PREFIX = 'process_runtime_';
export const TELEMETRY_SDK_PREFIX = 'telemetry_sdk_';
export const TELEMETRY_SDK_LANGUAGE_KEY = 'telemetry_sdk_language';
export const FARO_PREFIX = 'gf_feo11y';
export const EMBRACE_PREFIX = 'emb_';

export const METADATA_PREFIXES = [CLOUD_PREFIX, K8S_PREFIX, PROCESS_RUNTIME_PREFIX, FARO_PREFIX] as const;

export const METADATA_LABEL_KEYS_MAPPING = {
  [CLOUD_PREFIX]: 'cloud',
  [K8S_PREFIX]: 'k8s',
  [PROCESS_RUNTIME_PREFIX]: 'processRuntime',
  [FARO_PREFIX]: 'faro',
} as const;
