import { AGGREGATED_LABEL_VALUE } from 'constants/query';
import { SpanKind } from 'constants/spanKind';
import { FILTER_BY_NAME } from 'constants/variables';
import { baselineService } from 'services/BaselineService';
import { REDQueries } from 'types/queries';
import { hasEnvironmentAttribute } from 'utils/environmentFilter';
import { escapeRegExp } from 'utils/regexp';

export function makeRedLabels(
  withJob: boolean,
  withOperation: boolean,
  serviceType: 'server' | 'client',
  jobs?: string[]
): string {
  return [
    {
      name: 'span_kind',
      operator: '=~',
      value:
        serviceType === 'client'
          ? `${SpanKind.CLIENT}|${SpanKind.PRODUCER}`
          : `${SpanKind.SERVER}|${SpanKind.CONSUMER}`,
    },
    {
      name: 'job',
      operator: '=',
      value: withJob ? '${job}' : '',
    },
    {
      name: 'job',
      operator: '=~',
      value: jobs?.map((x) => escapeRegExp(x, true)).join('|') ?? '',
    },
    {
      name: 'span_name',
      operator: '=',
      value: withOperation ? '${operation}' : '',
    },
  ]
    .reduce<string[]>((acc, label) => {
      if (label.value) {
        acc.push(`${label.name}${label.operator}"${label.value}"`);
      }

      return acc;
    }, [])
    .join(', ');
}

export function makeRedLabelsAnomalyThreshold(withOperation: boolean): string {
  return [
    {
      name: 'job',
      operator: '=',
      value: '${job}',
    },
    {
      name: 'span_name',
      operator: '=',
      value: withOperation ? '${operation}' : AGGREGATED_LABEL_VALUE,
    },
    ...(hasEnvironmentAttribute()
      ? [
          {
            name: '${environmentAttribute}',
            operator: '=',
            value: '${environmentValue:anomalyThreshold}',
          },
        ]
      : []),
  ]
    .map((label) => `${label.name}${label.operator}"${label.value}"`)
    .join(', ');
}

export function getLatencyCountQuery(labels: string): string {
  return `sum(rate($\{metricName:spanMetricsCount}{${labels}} [$__rate_interval]))`;
}

export function getHistogramQuantile(query: string, value: number, groupBy: string): string {
  return `histogram_quantile(0.${value}, ${query} by (le,${groupBy}))`;
}

export function makeRedQueriesInstrumented({
  serviceType = 'server',
  groupBy,
  withJob,
  withOperation,
  jobs,
}: {
  groupBy: string;
  withJob?: boolean;
  withOperation?: boolean;
  serviceType?: 'client' | 'server';
  jobs?: string[];
}): REDQueries {
  const redLabels = makeRedLabels(!!withJob, !!withOperation, serviceType, jobs);
  const environmentFilter = hasEnvironmentAttribute()
    ? '${environmentAttribute}=~"${environmentValue:regex}"'
    : undefined;
  const labels = redLabels
    ? `${redLabels}${environmentFilter ? `, ${environmentFilter}` : ''}`
    : (environmentFilter ?? '');

  const thresholdLabels = makeRedLabelsAnomalyThreshold(!!withOperation);
  const filterBy = `$\{${FILTER_BY_NAME}:append`;

  const latencyCountQuery = getLatencyCountQuery(`${labels}${filterBy}}`);
  const latencySumQuery = `sum(rate($\{metricName:spanMetricsSum}{${labels}${filterBy}}} [$__rate_interval])) by (${groupBy})`;
  const latencyBucketQuery = `sum(rate($\{metricName:spanMetricsBucket}{${labels}${filterBy}}} [$__rate_interval]))`;

  const rateQuery = `${latencyCountQuery} by (${groupBy})`;

  const errorsQuery = `${getLatencyCountQuery(
    `${labels}, status_code="STATUS_CODE_ERROR"${filterBy}}`
  )} by (${groupBy})`;

  const PREFIX = baselineService.getRulePrefix();

  return {
    rate: {
      query: rateQuery,
      upperThreshold: `${PREFIX}:request:rate5m:anomaly_upper_threshold{${thresholdLabels}}`,
      lowerThreshold: `${PREFIX}:request:rate5m:anomaly_lower_threshold{${thresholdLabels}}`,
    },
    errors: {
      query: `(${errorsQuery} OR ${rateQuery} * 0) / ${rateQuery}`,
      upperThreshold: `${PREFIX}:error:ratio:anomaly_upper_threshold{${thresholdLabels}}`,
    },
    avg: {
      query: `${latencySumQuery} / (${latencyCountQuery} by (${groupBy}))`,
      upperThreshold: `${PREFIX}:duration:average:anomaly_upper_threshold{${thresholdLabels}}`,
      lowerThreshold: `${PREFIX}:duration:average:anomaly_lower_threshold{${thresholdLabels}}`,
    },
    p95: {
      query: getHistogramQuantile(latencyBucketQuery, 95, groupBy),
      upperThreshold: `${PREFIX}:duration:p95:anomaly_upper_threshold{${thresholdLabels}}`,
      lowerThreshold: `${PREFIX}:duration:p95:anomaly_lower_threshold{${thresholdLabels}}`,
    },
    p99: {
      query: getHistogramQuantile(latencyBucketQuery, 99, groupBy),
      upperThreshold: `${PREFIX}:duration:p99:anomaly_upper_threshold{${thresholdLabels}}`,
      lowerThreshold: `${PREFIX}:duration:p99:anomaly_lower_threshold{${thresholdLabels}}`,
    },
  };
}
