import { BackendSrvRequest } from '@grafana/runtime';

import { savePluginSettings, saveProvisionedPluginSettings } from 'api/plugin';
import { OTEL_TARGET_INFO_METRIC_NAME } from 'constants/semantics';
import { USER } from 'constants/user';
import { AppO11yPluginMeta, MetricsMode, PluginJsonData } from 'types/settings';

const STACK_HAS_DATA_LOCAL_STORAGE_KEY = 'app-observability-stack-has-data';

let defaultPluginConfigService: PluginConfigService;

export class PluginConfigService {
  private meta: AppO11yPluginMeta;
  private config: PluginJsonData;

  constructor(meta: AppO11yPluginMeta) {
    this.meta = meta;
    this.config = meta.jsonData ?? {};
  }

  getPluginConfig(): PluginJsonData {
    return this.config;
  }

  async savePluginConfig(config: Partial<PluginJsonData>, options: Partial<BackendSrvRequest> = {}): Promise<void> {
    if (USER.ROLE_FLAG.ADMIN) {
      const promises = [savePluginSettings(this.meta, config, options)];

      if (this.meta.jsonData?.grafanaComEndpoint) {
        promises.push(saveProvisionedPluginSettings(this.meta, config, options));
      }

      await Promise.all(promises);
      this.config = Object.assign({}, this.config, config);
      this.meta.jsonData = this.config;
    }
  }

  checkStackHasData(): boolean {
    const { stackHasData } = this.config;

    if (stackHasData) {
      return true;
    }

    const saved = localStorage.getItem(STACK_HAS_DATA_LOCAL_STORAGE_KEY);
    return !!saved;
  }

  async updateStackHasData(): Promise<void> {
    if (USER.ROLE_FLAG.ADMIN) {
      await this.savePluginConfig({ stackHasData: true }, { showErrorAlert: false, showSuccessAlert: false });
    } else {
      localStorage.setItem(STACK_HAS_DATA_LOCAL_STORAGE_KEY, 'true');
    }
  }

  getMetricsMode(): MetricsMode {
    if (this.config.metricsMode) {
      return this.config.metricsMode;
    }

    if (this.config.targetInfoMetricName === OTEL_TARGET_INFO_METRIC_NAME) {
      return MetricsMode.legacy;
    }

    return MetricsMode.tempoMetricsGen;
  }
}

export function getPluginConfigService(): PluginConfigService {
  return defaultPluginConfigService;
}

export function initializePluginConfigService(meta: AppO11yPluginMeta): void {
  if (!defaultPluginConfigService) {
    defaultPluginConfigService = new PluginConfigService(meta);
  }
}
