import { METRICS_MODE_LOCAL_STORAGE } from 'constants/misc';
import { getPluginConfigService } from 'services/PluginConfigService';
import { MetricsMode } from 'types/settings';

export function getMetricsMode() {
  const metricsMode: MetricsMode = getPluginConfigService().getMetricsMode();

  // Clean up metrics mode local storage, we don't need it anymore
  localStorage.removeItem(METRICS_MODE_LOCAL_STORAGE);

  return metricsMode;
}
