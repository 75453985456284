import React from 'react';

import { SceneComponentProps, sceneGraph, SceneObjectBase, VariableDependencyConfig } from '@grafana/scenes';

import { EnvironmentValueVariable } from 'components/EnvironmentValueVariable';
import { ENVIRONMENT_ATTRIBUTE_NAME, ENVIRONMENT_VALUE_NAME } from 'constants/variables';

export class EnvironmentFilterScene extends SceneObjectBase {
  static Component = EnvironmentFilterRenderer;

  protected _variableDependency = new VariableDependencyConfig(this, {
    variableNames: [ENVIRONMENT_ATTRIBUTE_NAME, ENVIRONMENT_VALUE_NAME],
  });

  constructor() {
    super({});
  }
}

function EnvironmentFilterRenderer({ model }: SceneComponentProps<EnvironmentFilterScene>) {
  const variable = sceneGraph.lookupVariable(ENVIRONMENT_VALUE_NAME, model) as EnvironmentValueVariable | undefined;

  if (!variable) {
    return null;
  }

  return <variable.Component model={variable} />;
}
