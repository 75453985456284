import { Unsubscribable } from 'rxjs';

import { SceneDataTransformer } from '@grafana/scenes';
import { DataTopic, FieldColorModeId, GraphGradientMode, TooltipDisplayMode } from '@grafana/schema';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { PromSceneQueryRunner } from 'components/PromSceneQueryRunner';
import { MIN_INTERVAL, PROMETHEUS_DS } from 'constants/datasources';
import { DEFAULT_FILL_OPACITY, PanelType } from 'constants/panels';
import { GROUP_BY_NAME } from 'constants/variables';
import { MaximizePanelButton } from 'panels/maximizePanelButton';
import { makeAnomalyHighlightOverrides, makeDrilldownLinks, makeOverrides } from 'panels/red/panelsConfig';
import { DataQueryExtended, QueryWithThresholds } from 'types/queries';
import { DURATION_COLOR } from 'utils/colors';
import { queriesWithAnomalyThresholds } from 'utils/queries';

import { AggregationDropdownScene, DurationAggregationFunction } from './DurationAggregationDropdown';
import { showAnomalyThresholdsBehavior } from './showAnomalyThresholdsBehavior';
import { showLegendBehavior } from './showLegendBehavior';
import { addDefaultSeriesNameTransformation } from './transformations/addDefaultSeriesNameTransformation';
import { addTWCFieldSeriesIndexTransformation } from './transformations/addTWCFieldSeriesIndexTransformation';
import { baselineAnomalyHighlightTransformation } from './transformations/baselineAnomalyHighlightTransformation';
import { cleanUpExemplarsTransformation } from './transformations/cleanUpExemplarsTransformation';
import { sortFramesTransformation } from './transformations/sortFramesTransformation';
import { updateTraceIdLinkTransformation } from './transformations/updateTraceIdLinkTransformation';
import { updateColorsBehaviour } from './updateColorsBehaviour';

export class DurationPanel extends ExplorablePanel {
  constructor({
    job,
    operation,
    isInstrumented,
    module,
    avg,
    p95,
    p99,
  }: {
    job: string;
    operation?: string;
    isInstrumented: boolean;
    module: 'operation' | 'service';
    avg: QueryWithThresholds;
    p95: QueryWithThresholds;
    p99: QueryWithThresholds;
  }) {
    const p99QueryId = `${module}P99Query`;
    const p95QueryId = `${module}P95Query`;
    const avgQueryId = `${module}AvgQuery`;

    const queries: Record<DurationAggregationFunction, DataQueryExtended[]> = {
      [DurationAggregationFunction.P99]: queriesWithAnomalyThresholds(
        {
          exemplar: true,
          expr: p99.query,
          displayName: '99th percentile',
          legendFormat: `$\{${GROUP_BY_NAME}:legend${isInstrumented ? '' : 'downstream'}:P99}`,
          refId: p99QueryId,
        },
        p99
      ),
      [DurationAggregationFunction.P95]: queriesWithAnomalyThresholds(
        {
          exemplar: true,
          expr: p95.query,
          displayName: '95th percentile',
          legendFormat: `$\{${GROUP_BY_NAME}:legend${isInstrumented ? '' : 'downstream'}:P95}`,
          refId: p95QueryId,
        },
        p95
      ),
      [DurationAggregationFunction.AVG]: queriesWithAnomalyThresholds(
        {
          expr: avg.query,
          displayName: 'Average',
          legendFormat: `$\{${GROUP_BY_NAME}:legend${isInstrumented ? '' : 'downstream'}:AVG}.`,
          refId: avgQueryId,
        },
        avg
      ),
    };

    const aggregationDropdown = new AggregationDropdownScene();

    const queryRunner = new PromSceneQueryRunner({
      datasource: PROMETHEUS_DS,
      queries: queries[aggregationDropdown.state.aggregationFunction],
      queriesWithThresholds: queries[aggregationDropdown.state.aggregationFunction],
      maxDataPointsFromWidth: true,
      minInterval: MIN_INTERVAL,
    });

    super({
      title: 'Duration',

      trackingSection: module,
      trackingPage: 'overview',
      trackingPanel: 'duration',

      fieldConfig: {
        defaults: {
          color: {
            mode: FieldColorModeId.Fixed,
            fixedColor: DURATION_COLOR,
          },
          unit: 's',
          custom: {
            fillOpacity: DEFAULT_FILL_OPACITY,
            spanNulls: true,
            gradientMode: GraphGradientMode.Opacity,
          },
          links: makeDrilldownLinks({
            withMinDuration: true,
            withOperation: module === 'operation',
            getScene: () => this,
            isInstrumented,
          }),
        },
        overrides: [
          ...makeOverrides(p99QueryId, DURATION_COLOR),
          ...makeOverrides(p95QueryId, DURATION_COLOR),
          ...makeOverrides(avgQueryId, DURATION_COLOR),
          ...makeAnomalyHighlightOverrides(),
        ],
      },

      options: {
        tooltip: {
          mode: TooltipDisplayMode.Multi,
        },
      },

      headerActions: [new MaximizePanelButton(PanelType.DURATION, job, operation), aggregationDropdown],

      $data: new SceneDataTransformer({
        transformations: [
          {
            operator: updateTraceIdLinkTransformation(isInstrumented, () => this),
            topic: DataTopic.Annotations,
          },
          {
            operator: cleanUpExemplarsTransformation,
            topic: DataTopic.Annotations,
          },
          sortFramesTransformation,
          addTWCFieldSeriesIndexTransformation,
          addDefaultSeriesNameTransformation,
          baselineAnomalyHighlightTransformation,
        ],
        $data: queryRunner,
      }),

      $behaviors: [
        showAnomalyThresholdsBehavior(queryRunner),
        showLegendBehavior(),
        (panel: ExplorablePanel) => updateColorsBehaviour(panel, DURATION_COLOR),

        // keep queries in sync with selected aggregation function
        (panel: ExplorablePanel) => {
          function updateQueries() {
            const nextQueries = queries[aggregationDropdown.state.aggregationFunction];
            queryRunner.setState({
              queries: nextQueries,
              queriesWithThresholds: nextQueries,
            });
            queryRunner.runQueries();
          }
          const unsubs: Unsubscribable[] = [];
          unsubs.push(aggregationDropdown.subscribeToState(updateQueries));
          return () => unsubs.forEach((unsub) => unsub.unsubscribe());
        },
      ],
    });
  }
}
