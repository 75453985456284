import { PRESERVE_URL_KEYS, ROUTE_DEFINITIONS, ROUTES } from 'constants/routing';
import { TrackedSceneAppPage } from 'faro/TrackedSceneAppPage';

import { makeServiceLogsScene } from './makeServiceLogsScene';

export function makeServiceLogsPage(job: string, encodedJob: string): TrackedSceneAppPage {
  return new TrackedSceneAppPage({
    viewName: 'service-logs',
    title: 'Logs',
    routePath: ROUTE_DEFINITIONS.logs,
    url: ROUTES.logs(encodedJob),
    preserveUrlKeys: PRESERVE_URL_KEYS,
    getScene: makeServiceLogsScene(job, encodedJob),
  });
}
