import { map } from 'rxjs';

import { DataTransformerID, FieldColorModeId, FieldConfigProperty, FieldMatcherID, FieldType } from '@grafana/data';
import { locationService } from '@grafana/runtime';
import { SceneDataTransformer } from '@grafana/scenes';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { PromSceneQueryRunner } from 'components/PromSceneQueryRunner';
import { MIN_INTERVAL, PROMETHEUS_DS } from 'constants/datasources';
import { DEFAULT_FIELD_CONFIG, DEFAULT_SPARKLINE_CELL_OPTIONS, PanelType } from 'constants/panels';
import { ROUTES } from 'constants/routing';
import { MaximizePanelButton } from 'panels/maximizePanelButton';
import { getRenderService } from 'services/RenderService';
import { DURATION_COLOR, ERROR_COLOR, RATE_COLOR } from 'utils/colors';
import { isMaxPanelRoute } from 'utils/maxPanel';

export interface RelationshipPanelOptions {
  job: string;
  operation?: string;
  title: string;
  serviceNameField: string;
  serviceNamespaceField: string;
  refIdSuffix: string;
  trackingSuffix: string;
  type: PanelType;
  queries: {
    rate: string;
    errors: string;
    duration: string;
  };
}

/// this is "abstract" make, abstracting common features of downstream and upstream panels
export function makeServiceOverviewRelationshipPanel(options: RelationshipPanelOptions): ExplorablePanel {
  const { pathname } = locationService.getLocation();
  const { serviceNameField, serviceNamespaceField, title, queries, type, job, operation } = options;
  const isRunningAsExtension = getRenderService().isRunningAsExtension();

  return new ExplorablePanel({
    title,

    trackingSection: 'service',
    trackingPage: 'overview',
    trackingPanel: options.trackingSuffix,

    pluginId: 'table',

    headerActions: [new MaximizePanelButton(type, job, operation)],

    fieldConfig: {
      defaults: {
        ...DEFAULT_FIELD_CONFIG,
        links: [
          {
            title: '',
            url: `${ROUTES.overview('${__data.fields.job:path}')}?\${__url_time_range}&\${__all_variables}`,
            ...(isRunningAsExtension ? { targetBlank: true } : {}),
          },
        ],
      },
      overrides: [
        {
          matcher: {
            id: FieldMatcherID.byName,
            options: serviceNamespaceField,
          },
          properties: [
            {
              id: 'custom.hidden',
              value: true,
            },
          ],
        },
        {
          matcher: {
            id: FieldMatcherID.byName,
            options: 'job',
          },
          properties: [
            {
              id: 'custom.hidden',
              value: true,
            },
          ],
        },
        {
          matcher: {
            id: FieldMatcherID.byName,
            options: 'Rate',
          },
          properties: [
            {
              id: 'custom.cellOptions',
              value: DEFAULT_SPARKLINE_CELL_OPTIONS,
            },
            {
              id: FieldConfigProperty.Unit,
              value: 'reqps',
            },
            {
              id: FieldConfigProperty.Decimals,
              value: 2,
            },
            {
              id: FieldConfigProperty.Color,
              value: {
                mode: FieldColorModeId.Fixed,
                fixedColor: RATE_COLOR,
              },
            },
          ],
        },
        {
          matcher: {
            id: FieldMatcherID.byName,
            options: 'Errors',
          },
          properties: [
            {
              id: 'custom.cellOptions',
              value: DEFAULT_SPARKLINE_CELL_OPTIONS,
            },
            {
              id: FieldConfigProperty.Unit,
              value: 'percentunit',
            },
            {
              id: FieldConfigProperty.Min,
              value: 0,
            },
            {
              id: FieldConfigProperty.NoValue,
              value: '0%',
            },
            {
              id: FieldConfigProperty.Color,
              value: {
                mode: FieldColorModeId.Fixed,
                fixedColor: ERROR_COLOR,
              },
            },
          ],
        },
        {
          matcher: {
            id: FieldMatcherID.byName,
            options: 'Duration, p95',
          },
          properties: [
            {
              id: 'custom.cellOptions',
              value: DEFAULT_SPARKLINE_CELL_OPTIONS,
            },
            {
              id: FieldConfigProperty.Unit,
              value: 's',
            },
            {
              id: FieldConfigProperty.Decimals,
              value: 2,
            },
            {
              id: FieldConfigProperty.Color,
              value: {
                mode: FieldColorModeId.Fixed,
                fixedColor: DURATION_COLOR,
              },
            },
          ],
        },
        {
          matcher: {
            id: FieldMatcherID.byName,
            options: 'Name',
          },
          properties: [
            {
              id: 'custom.filterable',
              value: true,
            },
          ],
        },
      ],
    },

    options: {
      footer: {
        enablePagination: true,
      },

      cellHeight: isMaxPanelRoute(pathname) ? 'lg' : 'sm',
    },

    $data: new SceneDataTransformer({
      transformations: [
        {
          id: DataTransformerID.timeSeriesTable,
          options: {},
        },
        {
          id: DataTransformerID.merge,
          options: {},
        },
        // add hidden "job" field, used for creating links
        () => (source) =>
          source.pipe(
            map((frames) =>
              frames.map((frame) => {
                const nameField = frame.fields.find((field) => field.name === serviceNameField);
                const namespaceField = frame.fields.find((field) => field.name === serviceNamespaceField);
                if (!nameField) {
                  // empty result has no fields
                  return frame;
                }
                return {
                  ...frame,
                  fields: [
                    {
                      name: 'job',
                      type: FieldType.string,
                      config: {},
                      values: nameField.values.map((name, index) => {
                        const namespace = namespaceField?.values[index];
                        if (namespace) {
                          return `${namespace}/${name}`;
                        }
                        return name;
                      }),
                    },
                    ...frame.fields,
                  ],
                };
              })
            )
          ),
        {
          id: 'organize',
          options: {
            excludeByName: {
              Time: true,
              'Time 1': true,
              'Time 2': true,
              'Time 3': true,
            },
            indexByName: {},
            renameByName: {
              [`Trend #serviceOverview${options.refIdSuffix}Rate`]: 'Rate',
              [`Trend #serviceOverview${options.refIdSuffix}Errors`]: 'Errors',
              [`Trend #serviceOverview${options.refIdSuffix}Duration`]: 'Duration, p95',
              [serviceNameField]: 'Name',
            },
          },
        },
      ],

      $data: new PromSceneQueryRunner({
        // @TODO also filter by namespace once it's properly available in metrics
        datasource: PROMETHEUS_DS,
        maxDataPoints: 100,
        minInterval: MIN_INTERVAL,
        queries: [
          {
            expr: queries.duration,
            refId: `serviceOverview${options.refIdSuffix}Duration`,
            timeRangeCompare: false,
          },
          {
            expr: queries.errors,
            refId: `serviceOverview${options.refIdSuffix}Errors`,
            timeRangeCompare: false,
          },
          {
            expr: queries.rate,
            refId: `serviceOverview${options.refIdSuffix}Rate`,
            timeRangeCompare: false,
          },
        ],
      }),
    }),
  });
}
