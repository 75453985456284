import { LoadingState } from '@grafana/data';
import {
  EmbeddedScene,
  EmbeddedSceneState,
  sceneGraph,
  SceneQueryRunner,
  VariableDependencyConfig,
} from '@grafana/scenes';

import { PROMETHEUS_DS } from 'constants/datasources';
import {
  BASELINE_CONSTANT_REF_ID,
  SERVICE_GRAPH_RATE_REF_ID,
  SERVICES_SPAN_KIND,
  TARGET_INFO_REF_ID,
} from 'constants/query';
import { isValidSpanKind } from 'constants/spanKind';
import {
  ENVIRONMENT_ATTRIBUTE_NAME,
  ENVIRONMENT_VALUE_NAME,
  JOB_NAME,
  LOKI_DS_NAME,
  PROMETHEUS_DS_NAME,
  TEMPO_DS_NAME,
} from 'constants/variables';
import { NewMetadataFetchedEvent } from 'modules/service/events';
import { getFullTargetInfoQuery } from 'queries/targetInfo';
import { getMetadataService } from 'services/MetadataService';
import { getPluginConfigService } from 'services/PluginConfigService';
import { hasEnvironmentAttribute } from 'utils/environmentFilter';

export class ServiceEmbeddedScene extends EmbeddedScene {
  protected _variableDependency = new VariableDependencyConfig(this, {
    variableNames: [
      ENVIRONMENT_ATTRIBUTE_NAME,
      ENVIRONMENT_VALUE_NAME,
      JOB_NAME,
      PROMETHEUS_DS_NAME,
      TEMPO_DS_NAME,
      LOKI_DS_NAME,
    ],
  });

  constructor(state: EmbeddedSceneState, isInstrumented?: boolean, isRuntime?: boolean) {
    const pluginConfig = getPluginConfigService().getPluginConfig();
    const $data = new SceneQueryRunner({
      datasource: PROMETHEUS_DS,
      queries: [
        // does not make sense to query target info when we know that the service is not instrumented
        ...(isInstrumented !== false
          ? [
              {
                refId: TARGET_INFO_REF_ID,
                expr: getFullTargetInfoQuery('${job}', hasEnvironmentAttribute() && !isRuntime),
              },
              {
                refId: BASELINE_CONSTANT_REF_ID,
                expr: `${pluginConfig.baselineRulePrefix ?? 'appo11y'}:duration:threshold`,
                instant: true,
              },
            ]
          : []),
        {
          refId: SERVICE_GRAPH_RATE_REF_ID,
          expr: `group by (server) ($\{metricName:serviceGraphRequestServerSecondsCount}{server="$\{job}"})`,
        },
        ...(pluginConfig.isClientOnlyServicesEnabled
          ? [
              {
                refId: SERVICES_SPAN_KIND,
                expr: `group by (span_kind, job) ($\{metricName:spanMetricsCount}{job="$\{job}", span_kind=~"SPAN_KIND_(CLIENT|PRODUCER|SERVER|CONSUMER)"})`,
              },
            ]
          : []),
      ].map((query) => ({ ...query, timeRangeCompare: false })),
    });

    super({ ...state, $data });

    this.addActivationHandler(() => {
      const unsubscribable = this.state.$data!.subscribeToState((newState, prevState) => {
        if (
          newState.data?.state === LoadingState.Done &&
          (prevState.data?.state === LoadingState.Loading || newState.data.series !== prevState.data?.series)
        ) {
          const metadataService = getMetadataService();
          const frames = newState.data?.series ?? [];
          const spanKindFrames = frames.filter(({ refId }) => refId === SERVICES_SPAN_KIND);
          const job = sceneGraph.interpolate(this, '${job}');

          spanKindFrames.forEach((frame) => {
            if (frame.fields.length > 1) {
              const [_, valuesField] = frame.fields;
              const labels = valuesField?.labels ?? {};

              if (labels.span_kind && isValidSpanKind(labels.span_kind)) {
                metadataService.addSpanKind(job, labels.span_kind);
                metadataService.setClientInfo(job);
              }
            }
          });

          const isInstrumented = frames.some(({ refId, length }) => refId === TARGET_INFO_REF_ID && Number(length) > 0);
          const baselineEnabled = frames.some(
            ({ refId, length }) => refId === BASELINE_CONSTANT_REF_ID && Number(length) > 0
          );

          metadataService.setIsBaselineEnabled(baselineEnabled);

          this.publishEvent(new NewMetadataFetchedEvent({ isInstrumented, frames, job, baselineEnabled }), true);
        }
      });

      return () => unsubscribable.unsubscribe();
    });
  }
}
