import { PRESERVE_URL_KEYS, ROUTE_DEFINITIONS, ROUTES } from 'constants/routing';
import { TrackedSceneAppPage } from 'faro/TrackedSceneAppPage';

import { makeServiceGoScene } from './makeServiceGoScene';

export function makeServiceGoPage(job: string, encodedJob: string): TrackedSceneAppPage {
  return new TrackedSceneAppPage({
    viewName: 'service-go',
    title: 'Go',
    routePath: ROUTE_DEFINITIONS.go,
    url: ROUTES.go(encodedJob),
    preserveUrlKeys: PRESERVE_URL_KEYS,
    getScene: makeServiceGoScene(job),
  });
}
