import { InterpolateFunction } from '@grafana/data';
import { sceneGraph, SceneObject, VariableCustomFormatterFn, VizPanel, VizPanelState } from '@grafana/scenes';

import { ACCESS_CONTROL } from 'constants/accessControl';
import { encodeURIParameter } from 'utils/routing';

import { ExploreButton } from './ExploreButton';

export interface ExplorablePanelState extends Partial<VizPanelState> {
  trackingSection: string;
  trackingPage: string;
  trackingPanel: string;
  headerActions?: SceneObject[];
}

export class ExplorablePanel extends VizPanel {
  constructor(state: ExplorablePanelState) {
    const { headerActions, trackingSection, trackingPage, trackingPanel } = state;
    super({
      ...state,
      headerActions: ACCESS_CONTROL.EXPLORE.VIEW
        ? [
            ...(headerActions || []),
            new ExploreButton({
              trackingSection,
              trackingPage,
              trackingPanel,
            }),
          ]
        : headerActions,
    });
  }

  // use upgraded encoding function that custom escapes "/"
  // @TODO this gets called for the whole template, not individual parameters in it
  // it will encode every one, potentially replacing / in query params
  // not sure if its an issue, but might want to look for better solution
  interpolate: InterpolateFunction = (value, scopedVars, format) => {
    if (value.includes(':path')) {
      return sceneGraph.interpolate(
        this,
        value.replace(/:path/g, ''),
        scopedVars,
        encodeURIParameter as VariableCustomFormatterFn
      );
    }

    return sceneGraph.interpolate(this, value, scopedVars, format as VariableCustomFormatterFn);
  };
}
