import React from 'react';

import { SceneObjectRef } from '@grafana/scenes';

import { HostModalScene } from 'components/HostModal';
import { NotificationScene } from 'components/Notification';
import { Subtitle } from 'components/Subtitle';
import { PRESERVE_URL_KEYS, ROUTES } from 'constants/routing';
import { TrackedSceneAppPage } from 'faro/TrackedSceneAppPage';

import { makeServiceMapScene } from './makeServiceMapScene';

export const SERVICE_MAP_VIEW_NAME = 'service-map';

export function makeServiceMapPage(
  notificationRef: SceneObjectRef<NotificationScene>,
  hostModalRef: SceneObjectRef<HostModalScene>
): TrackedSceneAppPage {
  return new TrackedSceneAppPage({
    viewName: SERVICE_MAP_VIEW_NAME,
    title: 'Service Map',
    subTitle: <Subtitle notificationRef={notificationRef} hostModalRef={hostModalRef} />,
    url: ROUTES.servicesMap(),
    preserveUrlKeys: PRESERVE_URL_KEYS,
    getScene: makeServiceMapScene(),
  });
}
