import {
  ConfigOverrideRule,
  DataLink,
  DataLinkClickEvent,
  FieldConfigProperty,
  FieldMatcherID,
  urlUtil,
} from '@grafana/data';
import { FieldColorModeId, GraphGradientMode } from '@grafana/schema';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { APP_O11Y_PALETTE_ID } from 'constants/panels';
import {
  LOWER_ANOMALY_THRESHOLD_LEGEND_NAME,
  LOWER_ANOMALY_THRESHOLD_REF_ID,
  UPPER_ANOMALY_HIGHLIGHT_LINE_REF_ID,
  UPPER_ANOMALY_THRESHOLD_LEGEND_NAME,
  UPPER_ANOMALY_THRESHOLD_REF_ID,
} from 'constants/query';
import { ROUTES } from 'constants/routing';
import { USER } from 'constants/user';
import { TEMPO_DS_TYPE } from 'constants/variables';
import { intervalToSeconds } from 'modules/alerting/utils/time';
import { TabChange } from 'modules/service/events';
import { getDataSourceService } from 'services/DataSourceService';
import { getRenderService } from 'services/RenderService';
import { TempoQuery } from 'types/queries';
import { ANOMALY_HIGHLIGHT_COLOR } from 'utils/colors';
import { buildRoute, encodeURIParameter } from 'utils/routing';
import { getFullInterpolatedTracesQuery, prepareTracesQueryForUninstrumentedExplore } from 'utils/tempo';

export function makeAnomalyHighlightOverrides(): ConfigOverrideRule[] {
  return [
    {
      matcher: {
        id: FieldMatcherID.byFrameRefID,
        options: UPPER_ANOMALY_HIGHLIGHT_LINE_REF_ID,
      },
      properties: [
        { id: 'custom.lineWidth', value: 0 },
        {
          id: FieldConfigProperty.Color,
          value: {
            mode: FieldColorModeId.Fixed,
            fixedColor: ANOMALY_HIGHLIGHT_COLOR,
          },
        },
        {
          id: 'custom.fillBelowTo',
          value: UPPER_ANOMALY_THRESHOLD_LEGEND_NAME,
        },
        {
          id: 'custom.fillOpacity',
          value: 50,
        },
        {
          id: 'custom.showPoints',
          value: 'never',
        },
        {
          id: 'custom.hideFrom',
          value: {
            tooltip: true,
            viz: false,
            legend: true,
          },
        },
      ],
    },
  ];
}

export function makeOverrides(refId: string, baselineColor: string, errorThreshold = false): ConfigOverrideRule[] {
  return [
    {
      matcher: {
        id: FieldMatcherID.byFrameRefID,
        options: `${refId}-compare`,
      },
      properties: [
        {
          id: FieldConfigProperty.Color,
          value: {
            mode: APP_O11Y_PALETTE_ID,
          },
        },
        {
          id: 'custom.lineStyle',
          value: {
            dash: [4, 5],
            fill: 'dash',
          },
        },
        { id: 'custom.lineWidth', value: 2 },
      ],
    },
    {
      matcher: {
        id: FieldMatcherID.byFrameRefID,
        options: UPPER_ANOMALY_THRESHOLD_REF_ID,
      },
      properties: [
        ...(!errorThreshold
          ? [
              {
                id: 'custom.fillBelowTo',
                value: LOWER_ANOMALY_THRESHOLD_LEGEND_NAME,
              },
            ]
          : []),
        {
          id: FieldConfigProperty.Color,
          value: {
            mode: FieldColorModeId.Fixed,
            fixedColor: baselineColor,
          },
        },
        {
          id: 'custom.showPoints',
          value: 'never',
        },
        {
          id: 'custom.gradientMode',
          value: GraphGradientMode.None,
        },
        {
          id: 'custom.fillOpacity',
          value: 20,
        },
        {
          id: 'custom.lineWidth',
          value: 0,
        },
      ],
    },
    {
      matcher: {
        id: FieldMatcherID.byFrameRefID,
        options: LOWER_ANOMALY_THRESHOLD_REF_ID,
      },
      properties: [
        {
          id: FieldConfigProperty.Color,
          value: {
            mode: FieldColorModeId.Fixed,
            fixedColor: ANOMALY_HIGHLIGHT_COLOR,
          },
        },
        {
          id: 'custom.showPoints',
          value: 'never',
        },
        {
          id: 'custom.lineWidth',
          value: 0,
        },
        {
          id: 'custom.fillBelowTo',
          value: 'Value', // may break eventually, but easier than legend name here
        },
        {
          id: 'custom.fillOpacity',
          value: 50,
        },
        {
          id: 'custom.gradientMode',
          value: GraphGradientMode.None,
        },
      ],
    },
  ];
}

interface MakeDrilldownLinksOptions {
  // filter links by duration (only Traces now)
  withMinDuration?: boolean;

  // filter links to errors (only Traces now)
  withError?: boolean;

  // filter links to specific operation (only Traces)
  withOperation?: boolean;

  isInstrumented?: boolean;
  isDurationDistribution?: boolean;

  getScene?: () => ExplorablePanel;
}

export function makeDrilldownLinks({
  isDurationDistribution,
  withError,
  withMinDuration,
  withOperation,
  isInstrumented,
  getScene,
}: MakeDrilldownLinksOptions = {}): DataLink[] {
  const isRunningAsExtension = getRenderService().isRunningAsExtension();

  if (!isInstrumented) {
    if (!USER.ROLE_FLAG.ADMIN || isRunningAsExtension) {
      return [];
    }

    return getUninstrumentedLinks({ withMinDuration, withError, withOperation });
  }

  if (isDurationDistribution) {
    return getDurationDistributionLinks({ getScene, isInstrumented, withOperation });
  }

  const getTracesQueryFromEvt = (
    evt: DataLinkClickEvent
  ): { tracesQuery: TempoQuery; job: string; operation?: string; from: number; to: number } => {
    const job = evt.replaceVariables!('${job}') || '';
    const operation = withOperation ? evt.replaceVariables!('${operation}') : undefined;
    const interval = intervalToSeconds(evt.replaceVariables!('${__interval}')) * 4;
    const time = Number(evt.replaceVariables?.('${__value.time}'));
    const { from, to } = getInterval(time, interval);

    const tracesQuery = getFullInterpolatedTracesQuery({
      interpolate: evt.replaceVariables,
      job,
      operation,
      withError,
      minDuration: withMinDuration ? Number(evt.replaceVariables?.('${__value.raw}')) : undefined,
      getScene,
    });

    return { tracesQuery, job, operation, from, to };
  };

  return [
    {
      title: 'Traces',
      ...(isRunningAsExtension
        ? {
            onClick: (evt) => {
              const { tracesQuery, from, to } = getTracesQueryFromEvt(evt);
              getScene?.().publishEvent(new TabChange({ tab: 'traces', query: tracesQuery.query, from, to }), true);
            },
          }
        : {
            onBuildUrl: (evt) => {
              const { tracesQuery, job, operation, from, to } = getTracesQueryFromEvt(evt);
              const baseUrl =
                withOperation && operation
                  ? ROUTES.operationTraces(encodeURIParameter(job), encodeURIParameter(operation))
                  : ROUTES.traces(encodeURIParameter(job));

              return buildRoute(baseUrl, {
                from,
                to,
                ...(tracesQuery ? { tracesQuery: encodeTracesQuery(tracesQuery) } : {}),
              });
            },
          }),
      url: '',
    },
    {
      title: 'Logs',
      ...(isRunningAsExtension
        ? {
            onClick: (evt) => {
              const interval = intervalToSeconds(evt.replaceVariables!('${__interval}'));
              const time = Number(evt.replaceVariables?.('${__value.time}'));
              const { from, to } = getInterval(time, interval);

              getScene?.().publishEvent(new TabChange({ tab: 'logs', query: undefined, from, to }), true);
            },
          }
        : {
            onBuildUrl: (evt) => {
              const job = evt.replaceVariables!('$job');
              const baseUrl = ROUTES.logs(encodeURIParameter(job));

              const interval = intervalToSeconds(evt.replaceVariables!('${__interval}'));
              const time = Number(evt.replaceVariables?.('${__value.time}'));
              const { from, to } = getInterval(time, interval);

              return buildRoute(baseUrl, { from, to });
            },
          }),
      url: '',
    },
  ];
}

function getDurationDistributionLinks({ getScene, withOperation }: MakeDrilldownLinksOptions = {}): DataLink[] {
  const getTracesQueryFromEvt = (
    evt: DataLinkClickEvent
  ): { tracesQuery: TempoQuery; job: string; operation?: string } => {
    const job = evt.replaceVariables!('${job}') || '';
    const operation = withOperation ? evt.replaceVariables!('${operation}') : undefined;

    const targetValue = evt.replaceVariables!('${__data.fields[Field]}');
    const buckets: string[] = evt.origin.config.custom.buckets || [];
    const targetValueIdx = buckets.findIndex((value) => value === targetValue);

    const targetBucket = cleanBucket(buckets.at(targetValueIdx));
    const previousTargetBucket = cleanBucket(buckets.at(targetValueIdx - 1));

    return {
      tracesQuery: getFullInterpolatedTracesQuery({
        interpolate: evt.replaceVariables,
        job,
        operation,
        withError: false,
        minDuration: previousTargetBucket,
        maxDuration: targetBucket,
        getScene,
      }),
      job,
      operation,
    };
  };

  return [
    {
      title: 'Traces',
      url: '',
      ...(getRenderService().isRunningAsExtension()
        ? {
            onClick: (evt) => {
              const { tracesQuery } = getTracesQueryFromEvt(evt);
              getScene?.().publishEvent(new TabChange({ tab: 'traces', query: tracesQuery.query }), true);
            },
          }
        : {
            onBuildUrl: (evt) => {
              const { tracesQuery, job, operation } = getTracesQueryFromEvt(evt);
              const baseUrl =
                withOperation && operation
                  ? ROUTES.operationTraces(encodeURIParameter(job), encodeURIParameter(operation))
                  : ROUTES.traces(encodeURIParameter(job));

              return buildRoute(baseUrl, { tracesQuery: encodeTracesQuery(tracesQuery) });
            },
          }),
    },
  ];
}

function getUninstrumentedLinks({
  withMinDuration,
  withError,
  withOperation,
}: MakeDrilldownLinksOptions = {}): DataLink[] {
  return [
    {
      title: 'Traces',
      onBuildUrl: (evt) => {
        const interval = intervalToSeconds(evt.replaceVariables!('${__interval}')) * 4;
        const job = evt.replaceVariables?.('${job}') || '';
        const time = Number(evt.replaceVariables?.('${__value.time}'));
        const operation = withOperation ? evt.replaceVariables!('${operation}') : undefined;

        const tracesQuery = getFullInterpolatedTracesQuery({
          interpolate: evt.replaceVariables,
          job,
          operation,
          withError,
          minDuration: withMinDuration ? Number(evt.replaceVariables?.('${__value.raw}')) : undefined,
        });

        if (tracesQuery) {
          prepareTracesQueryForUninstrumentedExplore(tracesQuery, evt.replaceVariables!);
        }

        const { from, to } = getInterval(time, interval);
        return urlUtil.renderUrl('/explore', {
          left: JSON.stringify({
            datasource: {
              type: TEMPO_DS_TYPE,
              uid: getDataSourceService().getSelectedDataSourceUID(TEMPO_DS_TYPE),
            },
            queries: [tracesQuery],
            range: { from, to },
          }),
        });
      },
      url: '',
    },
  ];
}

function getInterval(time: number, seconds: number) {
  return { from: time - seconds * 1000, to: time + seconds * 1000 };
}

function cleanBucket(bucket: string | undefined): string | undefined {
  if (bucket === undefined || bucket === '+Inf') {
    return undefined;
  }

  if (bucket === 'less than a millisecond' || bucket === '<1ms') {
    return '1ms';
  }

  return bucket;
}

function encodeTracesQuery(query: TempoQuery) {
  return encodeURIComponent(JSON.stringify(query));
}
