import React from 'react';
import { Unsubscribable } from 'rxjs';

import { SelectableValue, TimeRange } from '@grafana/data';
import {
  DataSourceVariable,
  EmbeddedScene,
  sceneGraph,
  SceneTimeRangeCompare,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { TextLink, useTheme2 } from '@grafana/ui';

import { ACCESS_CONTROL } from 'constants/accessControl';
import { ROUTES } from 'constants/routing';
import { GROUP_BY_NAME, PROMETHEUS_DS_NAME } from 'constants/variables';
import { TrackedSceneAppPage } from 'faro/TrackedSceneAppPage';
import { BaselineCompareUpdate, FilterByVariableUpdate, NewMetadataFetchedEvent } from 'modules/service/events';
import { getMetadataService } from 'services/MetadataService';
import { getPluginConfigService } from 'services/PluginConfigService';

import { GroupByVariable } from './GroupByVariable';

export const BASELINE_COMPARER_VALUE = 'baseline';

type TWCState = ConstructorParameters<typeof SceneTimeRangeCompare>[0];

interface TWCSelectableValue extends Omit<SelectableValue<string>, 'label' | 'value'> {
  label: string;
  value: string;
}

export class TimeRangeCompareWithBaseline extends SceneTimeRangeCompare {
  isBaselineEnabled = false;
  private baselineDefaultSet = false;

  protected _variableDependency = new VariableDependencyConfig(this, {
    variableNames: [PROMETHEUS_DS_NAME],
  });

  constructor(state: ConstructorParameters<typeof SceneTimeRangeCompare>[0]) {
    super(state);
    const origCompareOptions = this.getCompareOptions;

    // override getCompareOptions to add baseline option
    this.getCompareOptions = (timeRange: TimeRange): TWCSelectableValue[] => {
      const options = origCompareOptions.call(this, timeRange) as TWCSelectableValue[];
      const opts: TWCSelectableValue[] = [
        options[0],
        {
          label: 'Baseline',
          value: BASELINE_COMPARER_VALUE,
          isDisabled: !this.isBaselineEnabled,
          component: !this.isBaselineEnabled ? EnableBaselineLink : undefined,
        },
        ...options.slice(1),
      ];
      return opts;
    };

    // add activation handler to disable baseline option if group by or filterBy is enabled,
    // or disable group by if baseline is enabled
    this.addActivationHandler(() => {
      const groupByVariable = sceneGraph.lookupVariable(GROUP_BY_NAME, this) as GroupByVariable | undefined;
      const embeddedScene = this.getEmbeddedScene();
      const unsubscribables: Unsubscribable[] = [];

      if (groupByVariable) {
        unsubscribables.push(
          groupByVariable.subscribeToState((newState, prevState) => {
            if (
              newState.value !== prevState.value &&
              !groupByVariable.isEmpty() &&
              this.state.compareWith === BASELINE_COMPARER_VALUE
            ) {
              this.onClearCompare();
            }
          })
        );
      }

      unsubscribables.push(
        this.subscribeToState((newState, prevState) => {
          if (newState.compareWith !== prevState.compareWith && newState.compareWith === BASELINE_COMPARER_VALUE) {
            if (groupByVariable) {
              groupByVariable.setEmpty();
            }

            this.publishEvent(new BaselineCompareUpdate(), true);
          }
        })
      );

      unsubscribables.push(
        embeddedScene.subscribeToEvent(FilterByVariableUpdate, () => {
          if (this.state.compareWith === BASELINE_COMPARER_VALUE) {
            this.onClearCompare();
          }
        })
      );
      return () => {
        unsubscribables.forEach((u) => u.unsubscribe());
      };
    });

    // add a handler to check that baseline is activated for currently selected datasource
    // and reset the options accordingly
    this.addActivationHandler(() => {
      const promVar = sceneGraph.lookupVariable(PROMETHEUS_DS_NAME, this) as DataSourceVariable;
      const groupByVariable = sceneGraph.lookupVariable(GROUP_BY_NAME, this) as GroupByVariable | undefined;
      const embeddedScene = this.getEmbeddedScene();

      const addRemoveBaselineOption = () => {
        if (this.isClientOnly()) {
          return;
        }

        this.isBaselineEnabled = getMetadataService().isBaselineEnabled();
        this.setState({ compareOptions: this.getCompareOptions(sceneGraph.getTimeRange(this).state.value) });
        const datasourceUid = promVar.getValue() as string;
        if (datasourceUid) {
          const newState: TWCState = {
            compareOptions: this.getCompareOptions(sceneGraph.getTimeRange(this).state.value),
          };

          // activate baseline by default if enabled
          if (this.isBaselineEnabled && !this.state.compareWith && groupByVariable?.isEmpty()) {
            if (!this.baselineDefaultSet) {
              newState.compareWith = BASELINE_COMPARER_VALUE;
              this.baselineDefaultSet = true;
            }

            // deactivate baseline if data source changed and it doesn't support baseline
          } else if (!this.isBaselineEnabled && this.state.compareWith === BASELINE_COMPARER_VALUE) {
            newState.compareWith = undefined;
          }
          this.setState(newState);
        }
      };

      const unsubscribables: Unsubscribable[] = [];

      if (embeddedScene) {
        unsubscribables.push(embeddedScene.subscribeToEvent(NewMetadataFetchedEvent, addRemoveBaselineOption));
      }

      addRemoveBaselineOption();

      return () => unsubscribables.forEach((u) => u.unsubscribe());
    });
  }

  private getEmbeddedScene() {
    let embeddedScene: TrackedSceneAppPage | EmbeddedScene;

    // Support component being used in our plugin, or as an extension
    try {
      embeddedScene = sceneGraph.getAncestor(this, TrackedSceneAppPage);
    } catch (error) {
      embeddedScene = sceneGraph.getAncestor(this, EmbeddedScene);
    }

    return embeddedScene;
  }

  override getCompareTimeRange(timeRange: TimeRange): TimeRange | undefined {
    if (this.state.compareWith === 'baseline') {
      return undefined;
    }
    return super.getCompareTimeRange(timeRange);
  }

  // We should disable baseline comparison when:
  //   1. Feature flag is not available
  //   2. Current service is client only
  private isClientOnly(): boolean {
    const clientOnlyServiceIsEnabled = getPluginConfigService().getPluginConfig().isClientOnlyServicesEnabled;
    if (!clientOnlyServiceIsEnabled) {
      return false;
    }

    const job = sceneGraph.interpolate(this, '${job}');
    return getMetadataService().getIsClientOnly(job);
  }
}

function EnableBaselineLink() {
  const theme = useTheme2();
  return (
    <TextLink
      external={!ACCESS_CONTROL.BASELINE.ENABLE}
      style={{
        position: 'absolute',
        right: theme.spacing(1),
        textAlign: 'right',
      }}
      href={
        ACCESS_CONTROL.BASELINE.ENABLE
          ? ROUTES.settingsConfiguration()
          : 'https://grafana.com/docs/grafana-cloud/monitor-applications/application-observability/manual/automatic-baseline/'
      }
    >
      {ACCESS_CONTROL.BASELINE.ENABLE ? 'Enable' : 'Learn'}
    </TextLink>
  );
}
