import { SceneQueryRunner } from '@grafana/scenes';

import { ExplorablePanel } from 'components/ExplorablePanel';
import { PROMETHEUS_DS } from 'constants/datasources';
import { DEFAULT_FIELD_CONFIG } from 'constants/panels';

export function makeServiceDotnetCpuTimePanel(): ExplorablePanel {
  return new ExplorablePanel({
    title: 'CPU Time (User)',
    description: 'User processor time for this process, in seconds',

    trackingSection: 'service',
    trackingPage: 'dotnet',
    trackingPanel: 'cpu-time',

    fieldConfig: {
      defaults: {
        ...DEFAULT_FIELD_CONFIG,
        unit: 's',
      },
      overrides: [],
    },

    $data: new SceneQueryRunner({
      datasource: PROMETHEUS_DS,
      maxDataPointsFromWidth: true,
      queries: [
        {
          expr: 'rate({__name__=~"process_cpu_time|process_cpu_time_seconds_total",state="user", job="${job}", instance=~"${instance:regex}"}[$__rate_interval])\nor\nrate({__name__=~"process_cpu_time|process_cpu_time_seconds_total",process_cpu_state="user", job="${job}", instance=~"${instance:regex}"}[$__rate_interval])',
          refId: 'serviceDotnetCpuTime',
          legendFormat: '{{instance}}',
        },
      ],
    }),
  });
}
