import { DataSourceVariable, getUrlSyncManager, VariableValue } from '@grafana/scenes';

import { getDataSourceService } from 'services/DataSourceService';
import { DS_TYPE } from 'types/datasources';
import { trackDatasourceSelect } from 'utils/tracking';

export type SmartDataSourceVariableState = ConstructorParameters<typeof DataSourceVariable>[0];

export class SmartDataSourceVariable extends DataSourceVariable {
  constructor(state: SmartDataSourceVariableState) {
    super(state);

    getUrlSyncManager().initSync(this.getRoot());

    this.addActivationHandler(() => {
      const unsubscribable = this.subscribeToState(({ pluginId, value: newValue }, { value: oldValue }) => {
        if (newValue !== oldValue) {
          trackDatasourceSelect(String(newValue), pluginId as DS_TYPE);
        }
      });

      return () => unsubscribable.unsubscribe();
    });
  }

  validateAndUpdate() {
    let value: VariableValue | undefined = this.state.value;

    // set default value
    if (!value) {
      value = getDataSourceService().getSelectedDataSourceUID(this.state.pluginId as DS_TYPE);
      if (value) {
        this.changeValueTo(value);
      }
    }

    return super.validateAndUpdate();
  }
}
