import { getInstanceService } from './InstanceService';

let defaultHostInfoService: HostInfoService;
const dateToStartBlockingAppO11y = '2024-08-20';

class HostInfoService {
  hasHostData: boolean;
  hasSubmittedDefaultValue: boolean;

  constructor() {
    this.hasSubmittedDefaultValue = false;
    this.hasHostData = true;
  }

  getHasHostData(): boolean {
    return this.hasHostData;
  }

  isHostInfoRequired(): boolean {
    const instanceService = getInstanceService();
    if (!instanceService) {
      return false;
    }

    const createdAt = instanceService.getCreatedAtDate();
    const hostBasedPricingEnabledDate = new Date(dateToStartBlockingAppO11y);
    if (!createdAt || new Date(createdAt) < new Date(hostBasedPricingEnabledDate)) {
      return false;
    }

    return !instanceService.getIsInTrialPeriod();
  }

  shouldBlockApp(): boolean {
    // return this.isHostInfoRequired() && !this.getHasHostData();
    // Will enable blocking in another PR
    return false;
  }
}

export function getHostInfoService(): HostInfoService {
  return defaultHostInfoService;
}

export function initializeHostInfoService(): HostInfoService {
  if (!defaultHostInfoService) {
    const hostInfoService = new HostInfoService();
    defaultHostInfoService = hostInfoService;
  }

  return defaultHostInfoService;
}
